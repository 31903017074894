import React, { useState } from "react";
import axios from "axios";
import { 
    Button, 
    TextField, 
    Card, 
    Typography, 
    Grid, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    CircularProgress } from "@mui/material";
import "./LoanSearchPage.css"



const formatDate = (date) => {
    if (!date) return "-";
    const d = new Date(date);
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  //installmentamount from loanDetails
  const LoanScheduleTable = ({ scheduleData, installmentAmount }) => {
    return (
      <TableContainer>
        <Table bordered>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Installment Amount</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduleData && scheduleData.length > 0 ? (
              scheduleData.map((schedule, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{schedule.Id}</TableCell>
                    <TableCell>{installmentAmount}</TableCell>
                    <TableCell>{formatDate(schedule.ExpectedDueDate)}</TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Amount to Pay</TableCell>
                            <TableCell>Fully Paid</TableCell>
                            <TableCell>Payment Date</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {["Principal", "Interest", "Penalty"].map((type) => {
                            const typeData = {
                              Principal: {
                                isPaid: schedule.IsPrinciplePaid,
                                amountToPay: schedule.PrincipalAmount,
                                paymentDate: schedule.PrinciplePaidDate,
                              },
                              Interest: {
                                isPaid: schedule.IsInterestPaid,
                                amountToPay: schedule.InterestAmount,
                                paymentDate: schedule.InterestPaidDate,
                              },
                              Penalty: {
                                isPaid: schedule.IsPenaltyPaid,
                                amountToPay: schedule.PenaltyAmount,
                                paymentDate: schedule.PenaltyPaidDate,
                              },
                            }[type];
  
                            // Determine the amount to pay: 0 if fully paid, otherwise the respective amount
                            const amountToPay = typeData.isPaid ? 0 : typeData.amountToPay;
  
                            return (
                              <TableRow key={type}>
                                <TableCell>{type}</TableCell>
                                <TableCell>{amountToPay}</TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      color: typeData.isPaid ? "green" : "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {typeData.isPaid ? "True" : "False"}
                                  </Typography>
                                </TableCell>
                                <TableCell>{formatDate(typeData.paymentDate) || "-"}</TableCell>
                                <TableCell>
                                  {typeData.isPaid ? "Cleared" : "Pending"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="4">No loan schedules available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  

const LoanSearchPage = () => {
  const [loanId, setLoanId] = useState("");
  const [loanDetails, setLoanDetails] = useState(null);
  const [loanSchedule, setLoanSchedule] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (!loanId) {
      setError("Please enter a Loan ID.");
      return;
    }

    setError(null);
    setLoading(true);
    const token = localStorage.getItem("token");
    const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
    try {
      const response = await axios.post(
        "https://opobe.mcb.co.ke/opogateway/opoloans",
        {
          query: `
            query AllLoanSchedulesByLoanId($loanId: Int!) {
              allLoanSchedulesByLoanId(parameters: { loanId: $loanId }) {
                results
                error
              }
            }
          `,
          variables: { loanId: parseInt(loanId) },
        },
        {
          headers: {
            Authorization: formattedToken,
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data.data;

      if (data.allLoanSchedulesByLoanId.error) {
        setError(data.allLoanSchedulesByLoanId.error);
        setLoanDetails(null);
        setLoanSchedule([]);
        return;
      }

      const loanData = JSON.parse(data.allLoanSchedulesByLoanId.results);
     console.log("Loan Data:", loanData)
    
      // Assuming the first entry contains the loan details
      const loanDetailsData = loanData.LoanApplications[0];
      const loanScheduleData = loanData.LoanSchedules;

      setLoanDetails(loanDetailsData);
      setLoanSchedule(loanScheduleData);

      if (!loanData || !loanData.LoanApplications || loanData.LoanApplications.length === 0) {
        setError("No data found for the given Loan ID.");
        setLoanDetails(null);
        setLoanSchedule([]);
        return;
      }
    } catch (err) {
      setError("An error occurred while fetching loan data.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Card sx={{ padding: 3, marginTop: 4 }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8}>
        <Typography variant="h4" textAlign="center">Loan Schedule</Typography>
        </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              label="Enter Loan ID"
              value={loanId}
              onChange={(e) => setLoanId(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
              Search
            </Button>
          </Grid>
        </Grid>
        {error && <Typography color="error" sx={{ marginTop: 2 }}>{error}</Typography>}
      </Card>

      {loading && (
        <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
          <CircularProgress />
        </Grid>
      )}

      {loanDetails && (
        <Card sx={{ padding: 3, marginTop: 4 }}>
          <Typography variant="h4">Loan Details</Typography>
          <Grid container spacing={2}  justifyContent="space-evenly" sx={{ marginTop: 4 }}>
          <div>
            <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>LoanID:</strong> {loanDetails.Id}
            </Card>
          </div>
          <div>
            <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Loan Amount:</strong> {loanDetails.LoanAmount}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Principal Amount:</strong> {loanDetails.PrincipalAmount}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Borrow Date:</strong> {formatDate(loanDetails.DateCreated)}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Amount Disbursed:</strong> {loanDetails.AmountToDisburse}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Interest Amount:</strong> {loanDetails.InterestAmount}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Expected Clear Date:</strong> {formatDate(loanDetails.ExpectedDueDate)}
            </Card>
          </div>
          <div>
          <Card sx={{ padding: 3, marginTop: 4 }}>
            <strong>Loan Balance:</strong> {loanDetails.AmountToPay - loanDetails.PrincipalAmount}
            </Card>
          </div>
          </Grid>
        </Card>
      )}

        {/* {error && (
          <Typography color="error" sx={{ marginTop: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )} */}

      {loanSchedule && loanSchedule.length > 0 && (
        <Card sx={{ padding: 3, marginTop: 4 }}>
          <Typography variant="h4">Loan Schedule</Typography>
          <LoanScheduleTable 
          scheduleData={loanSchedule}
          installmentAmount={loanDetails.InstallmentAmount}  
          />
        </Card>
      )}
      
    </div>
    
  );
};

export default LoanSearchPage;
