import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";

const DynamicForm = ({
  fields,
  onSubmit,
  title,
  subtitle,
  initialValues,
  onFieldChange,
}) => {
  const [dependentOptions, setDependentOptions] = useState({});
  const [subdependentOptions, setSubDependentOptions] = useState({});
  const [currentPage, setCurrentPage] = useState(0); // Added for pagination
  const [preview, setPreview] = useState(null);


  const fieldsPerPage = 10; // Number of fields per page
  const totalPages = Math.ceil(fields.length / fieldsPerPage);

  const validateDOB = (value) => {
    const today = new Date();
    const minAllowedDate = new Date();
    minAllowedDate.setFullYear(today.getFullYear() - 18);
  
    if (new Date(value) > minAllowedDate) {
      return "You must be at least 18 years old.";
    }
    return undefined; // No error
  };
  const today = new Date();
const maxDate = new Date();
maxDate.setFullYear(today.getFullYear() - 18);
const maxDateString = maxDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  const validationSchema = yup.object().shape(
    fields.reduce((acc, field) => {
      let schema;
      switch (field.type) {
        case "text":
          schema = yup.string().nullable();
          break;
        case "number":
          schema = yup.number().nullable();
          break;
          case "date":
        schema = yup.date().nullable();
        if (field.name === "dob" || field.name === "dateOfBirth") {
          schema = schema.test("is-18+", validateDOB);
        }
        break;
        case "select":
          schema = yup.string();
          break;
        case "multiselect":
          schema = yup.array().nullable();
          break;
          case "file":
  schema = yup
    .mixed()
    .test(
      "fileSize",
      "File is too large",
      (value) => !value || (value && value.size <= 1024 * 1024 * 5) // Example: 5MB limit
    )
    .test(
      "fileType",
      "Unsupported File Format",
      (value) =>
        !value ||
        (value &&
          ["image/jpeg", "image/png", "image/gif"].includes(value.type))
    );
  break;

        default:
          schema = yup.string().nullable();
      }
      if (field.required) {
        schema = schema.required(`${field.label} is required`);
      }
      acc[field.name] = schema;
      return acc;
    }, {})
  );

  const handleFieldChangeInternal = async (
    setFieldValue,
    field,
    value,
    values
  ) => {
    setFieldValue(field.name, value);

    if (field.parent === "main") {
      const options = await onFieldChange(field, value);
      setDependentOptions(options || []);
    }
    if (field.parent === "dependent") {
      const subOptions = await onFieldChange(field, value);
      setSubDependentOptions(subOptions || []);
    }
  };

  const shouldDisableField = (field, values) => {
    if (field.readOnly) return true;
    if (field.disableCondition) {
      const disableField = fields.find(
        (f) => f.name === field.disableCondition.fieldName
      );
      return values[disableField.name] === field.disableCondition.value;
    }
    return false;
  };

  const getPaginatedFields = () => {
    const startIndex = currentPage * fieldsPerPage;
    return fields.slice(startIndex, startIndex + fieldsPerPage);
  };

  
  

  return (
    <Box m="20px">
      <Header title={title} subtitle={subtitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              {getPaginatedFields().map((field) => {
                const isDependent =
                  field.dependentOn && !values[field.dependentOn];
                const isDisabled = shouldDisableField(field, values);
                return (
                  <Box key={field.name} sx={{ gridColumn: field.gridColumn }}>
                    {field.type === "select" || field.type === "multiselect" ? (
                      <Autocomplete
                        disabled={isDependent || isDisabled}
                        multiple={field.type === "multiselect"}
                        options={
                          field.parent === "dependent"
                            ? dependentOptions || []
                            : field.parent === "subdependent"
                            ? Array.isArray(subdependentOptions)
                              ? subdependentOptions
                              : []
                            : Array.isArray(field.options) ? field.options : []
                        }
                        getOptionLabel={(option) =>
                          option ? option.label : ""
                        }
                        onChange={(event, newValue) => {
                          handleFieldChangeInternal(
                            setFieldValue,
                            field,
                            field.type === "multiselect"
                              ? newValue.map((item) => item.value)
                              : newValue
                              ? newValue.value
                              : "",
                            values
                          );
                        }}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={field.label}
                            variant="filled"
                            name={field.name}
                            error={
                              !!touched[field.name] && !!errors[field.name]
                            }
                            helperText={
                              touched[field.name] && errors[field.name]
                            }
                          />
                        )}
                        value={
                          Array.isArray(field.options)
                            ? field.type === "multiselect"
                              ? field.options.filter(
                                  (option) =>
                                    Array.isArray(values[field.name || '']) &&
                                    values[field.name || '']?.includes(option.value || '')
                                )
                              : field.options.find(
                                  (option) =>
                                    option.value === values[field.name]
                                ) || null
                            : null
                        }
                      />
                    ) : field.type === "checkbox" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values[field.name]}
                            onChange={(event) => {
                              handleFieldChangeInternal(
                                setFieldValue,
                                field,
                                event.target.checked,
                                values
                              );
                            }}
                            name={field.name}
                            disabled={isDisabled}
                          />
                        }
                        label={field.label}
                      />
                    ) : field.type === "file" ? (
                      <Box>
                      <Button
                        variant="contained"
                        component="label"
                        disabled={isDisabled}
                      >
                        {field.label}
                        <input
                          type="file"
                          hidden
                          name={field.name}
                          accept="image/*"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              setFieldValue(field.name, file); // Set the file value
                              setPreview(URL.createObjectURL(file)); // Generate a preview URL
                            }
                          }}
                        />
                      </Button>
                      {values[field.name] && (
                        <Box mt={2}>
                          <img
                            src={preview}
                            alt="Selected"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              padding: "5px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    
                    ) : (
                      <TextField
                        disabled={isDisabled || field.readonly}
                        fullWidth
                        variant="filled"
                        type={field.type}
                        label={field.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[field.name]}
                        name={field.name}
                        error={!!touched[field.name] && !!errors[field.name]}
                        helperText={touched[field.name] && errors[field.name]}
                        inputProps={
                          field.type === "date"
                            ? { max: field.name === "dob" || field.name === "dateOfBirth" 
                              ? maxDateString : undefined }
                            : undefined
                        }
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box display="flex" justifyContent="space-between" mt="20px">
              <Button
                type="button" 
                color="primary"
                variant="contained"
                disabled={currentPage === 0}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </Button>
              {currentPage < totalPages - 1 ? (
                <Button
                type="button"
                color="secondary"
                variant="contained"
                onClick={async (e) => {
                  e.preventDefault(); // Ensure no form submission
                  const currentFields = getPaginatedFields().map((field) => field.name);
                    // Validate the form
                  const validationErrors = await validateForm();
                  // Check for errors in the current page fields
                  const hasErrors = currentFields.some(
                    (fieldName) => validationErrors[fieldName]
                  );
                  // Check for errors in the current page fields
                  //const hasErrors = currentFields.some((fieldName) => errors[fieldName]);
          
                  if (!hasErrors) {
                    setCurrentPage((prev) => prev + 1);
                  } else {
                    // Optional: Focus on the first field with an error
                    const firstErrorField = currentFields.find((fieldName) => errors[fieldName]);
                    if (firstErrorField) {
                      const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
                      errorElement?.focus();
                    }
                  }
                }}
              >
                Next
              </Button>
              ) : (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              )}
            </Box>
            {isSubmitting && <CircularProgress />}
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default DynamicForm;
