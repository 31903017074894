import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  customerservice,
  companyManagementUrl,
  userManagementUrl,
} from "../../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../../data/DynamicServiceNew/jsondynamicservice";

const BulkMigrationPage = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const [searchTerm, setSearchTerm] = useState("");
  const [fieldOfficerData, setFieldOfficerData] = useState([]);
  const [filteredOfficers, setFilteredOfficers] = useState([]);
  const [selectedSearchOfficer, setSelectedSearchOfficer] = useState(null);
  const [officerSelected, setOfficerSelected] = useState("");
  const [dashboardData, setDashboardData] = useState(null);

  // Fetch Field Officer data
  useEffect(() => {
    const fetchFieldOfficers = async () => {
      try {
        const response = await fetchJsonDynamic(
          "allUsersView",
          null,
          null,
          userManagementUrl.uri
        );
        const allOfficers = JSON.parse(response.results) || [];
        const fieldOfficersWithRole5 = allOfficers.filter(
          (userRoleGroup) => userRoleGroup.RoleGroupId === 5
        );
        setFieldOfficerData(fieldOfficersWithRole5);
      } catch (error) {
        console.error("Error fetching Field Officer data:", error);
      }
    };
    fetchFieldOfficers();
  }, []);

  // Filter officers based on search term
  useEffect(() => {
    if (searchTerm.length >= 2) {
      const filtered = fieldOfficerData.filter((officer) =>
        [officer.FirstName, officer.LastName, officer.Username]
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredOfficers(filtered);
    } else {
      setFilteredOfficers([]);
    }
  }, [searchTerm, fieldOfficerData]);

  // Fetch dashboard data for the selected Field Officer
  const fetchDashboardData = async (fieldOfficerId) => {
    try {
      const response = await fetchJsonDynamic(
        "dashboardMetricsById",
        "dashboardMetrics",
        { fieldofficerid: fieldOfficerId },
        companyManagementUrl.uri
      );
      if (response?.results) {
        const data = JSON.parse(response.results)[0];
        setDashboardData(data || null);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // When a Field Officer is selected in the search dropdown, fetch its dashboard data
  useEffect(() => {
    if (selectedSearchOfficer) {
      fetchDashboardData(selectedSearchOfficer.Id);
    }
  }, [selectedSearchOfficer]);

  // Submit bulk migration
  const handleBulkMigrationSubmit = async () => {
    if (!selectedSearchOfficer || !officerSelected) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please select both source and destination Field Officers.",
      });
      return;
    }

    try {
      const mutationData = {
        poldFoId: selectedSearchOfficer.Id,
        pFoId: officerSelected,
        pdoneBy: userId,
      };

      const response = await executeAnyMutation(
        "executeBulkCustomerFoMigration",
        mutationData,
        "inputModel",
        false,
        customerservice.uri
      );

      if (response.results === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data migrated successfully.",
        }).then(() => {
          // Clear all fields
          setSearchTerm("");
          setSelectedSearchOfficer(null);
          setOfficerSelected("");
          setDashboardData(null);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: response.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Field Officer Dashboard</Typography>

      {/* Search Dropdown */}
      <Autocomplete
        options={filteredOfficers}
        getOptionLabel={(option) =>
          `${option.FirstName} ${option.LastName} (${option.Username})`
        }
        value={selectedSearchOfficer}
        onChange={(e, newValue) => setSelectedSearchOfficer(newValue)}
        inputValue={searchTerm}
        onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Field Officer by Name"
            placeholder="Start typing to search..."
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />

      {selectedSearchOfficer && (
        <>
          {/* Dashboard Data */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Field Officer Summary
              </Typography>
              {dashboardData ? (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Total Customers:</strong>{" "}
                      {dashboardData.Total_Customers}
                    </Typography>
                    <Typography>
                      <strong>Outstanding Loan Balance (OLB):</strong>{" "}
                      {dashboardData.Outstanding_Loan_Balance.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>New Customers:</strong>{" "}
                      {dashboardData.New_Customers}
                    </Typography>
                    <Typography>
                      <strong>Loans Due Today:</strong>{" "}
                      {dashboardData.Loans_Due_Today}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography>No dashboard data available.</Typography>
              )}
            </CardContent>
          </Card>

          {/* Destination Field Officer Selection */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Select Destination Field Officer
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Select Field Officer</InputLabel>
                <Select
                  value={officerSelected}
                  onChange={(e) => setOfficerSelected(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Field Officer
                  </MenuItem>
                  {fieldOfficerData.map((officer) => (
                    <MenuItem key={officer.Id} value={officer.Id}>
                      {officer.FirstName} {officer.LastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleBulkMigrationSubmit}
          >
            Submit for Field Officer Migration
          </Button>
        </>
      )}
    </Box>
  );
};

export default BulkMigrationPage;
