import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { List, ListItem, ListItemText } from "@mui/material";
import {
  caseManagementUrl,
  documentManagementUrl,
  emailService,
  salesManagement,
  smsservice,
  uploadCaseForClientService,
  uploadCaseService,
  uploadCorporateClients,
  uploadIndividualClients,
} from "../../config";
import {
  generateAndExecuteMutation,
  fetchDataEngine,
  graphqlQuery,
  generateAndExecuteBulkMutation,
} from "../Axios/DynamicService";
import { Formik, Field } from "formik";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { CheckBox } from "@mui/icons-material";
import { allCaseStatus } from "../Axios/queries";
import {
  getDocumentAccessPermissions,
  getDocumentAccessPermissionsById,
} from "../Axios/Non-DynamicServices";
import { MenuItem } from "@mui/material";
import { uploadReconFile } from "./DynamicFunctions";

export function CommunicationComponent(props) {
  const { communicationType } = props; // email or sms
  const base_url = emailService.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const validationSchema = yup.object().shape({
    subjectTitle:
      communicationType === "email"
        ? yup.string().required("Subject is required")
        : yup.string(),
    recipientEmail:
      communicationType === "email"
        ? yup
            .string()
            .email("Invalid email format")
            .required("Recipient email is required")
        : yup.string(),
    recipientNumber:
      communicationType === "sms"
        ? yup
            .string()
            .matches(/^[0-9]+$/, "Phone number must be digits only")
            .required("Recipient number is required")
        : yup.string(),
    body: yup.string().required("Body is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData =
        communicationType === "email"
          ? {
              subjectTitle: values.subjectTitle,
              recipientEmail: values.recipientEmail,
              body: values.body,
              messageId: 1,
            }
          : {
              phoneNumber: values.recipientNumber,
              body: values.body,
              messageId: 1,
            };
      const response = await generateAndExecuteMutation(
        communicationType === "email" ? "createEmailMessage" : "createMessage",
        mutationData,
        communicationType === "email" ? "email" : "smsmessage",
        communicationType === "email" ? base_url : smsservice.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        //props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${
            communicationType.charAt(0).toUpperCase() +
            communicationType.slice(1)
          } has been sent successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          {communicationType === "email" ? "Send Email" : "Send SMS"}
        </DialogTitle>
        <Formik
          initialValues={{
            subjectTitle: "",
            recipientEmail: "",
            recipientNumber: "",
            body: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Fill in the details to send a {communicationType}.
                </DialogContentText>
                {communicationType === "email" && (
                  <>
                    <TextField
                      fullWidth
                      label="Subject Title"
                      name="subjectTitle"
                      variant="filled"
                      value={values.subjectTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.subjectTitle && !!errors.subjectTitle}
                      helperText={touched.subjectTitle && errors.subjectTitle}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Recipient Email"
                      name="recipientEmail"
                      variant="filled"
                      value={values.recipientEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.recipientEmail && !!errors.recipientEmail}
                      helperText={
                        touched.recipientEmail && errors.recipientEmail
                      }
                      margin="normal"
                    />
                  </>
                )}
                {communicationType === "sms" && (
                  <TextField
                    fullWidth
                    label="Recipient Number"
                    name="recipientNumber"
                    variant="filled"
                    value={values.recipientNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.recipientNumber && !!errors.recipientNumber}
                    helperText={
                      touched.recipientNumber && errors.recipientNumber
                    }
                    margin="normal"
                  />
                )}
                <TextField
                  fullWidth
                  label="Body"
                  name="body"
                  variant="filled"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.body && !!errors.body}
                  helperText={touched.body && errors.body}
                  margin="normal"
                  multiline
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting
                    ? "Sending..."
                    : `Send ${
                        communicationType.charAt(0).toUpperCase() +
                        communicationType.slice(1)
                      }`}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export function DocumentAccessModal(props) {
  const [documentAccess, setDocumentAccess] = React.useState([]);
  const [documentById, setDocumentById] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const documentUrl = documentManagementUrl.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cleanJsonString = (jsonString) => {
    const parsedData = JSON.parse(jsonString);
    const cleanUsers = parsedData.users.map((user) => ({
      ...user,
      permissions: JSON.parse(user.permissions),
    }));
    return { ...parsedData, users: cleanUsers };
  };
  const cleanJson = (jsonString) => {
    const parsedData = JSON.parse(jsonString);
    const cleanUsers = parsedData.Users.map((user) => ({
      ...user,
      permissions: JSON.parse(user.permissions),
    }));
    return { ...parsedData, users: cleanUsers };
  };
  //  useEffect(() => {
  //    async function fetchAllData() {
  //      try {
  //        const returnFields = ["id", "processWorkFlow"];
  //        const inputFields = { id: caseId };
  //        const Facts = await fetchDataEngine(
  //          "userById",
  //          null,
  //          inputFields,
  //          returnFields,
  //          base_url
  //        );
  //        setCaseData(Facts);
  //      } catch (error) {
  //        console.error("Error fetching data:", error);
  //      } finally {
  //        setLoading(false);
  //      }
  //    }
  //    fetchAllData();
  //  }, []);
  const fetchDocumentData = async () => {
    const documentId = props.data.id;
    try {
      const data = await getDocumentAccessPermissions(documentId, documentUrl);
      const cleanedData = cleanJson(data);
      setDocumentAccess(cleanedData.users);

      const dataById = await getDocumentAccessPermissionsById(
        documentId,
        documentUrl
      );
      const cleanedDataById = cleanJsonString(dataById);
      setDocumentById(cleanedDataById.users);
    } catch (error) {
      console.error("Error fetching document data:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocumentData();
  }, []);

  const validationSchema = yup.object().shape({
    permissions: yup.object().required("Permissions are required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = Object.keys(values.permissions).map((userId) => ({
        documentId: props.data.id,
        userId: parseInt(userId),
        readRight: values.permissions[userId].ReadRight,
        downloadRight: values.permissions[userId].DownloadRight,
        editRight: values.permissions[userId].EditRight,
        deleteRight: values.permissions[userId].DeleteRight,
        companyId: 6,
        active: false,
        company: null,
      }));

      const response = await generateAndExecuteBulkMutation(
        "createAccessBulk",
        "accessList",
        mutationData,

        documentManagementUrl.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Document rights have been assigned successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  const initialValues = {
    permissions: documentAccess.reduce((acc, user) => {
      const userDoc = documentById.find((doc) => doc.userId === user.userId);
      acc[user.userId] = userDoc
        ? userDoc.permissions
        : {
            ReadRight: false,
            DownloadRight: false,
            EditRight: false,
            DeleteRight: false,
          };
      return acc;
    }, {}),
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          Assign Permissions
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Assign Privileges to team members
                </DialogContentText>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "fit-content",
                  }}
                >
                  {documentAccess.map((user) => (
                    <div key={user.userId}>
                      <Typography color={colors.greenAccent[400]}>
                        {user.UserName}
                      </Typography>
                      {[
                        "ReadRight",
                        "EditRight",
                        "DeleteRight",
                        "DownloadRight",
                      ].map((right) => (
                        <FormControlLabel
                          key={`${user.userId}-${right}`} // Unique key for each checkbox
                          control={
                            <Checkbox
                              checked={
                                values.permissions[user.userId]
                                  ? values.permissions[user.userId][right]
                                  : false
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  `permissions.${user.userId}.${right}`,
                                  event.target.checked
                                );
                              }}
                              name={`permissions.${user.userId}.${right}`}
                              color="primary"
                            />
                          }
                          label={`Can ${right.replace("Right", "")}`}
                        />
                      ))}
                    </div>
                  ))}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Asloging..." : "Assign Access"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
export function MarkPaid(props) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const base_url = salesManagement.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const validationSchema = yup.object().shape({
    amountPaid: yup.number().required("Amount Paid is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = {
        orderId: props.data.id,

        amountPaid: parseInt(values.amountPaid),
      };

      const response = await generateAndExecuteMutation(
        "markPurchasePaid",
        mutationData,
        "purchase",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Invoice marked as paid successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>Mark Paid</DialogTitle>
        <Formik
          initialValues={{ amountPaid: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Enter Amount Paid
                </DialogContentText>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amountPaid"
                  error={touched.amountPaid && !!errors.amountPaid}
                  helperText={touched.amountPaid && errors.amountPaid}
                  sx={{ gridColumn: "span 2" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Marking Paid..." : "Mark Paid"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export function UploadFileComponent(props) {
  const { uploadType, open, onClose } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const validationSchema = yup.object().shape({
    document: yup.mixed().required('Document is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let response;
      response = await uploadReconFile(values.document)
     

      if (response && response.status === 200) {
        props.onClose();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Document has been uploaded successfully.',
        });
        setUploadedDocuments([...uploadedDocuments, values.document]);
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while processing your request. Please try again later.',
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ color: colors.greenAccent[400] }}>
          Upload File
        </DialogTitle>
        <Formik
          initialValues={{
            document: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  fullWidth
                  type="file"
                  label="Document"
                  name="document"
                  onChange={(event) =>
                    setFieldValue("document", event.currentTarget.files[0])
                  }
                  onBlur={handleBlur}
                  error={touched.document && !!errors.document}
                  helperText={touched.document && errors.document}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <List>
                  {uploadedDocuments.map((doc, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={doc.name} />
                    </ListItem>
                  ))}
                </List>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Uploading..." : "Upload"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
