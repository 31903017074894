import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import { roleManagemenUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import ProcessAuthorizationForm from "./form";

const base_url = roleManagemenUrl.uri;

const ProcessAuthorizationTable = () => {

  const navigate = useNavigate();

  const actions = {
    entity: "Rights",
    isSubright: true,
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: [],
      objectName: "processAuthorizationViewByColumnValues",
      parameters: { },
      inputObjectName: "processAuthorizationView",
    },
  };
  
  return (
    <Box m="20px">
      <Box>
      <Button onClick={ () => navigate(-1)} variant="contained">
            Back
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Process Authorization"
        subtitle="Manage Process Authorization"
        FormComponent={ProcessAuthorizationForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default ProcessAuthorizationTable;
