import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Dialog,
  TextField,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice } from "../../../config";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const LoanLimitDialog = ({ open, onClose, Row }) => {
  const [newLoanLimit, setNewLoanLimit] = useState(""); // State for loan limit input
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

 const handleSetLoanLimit = async () => {
   if (!newLoanLimit || isNaN(newLoanLimit)) {
     Swal.fire({
       icon: "warning",
       title: "Invalid Input",
       text: "Please enter a valid loan limit amount.",
     });
     return;
   }

   try {
     // Temporarily close the dialog to allow the SweetAlert2 modal to show
     onClose();

     const confirmation = await Swal.fire({
       title: "Are you sure?",
       text: `Do you want to update the loan limit to ${newLoanLimit}?`,
       icon: "warning",
       showCancelButton: true,
       confirmButtonText: "Yes, update it!",
       cancelButtonText: "No, cancel",
     });

     if (confirmation.isConfirmed) {
       const mutationData = {
         customerId: Row.Id,
         companyId: Row.CompanyId,
         initiatorId: userId,
         newLoanLimit: parseFloat(newLoanLimit), // Convert to number
         action: 0,
       };

       const response = await executeAnyMutation(
         "updateCustomerLoanLimit",
         mutationData,
         "param",
         false,
         customerservice.uri
       );

       if (response.results) {
         Swal.fire({
           icon: "success",
           title: "Success!",
           text: `Loan limit updated successfully.`,
         });
       } else if (response.error) {
         throw new Error(response.error);
       }
     } else {
       // Reopen the dialog if the user cancels
       onClose(false); // Ensure the parent reopens the dialog
     }
   } catch (error) {
     Swal.fire({
       icon: "error",
       title: "Error!",
       text: "An error occurred while processing your request. Please try again later.",
     });
   }
 };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      disableEnforceFocus
      fullWidth={true}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
          maxWidth: isSmallScreen ? "100vw" : "80vw",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Finance Manager Decision
          <Divider
            sx={{ borderBottomWidth: 2, border: "1px solid #003049" }}
            orientation="horizontal"
          />
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledCard variant="outlined">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Initiate Loan</strong>
              </Typography>
              <Typography>
                Initiated By:{" "}
                <strong>
                  {Row.initiatorFirstName} {Row.initiatorLastName}
                </strong>
              </Typography>
              <Typography>
                For Customer: <strong>{Row.customerName}</strong>
              </Typography>
            </StyledCard>

            {/* Input field for new loan limit */}
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Enter New Loan Limit"
                fullWidth
                variant="outlined"
                value={newLoanLimit}
                onChange={(e) => setNewLoanLimit(e.target.value)}
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.01, // Optional for decimal inputs
                }}
              />
            </Box>

            {/* Submit Button */}
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSetLoanLimit}
                fullWidth
              >
                Set Loan Limit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
