// UserActions.js
import React from "react";
import { Box, IconButton, Typography, MenuItem, Select } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { CheckCircleOutline, LockResetRounded } from "@mui/icons-material";
import usePermissions from "./PermissionsHook";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { Button } from "react-bootstrap";

const nonPermissionActions = {
  resetpassword: true,
};

export const DynamicActions = ({
  id,
  isActive,
  entity,
  onEdit,
  onActivate,
  resetPassword,
}) => {
  const { hasPermission } = usePermissions();

  const handleActionSelect = (event) => {
    const action = event.target.value;

    switch (action) {
      case "edit":
        onEdit(id);
        break;
      case "activate":
        onActivate({
          variables: {
            id,
          },
        });
        break;
      case "resetPassword":
        resetPassword({
          variables: {
            id,
          },
        });
        break;
      default:
        break;
    }
  };

  const buttonText = isActive ? "Deactivate" : "Activate";
  const buttonIcon = isActive ? <BlockIcon /> : <CheckCircleOutline />;

  return (
    <Box>
      <Select value="" onChange={handleActionSelect} displayEmpty>
        <MenuItem value="" disabled>
          Actions
        </MenuItem>
        {hasPermission(entity, "EditPermission") && (
          <MenuItem value="edit">
            <IconButton>
              {" "}
              <EditIcon />
            </IconButton>

            <Typography>Edit</Typography>
          </MenuItem>
        )}
        {hasPermission(entity, "ActivatePermission") && (
          <MenuItem value="activate">
            <IconButton>{buttonIcon}</IconButton>
            <Typography>{buttonText}</Typography>
          </MenuItem>
        )}
        {entity === "Users" && (
          <MenuItem value="resetPassword">
            <IconButton>
              <LockResetRounded />
            </IconButton>
            <Typography variant="body1">Reset Password</Typography>
          </MenuItem>
        )}
      </Select>
    </Box>
  );
};
export const AddUserButton = ({ onClick, entity }) => {
  const { hasPermission } = usePermissions();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" justifyContent="flex-end" mt={1}>
      {hasPermission(entity, "CreatePermission") && (
        <Button
          variant="contained"
          style={{
            backgroundColor: colors.greenAccent[500],
            borderRadius: "4px",
          }}
          onClick={onClick}
        >
          <Typography style={{ color: colors.primary[400], margin: "0 8px" }}>
            Add {entity}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
