import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { tokens } from "../../theme";
import { Avatar } from "@mui/material";
import { fetchDataEngineCustomized } from "../../data/Axios/DynamicService";
import { userManagementUrl } from "../../config";
import ProfileCover from "../../Assets/Images/shillings.jpg";
import ProfileImage from "../../Assets/Images/money.jpg";

const base_url = userManagementUrl.uri;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} sx={{ backgroundColor: colors.primary[400] }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function PersonDetails() {
  const [tabIndex, setTabIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    // Add your logic to handle password change here
    console.log("Password changed");
  };

  const getTabStyle = (isSelected) => ({
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
    margin: "0.3rem",
    color: isSelected
      ? theme.palette.mode === "dark"
        ? colors.primary[900]
        : theme.palette.getContrastText(colors.primary[400])
      : "inherit",
    backgroundColor: isSelected ? colors.greenAccent[400] : "inherit",
    "&.Mui-selected": {
      color:
        theme.palette.mode === "dark"
          ? colors.primary[400]
          : colors.primary[900],
    },
    paddingLeft: "1rem",
    paddingRight: "1rem",
  });

  return (
    <Box m="20px">
      <Typography variant="h4" gutterBottom>
        Personal Details
      </Typography>
      <Box sx={{ position: "relative", mb: 2 }}>
        <img
          src={ProfileCover}
          alt="Your Alt Text"
          style={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
        <Avatar
          alt="Remy Sharp"
          src={ProfileImage}
          sx={{
            position: "absolute",
            bottom: 0,
            left: -4,
            width: 100,
            height: 100,
            margin: "4px",
          }}
        />
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="person details tabs"
        sx={{ borderBottom: 1, borderColor: "divider", p: 1 }}
      >
        <Tab label="About" style={getTabStyle(tabIndex === 0)} sx={{ p: 1 }} />
        <Tab label="Change Password" style={getTabStyle(tabIndex === 1)} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Typography
          variant="h6"
          style={{ backgroundColor: colors.primary[400] }}
        >
          About
        </Typography>
        <Typography>
          Name: {decodedToken.FirstName} {decodedToken.LastName}
        </Typography>
        <Typography>Username: {decodedToken.Username}</Typography>
        <Typography>Email: {decodedToken.email}</Typography>
        <Typography>Phone: {decodedToken.Phone}</Typography>
        <Typography>Company Name: {decodedToken.Company}</Typography>
        <Typography>Role: {decodedToken.RoleName}</Typography>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Typography
          variant="h6"
          style={{ paddingLeft: "4px", backgroundColor: colors.primary[400] }}
        >
          Change Password
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          onSubmit={handleChangePassword}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Old Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: colors.greenAccent[400],
              color: theme.palette.getContrastText(colors.greenAccent[400]),
              alignSelf: "center",
            }}
          >
            Change Password
          </Button>
        </Box>
      </TabPanel>
    </Box>
  );
}

export default PersonDetails;
