import React, { useState }  from "react";
import loanOrigination from "./../Assets/loan_origination.jpeg";
import Underwriting from "./../Assets/underwriting.jpeg";
import creditRisManagemt from "./../Assets/credit_risk_management.jpg";
import debtCollection from "./../Assets/debtCollection.jpeg";
import consumerLending from "./../Assets/Consumer-lending.png";
import comercialLending from "./../Assets/comercial-lending.jpeg";

function Analytics() {
  const [activeTab, setActiveTab] = useState("Loan Origination");

  const tabs = [
    "Loan Origination",
    "Underwriting",
    "Credit Risk Management",
    "Debt Collection",
    "Consumer Lending",
    "Commercial Lending",
  ];

  const content = {
    "Loan Origination": {
      title: "Streamlined Loan Origination",
      description:
        "Automate your loan origination process with ease. From application to approval, manage everything efficiently.",
      image:loanOrigination, // Placeholder image path
    },
    Underwriting: {
      title: "Efficient Underwriting",
      description:
        "Enhance your underwriting process with automated decision-making tools that assess risk and compliance.",
      image: Underwriting, // Placeholder image path
    },
    "Credit Risk Management": {
      title: "Comprehensive Credit Risk Management",
      description:
        "Mitigate risks with advanced credit scoring models and real-time analytics to protect your investments.",
      image: creditRisManagemt, // Placeholder image path
    },
    "Debt Collection": {
      title: "Automated Debt Collection",
      description:
        "Manage and recover outstanding debts efficiently with our automated debt collection tools.",
      image: debtCollection, // Placeholder image path
    },
    "Consumer Lending": {
      title: "Seamless Consumer Lending",
      description:
        "Provide customers with a seamless lending experience, from application to disbursement, tailored to their needs.",
      image: consumerLending, // Placeholder image path
    },
    "Commercial Lending": {
      title: "Robust Commercial Lending",
      description:
        "Support your business clients with flexible and robust commercial lending solutions.",
      image: comercialLending, // Placeholder image path
    },
  };

  return (
    <div className="bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white py-12 pt-8">
      <div className="max-w-[1240px] mx-auto px-6 md:px-12 lg:px-24 text-center">
        <h2 className="text-4xl font-bold mb-8">ANY KIND OF LENDING PROCESS</h2>
        <div className="flex justify-center mb-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-3 py-2 mx-2 rounded-full font-semibold ${
                activeTab === tab
                  ? "bg-[#00df9a] text-black"
                  : "bg-gray-700 text-white hover:bg-gray-600"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 text-left">
            <h3 className="text-3xl font-bold mb-4">{content[activeTab].title}</h3>
            <p className="text-lg text-gray-300">{content[activeTab].description}</p>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8">
            <img
              src={content[activeTab].image}
              alt={content[activeTab].title}
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export default Analytics;