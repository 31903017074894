import React from "react";
import { Box } from "@mui/material";
import { loanManagementUrl } from "../../../config";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = loanManagementUrl.uri;

const SababishaApprovalTable = () => {
  const isApproved = { approved: 0 };
  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    approveSababisha: {
      key: "APPROVESABABISHA",
      button_name: "Approve Sababisha",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "param", // Specify the model name here
      inputObjectName: "sababishaFunction",
      flag: 1,
    },
    dataObject: {
      ignoreFields: ["CreateDate", "UpdateDate", "IsActive"],
      objectName: "sababishaViewByColumnValues",
      parameters: isApproved,
      inputObjectName: "sababishaView",
    },
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Approve Disbursment"
        subtitle="Sababisha"
        // FormComponent={SuspendedTransactionForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default SababishaApprovalTable;
