import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../data/Axios/DynamicForm";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";

import { companyManagementUrl, setupManagementUrl } from "../../../config";
import { companyFields } from "../../../data/DynamicTable/CompanyManagementForms";
import { allSubscriptionPlans } from "../../../data/Axios/queries";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = companyManagementUrl.uri;
const setup_url = setupManagementUrl.uri;
const CompanyForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const subscriptionsDataResponse = await fetchJsonDynamic(
          "allSubscriptionPlans",
          null,
          null,
          setup_url
        );
        if (subscriptionsDataResponse?.results) {
          const results = JSON.parse(subscriptionsDataResponse?.results);
          setSubscriptionsData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);
  const subscriptionsOptions = subscriptionsData.map((subscriptions) => ({
    value: subscriptions.Id,
    label: subscriptions.PlanName,
  }));
  const fields = [
    ...companyFields,
    {
      name: "subscriptionId",
      label: "Subscription Plan",
      type: "select",
      options: subscriptionsOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];
  const initialValues = {
    companyName: props.data ? props.data.CompanyName : "",
    contactPhoneNumber: props.data ? props.data.ContactPhoneNumber : "",
    parentOffice: props.data ? props.data.ParentOffice : "",
    contactPerson: props.data ? props.data.ContactPerson : "",
    postalAddress: props.data ? props.data.PostalAddress : "",
    senderId: props.data ? props.data.SenderId : "",
    apiKey: props.data ? props.data.ApiKey : "",
    fsApiKey: props.data ? props.data.FsApiKey : "",
    subscriptionId: props.data ? props.data.SubscriptionId : "",
    createdBy: props.data ? props.data.CreatedBy : userId,
    createdDate: props.data ? props.data.CreatedDate : new Date().toISOString(),
    updatedBy: userId,
    updatedDate: new Date().toISOString(),
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { 
        companyName, 
        subscriptionId,
        contactPhoneNumber,
        parentOffice,
        contactPerson,
        postalAddress,
        senderId,
        apiKey,
        fsApiKey,
       } = values;

       const formattedPhoneNumber = contactPhoneNumber?.toString() || "";

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            companyName,
            subscriptionId,
            contactPhoneNumber: formattedPhoneNumber,
            parentOffice,
            contactPerson,
            senderId,
            apiKey,
            fsApiKey,
            postalAddress,
            updatedBy: userId,
          }
        : {
            companyName,
            contactPhoneNumber: formattedPhoneNumber,
            parentOffice,
            contactPerson,
            postalAddress,
            senderId,
            apiKey,
            fsApiKey,
            subscriptionId,
            createdBy: userId,
          };

      const response = await executeAnyMutation(
        props.isEditing ? "updateCompany" : "createCompany",
        mutationData,
        props.isEditing ? "user" : "company",
        false,
        base_url
      );

      if (Object.values(response.results)) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Company ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Company" : "Create Company"}
      subtitle={
        props.isEditing ? "Edit an Existing Company" : "Create a New Company"
      }
    />
  );
};

export default CompanyForm;
