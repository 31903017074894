import { format } from "date-fns";
import { Button } from '@mui/material';

export const AccountCategoriesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "status", headerName: "Status", flex: 1 },
  { field: "categoryName", headerName: "Category Name", flex: 1 },
  { field: "currency", headerName: "Currency", flex: 1 },
  { field: "rate", headerName: "Rate", flex: 1 },
  //   { field: "parentAccount", headerName: "Parent Account", flex: 1 },
  //   { field: "oldParent", headerName: "Old Parent", flex: 1 },
];
export const AccountSubCategoriesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "accountCategory", headerName: "Account Category", flex: 1 },

  { field: "accountName", headerName: "Account Name", flex: 1 },
  { field: "accountNumber", headerName: "Account Number", flex: 1 },
  { field: "accountType", headerName: "Account Type", flex: 1 },

  { field: "currency", headerName: "Currency", flex: 1 },

  { field: "rate", headerName: "Rate", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
];
export const AccountsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },

  { field: "accountName", headerName: "Account Name", flex: 1 },
  { field: "accountNumber", headerName: "Account Number", flex: 1 },
  { field: "accountType", headerName: "Account Type", flex: 1 },

  { field: "currency", headerName: "Currency", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
];
// AccountsColumns.js

export const getSuspendedTransactionsColumns = (handleReconcileAction, ignoreFields = ["isActive","Status"]) => {
  const columns = [
  { field: 'firstName', headerName: 'First Name', width: 100 },
  { field: 'transactionType', headerName: 'Transaction Type', width: 100 },
  { field: 'transAmount', headerName: 'Amount', width: 70 },
  { field: 'billRefNumber', headerName: 'billRefNumber', width: 100 },
  { field: 'isReconcilled', headerName: 'Reconciled', width: 70 },
  {
    field: 'reconcile',
    headerName: 'Reconcile',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleReconcileAction(params.row)} // Pass row data
      >
        Reconcile
      </Button>
    ),
  },
];
return columns.filter(column => !ignoreFields.includes(column.field));
};
;
export const PaymentTypesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "paymentType", headerName: "Payment Type", flex: 1 },
];
export const BanksColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "bankName", headerName: "Bank Name", flex: 1 },
  { field: "bankSwiftCode", headerName: "Bank Swift Code", flex: 1.2 },
  { field: "finCode", headerName: "Financial Code", flex: 1.1 },
  { field: "mobile", headerName: "Mobile", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
];
export const BankBranchesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "bankId", headerName: "Bank ID", flex: 1 },
  { field: "bankName", headerName: "Bank Name", flex: 1 },
  { field: "branchCode", headerName: "Branch Code", flex: 1 },
  { field: "branchName", headerName: "Branch Name", flex: 1 },
  { field: "branchSwiftCode", headerName: "Branch Swift Code", flex: 1 },
];
export const allBankBranchesViewColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  // { field: "bankId", headerName: "Bank ID", flex: 1 },
  { field: "bankName", headerName: "Bank Name", flex: 1 },
  { field: "branchCode", headerName: "Branch Code", flex: 1 },
  { field: "branchName", headerName: "Branch Name", flex: 1 },
  { field: "branchSwiftCode", headerName: "Branch Swift Code", flex: 1.2 },
];
export const BankAccountsColumns = [
  { field: "id", headerName: "ID", flex: 0.2 },
  { field: "bankName", headerName: "Bank Name", flex: 1.5 },
  { field: "branchName", headerName: "Branch Name", flex: 1.5 },
  { field: "accountName", headerName: "Account Name", flex: 1.5 },
  { field: "accountNumber", headerName: "Account No.", flex: 1.5 },
  { field: "accountDescription", headerName: "Account Desc", flex: 1.5 },
  // { field: "status", headerName: "Status", flex: 1 },
];
export const FiscalYearsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "fiscalYearName", headerName: "Fiscal Year", flex: 1 },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    renderCell: (params) => {
      const formattedDate = format(new Date(params.value), "MM/dd/yyyy");
      return formattedDate;
    },
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    renderCell: (params) => {
      const formattedDate = format(new Date(params.value), "MM/dd/yyyy");
      return formattedDate;
    },
  },
  { field: "status", headerName: "Status", flex: 1 },
];
export const TaxAuthoritiesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "taxAuthorityName", headerName: "Tax Authority Name", flex: 1 },
  { field: "jurisdiction", headerName: "Jurisdiction", flex: 1 },
  // { field: "countryId", headerName: "Country ID", flex: 1 },
  { field: "country", headerName: "Country", flex: 1 },
];

export const TaxRatesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "rate", headerName: "Rate", flex: 1 },
  // { field: "taxAuthorityID", headerName: "Tax Authority ID", flex: 1 },
  // { field: "countryId", headerName: "Country ID", flex: 1 },
  { field: "country", headerName: "Country", flex: 1 },
];
