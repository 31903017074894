import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme, useMediaQuery, Select, MenuItem,Typography } from "@mui/material";

import * as componentsMap from "../../scenes/Component/HorizontalTabComponents";
import { tokens } from "../../theme";
import { AssistWalkerOutlined, BusinessSharp, Dashboard, MonetizationOnOutlined, PeopleOutline, Person } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: "1rem",
            bgcolor: `${colors.primary[400]}`,
            minHeight: "80vh",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DynamicHorizontalTabs = ({ rightId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [subRightComponents, setSubRightComponents] = useState([]);
  const rights = JSON.parse(localStorage.getItem("rights"));
  useEffect(() => {
    if (rights) {
      try {
        const cleanedData = rights
          ? JSON.parse(rights.replace(/\\/g, ""))
          : null;

        const importedComponents = cleanedData
          .filter((child) =>
            child.children?.some(
              (subChild) => subChild.id === parseInt(rightId, 10)
            )
          )
          .flatMap(
            (child) =>
              child.children
                .find((subChild) => subChild.id === parseInt(rightId, 10))
                ?.subrights?.map((subRight) => {
                    const normalizedTitle = subRight.title.toLowerCase().replace(/\s/g, "");
                    // Find a component key that exactly matches the normalized title
                    const componentKey = Object.keys(componentsMap).find((key) =>
                      key.toLowerCase() === normalizedTitle
                    );
                  const component = componentsMap[componentKey];
                  return {
                    label: subRight.title,
                    content: component ? React.createElement(component) : null,
                    icon: getIconByName(subRight.title),
                  };
                }) || []
          );

        setSubRightComponents(importedComponents);
      } catch (error) {
        console.error("Failed to parse rights JSON:", error);
      }
    }
  }, [rights, rightId, componentsMap]);

  const getIconByName = (componentName) => {
    switch (componentName) {
      case "Customer Dashboard":
        return <   Dashboard />;
      case "Next Of Kin":
        return <PeopleOutline />;
      case "Guarantor":
        return <AssistWalkerOutlined />;
      case "Businnes Details":
        return <BusinessSharp />;
      case "BioData":
        return <Person />;
      case "LoanData":
        return <MonetizationOnOutlined />;     
      default:
        return null;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    handleChange(event, event.target.value);
  };

  const getTabStyle = (isSelected) => ({
    mx: 0.5,
    color: theme.palette.mode === "dark" ? "white" : "black",
    bgcolor: isSelected
      ? theme.palette.mode === "light"
        ? colors.greenAccent[300]
        : colors.greenAccent[700]
      : "inherit",
    "&.Mui-selected": {
      color: theme.palette.mode === "dark" ? "white" : colors.primary[900],
    },
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
  });



  return (
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2,
        bgcolor: `${colors.primary[400]}`,
        height: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      {isSmallScreen ? (
        <Select
          value={value}
          onChange={handleSelectChange}
          fullWidth
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ bgcolor: `${colors.primary[400]}` }}
        >
          {subRightComponents.map((tab, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ bgcolor: `${colors.primary[400]}` }}
            >
              {tab.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ p: 1, height: "100px" }}
          //   aria-label={title || "Tabs"}
        >
          {subRightComponents.map((tab, index) => (
            <Tab
              key={index}
              sx={getTabStyle(value === index)}
              icon={tab.icon}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      )}
      {subRightComponents.map((tab, index) => (
        <CustomTabPanel
          key={index}
          value={value}
          index={index}
          // className="w-100"
        >
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};



export default DynamicHorizontalTabs;
