import axios from "axios";
import { smsservice, customerservice } from "../../../../config";

const endpoint = smsservice.uri;
const endpoint2 = customerservice.uri;

export const fetchCustomers = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const FETCH_CUSTOMERS = `
    query AllCustomers {
      allCustomers {
        results
        error
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint2,
      {
        query: FETCH_CUSTOMERS,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data.data.allCustomers;

    // Handle any server-reported errors
    if (data.error) {
      console.error("Error fetching customers:", data.error);
      return [];
    }

    // Parse `results` if it's a JSON string
    const customers = JSON.parse(data.results);

    if (!Array.isArray(customers)) {
      throw new Error("Unexpected format for customer data");
    }

    return customers; // Return the parsed customer list
  } catch (error) {
    console.error("Error fetching customers:", error.message || error);
    return [];
  }
};

export const fetchCustomersByStatus = async (status) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  if (!status) {
    return [];
  }

  // Define query templates
  let FETCH_CUSTOMERS = "";
  if (status.length > 0) {
    // Build the dynamic statuses query
    const statusesQuery = status
      .map((s) => `${s}:1`) // Map each status to a string representation
      .join("\n "); // Join them with a comma

    // Construct the query string
    FETCH_CUSTOMERS = `
    query CustomersDynamic {
      customersDynamic(
        customer: {
          ${statusesQuery}
        }
      ) {
        results
        error
      }
    }
  `;
  } else {
    FETCH_CUSTOMERS = `
      query CustomersDynamic {
        customersDynamic(
          customer: {
            companyId: null
          }
        ) {
          results
          error
        }
      }
    `;
  }

  try {
    const response = await axios.post(
      endpoint2,
      {
        query: FETCH_CUSTOMERS,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const data =
      status === "allcustomers"
        ? response.data.data.allCustomers
        : response.data.data.customersDynamic;

    // Handle any server-reported errors
    if (data.error) {
      console.error("Error fetching customers:", data.error);
      return [];
    }

    // Parse `results` if it's a JSON string
    const customers =
      typeof data.results === "string"
        ? JSON.parse(data.results)
        : data.results;

    if (!Array.isArray(customers)) {
      throw new Error("Unexpected format for customer data");
    }

    return customers; // Return the parsed customer list
  } catch (error) {
    console.error("Error fetching customers:", error.message || error);
    return [];
  }
};

//#region CustomSMS
export const sendCustomSMS = async (message, checkedCustomers) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlQuery = `
    mutation CreateMessage($smsMessage: SmsMessagesInput!) {
      createMessage(smsmessage: $smsMessage) {
        id
        phoneNumber
        body
        sentDateTime
        status
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        retries
        apiSubcategoryId
        responseId
        failedReason
      }
    }
  `;

  const smsPromises = checkedCustomers.map(async (customer) => {
    const variables = {
      smsMessage: {
        phoneNumber: customer.phoneNumber,
        body: message,
      },
    };

    try {
      const response = await axios.post(
        endpoint,
        {
          query: graphqlQuery,
          variables: variables,
        },
        {
          headers: {
            Authorization: formattedToken,
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;

      if (!result.data || result.errors) {
        throw new Error(
          result.errors ? result.errors[0].message : "Failed to send SMS"
        );
      }

      console.log(
        `SMS sent to ${customer.phoneNumber}:`,
        result.data.createMessage
      );
    } catch (error) {
      console.error(
        `Error sending SMS to ${customer.phoneNumber}:`,
        error.message || error
      );
    }
  });

  await Promise.all(smsPromises);
  alert("Custom SMS batch sent successfully!");
};

export const fetchCustomSMS = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const FETCH_CUSTOM_SMS = `
    query SmSesByCompany {
      smSesByCompany {
        id
        phoneNumber
        body
        sentDateTime
        status
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        retries
        apiSubcategoryId
        responseId
        failedReason
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: FETCH_CUSTOM_SMS,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const messages = response.data.data.smSesByCompany; // Corrected line

    if (!messages) {
      throw new Error("No messages found");
    }

    return messages; // Return the messages directly
  } catch (error) {
    console.error("Error fetching SMS by company:", error.message || error);
    return [];
  }
};

export const createMessage = async (
  message,
  checkedCustomers,
  currentUserId
) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlQuery = `
    mutation CreateMessage($smsMessage: SmsMessagesInput!) {
      createMessage(
        smsmessage: $smsMessage
      ) {
        id
        phoneNumber
        body
        sentDateTime
        status
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        retries
        apiSubcategoryId
        responseId
        failedReason
      }
    }
  `;

  const smsPromises = checkedCustomers.map(async (customer) => {
    const variables = {
      smsMessage: {
        phoneNumber: customer.phoneNumber,
        body: message,
        status: message.status,
        statusFlag: message.functionFlag || null, // Handle null for functionFlag
        createdBy: message.createdBy ? parseInt(message.createdBy) : null,
        companyId: customer.CompanyId || "defaultCompanyId",
      },
    };

    try {
      const response = await axios.post(
        endpoint,
        {
          query: graphqlQuery,
          variables: variables,
        },
        {
          headers: {
            Authorization: formattedToken,
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;

      if (!result.data || result.errors) {
        throw new Error(
          result.errors ? result.errors[0].message : "Failed to send SMS"
        );
      }

      console.log(
        `SMS sent to ${customer.phoneNumber}:`,
        result.data.createMessage
      );
    } catch (error) {
      console.error(
        `Error sending SMS to ${customer.phoneNumber}:`,
        error.message || error
      );
    }
  });

  // Await all promises to resolve
  await Promise.all(smsPromises);
};
/**
 * A function that replaces all the regexs of the custom message
 * @returns
 */

export const handleregex = (message, customer) => {
  try {
    // Validate the customer object and its FirstName property
    if (!customer || !customer.FirstName) {
      throw new Error("Customer object must have a valid FirstName property.");
    }

    // Replace all occurrences of 'firstName' (case-insensitive) with customer.FirstName
    const personalizedMessage = message
      .replace(/firstName/gi, customer.FirstName)
      .replace(/lastName/gi, customer.OtherNames)
      .replace(
        /Company/gi,
        customer.CompanyName ? customer.CompanyName : "MCB tECHNOLOGIES LTD"
      )
      .replace(/cdate/gi, new Date(customer.DateCreated).toLocaleString())
      .replace(/today/gi, new Date().toLocaleString())
      .replace(/loanlimit/gi, customer.LoanLimit ? customer.LoanLimit : 0)
      .replace(/cashsales/gi, customer.AverageCashSales ? customer.AverageCashSales : 0);
    // Return the updated message
    return personalizedMessage;
  } catch (error) {
    console.error("Error in handleregex:", error.message);
    throw error; // Rethrow the error to the caller
  }
};

// Example Usage
(async () => {
  const customer = {
    FirstName: "John",
    MobileNumber: "1234567890",
    CreatedBy: "User1",
    CompanyId: "CompanyA",
  };

  const message = "Hello firstName, we are excited to have you onboard!";

  try {
    const result = await handleregex(message, customer);
    console.log(result); // Output: "Hello John, we are excited to have you onboard!"
  } catch (error) {
    console.error("An error occurred:", error.message);
  }
})();

export const createSMSesBulk = async (messages) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlQuery = `
    mutation CreateSMSesBulk($smsMessages: [SmsmessagesInput!]!) {
      createSMSesBulk(
        smsmessages: $smsMessages
      ) {
        id
        phoneNumber
        body
        sentDateTime
        status
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        retries
        apiSubcategoryId
        responseId
        failedReason
      }
    }
  `;

  // Prepare the messages array for the bulk request
  const smsMessages = messages.map((message) => ({
    phoneNumber: message.MobileNumber,
    body: message.body,
    status: message.status || null, // Handle undefined or null for status
    statusFlag: message.statusFlag || null, // Handle undefined or null for statusFlag
    createdBy: message.createdBy ? parseInt(message.createdBy) : null, // Convert createdBy to integer if it exists
    companyId: message.companyId || null, // Handle undefined or null for companyId
    // apiKey: message.apiKey || null, // Handle undefined or null for apiKey
    apiSubcategoryId: message.apiSubcategoryId || null, // Handle undefined or null for apiSubcategoryId
    retries: message.retries || null, // Handle undefined or null for retries
    responseId: message.responseId || null, // Handle undefined or null for responseId
  }));

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlQuery,
        variables: { smsMessages },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    if (!result.data || result.errors) {
      throw new Error(
        result.errors ? result.errors[0].message : "Failed to send SMS in bulk"
      );
    }

    console.log("Bulk SMS sent successfully:", result.data.createSMSesBulk);
  } catch (error) {
    console.error("Error sending bulk SMS:", error.message || error);
  }
};

export const editMessage = async (smsData) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const EDIT_MESSAGE = `
    mutation EditMessage($smsMessage: SmsMessageInput!) {
      editMessage(smsMessage: $smsMessage) {
        id
        phoneNumber
        body
        sentDateTime
        status
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        retries
        apiSubcategoryId
        responseId
        failedReason
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: EDIT_MESSAGE,
        variables: { smsMessage: smsData },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.editMessage; // Adjust according to your response structure
  } catch (error) {
    console.error("Error editing message:", error.message || error);
    return null;
  }
};
//#endregion

//#region Promotions Data
export const fetchPromotions = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlQuery = `
    query Promotion {
      promotion {
        id
        message
        isActive
        companyId
        createdDate
        createdBy
        updatedDate
        updatedBy
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlQuery,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    if (!result.data || result.errors) {
      throw new Error(
        result.errors ? result.errors[0].message : "Failed to fetch promotions"
      );
    }

    console.log("Promotions fetched:", result.data.promotion);
    return result.data.promotion; // Note: changed from result.data.promotions to result.data.promotion
  } catch (error) {
    console.error("Error fetching promotions:", error.message || error);
    return [];
  }
};
export const editPromotion = async (promotion) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlMutation = `
    mutation EditPromotion($promotion: PromotionsInput!) {
      editPromotion(promotion: $promotion) {
        id
        message
        isActive
        companyId
        createdDate
        createdBy
        updatedDate
        updatedBy
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlMutation,
        variables: {
          promotion: {
            id: promotion.id,
            message: promotion.message,
            isActive: promotion.isActive,
            companyId: parseInt(promotion.companyId) || null, // Use provided value or null
            createdDate: promotion.createdDate || null, // Use provided value or null
            createdBy: parseInt(promotion.createdBy) || null, // Use provided value or null
            updatedDate: new Date().toISOString(), // Set to current date
            updatedBy: parseInt("currentUserId"), // Replace with actual user ID
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    // Check for errors in the result
    if (!result.data || result.errors) {
      throw new Error(
        result.errors ? result.errors[0].message : "Failed to edit promotion"
      );
    }

    console.log("Promotion edited:", result.data.editPromotion);
    return result.data.editPromotion;
  } catch (error) {
    console.error("Error editing promotion:", error.message || error);
    throw error; // Rethrow the error for further handling
  }
};

export const sendPromotionSMS = async (promotionMessage) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlQuery = `
    mutation CreatePromotion($promotion: CreatePromotionInput!) {
      createPromotion(promotion: $promotion) {
        id
        message
        isActive
        companyId
        createdDate
        createdBy
        updatedDate
        updatedBy
      }
    }
  `;

  const variables = {
    promotion: {
      id: null,
      message: promotionMessage,
      isActive: true,
      companyId: null,
      createdDate: new Date().toISOString(),
      createdBy: null,
      updatedDate: new Date().toISOString(),
      updatedBy: null,
    },
  };

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlQuery,
        variables: variables,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    if (!result.data || result.errors) {
      throw new Error(
        result.errors ? result.errors[0].message : "Failed to create promotion"
      );
    }

    console.log("Promotion created:", result.data.createPromotion);
    return result.data.createPromotion;
  } catch (error) {
    console.error("Error creating promotion:", error.message || error);
    return null;
  }
};

export const createPromotion = async (promotion) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlMutation = `
    mutation CreatePromotion($promotion: PromotionsInput!) {
      createPromotion(promotion: $promotion) {
        id
        message
        isActive
        companyId
        createdDate
        createdBy
        updatedDate
        updatedBy
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlMutation,
        variables: {
          promotion: {
            message: promotion.message,
            isActive: promotion.isActive,
            companyId: parseInt(promotion.companyId) || null, // Use provided value or null
            createdDate: new Date().toISOString(), // Set to current date for creation
            createdBy: parseInt(promotion.createdBy) || null, // Replace with actual user ID or provided value
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    // Check for errors in the result
    if (!result.data || result.errors) {
      throw new Error(
        result.errors ? result.errors[0].message : "Failed to create promotion"
      );
    }

    console.log("Promotion created:", result.data.createPromotion);
    return result.data.createPromotion;
  } catch (error) {
    console.error("Error creating promotion:", error.message || error);
    throw error; // Rethrow the error for further handling
  }
};
export const createAndSendPromotion = async (promotionInput) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const graphqlMutation = `
    mutation CreateAndSendPromotion($input: CreateAndSendInput!) {
      createAndSendPromotion(input: $input) {
        id
        message
        isActive
        companyId
        createdDate
        createdBy
        updatedDate
        updatedBy
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: graphqlMutation,
        variables: {
          input: {
            message: promotionInput.message || null,
            functionFlag: promotionInput.functionFlag || null,
            customerIds:
              promotionInput.functionFlag === 1
                ? null
                : promotionInput.customerIds || null, // Set to null if functionFlag is 1, else use customer IDs
            createdBy: promotionInput.createdBy
              ? parseInt(promotionInput.createdBy)
              : null, // Convert createdBy to integer or null
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const result = response.data;

    // Check for errors in the result
    if (!result.data || result.errors) {
      throw new Error(
        result.errors
          ? result.errors[0].message
          : "Failed to create and send promotion"
      );
    }

    console.log(
      "Promotion created and sent:",
      result.data.createAndSendPromotion
    );
    return result.data.createAndSendPromotion;
  } catch (error) {
    console.error(
      "Error creating and sending promotion:",
      error.message || error
    );
    throw error; // Rethrow the error for further handling
  }
};
//#endregion
