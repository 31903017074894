import React from "react";
import { Typography, Box, useTheme, Breadcrumbs } from "@mui/material";
import { tokens } from "../theme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Header = ({ title, subtitle, action }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Typography
          variant="h6"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{
            textTransform: "uppercase",
            letterSpacing: "0.5px",
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color={colors.greenAccent[400]}>
            {subtitle}
          </Typography>
        )}
      </Breadcrumbs>
      {action && <Box>{action}</Box>}
    </Box>
  );
};

export default Header;
