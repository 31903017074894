import axios from "axios";

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;
  const formattedToken = `Bearer ${token.replace(/"/g, "")}`;
  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
  };
};

export const fetchJsonDynamic = async (objectName =null, inputObjectName =null, params=null, baseUrl) => {
  const headers = getAuthHeaders();

  // Construct the query dynamically based on the presence of params
  const query = {
    query: `
      {
        ${objectName}${
      params
        ? `(${inputObjectName}: {${Object.entries(params)
            .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
            .join(", ")}})`
        : ""
    } {
            results
            error
        }
      }
    `,
  };

  try {
    const response = await axios.post(baseUrl, query, { headers });
    return response?.data?.data?.[objectName];
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};


export const fetchJson = async ({ objectName = null, inputObjectName = null, params = null, baseUrl }) => {
  const headers = getAuthHeaders();

  // Construct the query dynamically based on the presence of params
  const query = {
    query: `
      {
        ${objectName}${
          params
            ? `(${inputObjectName}: {${Object.entries(params)
                .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
                .join(", ")}})`
            : ""
        } {
          results
          error
        }
      }
    `,
  };

  try {
    const response = await axios.post(baseUrl, query, { headers });
    return response?.data?.data?.[objectName];
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const generateColumnsFromResults = (resultsArray, MoreignoreFields) => {
  // Ensure there's data to process
  if (!resultsArray || resultsArray.length === 0) return [];

  // Get all keys (fields) from the first object in the results array
  const result = resultsArray[0];
  const allowedIsFields = ["IsGroup", "IsDepositRequired", "IsDepositPercentage", "IsPercentage"]; // Add any "is" fields you want to allow

const ignoreNullCheck =MoreignoreFields ?MoreignoreFields:[]
  // Fields to ignore (including those passed in the "ignore" parameter)
  const ignoreFields = [
    "CreatedDate",
    "CreatedBy",
    "UpdatedDate",
    "UpdatedBy",
    //"isActive",
    //"CompanyName",
    ...(MoreignoreFields|| []),
  ];

  // Get all keys (fields) from the result object
  const fields = Object.keys(result);

  // Map the extracted field names to the desired column format, ignoring specific fields
  const columns = fields
    .filter(
      (field) =>
        !ignoreFields.includes(field) && // Exclude predefined ignored fields
        (field.toLowerCase() === "id" || !field.toLowerCase().endsWith("id")) && // Include exact 'Id', exclude fields ending with "id"
        (!field.toLowerCase().startsWith("is") || allowedIsFields.includes(field)) // Exclude boolean fields starting with "is"
    )
    .map((field) => ({
      field: field === "Id" ? "id" : field, // Convert 'Id' to 'id'
      headerName: formatHeaderName(field === "Id" ? "id" : field), // Format header to be human-readable, 'Id' to 'id'
      flex: 1, // Adjust the column width flexibility
    }));

  return columns;
};

// Helper function to format the header name (camelCase or PascalCase to readable)
const formatHeaderName = (field) => {
  return field
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
};


// Helper function to build GraphQL mutation queries dynamically
export const generateMutationQuery = (
  mutationName,
  variables,
  mutationObjectName,
  isBulk
) => {
  let args = "";

  // Helper function to format the values based on their datatype
  const formatValue = (value) => {
    if (typeof value === "string") {
      // Enclose strings in quotes
      return `"${value}"`;
    } else if (Array.isArray(value)) {
      // If it's an array, handle each item based on its type
      return `[${value.map((item) => formatValue(item)).join(", ")}]`;
    } else if (value === null) {
      // Handle null values
      return `null`;
    } else {
      // Return the value as is (for numbers, booleans, etc.)
      return value;
    }
  };

  if (isBulk) {
    // Handle bulk operations (arrays of objects or IDs)
    for (const [key, value] of Object.entries(variables)) {
      args += `${key}: ${formatValue(value)}, `;
    }
  } else {
    // Handle single or object-based mutations
    for (const [key, value] of Object.entries(variables)) {
      args += `${key}: ${formatValue(value)}, `;
    }
  }

  // Remove trailing comma and space
  args = args.trim().replace(/,\s*$/, "");

  // Conditionally include mutationObjectName only if it's provided
  return mutationObjectName
    ? `
    mutation {
      ${mutationName}(${mutationObjectName}: {${args}}) {
        results
        error
      }
    }
  `
    : `
    mutation {
      ${mutationName}(${args}) {
        results
        error
      }
    }
  `;
};

// Dynamic mutation service
export const executeAnyMutation = async (
  mutationName,
  variables,
  mutationObjectName,
  isBulk,
  baseUrl
) => {
  const headers = getAuthHeaders(); // Add authentication headers
  const query = generateMutationQuery(
    mutationName,
    variables,
    mutationObjectName,
    isBulk
  ); // Generate mutation query

  try {
    const response = await axios.post(baseUrl, { query }, { headers });
    return response?.data?.data?.[mutationName];
  } catch (error) {
    return { error: "Failed to execute mutation" };
  }
};
