import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { createEmailMessage, fetchEmails } from "./components/emailService"; // Update the service paths as necessary
import { fetchCustomers } from "./components/smsService";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { allCompanyEmailSettings } from "../../../data/Axios/queries";
const EmailsPage = () => {
  const theme = useTheme();
  const [subject, setSubject] = useState("");
  const [subjectTitle, setSubjectTitle] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [companies, setCompanies] = useState([]); 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [pastEmails, setPastEmails] = useState([]);
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const loadEmailCampaignsAndCustomers = async () => {
      try {
        const emailCampaigns = await fetchEmails();
        const customerList = await fetchCustomers();
        //const companyList = await allCompanies();  // Fetch company data

        setPastEmails(emailCampaigns);
        setCustomers(customerList);
        //setCompanies(companyList);  

      } catch (error) {
        console.error("Error loading email campaigns or customers:", error);
      }
    };

    loadEmailCampaignsAndCustomers();
  }, []);
  const handleSelectAllCustomers = () => {
    if (isAllSelected) {
      setSelectedCustomers([]);
      setSelectedEmails([]);
    } else {
      const allSelected = customers;
      setSelectedCustomers(allSelected);
      setSelectedEmails(allSelected.map((customer) => customer.EmailAddress));
    }
  };

  const handleSelectSingleCustomer = (customer) => {
    const isSelected = selectedEmails.includes(customer.EmailAddress);
    if (isSelected) {
      setSelectedCustomers((prev) =>
        prev.filter((c) => c.EmailAddress !== customer.EmailAddress)
      );
      setSelectedEmails((prev) =>
        prev.filter((email) => email !== customer.EmailAddress)
      );
    } else {
      setSelectedCustomers((prev) => [...prev, customer]);
      setSelectedEmails((prev) => [...prev, customer.EmailAddress]);
    }
  };

  const handleSendEmail = async () => {
    if (emailContent && selectedCustomers.length ) {
      try {
        for (const customer of selectedCustomers) {
          const emailInput = {
            body: emailContent,
            subject: subject,
            subjectTitle: subjectTitle,
            recipientEmail: customer.EmailAddress,
            createdBy: parseInt(customer.CreatedBy, 10),
          };

          await createEmailMessage(emailInput);
        }
        setShowSuccessMessage(true);
        setSuccessMessageText("Email sent successfully!");
        setEmailContent("");
        setSubjectTitle("");
        setSubject("");
        setSelectedCustomers([]);
        setSelectedEmails([]);
        const emails = await fetchEmails();
        setPastEmails(emails);
      } catch (error) {
        console.error("Error sending email:", error);
        setShowErrorMessage(true);
        setErrorMessageText("Failed to send email. Please try again.");
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const handleCustomerChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      handleSelectAllCustomers();
    } else {
      const selectedCustomer = customers.find(
        (customer) => customer.Id === value[value.length - 1]
      );
      if (selectedCustomer) {
        handleSelectSingleCustomer(selectedCustomer); // Handle single customer selection
      }
    }
  };

  const isAllSelected = selectedCustomers.length === (customers?.length || 0);

  const handleCloseSnackbar = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleEmailSelect = (id) => {
    const selectedEmail = pastEmails.find((email) => email.id === id);
    if (selectedEmail) {
      setEmailContent(selectedEmail.body);
      setSubjectTitle(selectedEmail.subjectTitle)
      setSubject(selectedEmail.subject);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "subjectTitle", headerName: "Subject Title", flex: 4 },
    { field: "subject", headerName: "Subject", flex: 4 },
    { field: "body", headerName: "Email Content", flex: 4 },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 80,
      valueGetter: (params) =>
        new Date(params.row.createdDate).toLocaleDateString(),
    },
    { field: "status", headerName: "Status", flex: 4 },

    {
      field: "action",
      headerName: "Action",
      width:80,
      renderCell: (params) => (
        <Button onClick={() => handleEmailSelect(params.row.id)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>Create a New Email </h2>
        </div>
      </div>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <TextField
          label="Subject Title"
          name="subjectTitle"
          variant="outlined"
          value={subjectTitle}
          onChange={(e) => setSubjectTitle(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <TextField
          label="Subject"
          name="subject"
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <TextField
          label="Email Content"
          name="body"
          variant="outlined"
          multiline
          rows={4}
          value={emailContent}
          onChange={(e) => setEmailContent(e.target.value)}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel>Send to Customers</InputLabel>
        <Select
          multiple
          value={selectedCustomers.map((customer) => customer.FirstName)} // Display customer names
          onChange={handleCustomerChange}
          renderValue={(selected) =>
            selected.length ? selected.join(", ") : "Select Customers"
          }
        >
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              onChange={handleSelectAllCustomers}
            />
            <ListItemText primary="Select All" />
          </MenuItem>

          {customers.length > 0 &&
            customers.map((customer) => (
              <MenuItem key={customer.Id} value={customer.FirstName}>
                <Checkbox
                  checked={selectedEmails.includes(customer.EmailAddress)}
                  onChange={() => handleSelectSingleCustomer(customer)}
                />
                <ListItemText
                  primary={`${customer.FirstName} (${customer.EmailAddress})`}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendEmail}
        disabled={!emailContent || !selectedCustomers.length}
      >
        Send Email
      </Button>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid
          rows={pastEmails}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
          disableSelectionOnClick
        />
      </Box>

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessageText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessageText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmailsPage;
