import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Block as BlockIcon,
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetchFixedExpensesData,
  addFixedExpense,
  updateFixedExpense,
  toggleFixedExpenseStatus,
  fetchFixedExpenseById,
  fetchIntervals,
  fetchAccountSubCategory,
} from "../../../data/expensesData";
import Swal from "sweetalert2";

const FixedExpenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fixedExpenses, setFixedExpenses] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0); // Total amount of expenses
  const [modalOpen, setModalOpen] = useState(false); // Consolidated modal state
  const [intervals, setIntervals] = useState([]);
  const [accountSubcategory, setAccountSubCategory] = useState([]);
  const [editingExpense, setEditingExpense] = useState(null); // Track whether editing
  const [formData, setFormData] = useState({
    id: null,
    entry: "",
    amount: "",
    description: "",
    intervalId: "",
    isActive: "",
    accountSubcategoryId: "",
  });

  const navigate = useNavigate();

  // Define fetchData with useCallback to memoize it
  const fetchData = useCallback(async () => {
    try {
      const { fixedExpenses } = await fetchFixedExpensesData();
      const intervalsData = await fetchIntervals();
      setIntervals(intervalsData);
      const accountSubcategoryData = await fetchAccountSubCategory();
      setAccountSubCategory(accountSubcategoryData);

      const mappedExpenses = fixedExpenses.map((expense) => {
        const interval = intervalsData.find((i) => i.id === expense.intervalId);
        return {
          ...expense,
          intervalName: interval
            ? interval.intervalName
            : expense.intervalId || "Unknown",
        };
      });
      console.log("Mapped Expenses:", mappedExpenses); // Debugging
      setFixedExpenses(mappedExpenses);

      const total = mappedExpenses.reduce(
        (sum, expense) => sum + parseFloat(expense.amount || 0),
        0
      );
      setTotalExpenses(total);
    } catch (error) {
      console.error("Error fetching fixed expenses data:", error);
      // Optionally, set error state here to display to the user
    }
  }, []);

  // Fetch fixed expenses data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Open modal (edit or add mode)
  const handleOpenModal = async (expenseId = null) => {
    if (expenseId) {
      try {
        const fetchedExpense = await fetchFixedExpenseById(expenseId);
        if (fetchedExpense) {
          setFormData({
            id: fetchedExpense.id,
            entry: fetchedExpense.entry,
            amount: fetchedExpense.amount,
            description: fetchedExpense.description,
            intervalId: fetchedExpense.intervalId,
            isActive: true,
            accountSubcategoryId: fetchedExpense.accountSubCategoryId,
          });
          setEditingExpense(fetchedExpense);
        } else {
          // Handle case where expense is not found
          setFormData({
            id: null,
            entry: "",
            amount: "",
            description: "",
            intervalId: "",
          });
          setEditingExpense(null);
        }
      } catch (error) {
        console.error("Error fetching expense by ID:", error);
        // Optionally, handle the error (e.g., show a notification)
      }
    } else {
      setFormData({
        id: null,
        entry: "",
        amount: "",
        description: "",
        intervalId: "",
      });
      setEditingExpense(null);
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditingExpense(null);
    setFormData({
      id: null,
      entry: "",
      amount: "",
      description: "",
      intervalId: "",
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission (add or edit)
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form data:", formData); // Debugging

    // Validate form data if necessary
    if (!formData.entry || !formData.amount || !formData.intervalId) {
      // Optionally, set error state to display validation messages
      console.error("Form validation failed: Missing required fields");
      return;
    }

    try {
      if (editingExpense) {
        await updateFixedExpense(formData);
      } else {
        await addFixedExpense(formData);
      }
      handleCloseModal();
      await fetchData(); // Refetch data after adding or updating
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally, handle the error (e.g., show a notification)
    }
  };

  // Toggle active status of an expense
  const handleToggleStatus = async (expense) => {
    const newStatus = !expense.isActive;
    try {
      const result = await toggleFixedExpenseStatus(expense.id, newStatus);

      if (result !== null) {
        Swal.fire(
          "Success!",
          `${expense.description} ${
            newStatus === true ? "Activated" : "deactivated"
          } Successfully.`,
          "success"
        );
        await fetchData(); // Refetch data after toggling status
      }
    } catch (error) {
      console.error("Error toggling expense status:", error);
      // Optionally, handle the error
    }
  };

  // Handle action select (edit or toggle status)
  const handleActionSelect = (e, id) => {
    const action = e.target.value;
    if (action === "edit") {
      handleOpenModal(id);
    } else if (action === "toggleStatus") {
      const expense = fixedExpenses.find((exp) => exp.id === id);
      if (expense) {
        handleToggleStatus(expense);
      }
    }
  };
  const uniqueAccountSubcategories = accountSubcategory.filter(
    (value) => value.accountCategoryId === 2
  );
  // Define columns for DataGrid
  const columns = [
    { field: "id", headerName: "Id", flex: 1 },
    { field: "entry", headerName: "Entry", flex: 1 },
    { field: "accountName", headerName: "Account Type", flex: 1.5 },
    { field: "description", headerName: "Description", flex: 1.5 },
    { field: "intervalName", headerName: "Paid", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = Number(params.value);
        return isNaN(value) ? params.value : value.toLocaleString();
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row: { isActive } }) => {
        const statusText = isActive ? "Active" : "Inactive";
        const statusColor = isActive ? "green" : "red";
        return (
          <Typography variant="body1" style={{ color: statusColor }}>
            {statusText}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row: { id, isActive } }) => (
        <Box>
          <Select
            value=""
            onChange={(e) => handleActionSelect(e, id)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Actions
            </MenuItem>
            <MenuItem value="edit">
              <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
              Edit
            </MenuItem>
            <MenuItem value="toggleStatus">
              {isActive ? (
                <>
                  <BlockIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Deactivate
                </>
              ) : (
                <>
                  <CheckCircleIcon
                    fontSize="small"
                    style={{ marginRight: "8px" }}
                  />
                  Activate
                </>
              )}
            </MenuItem>
          </Select>
        </Box>
      ),
    },
  ];

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box m="20px">
      <IconButton color="primary" onClick={handleBackClick} sx={{ mr: 1 }}>
        <ArrowBackIcon />
      </IconButton>
      <Header title="Expense Management" subtitle="Manage Fixed Expenses" />

      <Button
        onClick={() => handleOpenModal(null)}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
      >
        Add Fixed Expense
      </Button>
      <Box
        sx={{
          backgroundColor: "initial",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "20px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#4F93D7",
            fontWeight: "600",
          }}
        >
          Total Fixed Expenses: KES {totalExpenses.toLocaleString()}
        </Typography>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid
          rows={fixedExpenses}
          columns={columns}
          getRowId={(row) => row.id}
          // Optionally, add pagination or other props
        />
      </Box>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#fff" : colors.primary[700],
            padding: "16px",
            borderRadius: "8px",
            width: "80%",
            maxWidth: "600px",
            margin: "auto",
            marginTop: "10%",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          }}
        >
          <Typography variant="h5" gutterBottom>
            {editingExpense ? "Edit Fixed Expense" : "Add Fixed Expense"}
          </Typography>

          <TextField
            fullWidth
            label="Entry"
            name="entry"
            value={formData.entry}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            required
          />
          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel id="interval-select-label">AccountType</InputLabel>
            <Select
              labelId="interval-select-label"
              name="AccountSubCategoryId"
              value={formData.accountSubcategoryId}
              onChange={handleInputChange}
              displayEmpty
              label="Account SubCategory"
            >
              {uniqueAccountSubcategories.map((interval) => (
                <MenuItem key={interval.id} value={interval.id}>
                  {interval.accountName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            type="number"
            inputProps={{ min: "0", step: "0.01" }}
            required
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            required
          />
          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel id="interval-select-label">Payment Interval</InputLabel>
            <Select
              labelId="interval-select-label"
              name="intervalId"
              value={formData.intervalId}
              onChange={handleInputChange}
              displayEmpty
              label="Payment Interval"
            >
              {intervals.map((interval) => (
                <MenuItem key={interval.id} value={interval.id}>
                  {interval.intervalName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              mt: 2,
            }}
          >
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {editingExpense ? "Update" : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FixedExpenses;
