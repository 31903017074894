import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import Swal from "sweetalert2";
import { customerservice, companyManagementUrl } from "../../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../../data/DynamicServiceNew/jsondynamicservice";

const GroupBranchMigrationPage = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const [searchTerm, setSearchTerm] = useState("");
  const [branchData, setBranchData] = useState([]);
  const [selectedSearchBranch, setSelectedSearchBranch] = useState(null);
  const [branchSelected, setBranchSelected] = useState("");
  const [dashboardData, setDashboardData] = useState(null);

  // Fetch branch data
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetchJsonDynamic(
          "branch",
          null,
          null,
          companyManagementUrl.uri
        );
        const allBranches = JSON.parse(response.results) || [];
        setBranchData(allBranches);
      } catch (error) {
        console.error("Error fetching branch data:", error);
      }
    };
    fetchBranches();
  }, []);

  // Fetch dashboard data
  const fetchDashboardData = async (branchId) => {
    try {
      const response = await fetchJsonDynamic(
        "groupDashboardMetricsById",
        "dashboardMetrics",
        { branchid: branchId },
        companyManagementUrl.uri
      );
      if (response?.results) {
        const data = JSON.parse(response.results)[0];
        setDashboardData(data || null);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // When a branch is selected in the search dropdown, fetch its dashboard data
  useEffect(() => {
    if (selectedSearchBranch) {
      fetchDashboardData(selectedSearchBranch.Id);
    }
  }, [selectedSearchBranch]);

  // Submit customer migration
  const handleCustomersMigrationSubmit = async () => {
    if (!selectedSearchBranch || !branchSelected) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please select both source and destination branches.",
      });
      return;
    }

    try {
      const mutationData = {
        poldBranchId: selectedSearchBranch.Id,
        pbranchId: branchSelected,
        pdoneBy: userId,
      };

      const response = await executeAnyMutation(
        "executeBulkGroupBranchMigration",
        mutationData,
        "inputModel",
        false,
        customerservice.uri
      );

      if (response.results === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Customers migrated successfully.",
        }).then(() => {
          // Clear all fields
          setSearchTerm("");
          setSelectedSearchBranch(null);
          setBranchSelected("");
          setDashboardData(null);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: response.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Branch Dashboard</Typography>

      {/* Search Dropdown */}
      <Autocomplete
        options={branchData}
        getOptionLabel={(option) => option.Name || ""}
        value={selectedSearchBranch}
        onChange={(e, newValue) => setSelectedSearchBranch(newValue)}
        inputValue={searchTerm}
        onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Branch by Name"
            placeholder="Start typing to search..."
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />

      {selectedSearchBranch && (
        <>
          {/* Dashboard Data */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Branch Summary
              </Typography>
              {dashboardData ? (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Total Customers:</strong>{" "}
                      {dashboardData.GroupTotalCustomers}
                    </Typography>
                    <Typography>
                      <strong>Outstanding Loan Balance (OLB):</strong>{" "}
                      {dashboardData.GroupOutstandingLoanBalance.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>New Customers:</strong>{" "}
                      {dashboardData.GroupNewCustomers}
                    </Typography>
                    <Typography>
                      <strong>Loans Due Today:</strong>{" "}
                      {dashboardData.GroupLoansDueToday}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography>No dashboard data available.</Typography>
              )}
            </CardContent>
          </Card>

          {/* Destination Branch Selection */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Select Destination Branch
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Select Branch</InputLabel>
                <Select
                  value={branchSelected}
                  onChange={(e) => setBranchSelected(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Branch
                  </MenuItem>
                  {branchData.map((branch) => (
                    <MenuItem key={branch.Id} value={branch.Id}>
                      {branch.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleCustomersMigrationSubmit}
          >
            Submit For Customers Migration
          </Button>
        </>
      )}
    </Box>
  );
};

export default GroupBranchMigrationPage;
