import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD, ForgetPasswordApi } from "../../data/userData"; // Import your mutation
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import forgotpassword from "../../Assets/Images/support.jpg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";

// Define a theme with custom styles
const theme = createTheme();

export default function ForgotPasswordForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event, field) => {
    event.preventDefault();
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("Email");
    try {
      const response = await ForgetPasswordApi(email);
      if (response === "ERR_NETWORK") {
        Swal.fire({
          icon: "error",
          title: "Error Network!",
          text: "Unable to login, check your internet connection and try again.",
        });
      }
      if (
        response.code === "ERR_BAD_RESPONSE" ||
        response.code === "ERR_BAD_REQUEST"
      ) {
        if (response.response.data) {
          Swal.fire({
            icon: "error",
            title: "Invalid Credentials!",
            text: response.response.data,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Server Error!",
            text: "Server error, contact Admin",
          });
        }
      }
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Password has been reset succesfully,please check your email, 
                   `,
        });
        navigate("/login");
      }

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          padding: 2,
          position: "relative", // Needed for absolute positioning of the child
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${forgotpassword})`, // Your image URL
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.8, // Adjust the opacity level as needed
            zIndex: -1, // Ensure the div stays behind the content
          }}
        ></div>

        <Paper
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            borderRadius: "0.5rem", // Smooth curves
            backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent background
            backdropFilter: "blur(2px)", // Blur effect
            width: ["100%", "90%", "70%", "60%"], // Responsive widths
            margin: "auto", // Center the form
            [theme.breakpoints.up("sm")]: {
              width: "90%", // Adjust width for small screens and up
            },
            [theme.breakpoints.up("md")]: {
              width: "60%", // Adjust width for medium screens and up
            },
            [theme.breakpoints.up("lg")]: {
              width: "30%", // Adjust width for large screens and up
            },
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="Email"
            label="Email"
            name="Email"
            type="text"
            autoComplete=""
            sx={{ marginBottom: 2 }} // Add spacing between fields
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
          Reset
          </Button>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
