import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ClipLoader } from "react-spinners";
import L from "leaflet";
import { height } from "@mui/system";
import { fetchCustomersByStatus } from "../Adohc/promotions/components/smsService";
import {
  companyManagementUrl,
  customerservice,
  userManagementUrl,
} from "../../config";
import {
  fetchJson,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
// Styles as constants

// Function to get a custom colored marker using DivIcon
const getColoredIcon = (color) => {
  return L.divIcon({
    className: "custom-icon",
    html: `
      <div style="
        width: 20px;
        height: 32px;
        position: relative;
        transform: translate(-10px, -32px);
      ">
        <div style="
          background-color: ${color};
          width: 20px;
          height: 20px;
          border-radius: 50% 50% 50% 0;
          transform: rotate(-45deg);
          position: absolute;
          left: 0;
          top: 0;
          border: 2px solid white;
        "></div>
        <div style="
          width: 10px;
          height: 10px;
          background-color: ${color};
          position: absolute;
          left: 5px;
          top: 25px;
          border-radius: 50%;
        "></div>
      </div>
    `,
    iconSize: [24, 36],
    iconAnchor: [10, 32],
    popupAnchor: [0, -32],
  });
};

const statuses = [
  "isLoanApproved",
  "isLoanCleared",
  "isInArrears",
  "isNonPerforming",
  "isWrittenOff",
  "isLoanDeclined",
  "isFieldOfficerApproved",
  "isBranchApproved",
  "isRegionApproved",
  "isCompanyApproved",
  "isMigrated",
  "isRescheduled",
  "isRebooked",
  "isVerified",
];
// Function to determine marker color based on registrationStatusId
const getMarkerColor = (regStatusId) => {
  if ([3, 5, 8].includes(regStatusId)) {
    return "green";
  } else if ([4, 6, 9].includes(regStatusId)) {
    return "red";
  } else if (regStatusId === 11) {
    return "lightgreen";
  } else {
    return "green";
  }
};
const cardBodyStyle = {
  padding: "5px", // Reduced padding inside the card body
  height: "50px", // Fixed small height for the card
  display: "flex",
  alignItems: "center", // Center the content vertically
  justifyContent: "space-evenly", // Evenly distribute content horizontally
};

const cardTitleStyle = {
  marginBottom: "10px", // Space between title and dropdown
  fontSize: "16px", // Adjust font size as needed
};
const legendStyle = {
  position: "absolute",
  top: "50%", // Center vertically
  right: "20px",
  zIndex: 1000,
  background: "rgba(255, 255, 255, 0.8)",
  padding: "10px",
  borderRadius: "5px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  transform: "translateY(-50%)", // Offset by half the height
};
// Hover effect using a class

const legendIconStyle = {
  display: "inline-block",
  width: "15px",
  height: "15px",
  marginRight: "5px",
  borderRadius: "50%",
};
const UpdateMapCenter = ({ coordinates }) => {
  const map = useMap();
  useEffect(() => {
    if (coordinates) {
      map.setView(coordinates, map.getZoom());
    }
  }, [coordinates, map]);
  return null;
};

const MapComponent = ({}) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const userId = parseInt(decodedToken.Id);
  const tokenRegionId = parseInt(decodedToken.RegionId);
  const tokenBranchId = parseInt(decodedToken.BranchId);
  const [defaultPosition, setDefaultPosition] = useState([-0.70732, 36.45483]);
  const [CustomerData, setCustomerData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [branchData, setBranchData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [foData, setFoData] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedFo, setSelectedFo] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const handleView360 = (Id) => {
    navigate("/customer-360");
    localStorage.setItem("CustomerId", Id);
  };
  const mapWrapperStyle = {
    position: "relative",
    height: "100vh",
    width: "100%",
  };

  const filtersStyle = {
    position: "absolute",
    top: "10px",
    left: "0",
    right: "0",
    zIndex: 1000,
    background: isHovered ? "rgba(255, 255, 255, 0.9)" : "transparent",
    //background: "rgba(255, 255, 255, 0.9)",
    padding: "5px", // Reduced padding to make the card smaller
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s ease",
    marginRight: "10px",
    marginLeft: "10px",
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const regionResponse = await fetchJson({
        objectName: "region",
        baseUrl: companyManagementUrl.uri,
      });
      const allRegions = JSON.parse(regionResponse.results) || [];
      if (allRegions) {
        setRegionData(allRegions || []);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    const fetchCustomer = async () => {
      const parameters = {
        regionId: parseInt(selectedRegion) || null,
        branchId: parseInt(selectedBranch) || null,
        fieldOfficerId: parseInt(selectedFo) || null,
      };
      const response = await fetchJson({
        objectName: "customersDynamic",
        baseUrl: customerservice.uri,
        inputObjectName: "customer",
        params: parameters,
      });
      if (!response) {
        return;
      }
      const allCustomers = JSON.parse(response.results) || [];
      if (allCustomers != null) {
        const customer = allCustomers.map((farmer) => ({
          coordinates: [farmer.Latitude, farmer.Longitude],
          customerFirstName: farmer.FirstName,
          customerLastName: farmer.OtherNames,
          CustomerId: farmer.Id,
          // idNo: farmer.idNo,
          customerPhone: farmer.MobileNumber,
          //   loanBalance: farmer.farmRegStatusId,
          //   regStatus: farmer.farmRegStatus,
        }));
        setCustomerData(customer);
      }
    };
    fetchCustomer();
  }, [selectedRegion, selectedBranch, selectedFo]);
  const fetchBranches = async (regionId) => {
    try {
      const params = { regionId: parseInt(regionId) };

      const businessDetails = await fetchJsonDynamic(
        "allBranchesByColumnValues",
        "branchModel",
        params,
        companyManagementUrl.uri
      );

      const allBranchDetails = JSON.parse(businessDetails.results) || [];

      const branchOptions = allBranchDetails.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.Name,
      }));

      //setMappedBranches(branchOptions)

      return branchOptions; // Return the mapped branch options
    } catch (error) {
      return [];
    }
  };
  const fetchFieldOfficer = async (branchId) => {
    try {
      const params = { branchId: parseInt(branchId) };

      const businessDetails = await fetchJsonDynamic(
        "allUsersByColumnValues",
        "usermodel",
        params,
        userManagementUrl.uri
      );

      const user = JSON.parse(businessDetails.results) || [];

      const branchOptions = user.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.FirstName,
      }));

      //setMappedBranches(branchOptions)

      return branchOptions; // Return the mapped branch options
    } catch (error) {
      return [];
    }
  };
  const handleRegionChange = async (Id) => {
    //const c = e.target.value;
    setSelectedRegion(Id);
    const data = await fetchBranches(parseInt(Id));
    setBranchData(data);
  };

  const handleBranchChange = async (id) => {
    // const id = e.target.value;
    setSelectedBranch(id);
    const data = await fetchFieldOfficer(parseInt(id));
    setFoData(data);
  };

  const handleFOChange = (e) => {
    setFoData(e);
  };

  //   const handleStatusChange = (e) => {
  //     setSelectedStatus(e.target.value);
  //   };
  const validFarmDetails = CustomerData?.filter(
    (cust) => cust.coordinates[0] !== null && cust.coordinates[1] !== null
  );
  const firstValidCoordinate = validFarmDetails?.[0]?.coordinates;
  useEffect(() => {
    if (firstValidCoordinate) {
      setDefaultPosition(firstValidCoordinate);
    }
  }, [firstValidCoordinate]);

  useEffect(() => {
    if (RoleGroupId === 4) {
      handleRegionChange(tokenRegionId);
    } else if (RoleGroupId === 5) {
      handleBranchChange(tokenBranchId);
    }
  }, [RoleGroupId, tokenRegionId, tokenBranchId]);

  const dropdownConfig = [
    {
      roles: [2],
      label: "Region",
      data: regionData,
      selectedValue: selectedRegion,
      onChange: handleRegionChange,
      getKey: (item) => item.Id,
      getLabel: (item) => item.Name,
    },
    {
      roles: [2, 3],
      label: "Branch",
      data: branchData,
      selectedValue: selectedBranch,
      onChange: handleBranchChange,
      getKey: (item) => item.value,
      getLabel: (item) => item.label,
    },
    {
      roles: [2, 3, 4, 5],
      label: "Field Officer",
      data: foData,
      selectedValue: selectedFo,
      onChange: handleFOChange,
      getKey: (item) => item.value,
      getLabel: (item) => item.label,
    },
  ];
  return (
    <div style={mapWrapperStyle}>
      <MapContainer
        center={defaultPosition}
        zoom={20}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <UpdateMapCenter coordinates={firstValidCoordinate} />
        {validFarmDetails?.map((cust, index) => (
          <Marker
            key={index}
            position={cust.coordinates}
            icon={getColoredIcon(getMarkerColor(cust.registationStatusId))}
          >
            <Popup>
              <strong>Customer Name:</strong> {cust.customerFirstName} {""}{" "}
              {cust.customerLastName}
              <br />
              <strong>Phone:</strong> {cust.customerPhone}
              <br />
              <Button
                variant="contained"
                size="sm"
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  borderRadius: "4px",
                }}
                onClick={() => handleView360(cust.CustomerId)}
              >
                View More
              </Button>
            </Popup>
          </Marker>
        ))}

        {/* Floating Filters */}
        <div
          className="mt-2"
          style={filtersStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className="card"
            style={{ border: "none", background: "transparent" }}
          >
            <h4 className="ms-2 mt-1" style={cardTitleStyle}>
              Filters
            </h4>
            <div className="card-body" style={cardBodyStyle}>
              {/* <div className="form-group" style={{ marginBottom: "0" }}>
                <select
                  className="form-select"
                  onChange={handleStatusChange}
                  value={selectedStatus}
                >
                  <option value="">All Statuses</option>
                  {statusValue?.map((status) => (
                    <option
                      key={status.registrationStatusId}
                      value={status.registrationStatusId}
                    >
                      {status.registrationStatus}
                    </option>
                  ))}
                </select>
              </div> */}
              {dropdownConfig.map(
                ({
                  roles,
                  label,
                  data,
                  selectedValue,
                  onChange,
                  getKey,
                  getLabel,
                }) =>
                  roles.includes(RoleGroupId) && (
                    <div
                      className="form-group"
                      style={{ marginBottom: "0" }}
                      key={label}
                    >
                      <select
                        className="form-select"
                        onChange={(e) => onChange(e.target.value)}
                        value={selectedValue}
                      >
                        <option value="">{label}</option>
                        {data?.map((item) => (
                          <option key={getKey(item)} value={getKey(item)}>
                            {getLabel(item)}
                          </option>
                        ))}
                      </select>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>

        {/* Floating Legend */}
        {/* <div style={legendStyle}>
          <h4>Legend</h4>
          <ul>
            <li>
              <span
                style={{ ...legendIconStyle, backgroundColor: "green" }}
              ></span>
              Approved
            </li>
            <li>
              <span
                style={{ ...legendIconStyle, backgroundColor: "red" }}
              ></span>
              Rejected
            </li>
            <li>
              <span
                style={{ ...legendIconStyle, backgroundColor: "lightgreen" }}
              ></span>
              Archived
            </li>
            <li>
              <span
                style={{ ...legendIconStyle, backgroundColor: "yellow" }}
              ></span>
              Other Statuses
            </li>
          </ul>
        </div> */}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
