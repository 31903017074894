import React from 'react';
import {
  FaRegChartBar,
  FaRegHandshake,
  FaLock,
  FaUserShield,
  FaBell
} from "react-icons/fa";

function Features() {
  return (
    <div className='bg-gradient-to-t from-black via-gray-900 to-gray-800  flex flex-col items-center px-6 md:px-12 lg:px-24 pb-8'>
      <div className="max-w-[1240px] mx-auto flex flex-col items-center text-center mb-8">
        <p className="text-[#00df9a] font-bold uppercase text-lg">
          Features
        </p>
        <h1 className="md:text-5xl sm:text-4xl text-2xl py-4 font-bold text-white">
          Get Insights About Our System
        </h1>
        <p className="text-gray-400 md:text-xl text-lg max-w-[800px]">
          Explore the powerful features of our loan management system that will help you streamline operations, secure data, and enhance client management.
        </p>
      </div>
      <div className=" bg-gradient-to-b from-black via-gray-900 to-gray-800 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-[1400px] p-6 shadow-lg rounded-xl w-full">
        {/* Comprehensive Reporting */}
        <div className="bg-gradient-to-t from-black via-gray-900 to-gray-800 p-6 shadow-lg rounded-xl flex flex-col items-center text-center">
          <FaRegChartBar className="text-indigo-600 w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-white">Comprehensive Reporting</h3>
          <p className="text-gray-400 mt-2">
            Gain insights into your loan portfolio with detailed reports on repayment statuses, default rates, and more.
          </p>
        </div>

        {/* Secure Transactions */}
        <div className="bg-gradient-to-t from-black via-gray-900 to-gray-800 p-6 shadow-lg rounded-xl flex flex-col items-center text-center">
          <FaLock className="text-green-600 w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-white">Secure Transactions</h3>
          <p className="text-gray-400 mt-2">
            Protect sensitive financial data with advanced encryption and security protocols.
          </p>
        </div>

        {/* Client Management */}
        <div className="bg-gradient-to-t from-black via-gray-900 to-gray-800 p-6 shadow-lg rounded-xl flex flex-col items-center text-center">
          <FaRegHandshake className="text-blue-600 w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-white">Client Management</h3>
          <p className="text-gray-400 mt-2">
            Efficiently manage client information, track interactions, and maintain detailed records.
          </p>
        </div>

        {/* Automated Notifications */}
        <div className="bg-gradient-to-t from-black via-gray-900 to-gray-800 p-6 shadow-lg rounded-xl flex flex-col items-center text-center">
          <FaBell className="text-yellow-600 w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-white">Automated Notifications</h3>
          <p className="text-gray-400 mt-2">
            Keep clients informed with automatic alerts for payment reminders, due dates, and more.
          </p>
        </div>

        {/* User Access Control */}
        <div className="bg-gradient-to-t from-black via-gray-900 to-gray-800 p-6 shadow-lg rounded-xl flex flex-col items-center text-center">
          <FaUserShield className="text-red-600 w-12 h-12 mb-4" />
          <h3 className="text-xl font-bold text-white">User Access Control</h3>
          <p className="text-gray-400 mt-2">
            Manage user permissions and ensure only authorized personnel have access to critical features.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
