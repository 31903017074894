export const suspendedTransactionFields = [
  {
    name: "billRefNumber",
    label: "Bill Reference Number",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "businessShortCode",
    label: "Business Short Code",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "invoiceNumber",
    label: "Invoice Number",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "middleName",
    label: "Middle Name",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "msisdn",
    label: "MSISDN",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "orgAccountBalance",
    label: "Organization Account Balance",
    type: "number",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "transactionType",
    label: "Transaction Type",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "transAmount",
    label: "Transaction Amount",
    type: "number",
    gridColumn: "span 2",
    readonly: true,
  },
  {
    name: "transTime",
    label: "Transaction Time",
    type: "text",
    gridColumn: "span 2",
    readonly: true,
  },
];
