import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AnchorTemporaryDrawer from "../../components/Drawer";
import {
  fetchDataEngine,
  fetchDataEngineCustomized,
  generateAndExecuteMutation,
  graphqlQuery,
} from "../Axios/DynamicService";
import { useNavigate } from "react-router-dom";
import {
  DocumentAccessModal,
  UpdateCaseStatusModal,
  AssignTaskModal,
  MarkPaid,
} from "./Modal";
import { UpdateDataToStore } from "../../store/Actions/CustomAction";
import { Card, CardBody } from "react-bootstrap";
import usePermissions from "../RBAC/PermissionsHook";
import useSubRightPermissionsHook from "../RBAC/SubRightPermissionHook";
import { documentManagementUrl } from "../../config";
import { deleteDocument, markTaskDone } from "./DynamicFunctions";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import { generateColumnsFromQuery } from "../Axios/DynamicService";

const base_file_path = documentManagementUrl.baseFilePath;

const DynamicTable = ({
  title,
  subtitle,
  columns,
  FormComponent,
  query,
  base_url,
  actions,
  DataFromGetBy,
  getRowId,
  ignoreFields,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { hasPermission } = usePermissions();
  const { hasSubRightPermission } = useSubRightPermissionsHook();
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const [assignDocumentAccessOpen, setAssignDocumentAccessOpen] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [generatedColumn, setGeneratedColumn] = useState([]);
  const [OpenMarkPaidModal, setOpenMarkPaidModal] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [saleOrderPayments, setSaleOrderPaymentAmount] = useState(0);

  const handleOpenMarkPaidModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setOpenMarkPaidModal(true);
    setEditData(itemToEdit);
  };
  const handleSubmit = async (id) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to submit this document? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit it!",
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        const mutationData = {
          id: id,
        };
        const response = await generateAndExecuteMutation(
          actions.submit.inputObjectName,
          mutationData,
          actions.submit.inputSubObjectName,
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Document submited successfully.`,
          });
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleRemoveTeamMember = async (row) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you  want to remove this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove!",
        cancelButtonText: "No, keep",
      });

      if (confirmation.isConfirmed) {
        const mutationData = {
          ids: row.id,
          ...(actions.entity === "Team Assignments" && { userId: row.userId }),
          ...(actions.entity === "Judge Assignments" && {
            userId: row.judgeId,
          }),
          ...(actions.entity === "Court Assignments" && {
            userId: row.courtId,
          }),
          isActive: false,
          modelName: actions.RemoveItem.modelName,
        };

        const response = await generateAndExecuteMutation(
          actions.RemoveItem.inputObjectName,
          mutationData,
          null,
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Item removed successfully.`,
          });
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  useEffect(() => {
    /**
     * Fetches the columns from the query. If the columns are not provided, it generates columns from the query.
     * The generated columns are set to the generatedColumn state.
     */
    function fetchcolumns() {
      if (columns === null || columns === undefined) {
        const columnsData = generateColumnsFromQuery(query, ignoreFields);
        setGeneratedColumn(columnsData);
      } else {
        setGeneratedColumn(columns);
      }
    }

    fetchcolumns();
  }, [columns]);

  /**
   * Removes a leave approver
   * @param {Object} row The row data of the approver to be removed
   * @returns {Promise<void>}
   */
  const MarkIsDelivered = async (row) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you  want to mark this order as  delivered}?"`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Mark!",
        cancelButtonText: "No, keep",
      });

      if (confirmation.isConfirmed) {
        const mutationData = { purchaseOrderId: row.id };

        const response = await generateAndExecuteMutation(
          actions.markIsDelivered.dataObject.inputObjectName,
          mutationData,
          null,
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Item marked successfully.`,
          });
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  const ProcessCustomerOutstandingBalance = async (row) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you  want to notify the customer on The credit?"`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Mark!",
        cancelButtonText: "No, keep",
      });

      if (confirmation.isConfirmed) {
        const mutationData = { customerId: row.id };

        const response = await generateAndExecuteMutation(
          actions.processCustomeBalance.dataObject.inputObjectName,
          mutationData,
          "balanceParams",
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Notification was sent successfully.`,
          });
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const MarkIsPaid = async (row) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you  want to mark this order as paid?"`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Mark!",
        cancelButtonText: "No, keep",
      });

      if (confirmation.isConfirmed) {
        const mutationData = { purchase: row.id };

        const response = await generateAndExecuteMutation(
          actions.markIsVendorPaid.dataObject.inputObjectName,
          mutationData,
          null,
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Item marked successfully.`,
          });
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const removeSurrenderLine = async (row) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you  want to remove this line?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove!",
        cancelButtonText: "No, keep",
      });

      if (confirmation.isConfirmed) {
        const mutationData = {
          id: row.id,
          applicationId: row.applicationId,
          amount: row.amount,
          description: row.description,
          isActive: 0,
        };
        if (actions.dataObjectForMutation.isSurrenderLine) {
          mutationData.surrenderId = row.surrenderId;
        }

        const response = await generateAndExecuteMutation(
          actions.dataObjectForMutation.inputObjectName,
          mutationData,
          actions.dataObjectForMutation.inputSubObjectName,
          base_url
        );
        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Line Removed successfully.`,
          });
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  /**
   * Handles the activation/deactivation of a given row
   * @param {boolean|number} isActive The current isActive state of the row
   * @param {number} id The ID of the row to activate/deactivate
   * @returns {Promise<void>}
   */
  const handleActivateDeactivate = async (isActive, active, id) => {
    try {
      const currentState = isActive ?? active; // Fallback to `active` if `isActive` is undefined

      const actionType =
        currentState === true || currentState === 1 ? "deactivate" : "activate";

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${actionType} ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionType} it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        let mutationData = null;

        // Prepare the mutation data based on the model name
        if (actions.modelName) {
          mutationData = {
            modelName: actions.activateDeactivate.modelName,
            isActive: !currentState, // Toggle the current state
            moduleIds: [id], // Wrap the ID in an array as expected by the mutation
          };
        } else {
          mutationData = {
            [actions.activateDeactivate.modelName]: [id], // Wrap the ID in an array
            isActive: !currentState, // Toggle the current state
          };
        }

        // Execute the mutation
        const response = await generateAndExecuteMutation(
          actions.activateDeactivate.inputObjectName,
          mutationData,
          actions.activateDeactivate.inputSubObjectName,
          base_url
        );

        // Check the response and show success
        if (response && Object.keys(response)[0]) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${actionType}d successfully.`,
          });

          // Refresh the table after the operation
          setRefreshTable(true);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  /**
   *
   * @param {*} isApproved
   * @param {*} id
   * the function handles approval and disapproval of expenseEntries
   */
  const handleApproveDisapprove = async (isCancelled, id) => {
    try {
      const actionType =
        isCancelled === true || isCancelled === 1 ? "deactivate" : "activate";

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${actionType} ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionType} it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        let mutationData = null;

        // Prepare the mutation data based on the model name
        if (actions.modelName) {
          mutationData = {
            modelName: actions.activateDeactivate.modelName,
            isActive: !isCancelled, // Toggle the current state
            moduleIds: [id], // Wrap the ID in an array as expected by the mutation
          };
        } else {
          mutationData = {
            [actions.activateDeactivate.modelName]: [id], // Wrap the ID in an array
            isActive: !isCancelled, // Toggle the current state
          };
        }

        // Execute the mutation
        const response = await generateAndExecuteMutation(
          actions.activateDeactivate.inputObjectName,
          mutationData,
          actions.activateDeactivate.inputSubObjectName,
          base_url
        );

        // Check the response and show success
        if (response && Object.keys(response)[0]) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${actionType}d successfully.`,
          });

          // Refresh the table after the operation
          setRefreshTable(true);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleActivateDeactivate1 = async (active, id) => {
    try {
      // Determine the action based on the current active state
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${
          active === true || active === 1 ? "deactivate" : "activate"
        } ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${
          active === true || active === 1 ? "deactivate" : "activate"
        } it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        // Prepare mutation data with active state toggled
        const mutationData = {
          [actions.activateDeactivate.modelName]: [id], // Wrap the ID in an array
          isActive: !(active === true || active === 1), // Toggle the active state
        };

        // Perform the mutation
        const response = await generateAndExecuteMutation(
          actions.activateDeactivate.inputObjectName,
          mutationData,
          actions.activateDeactivate.inputSubObjectName,
          base_url
        );

        // If the mutation was successful
        if (Object.keys(response)[0]) {
          // Show success message
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${
              active === true || active === 1 ? "deactivated" : "activated"
            } successfully.`,
          });

          // Update the table data to reflect the change
          setRefreshTable((prev) =>
            prev.map((company) =>
              company.id === id
                ? { ...company, active: !active } // Toggle the active state in the table
                : company
            )
          );
        }
      }
    } catch (error) {
      // Handle errors
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  const handleApproveDisapproveCredit = async (isApproved, id) => {
    try {
      // Show confirmation dialog
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${
          isApproved ? "Disapprove For Credit" : "Approve For Credit"
        } ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${isApproved ? "Disapprove " : "Approve"} it!`,
        cancelButtonText: "No, keep it",
      });

      // If user confirms the action
      if (confirmation.isConfirmed) {
        let mutationData = null;

        if (actions.modelName) {
          mutationData = {
            modelName: actions.approveddisapproved.modelName,
            isApproved: !isApproved, // Toggle approval state
            moduleIds: [id], // Send ID in an array as expected
          };
        } else {
          mutationData = {
            [actions.approveddisapproved.modelName]: [id],
            isApproved: !isApproved, // Toggle approval state
          };
        }

        // Execute mutation with the updated approval state
        const response = await generateAndExecuteMutation(
          actions.approveddisapproved.inputObjectName,
          mutationData,
          actions.approveddisapproved.inputSubObjectName,
          base_url
        );

        if (response && Object.keys(response)[0]) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${
              !isApproved ? "Approved" : "Disapproved"
            } successfully.`,
          });

          // Refresh the table to show updated data
          setRefreshTable(true);
        }
      }
    } catch (error) {
      // Handle error case
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleApprove = async (row) => {
    try {
      const isApproveAction = row.statusDescription !== "Approved";
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to approve this ${actions.actionName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes,  approve  it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        let mutationData = {
          applicationId: row.id,
          userId: row.userId,
          status: 1,
          // Use the reason if provided
        };

        const response = await generateAndExecuteMutation(
          actions.dataObject.inputObjectName,
          mutationData,
          actions.dataObject.inputSubObjectName,
          base_url
        );

        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.actionName} approved successfully.`,
          });
          // Refresh table after the operation
          setRefreshTable(true);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleReject = async (row) => {
    try {
      const isApproveAction = row.statusDescription !== "Approved";
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to Reject
        this ${actions.actionName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes,  Reject it!`,
        cancelButtonText: "No, keep it",
        input: "text",
        inputPlaceholder: "Enter the reason for rejection",
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason for rejection!";
          }
        },
      });

      if (confirmation.isConfirmed) {
        let mutationData = {
          applicationId: row.id,
          userId: row.userId,
          status: 2,
          comments: confirmation.value, // Use the reason if provided
        };

        const response = await generateAndExecuteMutation(
          actions.dataObject.inputObjectName,
          mutationData,
          actions.dataObject.inputSubObjectName,
          base_url
        );

        if (Object.values(response)[0] != null) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.actionName}
            rejected
             successfully.`,
          });
          // Refresh table after the operation
          setRefreshTable(true);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  const handleOpenPaymentDialog = (row) => {
    setPaymentDialogOpen(true);
  };

  const handlesetSaleOrderPaymentAmount = (row) => {
    setSaleOrderPaymentAmount(parseInt(row));
  };

  async function fetchData() {
    try {
      const caseIdFromSession = JSON.parse(localStorage.getItem("CaseId"));
      let inputFields = "";
      if (actions.dataObject.inputFields) {
        inputFields = actions.dataObject.inputFields;
      } else {
        inputFields = {
          caseId: caseIdFromSession,
          isActive: true,
        };
      }

      if (actions.dataObject && !actions.dataObject.isDocumentUplod) {
        const data = await fetchDataEngine(
          actions.dataObject.inputObjectName,
          actions.dataObject.inputSubObjectName,
          inputFields,
          actions.dataObject.returnFields,
          base_url
        );

        if (data) {
          setData(data);
        }
      } else if (actions.dataObject.isDocumentUplod) {
        inputFields = {
          caseId: caseIdFromSession,
        };
        const data = await fetchDataEngineCustomized(
          actions.dataObject.inputObjectName,
          actions.dataObject.inputSubObjectName,
          inputFields,
          actions.dataObject.returnFields,
          base_url
        );
        if (data) {
          setData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    let isMounted = true;

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (refreshTable) {
      fetchData();
      setRefreshTable(false);
    }
  }, [refreshTable]);
  async function fetchAllData() {
    try {
      let fetchedData = null;
  
      if (query && base_url) {
        const response = await graphqlQuery(query, base_url); // Fetch data from GraphQL API
  
        if (response && response.data) {
          const { allLoanApplications } = response.data;
          if (allLoanApplications?.results) {
            // Parse the results if it is a JSON string
            fetchedData = JSON.parse(allLoanApplications?.results);
            console.log("Parsed Data:", fetchedData); // Add this log

          }
        }
      }
  
      if (fetchedData) {
        setData(fetchedData); // Set the parsed data
      } else {
        setData([]); // Default to empty data if no data is fetched
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Set empty data in case of error
    }
  }
  

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchAllData();
    }
    return () => {
      isMounted = false;
    };
  }, [query, base_url]);

  useEffect(() => {
    if (refreshTable) {
      fetchAllData(); // Re-fetch data when refresh is triggered
      setRefreshTable(false); // Reset refreshTable flag after refreshing
    }
  }, [refreshTable]); // Dependency: `refreshTable`

  const filterActionsByRole = (action) => {
    if (actions[action].permission === "hasNoPermission") {
      return actions[action].roleGroupId.includes(RoleGroupId);
    } else if (actions.isSubRight) {
      return hasSubRightPermission(actions.entity, actions[action].permission);
    } else {
      return hasPermission(actions.entity, actions[action].permission);
    }
  };

  const columnsWithActions = [
    ...generatedColumn.map((column) => {
      if (column.field === "id") {
        return {
          ...column,
          renderCell: (params) => params.api.getRowIndex(params.row.id) + 1,
        };
      }
      return column;
    }),
    ...(actions.showStatus !== true
      ? [
          {
            field: "isActive",
            headerName: "Status",
            flex: 0.5,
            renderCell: ({ row: { isActive } }) => {
              const statusText =
                isActive === true || isActive === 1 ? "Active" : "Inactive";
              const statusColor =
                isActive === true || isActive === 1 ? "green" : "red";
              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),
    ...(actions.showDelivery === true
      ? [
          {
            field: "deliveryStatus",
            headerName: "Delivery Status",
            flex: 0.5,
            renderCell: ({ row: { deliveryStatus } }) => {
              const statusText =
                deliveryStatus === true || deliveryStatus === 1
                  ? "Received"
                  : "Pending Delivery";
              const statusColor =
                deliveryStatus === true || deliveryStatus === 1
                  ? "green"
                  : "red";
              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),
    ...(actions.showPayments === true
      ? [
          {
            field: "paymentStatus",
            headerName: "Payment Status",
            flex: 0.5,
            renderCell: ({ row: { paymentStatus } }) => {
              const statusText =
                paymentStatus === true || paymentStatus === 1
                  ? "paid"
                  : "Pending Payements";
              const statusColor =
                paymentStatus === true || paymentStatus === 1 ? "green" : "red";
              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Select
              value=""
              displayEmpty
              onChange={(e) => handleAction(e.target.value, row.id, row)}
            >
              <MenuItem value="" disabled>
                Actions
              </MenuItem>
              {Object.keys(actions)
                .filter(
                  (action) =>
                    action !== "add" &&
                    actions[action].Show_Button &&
                    filterActionsByRole(action)
                )
                .map((action) => {
                  if (action === "activateDeactivate") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleActivateDeactivate(row.isActive, row.id)
                        }
                      >
                        {row.isActive === true || row.isActive === 1
                          ? "Deactivate"
                          : "Activate"}
                      </MenuItem>
                    );
                  }
                  if (action === "approveddisapproved") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleApproveDisapproveCredit(row.creditApproved)
                        }
                      >
                        {row.creditApproved === true
                          ? "Disapprove For Credit"
                          : "Approve For Credit"}
                      </MenuItem>
                    );
                  }
                  if (action === "payments") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handlesetSaleOrderPaymentAmount(row.outstanding)
                        }
                      >
                        {parseInt(row.outstanding) > 0 ? "Make Payment" : null}
                      </MenuItem>
                    );
                  }
                  return (
                    actions[action] && (
                      <MenuItem key={actions[action].key} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </Box>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsEditing(false);
    setEditData(null);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };
  const handleOpenTaskModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setTaskModalOpen(true);
    setEditData(itemToEdit);
  };
  const handleAssignDocumentAccessModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setAssignDocumentAccessOpen(true);
    setEditData(itemToEdit);
  };
  const handleEdit = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setEditData(itemToEdit);
    setIsEditing(true);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleAction = async (action, id, row) => {
    switch (action) {
      case "edit":
        handleEdit(id);
        break;
      case "approveddisapproved":
        handleApproveDisapproveCredit(row.creditApproved, id);
        break;
      case "ViewRelievers":
        localStorage.setItem("MyLeaveApplicationsData", JSON.stringify(row));
        navigate("/relievers");
        break;
      case "add":
        handleAdd();
        break;
      case "documentAccess":
        handleAssignDocumentAccessModal(id);
        break;
      case "ViewPurchaseOrderItems":
        localStorage.setItem("PurchaseOrderData", JSON.stringify(row));
        navigate("/pos-purchase-orders-items");
        break;
      case "ViewSaleOrderItems":
        localStorage.setItem("SaleOrderData", JSON.stringify(row));
        navigate("/pos-sales-orders-items");
        break;
      case "ViewCase":
        localStorage.setItem("CaseId", JSON.stringify(id));
        navigate("/case-details");
        break;
      case "AssignTask":
        handleOpenTaskModal(id);
        break;
      case "approve":
        handleApprove(row);
        break;
      case "ViewSurrenderLineByApplication":
        localStorage.setItem("SurrenderId", JSON.stringify(row));
        navigate("/imprest-Surrender-Lines");
        break;
      case "viewStaffClaimsLines":
        localStorage.setItem("claimApplicationId", JSON.stringify(row));
        navigate("/staff-claim-Lines");
        break;
      case "decline":
        handleReject(row);
        break;
      case "markIsDelivered":
        await MarkIsDelivered(row);
        break;
      case "processCustomeBalance":
        await ProcessCustomerOutstandingBalance(row);
        break;
      case "markIsVendorPaid":
        await handleOpenMarkPaidModal(id);
        break;
      case "removeSurrenderLine":
        await removeSurrenderLine(row);
        break;
      case "submit":
        await handleSubmit(id);
        break;
      case "RemoveItem":
        await handleRemoveTeamMember(row);
        break;
      case "activateDeactivate":
        handleActivateDeactivate(row.isActive, id);
        break;
      case "activateDeactivate1":
        handleActivateDeactivate1(row.active, id);
        break;
      case "payments":
        const newOrderId = row.id; // Assuming 'orderId' is the field in your row data
        localStorage.setItem("orderId", newOrderId); // Store the orderId in local storage
        handleOpenPaymentDialog(); // Open the payment dialog
        break;
      default:
        break;
    }
  };

  const handleSearch = (input) => {
    setSearchTerm(input); // Update searchTerm state

    // Use the generatedColumns if columns is null or undefined
    const activeColumns = columns ?? generatedColumn;

    // Ensure that activeColumns is defined and is an array before filtering
    if (activeColumns && Array.isArray(activeColumns)) {
      if (input.trim() !== "") {
        const filteredData = data.filter((row) =>
          activeColumns.some((column) => {
            const cellValue = row[column.field];
            return (
              typeof cellValue === "string" &&
              cellValue.toLowerCase().includes(input.toLowerCase())
            );
          })
        );
        setData(filteredData);
      } else {
        setRefreshTable(true); // Reset the table when the search term is cleared
      }
    } else {
      console.error(
        "Columns or generatedColumns are not available for search."
      );
    }
  };

  return (
    <Box m="21">
      <Box
        height="75vh"
        m="0px 0 0 0"
        // width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header title={title} subtitle={subtitle} />
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <TextField
              variant="outlined"
              size="medium"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on input change
              placeholder={`Search ${title}`} // Use placeholder instead of label
              sx={{
                mr: 2,
                width: {
                  xs: "100%", // Full width on extra-small screens
                  sm: "100%", // 75% width on small screens
                  md: "100%", // 60% width on medium screens
                  lg: "400px", // 50% width on large screens
                  xl: "400px", // 40% width on extra-large screens
                },
                height: "40px",
                backgroundColor: colors.blueAccent[900],
                borderRadius: "25px", // Add border radius
                "& .MuiOutlinedInput-root": {
                  paddingLeft: "40px", // Add padding to prevent overlap with the icon
                  "& fieldset": {
                    border: "none", // Remove the border
                  },
                },
                "& .MuiInputAdornment-root": {
                  position: "absolute", // Position the icon absolutely
                  left: "10px", // Position icon within the field
                },
                "& .MuiInputBase-input": {
                  textAlign: "left", // Align text to the left
                  padding: "12px 0", // Adjust padding to align the text vertically
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {actions.add &&
            actions.add.Show_Button &&
            (actions.isSubRight
              ? hasSubRightPermission(actions.entity, actions.add.permission)
              : hasPermission(actions.entity, actions.add.permission)) && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="contained"
                  size="sm"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={() => handleAction("add")}
                >
                  <Typography sx={{ color: colors.primary[400], mx: 1 }}>
                    {actions.add.button_name}
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
        <DataGrid
          checkboxSelection
          rows={data || DataFromGetBy || []}
          columns={columnsWithActions}
          components={{ Toolbar: GridToolbar }}
          sx={{ minWidth: isMobile ? "auto" : "auto", width: "100%" }}
          getRowId={getRowId}
        />
        {OpenMarkPaidModal && (
          <MarkPaid
            data={editData}
            open={OpenMarkPaidModal}
            onClose={() => setOpenMarkPaidModal(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}
        {assignDocumentAccessOpen && (
          <DocumentAccessModal
            data={editData}
            open={assignDocumentAccessOpen}
            onClose={() => setAssignDocumentAccessOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}
        {/* <Payment
          open={paymentDialogOpen}
          onClose={() => setPaymentDialogOpen(false)}
          onConfirmPayment={() => {
            setPaymentDialogOpen(false);
            setRefreshTable(true);
            // setSnackbarMessage("Payment successful! Thank you.");
            // setSnackbarOpen(true);
          }}
          saleOrderPaymentAmount={saleOrderPayments}
          saleOrderPayment={true}
        /> */}
        <AnchorTemporaryDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          FormComponent={() => (
            <FormComponent
              isEditing={isEditing}
              data={editData}
              onAction={() => setRefreshTable(true)}
              onClose={() => setDrawerOpen(false)}
              refetchData={() => setRefreshTable(true)}
            />
          )}
        />
        {isMobile && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullScreen={isMobile}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setDialogOpen(false)}
                aria-label="close"
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormComponent
                onClick={() => setDialogOpen(false)}
                isEditing={isEditing}
                courtData={editData}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Box>
  );
};

export default DynamicTable;
