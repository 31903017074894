import DynamicHorizontalTabs from "../../data/DynamicTable/DynamicHorizontalTab";



const HorizontalTab = () => {
  const rightId = localStorage.getItem("RightIdFromSidebar");
  return (
    <div className="container-fluid">
      <DynamicHorizontalTabs rightId={rightId} />
    </div>
  );
};

export default HorizontalTab;