import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import {
  CREATE_RIGHT,
  UPDATE_RIGHT,
  ALL_MODULES,
} from "../../../data/RoleManagementData"; // Import your GraphQL queries and mutations
import swal from "sweetalert";
import { useState, useEffect } from "react";

import { loanManagementUrl, setupManagementUrl } from "../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import Swal from "sweetalert2";
import DynamicForm from "../../../data/Axios/DynamicForm";

const setup_url = loanManagementUrl.uri;

const Form = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const [disbursementData, setDisbursmentDAta] = useState([]);
  const [loanding, setLoading] = useState("false");

  const initialValues = {
    EntryOrder: props.data ? props.data.EntryOrder : "",
    Setting: props.data ? props.data.Setting : "",
    Value: props.data ? props.data.Value : "",
  };

  const validationSchema = yup.object().shape({
    Value: yup.string().required("Value required"),
  });
  useEffect(() => {
    async function fetchAllData() {
      try {
        const data = await fetchJsonDynamic(
          "disbursmentLevel",
          "disbursmentLevel",
          null,
          setupManagementUrl.uri
        );
        setDisbursmentDAta(JSON.parse(data.results));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const setDropdownOpen = () => {
    setDropdownOpen(true);
  };
  const setDropdownClose = () => {
    setDropdownOpen(false);
  };

  // Create disbursement options array
  const disbursementOptions = disbursementData
    ? disbursementData.map((status) => ({
        parent_key: status.ID, // Correct syntax for creating an object
        label: status.Name,
        value: String(status.ID), // Use `String()` to ensure value is a string
      }))
    : []; // Default to an empty array if disbursementData is null/undefined

  // Define fields array
  const fields = [
    // Conditional field based on props.data.Setting
    props.data.Setting === "Disbursement LevelId"
      ? {
          name: "Value",
          label: "Value*",
          type: "select",
          options: disbursementOptions, // Pass the generated options
          required: true,
          parent: "main",
          gridColumn: "span 4",
        }
      : {
          name: "Value",
          label: "Value*",
          type: "text",
          required: true,
          gridColumn: "span 2",
        },
  ];

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { Value } = values;
      const variables = { id: props.data.Id, value: Value };

      const response = await executeAnyMutation(
        "updateLoanSettings",
        variables,
        "loanSettings",
        false,
        setup_url
      );
      if (response) {
        props.onClose();
        props.onAction();

        // Show success message after form has closed
        await Swal.fire(
          "Success!",
          `Loan Settings has been ${
            props.isEditing ? "updated" : "created"
          } successfully`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    // Set submitting to false to indicate the form submission is complete
    setSubmitting(false);

    // Close the modal or perform any other actions after submission
  };
  console.log("disbursementData:", disbursementData);

  return (
    <DynamicForm
      fields={fields}
      onSubmit={handleSubmit}
      title={`Edit ${props.data.Setting}`}
      subtitle={`Edit ${props.data.Setting}`}
      initialValues={initialValues}
      //   onFieldChange={onFieldChange}
    />
  );
};
export default Form;