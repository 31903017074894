import { Box } from "@mui/material";
import { AccountsandFinanceurl } from "../../../../config";
import AccountCategoryForm from "./form";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
const base_url = AccountsandFinanceurl.uri;

function AccountCategoriesTable() {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    isSubRight: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    dataObject: {
      ignoreFields: [
        "Status",
        "CreatedBy",
        "ParentAccount",
        "OldParent",
        "UpdatedBy",
        "UpdatedDate",
        "CreatedDate",
        "CompanyId",
        "Company"
      ],
      objectName: "accountCategory",
      parameters: "",
    },
    showStatus : true,
  };
  return (
    <Box m="2px">
      <DynamicTableForJson
        title="Account Categories"
        subtitle="Create, view or edit account categories"
        FormComponent={AccountCategoryForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default AccountCategoriesTable;
