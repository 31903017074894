import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  useTheme,
  Card,
} from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
  allReportTypes,
} from "../../../data/Axios/queries";
import {
  setupManagementUrl,
  reportsManagementUrl,
  emailService,
} from "../../../config";
// import { generateAndExecuteMutation } from "../../../data/Axios/DynamicService";
import { generateReport } from "../../../data/DynamicTable/DynamicFunctions";
import { executeAnyMutation, fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = reportsManagementUrl.uri

const Filter = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportName, setReportName] = useState([]);
  const [filteredMinorOptions, setFilteredMinorOptions] = useState([]);
  const [filteredReportTypesOptions, setFilteredReportTypesOptions] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReportTypeID, setSelectedReportTypeID] = useState(null);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const minorReportsDataResponse = await fetchJsonDynamic(
          "reportsMinorClassifications",
          null,
          null,
          base_url
        );
        if (minorReportsDataResponse?.results) {
          const minor = JSON.parse(minorReportsDataResponse?.results);
          setMinor(minor || []);
          const minorOptions = minor
                      ? minor.map((item) => ({
                          parent_key: item.id,
                          value: item.Id,
                          label: item.MinorClassification,
                        }))
                      : [];
        }
        const majorReportsDataResponse = await fetchJsonDynamic(
          "reportsMajorClassifications",
          null,
          null,
          base_url
        );
        if (majorReportsDataResponse?.results) {
          const results = JSON.parse(majorReportsDataResponse?.results);
          setMajor(results || []);
        }
        const reportTypesDataResponse = await fetchJsonDynamic(
          "reportTypes",
          null,
          null,
          base_url
        );
        if (reportTypesDataResponse?.results) {
          const results = JSON.parse(reportTypesDataResponse?.results);
          setReportTypes(results || []);
          const reportTypeOptions = results
                      ? results.map((item) => ({
                          parent_key: item.id,
                          value: item.Id,
                          label: item.ReportName,
                        }))
                      : [];
          // setFilteredReportTypesOptions(reportTypeOptions || [])
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major
    ? major.map((item) => ({
        parent_key: item.id,
        value: item.Id,
        label: item.MajorClassification,
      }))
    : [];

  const fields = [
    {
      name: "MajorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: true,
      parent: "main",
      gridColumn: "span 1",
    },
    {
      name: "MinorClassificationID",
      label: "Minor Classification",
      type: "select",
      options: filteredMinorOptions,
      required: true,
      gridColumn: "span 1",
      parent: "main",
    },
    {
      name: "reportTypeID",
      label: "Report Type",
      type: "select",
      // type: "select",
      options: filteredReportTypesOptions,
      required: true,
      gridColumn: "span 1",
      onChange: (e) => setSelectedReportTypeID(e.target.value),
    },
    ...(selectedReportTypeID !== "1" && selectedReportTypeID !== "2"
      ? [
          {
            name: "StartDate",
            label: "Start Date",
            type: "date",
            gridColumn: "span 1",
          },
          {
            name: "EndDate",
            label: "End Date",
            type: "date",
            gridColumn: "span 1",
          },
        ]
      : []),
  ];

  const initialValues = {
    MajorClassificationID: props.data ? props.data.majorClassificationID : "",
    MinorClassificationID: props.data ? props.data.minorClassificationID : "",
    ReportName: props.data ? props.data.reportName : "",
    StartDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"), // Default to one week ago
    EndDate: dayjs().format("YYYY-MM-DD"), // Default to today
  };

  const onFieldChange = async (field, value) => {
    let updatedOptions = {};

    if (field.name === "MajorClassificationID") {
      // Filter minor classification options based on the selected major classification ID
      const filteredMinorOptions = minor
        .filter((item) => item.MajorClassificationID === value)
        .map((item) => ({
          parent_key: item.MajorClassificationID,
          value: item.Id,
          label: item.MinorClassification,
        }));

      // Clear report type options when major classification changes
      setFilteredReportTypesOptions([]);

      // Store filtered options in the updatedOptions object
      updatedOptions = {
        MinorClassificationID: filteredMinorOptions,
      };

      // Update the state for filtered minor options
      setFilteredMinorOptions(filteredMinorOptions);
    }
    if (field.name === "MinorClassificationID") {
      // Filter report types based on the selected minor classification ID
      const filteredReportTypesOptions = reportTypes
        .filter((item) => item.MinorClassificationID === value)
        .map((item) => ({
          parent_key: item.MinorClassificationID,
          value: item.Id,
          label: item.ReportName,
        }));

      // Store filtered options in the updatedOptions object
      updatedOptions = {
        ...updatedOptions,
        reportTypeID: filteredReportTypesOptions,
      };

      // Update the state for filtered report types
      setFilteredReportTypesOptions(filteredReportTypesOptions);
    }
    return updatedOptions;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { reportTypeID, StartDate, EndDate } = values;
      const ReportType =
        reportTypes.find((item) => item.Id === reportTypeID)?.ReportName || "";

      const mutationData = {
        ReportTypeId: reportTypeID,
        ReportType,
        StartDate,
        EndDate,
      };

      // Open a new tab immediately, which will not be blocked
      const newTab = window.open("", "_blank");

      // Write a simple loading message or animation to the newly opened tab
      newTab.document.write(`
        <html>
          <head>
            <title>Loading...</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f0f0;
              }
              .loading {
                font-size: 24px;
                color: #333;
              }
            </style>
          </head>
          <body>
            <div class="loading">Loading, please wait...</div>
          </body>
        </html>
      `);
      newTab.document.close(); // Necessary to complete the document creation

      const response = await generateReport(
        mutationData,
        reportsManagementUrl.generate_report_uri,
        setIsLoading
      );

      if (response.ok) {
        const htmlContent = await response.text();

        // Replace the loading message with the actual content
        newTab.document.open(); // Reopen the document to overwrite it
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        console.error("Error:", response.statusText);
        newTab.close(); // Close the tab if there's an error
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <Card m="10px" sx={{ background: `${colors.primary[400]} !important` }}>
      <DynamicForm
        fields={fields}
        onSubmit={handleSubmit}
        title="Report Browser"
        subtitle="Please select the report parameters"
        initialValues={initialValues}
        onFieldChange={onFieldChange}
      />
    </Card>
  );
};

export default Filter;
