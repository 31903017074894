import React from "react";
import { ReactTyped } from "react-typed";
import landingImage from "./../Assets/log_in_cover_1.png";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b from-black via-gray-900 to-gray-800 h-screen flex items-center px-6 md:px-12 lg:px-24">
      <div className="flex flex-col justify-center items-start text-left w-full md:w-1/2 space-y-6">
        
        <h1 className="text-white  sm:text-6xl text-4xl font-extrabold leading-tight">
          Manage Your Loans Efficiently
        </h1>
        <div className="flex flex-col sm:flex-row items-start ">
          <p className="text-gray-300  sm:text-4xl text-2xl font-semibold p">
            Your One-Stop Solution for 
            <ReactTyped
            className="text-[#00df9a]  sm:text-4xl text-2xl font-semibold pl-0 sm:pl-2"
            strings={[
              "Personal Loans",
              "Business Financing",
              "Client Insights",
              "Repayment Tracking",
              "Loan Management",
            ]}
            typeSpeed={100}
            backSpeed={50}
            loop={true}
          />
          </p>
          
        </div>
        <p className="text-gray-400 md:text-2xl text-xl font-light leading-relaxed max-w-md">
          One Plus One provides seamless loan management, helping you track
          repayments, manage client accounts, and grow your financial portfolio.
        </p>
        <button
          className="bg-[#00df9a] text-black w-[220px] rounded-full font-semibold my-6 py-3 hover:bg-[#00c77a] transition duration-300"
          onClick={() =>
            navigate("/login")
          }
        >
          Get Started
        </button>
      </div>
      <div className="hidden md:flex w-full md:w-1/2 justify-center items-center">
        <img
          src={landingImage}
          alt="Project Showcase"
          className="rounded-lg shadow-lg max-h-[100%] max-w-[100%]"
        />
      </div>
    </div>
  );
}

export default Hero;
