import React from 'react'

function CopyRight() {
  return (
    <div className="bg-gray-900 lg:col-span-3 flex justify-center">
      <p className="text-gray-500 text-center">
        One Plus One © 2020 - All Rights Reserved
      </p>
    </div>
  );
}

export default CopyRight
