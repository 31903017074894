/* eslint-disable default-case */
import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Drawer,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  BarChart as BarChartIcon,
  SettingsOutlined,
} from "@mui/icons-material";
import { tokens } from "../../theme";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Groups2Outlined } from "@mui/icons-material";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import CasesIcon from "@mui/icons-material/Cases";
import WorkIcon from "@mui/icons-material/Work";
import AddchartIcon from "@mui/icons-material/Addchart";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CheckIcon from "@mui/icons-material/Check";
import GavelIcon from "@mui/icons-material/Gavel";
import TerrainIcon from "@mui/icons-material/Terrain";
import MessageIcon from "@mui/icons-material/Message";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AppsIcon from "@mui/icons-material/Apps";
import Axios from "axios";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FeedIcon from "@mui/icons-material/Feed";
import ApprovalIcon from "@mui/icons-material/Approval";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoneyIcon from "@mui/icons-material/Money";
import AddHomeIcon from "@mui/icons-material/AddHome";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import GroupsIcon from "@mui/icons-material/Groups";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GradingIcon from "@mui/icons-material/Grading";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

function getIconByName(iconName) {
  switch (iconName) {
    case "home":
      return <HomeOutlinedIcon />;
    case "Product":
      return <ProductionQuantityLimitsIcon />;
    case "users":
      return <PeopleOutlinedIcon />;
    case "contacts":
      return <ContactsOutlinedIcon />;
    case "receipt":
      return <ReceiptOutlinedIcon />;
    case "Client":
      return <PersonIcon />;
    case "diaries":
      return <CalendarTodayOutlinedIcon />;
    case "help":
      return <HelpOutlinedIcon />;
    case "barChart":
      return <BarChartOutlinedIcon />;
    case "pieChart":
      return <PieChartOutlinedIcon />;
    case "timeline":
      return <TimelineOutlinedIcon />;
    case "menu":
      return <MenuOutlinedIcon />;
    case "map":
      return <MapOutlinedIcon />;
    case "analytics":
      return <AnalyticsOutlinedIcon />;
    case "Accounts":
      return <ManageAccountsIcon />;
    case "Dashboard":
      return <DashboardIcon />;
    case "clients":
      return <GroupIcon />;
    case "Cases":
      return <WorkIcon />;
    case "diaries":
      return <MenuBookIcon />;
    case "adhoc":
      return <AccountTreeIcon />;
    case "chartofAccounta":
      return <AddchartIcon />;
    case "reports":
      return <SummarizeIcon />;
    case "companies":
      return <LocationCityIcon />;
    case "module":
      return <ViewModuleIcon />;
    case "role":
      return <SettingsApplicationsIcon />;
    case "rolegroup":
      return <AddTaskIcon />;
    case "rights":
      return <CheckIcon />;
    case "subright":
      return <AppsIcon />;
    // setup Management
    case "Court Setup-icon":
      return <GavelIcon />;
    case "Geography-icon":
      return <TerrainIcon />;
    case "Message-icon":
      return <MessageIcon />;
    case "othersetups":
      return <SettingsApplicationsIcon />;
    case "Lead-icon":
      return <ViewModuleIcon />;
    case "systemsetups":
      return <SettingsSuggestIcon />;
    case "module":
      return <ViewModuleIcon />;
    case "leave Approvals":
      return <CheckCircleIcon />;
    case "leave applications":
      return <ApprovalIcon />;
    case "pos-setups":
      return <SettingsApplicationsIcon />;
    case "imprestApprovals":
      return <CheckCircleIcon />;
    case "my imprest applications":
      return <MoneyIcon />;
    case "Staff Claim Approvals":
      return <CreditScoreIcon />;
    case "mystaffclaims":
      return <PaymentIcon />;
    case "expenses":
      return <PaymentIcon />;
    case "Imprest Surrender":
      return <LocalOfferIcon />;
    case "PettyCash":
      return <MoneyIcon />;
    case "PointOfSale":
      return <PointOfSaleOutlinedIcon />;
    case "customer":
      return <GroupIcon />;
    case "groups":
      return <Diversity3Icon />;
    case "suspendedtransactions":
      return <QuizOutlinedIcon />;
    case "manualrecon":
      return <UploadFileOutlinedIcon />;
    case "accounttransfers":
      return <CurrencyExchangeIcon />;
    case "processauthorization":
      return <VerifiedUserIcon />;
    case "manageaccounttransfers":
      return <GradingIcon />;
    case "customermigration":
      return <PersonRemoveIcon />;
    case "groupmigration":
      return <GroupRemoveIcon />;
    case "loanschedule":
      return <PendingActionsIcon />;
    case "sababisha":
      return <PublishedWithChangesIcon />;
    case "approval":
      return <BookmarkAddedOutlinedIcon/>;
    case "bookloan":
        return <BookmarkAddOutlinedIcon />;
    

    //modules
    case "setupmanagement":
      return <SettingsApplicationsIcon />;
    case "usermanagement":
      return <PersonIcon />;
    case "rolemanagement":
      return <AddTaskIcon />;
    case "accountsandfinancemanagement":
      return <ManageAccountsIcon />;
    case "clientmanagement":
      return <GroupIcon />;
    case "casemanagement":
      return <WorkIcon />;
    case "companymanagement":
      return <LocationCityIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "hrmanagement":
      return <FeedIcon />;
    case "customers":
      return <GroupsIcon />;
    case "settings":
      return <SettingsOutlined />
  }
}

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  setMenuSelected,
  selectedMenu,
  submenuitems,
  isCollapsed,
  setIsCollapsed,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <SubMenu
      active={selectedMenu === title}
      style={{ color: colors.grey[200] }}
      icon={
        <Tooltip
          title={
            <Typography
              style={{
                color: colors.grey[200],
                width: "auto",
                fontSize: "1rem", // Adjust the font size as needed
              }}
            >
              {isCollapsed ? title : ""}
            </Typography>
          }
          placement="right"
        >
          <Box>{getIconByName(icon)}</Box>
        </Tooltip>
      }
      title={title}
      to={to}
      //
      onOpenChange={() => setMenuSelected(title)}
    >
      {submenuitems.map((submenuitem, j) => (
        <Tooltip
          key={j}
          title={
            <Typography
              style={{
                color: colors.grey[200],
                width: "auto",
                fontSize: "0.8rem", // Adjust the font size as needed
              }}
            >
              {isCollapsed ? submenuitem.title : ""}
            </Typography>
          }
          placement="right"
        >
          <MenuItem
            active={selected === submenuitem.title}
            style={{ color: colors.grey[200] }}
            //
            onClick={() => {
              setSelected(submenuitem.to);
              setMenuSelected(title);
              localStorage.setItem("RightIdFromSidebar", submenuitem.id);
              localStorage.setItem("SelectedRight", submenuitem.title);
            }}
            icon={<Box>{getIconByName(submenuitem.icon)}</Box>}
            to={submenuitem.to}
          >
            <Typography
              style={{
                color: colors.grey[300], // Set the color for subrights' text here
              }}
            >
              <Link to={submenuitem.to} style={{ color: "inherit" }}>
                {submenuitem.title}
              </Link>
            </Typography>
          </MenuItem>
        </Tooltip>
      ))}
    </SubMenu>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [selectedMenu, setMenuSelected] = useState("Dashboard");
  const [apiData, setData] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isButtonVisible, setButtonVisible] = useState(true);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const memoizeReturnFilePath = (() => {
    let cachedResult;
    let cachedCompanyId;
    let companyName;

    const getDecodedToken = () => {
      try {
        return JSON.parse(localStorage.getItem("decodedToken"));
      } catch (e) {
        return null; // or some default value
      }
    };

    return () => {
      const decodedToken = getDecodedToken();
      const currentCompanyId = decodedToken?.CompanyId;

      if (cachedResult !== undefined && cachedCompanyId === currentCompanyId) {
        return [cachedResult, companyName];
      }

      cachedCompanyId = currentCompanyId;
      companyName = decodedToken?.Company;

      if (localStorage.getItem("filePath") === "null") {
        cachedResult = `../../assets/m.svg`;
      } else {
        cachedResult = localStorage.getItem("filePath");
      }

      return [cachedResult, companyName];
    };
  })();

  const [filePath, companyName] = memoizeReturnFilePath();

  useEffect(() => {
    const rights = JSON.parse(localStorage.getItem("rights"));
    if (!rights) {
      localStorage.removeItem("token");
      localStorage.clear();
      navigate("/login");
    }
    const cleanedData = rights ? JSON.parse(rights.replace(/\\/g, "")) : null;
    setData(cleanedData);

    //restore sidebar state from local storage
    const savedMenuState = localStorage.getItem("selectedMenu");
    const savedSubMenuState = localStorage.getItem("selected");
    if (savedMenuState) setMenuSelected(savedMenuState);
    if (savedSubMenuState) setSelected(savedSubMenuState);
  }, []);

  //save sidebar state to local storage
  useEffect(() => {
    localStorage.setItem("selectedMenu", selectedMenu);
    localStorage.setItem("selected", selected);
  }, [selectedMenu, selected]);

  const sidebarContent = (
    <Box
      sx={{
        height: "170vh",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-sidebar-item": {
          padding: "5px 5px 5px 5px !important",
        },
        "& .pro-sidebar-inner:hover": {
          color: `${colors.primary[500]} !important`,
        },
        "& .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus ": {
          color: `${colors.primary[500]} !important`,
        },
        "& .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover": {
          color: `${colors.primary[500]} !important`,
        },
        "& span.pro-inner-content:focus": {
          color: `${colors.primary[500]} !important`,
        },
      }}
    >
      {isSmallScreen && (
        <IconButton
          style={{
            margin: "3px",
            display: isSmallScreen ? "none" : "block",
            zIndex: isSmallScreen ? -1 : "auto",
          }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <MenuOutlinedIcon />
        </IconButton>
      )}
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "0 0 0 0", color: colors.grey[400] }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="Center"
                alignItems="Center"
                ml="1px"
              >
                <Box display="flex" justifyContent="start" alignItems="start">
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    // src={`../../assets/m.svg`}
                    src={filePath}
                    style={{ cursor: "pointer", borderRadius: "10%" }}
                  />
                </Box>

                <IconButton
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="10px" ms="10px">
              <Box ms="10px" textAlign="Center" sx={{ flexWrap: "wrap" }}>
                <Typography
                  variant="h5"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "1rem" }}
                >
                  {companyName}
                </Typography>
              </Box>
            </Box>
          )}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : "1px"}>
            {apiData.map((menuitem, i) => (
              <Item
                key={i}
                title={menuitem.title}
                icon={menuitem.icon}
                selected={selected}
                setSelected={setSelected}
                setMenuSelected={setMenuSelected}
                selectedMenu={selectedMenu}
                submenuitems={menuitem.children}
                isCollapsed={isCollapsed}
              />
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
  return (
    <Box>
      {isSmallScreen || isMediumScreen ? (
        <>
          {isButtonVisible && (
            <IconButton
              onClick={() => {
                setDrawerOpen(true);
                setButtonVisible(true);
              }}
              style={{ position: "fixed", top: 16, left: 16, zIndex: 1300 }}
            >
              <MenuOutlinedIcon />
            </IconButton>
          )}
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => {
              setDrawerOpen(false);
              setButtonVisible(true);
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {sidebarContent}
          </Drawer>
        </>
      ) : (
        sidebarContent
      )}
    </Box>
  );
};

export default Sidebar;
