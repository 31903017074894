import { createContext, useContext } from "react";
import { AbilityBuilder } from "@casl/ability";

// Create a new context for storing the user's abilities
export const AbilityContext = createContext();

// Custom hook to access the ability instance from the context
export const useAbility = () => useContext(AbilityContext);

export function defineAbilitiesFor(roleRights) {
  const { can, cannot, build } = new AbilityBuilder();
  if (roleRights) {
    roleRights.forEach((role) => {
      role.children.forEach((child) => {
        const permissions = child.permissions;
        const entity = child.title;

        // Check if CreatePermission is equal to 1
        if (permissions.CreatePermission === 1) {
          can("create", entity);
        } else {
          cannot("create", entity);
        }

        // Add other permissions based on your requirements
        if (permissions.EditPermission) can("update", entity);
        if (permissions.ActivatePermission) can("activate", entity);
        if (permissions.ExportPermission) can("export", entity);
      });
    });
  }
  return build();
}

// utils/permissions.js
export const transformRoleRights = () => {
  const rights = JSON.parse(localStorage.getItem("rights"));
  const cleanedData = rights ? JSON.parse(rights.replace(/\\/g, "")) : [];
  const roles = {};
  const permissions = {};

  cleanedData.forEach((role) => {
    role.children.forEach((child) => {
      const permKeyBase = child.title.replace(/\s+/g, "");
      const permKeys = Object.keys(child.permissions);

      permKeys.forEach((perm) => {
        const permissionName = `${permKeyBase}.${perm}`;
        permissions[permissionName] = !!child.permissions[perm];
      });

      if (!roles[role.title]) {
        roles[role.title] = {};
      }

      roles[role.title][child.title] = permKeys.reduce((acc, perm) => {
        acc[`${permKeyBase}.${perm}`] = !!child.permissions[perm];
        return acc;
      }, {});
    });
  });

  return { roles, permissions };
};
