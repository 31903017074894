export const customerFields = [
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "otherNames",
    label: "Other Names *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "mobileNumber",
    label: "Mobile Number *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailAddress",
    label: "Email Address",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  // {
  //   name: "dateOfBirth",
  //   label: "Date of Birth",
  //   type: "date",
  //   gridColumn: "span 2",
  //   required: true,
  // },
  {
    name: "idNoOrPassport",
    label: "ID No or Passport *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "physicalAddress",
    label: "Physical Address",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "postalAddress",
    label: "Postal Address",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "postalCode",
    label: "Postal Code",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
];
export const nextOfKinFields = [
  {
    name: "nextOfKinRelationship",
    label: "Relationship",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "otherNames",
    label: "Other Names",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "nextOfKinMobileNumber",
    label: "Mobile Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "idNoOrPassport",
    label: "ID No or Passport",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },

 
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "currentResidence",
    label: "Current Residence",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "town",
    label: "Town",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "houseName",
    label: "House Name",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "floorNumber",
    label: "Floor Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "roomNumber",
    label: "Room Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "occupation",
    label: "Occupation",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "permanentResidence",
    label: "Permanent Residence",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
];
export const guarantorFields = [
 
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "otherNames",
    label: "Other Names",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "mobileNumber",
    label: "Mobile Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "idNoOrPassport",
    label: "ID No or Passport",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },


  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "currentResidence",
    label: "Current Residence",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "town",
    label: "Town",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "houseName",
    label: "House Name",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "floorNumber",
    label: "Floor Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "roomNumber",
    label: "Room Number",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "occupation",
    label: "Occupation",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "permanentResidence",
    label: "Permanent Residence",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
];
export const customerBusinessDetailsFields = [
  {
    name: "businessName",
    label: "Business Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "yearOfEstablishment",
    label: "Year of Establishment",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "businessLocation",
    label: "Business Location",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "landMark",
    label: "Landmark",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "averageCashSales",
    label: "Average Cash Sales",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },

 
];
