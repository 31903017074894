import Analytics from "../../components/Analytics";
import CopyRight from "../../components/CopyRight";
import Features from "../../components/Features";
import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import NavBar from "../../components/NavBar";
import NewsLetter from "../../components/NewsLetter";


function LandingPage() {
  return (
    <div className="w-full h-auto">
      <NavBar />
      <Hero />
      <Features />
      <Analytics />
      <NewsLetter />
      {/* <Features /> */}
      <Footer />
      <CopyRight />
    </div>
  );
}

export default LandingPage;
