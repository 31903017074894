import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "apollo-link-error";

let hasRefreshed = false;

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
  // if (networkError && networkError.statusCode === 401 && !hasRefreshed) {
  //   hasRefreshed = true;
  //   window.location.reload();
  // }
});

const AppConfig = {
  USER_MANAGEMENT_URL: new HttpLink({
    uri: "https://opobe.mcb.co.ke/opogateway/opoUser",
    // uri: "https://localhost:7131/graphql/",
  }),
  ROLE_MANAGEMENT_URL: new HttpLink({
    uri: "https://opobe.mcb.co.ke/opogateway/OPORole",
    //uri: "https://localhost:7052/graphql/",
  }),
};

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("Token not found in localStorage");
    return {};
  }

  const formattedToken = `Bearer ${token.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
  };
};

// Middleware to add authorization headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const headers = getAuthHeaders();
  if (headers.Authorization) {
    operation.setContext({
      headers: {
        ...headers,
      },
    });
  } else {
    console.warn("Authorization header is missing");
  }

  return forward(operation);
});

export const createUsersClient = () => {
  const userManagementHttpLink = new HttpLink({
    uri: "https://opobe.mcb.co.ke/opogateway/opoUser",
    // uri: "https://localhost:7131/graphql/",
  });
  const userHttpLinkWithAuth = authMiddleware.concat(userManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(userHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const createRoleClient = () => {
  const roleManagementHttpLink = new HttpLink({
    uri: "https://opobe.mcb.co.ke/opogateway/OPORole",
    //uri: "https://localhost:7052/graphql/",
  });
  const roleHttpLinkWithAuth = authMiddleware.concat(roleManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(roleHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};

export const createSetupClient = () => {
  const setupManagementHttpLink = new HttpLink({
    uri: "https://opobe.mcb.co.ke/opogateway/opoSetupManagement",
    // uri: "https://localhost:7266/graphql/",
  });
  const setupHttpLinkWithAuth = authMiddleware.concat(setupManagementHttpLink);

  return new ApolloClient({
    link: onErrorLink.concat(setupHttpLinkWithAuth),
    cache: new InMemoryCache(),
  });
};
export default AppConfig;

export const loginUrl = {
  login: "https://opobe.mcb.co.ke/opogateway/opoUser/login",
  // login: "https://localhost:7131/Login",
  // login:"https://localhost:7091/login/"
};
export const roleManagemenUrl = {
  //uri: "https://localhost:7052/graphql/",
  uri: "https://opobe.mcb.co.ke/opogateway/OPORole",
};
export const userManagementUrl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoUser",
  //uri: "https://localhost:7131/graphql/",
};

export const MCBSwitchService = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoUser/mcbswitch",
  // uri: "https://localhost:7131/mcbswitch/",
};

export const companyManagementUrl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoCompanyManagement",
  //uri: " https://localhost:7239/graphql/",
  upload_logo_uri:
    "https://opobe.mcb.co.ke/opogateway/opoCompanyManagement/UploadLogo",
  // uri:"https://localhost:7157/graphql/",
};

export const smsservice = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoSMS",
  // uri: "https://localhost:7068/graphql/",
};
//customer
export const customerservice = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoCustomer",
  //uri: "https://localhost:7048/graphql/",
  statement: "https://opobe.mcb.co.ke/opogateway/opoCustomer/UploadStatement",
  generate_report_uri:
    "https://opobe.mcb.co.ke/opogateway/opoCustomer/viewReport",
  // generate_report_uri: "https://localhost:7048/viewReport",
};
export const emailService = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoemails",
};
export const AccountsandFinanceurl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoaccountsmanagement",
  //uri: "https://localhost:7237/graphql/",
  upload_file_uri:
    "https://opobe.mcb.co.ke/opogateway/opoaccountsmanagement/ReconUpload",
  // uri:"https://localhost:7157/graphql/",
};

export const ForgetPasswordApiService = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoUser/forgotpassword",
};

export const setupManagementUrl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoSetupManagement",
  // uri: "https://localhost:7210/graphql/",
};
export const inventoryManagementUrl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoinventory",
};
export const uploadIndividualClients = {
  uri: "https://matrix.mcb.co.ke/gateway/Clientmanagement/uploadIndividualClients",
};
export const downloadIndividualClients = {
  uri: "https://matrix.mcb.co.ke/gateway/Clientmanagement/DownloadIndividualTemplate",
};
export const documentManagementUrl = {
  // uri: "https://localhost:7294/graphql",
  uri: "https://opobe.mcb.co.ke/opogateway/opodocumentmanagement",
  baseFilePath:
    "https://opobe.mcb.co.ke/opogateway/opodocumentmanagement/ReadDocument?",
  // baseFilePath: "https://matrix.mcb.co.ke/gateway/documentmanagement/ReadDocumentNoSecurity?"
};

export const salesManagement = {
  uri: "https://opobe.mcb.co.ke/opogateway/oposale",
  // uri: "https://localhost:7022/graphql/",
};
export const notificationservice = {
  //uri:"https://localhost:7057/graphql/",
  uri: "https://opobe.mcb.co.ke/opogateway/opoNotifications",
};
export const loanManagementUrl = {
  //uri: "https://localhost:7113/graphql/",
  uri: "https://opobe.mcb.co.ke/opogateway/opoloans",
};

export const statementUploader = {
  // uri: "https://finsights.mcb.co.ke/api/upload",
  uri: "https://fsbe.mcb.co.ke/finsightsgateway/api/upload",
  bank_url: "https://fsbe.mcb.co.ke/finsightsgateway/api/uploadBankStatement",
};

export const reportsManagementUrl = {
  uri: "https://opobe.mcb.co.ke/opogateway/opoReports",
  // uri: "https://localhost:7056/graphql/",
  generate_report_uri:
    "https://finsights.mcb.co.ke/OPOReports/generateReport",
  engine_uri: "https://finsights.mcb.co.ke/engine/generate-report",
  fs_engine_uri: "https://finsights.mcb.co.ke/engine/getStatementReport",
  fs2_engine_uri:
    "Https://fsbe.mcb.co.ke/finsightsgateway/api/generateFSReport",
};
