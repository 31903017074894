import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { generateAndExecuteMutation } from "../../../../data/Axios/DynamicService";
import { accountFields } from "../../../../data/DynamicTable/AccountsDynamicForms";
import { AccountsandFinanceurl } from "../../../../config";
import { executeAnyMutation } from "../../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = AccountsandFinanceurl.uri;

const AccountsForm = (props) => {
  const initialValues = {
    accountName: props.data ? props.data.AccountName : "",
    accountNumber: props.data ? props.data.AccountNumber : "",
    accountType: props.data ? props.data.AccountType : "",
    currency: props.data ? props.data.Currency : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { accountName, accountNumber, accountType, currency } =
        values;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            accountName,
            accountNumber,
            accountType,
            currency,
          }
        : {
            accountName,
            accountNumber,
            accountType,
            currency,
          };

      const response = await executeAnyMutation(
        props.isEditing ? "updateAccounts" : "createAccounts",
        mutationData,
        "accounts",
        false,
        base_url
      );

      if (response?.results) {
        props.onClose();
        props.onAction();
     
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Account  ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={accountFields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Account S" : "Create Account "}
      subtitle={
        props.isEditing ? "Edit an Existing Account " : "Create a New Account "
      }
    />
  );
};

export default AccountsForm;
