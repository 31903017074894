import { Box, Button, TextField, MenuItem, Select, InputLabel, FormControl, Tooltip, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Header from "../../../components/Header";
import { setupManagementUrl} from "../../../config";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import { allProductChargesAccounts } from "../../../data/Axios/queries";
import Swal from "sweetalert2";
import axios from "axios";


const setup_url = setupManagementUrl.uri;

const Form = (props) => {
  const ProductId = localStorage.getItem("ProductId");
  const [accountSubcategories, setAccountSubcategories] = useState([]); // State for account subcategories

  // Fetch account subcategories on component mount
  useEffect(() => {
    const fetchAccountSubcategories = async () => {
      const token = localStorage.getItem("token");
      const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
      try {
        const response = await axios.post(
          setup_url,
          allProductChargesAccounts,
          { headers: { 
            Authorization: formattedToken,
            "Content-Type": "application/json", } }
        );
  
        console.log("Full Response:", response);
  
        // Ensure the results are correctly accessed from data.accountSubCategory.results
        const resultsString = response?.data?.data?.productChargesAccount?.results;
  
        if (resultsString) {
          // Parse the results string into a JavaScript array
          const accountData = JSON.parse(resultsString);
          console.log("Account Data:", accountData);
          
          // Set the account data to state if it's valid
          setAccountSubcategories(accountData); 
        } else {
          console.error("Results field is not found or is empty.");
        }
      } catch (error) {
        console.error("Error fetching account subcategories:", error);
      }
    };
  
    fetchAccountSubcategories();
  }, []);
  
  const initialValues = {
    chargeName: props.data ? props.data.ChargeName : "",
    chargeAmount: props.data ? props.data.ChargeAmount : "",
    accountId: props.data ? props.data.AccountId || "" : "",  // Add accountId in initialValues
    isPercentage: props.data ? props.data.IsPercentage : false,
  };

  const validationSchema = yup.object().shape({
    chargeName: yup.string().required("Charge Name required"),
    chargeAmount: yup.number().required("Charge Amount required").positive("Charge Amount must be a positive number"),
    accountId: yup.string().required("Account selection required"),  // Add validation for account selection
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { chargeName, chargeAmount, accountId, isPercentage } = values;
      const productId = parseInt(ProductId);
      const variables = props.isEditing
        ? { id: props.data.Id, chargeName, chargeAmount, productId, accountId, isPercentage }
        : { chargeName, chargeAmount, productId, accountId, isPercentage };

      const response = await executeAnyMutation(
        props.isEditing ? "updateProductCharge" : "createProductCharge",
        variables,
        "result",
        false,
        setup_url
      );
      if (response) {
        props.onClose();
        props.onAction();
        await Swal.fire(
          "Success!",
          `Product Charge has been ${props.isEditing ? "updated" : "created"} successfully`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Product Charge" : "Create Product Charge"}
        subtitle={props.isEditing ? "Edit an Existing Product Charge" : "Create a New Product Charge"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Product Charge"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.chargeName}
                name="chargeName"
                error={touched.chargeName && !!errors.chargeName}
                helperText={touched.chargeName && errors.chargeName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Product Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.chargeAmount}
                name="chargeAmount"
                error={touched.chargeAmount && !!errors.chargeAmount}
                helperText={touched.chargeAmount && errors.chargeAmount}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel>Account</InputLabel>
                <Select
                  name="accountId"
                  value={values.accountId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.accountId && !!errors.accountId}
                >
                  {accountSubcategories.map((subcategory) => (
                    <MenuItem key={subcategory.Id} value={subcategory.Id}>
                      {subcategory.AccountName}  {/* Adjust based on subcategory object structure */}
                    </MenuItem>
                  ))}
                </Select>
                {touched.accountId && errors.accountId && (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.accountId}</div>
                )}
              </FormControl>
            </Box>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isPercentage}
                    onChange={(e) => setFieldValue("isPercentage", e.target.checked)}
                    name="isPercentage"
                  />
                }
                label={
                    <Box display="flex" alignItems="center">
                      Is Percentage
                      <Tooltip title="Check if this product charge is Percentage">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
