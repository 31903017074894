import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl } from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import {
  allAccountCategories,
  allAccountTypes,
  allAccounts,
} from "../../../../data/Axios/queries";
import {
  AccountCategoriesColumns,
  AccountSubCategoriesColumns,
  AccountsColumns,
} from "../../../../data/DynamicTable/AccountsColumns";
import AccountCategoryForm from "./form";
import AccountsForm from "./form";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
const base_url = AccountsandFinanceurl.uri;

function AccountsTable() {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    isSubRight: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
      inputObjectName: "bulkActivateDeactivateAccounts",
      modelName: "accountIds",
    },
    dataObject: {
      ignoreFields: [
        "CreatedBy",
        "CreatedDate",
        "CurrencyId",
        "Id",
        "IdentifierId",
        "IdentifierType",
        "IsActive",
        "StatusFlag",
        "Status",
        "UpdatedBy",
        "UpdatedDate",
        "CompanyId",
        "Company",
      ],
      objectName: "accounts",
      parameters: "",
    },
    showStatus: false,
  };
  return (
    <Box m="2px">
      <DynamicTableForJson
        title="Accounts"
        subtitle="Create, view or edit accounts"
        FormComponent={AccountsForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default AccountsTable;
