import React from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Block as BlockIcon,
  CheckCircleOutline,
  PlusOne,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../components/Header";
import AnchorTemporaryDrawer from "../../../components/Drawer";
import { ALL_MODULES, CREATE_MODULE } from "../../../data/RoleManagementData";
import { tokens } from "../../../theme";
import { useState } from "react";
import ModuleForm from "./forms";
import { AllModules } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { roleManagemenUrl } from "../../../config";
import { AddUserButton } from "../../../data/RBAC/DynamicActions";
import { PulseLoader } from "react-spinners"; // Importing the loader\
import { Modulecolumns } from "../../../data/Columns/Columns";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = roleManagemenUrl.uri;

const Modules = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      inputObjectName: "bulkActivateDeactivateModules",
      modelName: "moduleIds",
      inputSubObjectName: null,
      Show_Button: true,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "allModules",
      parameters: "",
    },
    UserId: userId,
    required: "moduleIds",
    // modelName: "bulkActivateDeactivateModules",
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Module Management"
        subtitle="Manage Modules"
        FormComponent={ModuleForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default Modules;
