import React from "react";
import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../config";
import RightsForm from "./forms";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import GenderForm from "./forms";

const base_url = setupManagementUrl.uri;

const GenderTable = () => {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate"],
      objectName: "allGender",
      parameters: "",
    },
    required: "rightIds",
    isSubRight: true,
    showStatus:true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Gender Management"
        subtitle="Manage Gender"
        FormComponent={GenderForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default GenderTable;
