import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
} from "../../../data/Axios/queries";
import { reportsManagementUrl, setupManagementUrl } from "../../../config";
import {
  generateAndExecuteMutation,
  generateMutation,
  graphqlMutation,
} from "../../../data/Axios/DynamicService";
import { graphqlQuery } from "../../../data/Axios/DynamicService";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";

const MinorReportForm = (props) => {
  const base_url = reportsManagementUrl.uri;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const selectedRowId = props.selectedRowId; // Assume this is passed when editing

  useEffect(() => {
    async function fetchAllData() {
      try {
        const Response = await graphqlQuery(allReportMinorClassifications, base_url);
        if (Response) {
          setData(Response.data || []);
        }

        const MajorResponse = await graphqlQuery(allReportsMajorClassifications, base_url);

        const resultsString = MajorResponse.results;
        if (resultsString) {
          const parsedResults = JSON.parse(resultsString);

          if (Array.isArray(parsedResults) && parsedResults.length > 0) {
            setMajor(parsedResults);
          } else {
            console.error("Parsed results is not an array or is empty:", parsedResults);
            setMajor([]);
          }
        } else {
          console.error("Results string is undefined or null:", resultsString);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setMajor([]);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major.map((result) => ({
    parent_key: result.Id,
    value: result.Id,
    label: result.MajorClassification
  }));

  const fields = [
    {
      name: "majorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: false,
      parent: "main",
      gridColumn: "span 2",
    },
    {
      name: "minorClassification",
      label: "Minor Classification",
      type: "text",
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    majorClassificationID: props.data ? props.data.MajorClassificationID : "",
    minorClassification: props.data ? props.data.MinorClassification : "",
  };

  const onFieldChange = async (field, value) => {
    if (field.name === "majorReport") {
      const dependentOptions = [
        { value: "subReport1", label: "Sub Report 1" },
        { value: "subReport2", label: "Sub Report 2" },
      ];
      return { minorReport: dependentOptions };
    }
    return {};
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassificationID, minorClassification } = values;
      const majorClassification =
        major.find((item) => item.Id === majorClassificationID)?.MajorClassification || null;

      const mutationName = props.isEditing
        ? "updateReportsMinorClassification"
        : "createReportsMinorClassification";

      const mutationData = {
        id: selectedRowId || props.data?.Id || null, // Use the selectedRowId for editing, else null for new
        majorClassificationID,
        majorClassification,
        minorClassification,
        createdBy: userId,
      };

      // Generate the mutation
      const mutation = `
        mutation {
          ${mutationName}(
            result: {
              id: ${mutationData.id}
              majorClassification: "${mutationData.majorClassification}"
              minorClassification: "${mutationData.minorClassification}"
              majorClassificationID: ${mutationData.majorClassificationID}
              createdBy: ${mutationData.createdBy}
            }
          ) {
            error
            results
          }
        }
      `;

      console.log("Generated Mutation:", mutation); // Debugging log

      // Execute the mutation
      const response = await graphqlMutation(mutation, base_url);
      props.onAction();
      props.onClose();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Minor Report ${props.isEditing ? "updated" : "created"} successfully.`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      fields={fields}
      onSubmit={handleSubmit}
      title="Minor Report Form"
      subtitle="Fill in the details for the minor report"
      initialValues={initialValues}
      onFieldChange={onFieldChange}
    />
  );
};

export default MinorReportForm;
