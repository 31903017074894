import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { setupManagementUrl } from "../../../config";
import Form from "./forms";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import ProductChargesTable from "../../SetupManagement/ProductCharge";
import ProductChargesAccountsTable from "../ProductChargesAccount";

//const base_url = setupManagementUrl.uri;
const base_url = setupManagementUrl.uri;
const ignoreFields = ["PrecedenceOrder", "UpdatedBy"];

const ProductsTable = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId) 
  const Id = selectedProductId
  console.log("Current selectedProductId:", Id); // Log on every render

  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    ProductChargesTable: {
      key: "ProductCharge",
      button_name: "Product Charges",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
      
    },
    ProductChargesAccountsTable: {
      key: "ProductChargeAccount",
      button_name: "Product Charges Acc",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
      
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "productIds",
      inputObjectName: "bulkActivateDeactivateProducts",
    },
    dataObject: {
      ignoreFields,
      objectName: "allProductsByColumnValues",
      inputObjectName: "parameters",
      parameters: {companyId: companyId},
    },
  };
  const handleAction = (actionType, productId) => {
    if (actionType === "ProductChargesTable") {
      setSelectedProductId(productId); // Set the selected product ID
    }
    // Handle other actions as needed
  };

  return (
    <Box m="20px">
      {selectedProductId ? (
        <Box>
          <Button onClick={() => setSelectedProductId(null)} variant="contained">
            Back to Products
          </Button>
          <ProductChargesTable Id={selectedProductId} />
          <ProductChargesAccountsTable Id={selectedProductId} /> 
        </Box>
      ) : (
        <DynamicTableForJson
          title="Products"
          subtitle="Manage Products"
          FormComponent={Form}
          base_url={base_url}
          actions={actions}
          onRowClick={(row) => handleAction(actions.ProductChargesTable.key, row.Id)} // Call handleAction with product ID
          setSelectedProductId={setSelectedProductId}
          />
      )}
    </Box>
  );
};

export default ProductsTable;
