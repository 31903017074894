import React from "react";
import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../config";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import ProcessForm from "./form";

const base_url = setupManagementUrl.uri;

const ProcessTable = () => {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate"],
      objectName: "allProcesses",
      parameters: "",
    },
    required: "rightIds",
    isSubRight: true,
    showStatus: true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Process Management"
        subtitle="Manage Process"
        FormComponent={ProcessForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default ProcessTable;
