import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../../data/DynamicTable/dynamicTableForJson";
import { customerservice } from "../../../../../config";
import GroupMembersForm from "./form"; // Adjust this import to match your CustomerGroupView form component
import { useNavigate } from "react-router-dom";

const base_url = customerservice.uri;

const GroupMembersTable = () => {

  const groupId = localStorage.getItem("GroupId");
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const navigate = useNavigate();

  const actions = {
    entity: "Rights",
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: [
        "CustomerId",
        "RoleId",
        "RoFirstname",
        "RoLastname",
        "GroupMobile",
        "Company",
        "Branch",
        "Region",
        "LoanLimit",
        "GroupName",
        "GroupId",
        "IsActive",
        "RegFeePaid",
        "AccountBalance",
        "CustomerLimit",
        "Email",
        "GroupOlb",
        "Title",
        "GenderName",
        "IdNoOrPassport",
        "DateCreated",
        "CustomerIsActive",
        "CompanyId",
        "RegionId",
        "BranchId",
        "FieldOfficerId",
      ],
      objectName: "customerGroupViewByColumnValues",
      parameters: { groupId: parseInt(groupId) },
      inputObjectName: "customerGroupView",
    },
  };
  
  return (
    <Box m="20px">
      <Box>
      <Button onClick={ () => navigate(-1)} variant="contained">
            Back to Groups
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Group Members"
        subtitle="Manage Group Members"
        FormComponent={GroupMembersForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default GroupMembersTable;
