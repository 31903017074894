import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import {
  CREATE_RIGHT,
  UPDATE_RIGHT,
  ALL_MODULES,
} from "../../../data/RoleManagementData"; // Import your GraphQL queries and mutations
import swal from "sweetalert";
import { useState, useEffect } from "react";
import Header from "../../../components/Header";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import { allCompanies } from "../../../data/Axios/queries";
import { setupManagementUrl } from "../../../config";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import Swal from "sweetalert2";

const setup_url = setupManagementUrl.uri;

const IndustryTypesForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));

  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  const initialValues = {
    name: props.data ? props.data.Name : "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Titleis required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { name } = values;
      const variables = props.isEditing
        ? { id: props.data.Id, name }
        : { name };

      const response = await executeAnyMutation(
        props.isEditing ? "updateIndustryTypes" : "createIndustryTypes",
        variables,
        "functionParams",
        false,
        setup_url
      );
      if (response) {
        props.onClose();
        props.onAction();

        // Show success message after form has closed
        await Swal.fire(
          "Success!",
          `Industry Type has been ${
            props.isEditing ? "updated" : "created"
          } successfully`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    // Set submitting to false to indicate the form submission is complete
    setSubmitting(false);

    // Close the modal or perform any other actions after submission
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Industry Type" : "Create Industry Type"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Industry Type"
            : "Create a New Industry Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Industry Type*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default IndustryTypesForm;
