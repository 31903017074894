import React from "react";
import { Box, Button } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
import { useQuery} from "@apollo/client";
import { setupManagementUrl } from "../../../config";
import Form from "./forms";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import { RowingOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const base_url = setupManagementUrl.uri;

const ignoreFields = ["UpdateDate"];

const ProductChargesTable = ({}) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const productId= localStorage.getItem("ProductId")

   const navigate = useNavigate();

  const actions = {
    entity:localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "productChargeIds",
      inputObjectName: "bulkActivateDeactivateProductCharges",
    },
    dataObject: {
      ignoreFields,
     objectName: "productChargesByProductId",
    parameters: {productId: parseInt(productId)},
    inputObjectName:"productcharge",
    },
  };
  
  return (
    <Box m="20px">
      <Box >
       <Button onClick={ () => navigate(-1)} variant="contained">
            Back to Products
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="ProductCharges"
        subtitle="Manage ProductCharges"
        //columns={RightsColumns}
        FormComponent={Form}
        //query={allRights}
        base_url={base_url}
        actions={actions}
        />
    </Box>
  );
};

export default ProductChargesTable;
