import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../../data/DynamicTable/dynamicTableForJson";
import { customerservice } from "../../../../../config";
import CustomerBusinessDetailsForm from "./form";
import { useNavigate } from "react-router-dom";



const base_url = customerservice.uri;

const BusinessDetailsTable = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const navigate = useNavigate();

  const customerId= localStorage.getItem("CustomerId")
  const actions = {
    entity: "Rights",
    isSubright:true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate",],
      objectName: "customerBusinessDetailsById",
      parameters:{customerId:parseInt(customerId)},
      inputObjectName:"customerBusinessDetails"
    },


  };

  return (
    <Box m="20px">
      <Box >
       <Button onClick={ () => navigate(-1)} variant="contained">
            Back to Customers
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Business Details"
        subtitle="Manage Business Details"
        FormComponent={CustomerBusinessDetailsForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default BusinessDetailsTable;
