import React from "react";
import { Box } from "@mui/material";
import {
  ReportTypesColumns,
  RoleGroupColumns,
} from "../../../data/DynamicTable/Collumns";
import { allReportTypes, allRoleGroups } from "../../../data/Axios/queries";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import {
  reportsManagementUrl,
  roleManagemenUrl,
  setupManagementUrl,
} from "../../../config";

import ReportTypesForm from "./Forms";

const base_url = reportsManagementUrl.uri;

const ReportTypes = () => {
  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "reportTypes",
      parameters: "",
    },
    showStatus: true,
  };

  return (
    <Box m="10px">
      <DynamicTableForJson
        title="Reports"
        subtitle="Reports Types"
        //columns={ReportTypesColumns}
        FormComponent={ReportTypesForm}
        query={allReportTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default ReportTypes;
