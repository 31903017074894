import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Button from "./button";
import logo from "./../Assets/opo-purple.jpg";

function NavBar() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="fixed w-full bg-black text-white z-50 shadow-lg top-0">
      <div className="flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4">
         <a href="/" className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 w-auto" /> 
        </a>
        <ul className="hidden md:flex">
          <li
            className="p-4 text-yellow-100 cursor-pointer"
            onClick={() => (window.location.href = "#pricing")}
          >
            Home
          </li>
          <li
            className="p-4 text-yellow-100 cursor-pointer"
            onClick={() => (window.location.href = "#footer")}
          >
            Company
          </li>
          <li
            className="p-4 text-yellow-100 cursor-pointer"
            onClick={() => (window.location.href = "#newsletter")}
          >
            Resources
          </li>
          <li
            className="p-4 text-yellow-100 cursor-pointer"
            onClick={() => (window.location.href = "https://mcb.co.ke/")}
          >
            About
          </li>
          <li
            className="p-4 text-yellow-100 cursor-pointer"
            onClick={() => (window.location.href = "#newsletter")}
          >
            Contact
          </li>
        </ul>
        <Button className="hidden md:block" />
        <div onClick={handleNav} className="block md:hidden z-10">
          {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </div>
      </div>
      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full bg-[#000300] ease-in-out duration-500 md:hidden"
            : "fixed left-[-100%] top-0 w-[60%] h-full bg-[#000300] ease-in-out duration-500 md:hidden"
        }
      >
        <ul className="p-4 uppercase">
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#pricing";
            }}
          >
            Home
          </li>
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#footer";
            }}
          >
            Company
          </li>
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#newsletter";
            }}
          >
            Resources
          </li>
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#footer";
            }}
          >
            About
          </li>
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#footer";
            }}
          >
            Contact
          </li>
          <li
            className="p-4 text-[#00df9a] cursor-pointer"
            onClick={() => {
              handleNav();
              window.location.href = "#newsletter";
            }}
          >
            Pricing
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;