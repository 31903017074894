import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { AccountsandFinanceurl } from "../../../../config";
import { accountSubcategoryFields } from "../../../../data/DynamicTable/AccountsDynamicForms";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../../data/Axios/DynamicService";
import { allAccountCategories } from "../../../../data/Axios/queries";
import { executeAnyMutation } from "../../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = AccountsandFinanceurl.uri;

const AccountSubCategoryForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialValues = {
    accountCategory: props.data ? props.data.AccountCategory : "",
    accountCategoryId: props.data ? props.data.AccountCategoryId : "",
    accountNumber: props.data ? props.data.AccountNumber : "",
    accountName: props.data ? props.data.AccountName : "",
    accountType: props.data ? props.data.AccountType : "",
    currency: props.data ? props.data.Currency : "",
    parentAccount: props.data ? props.data.parentAccount : "",
    rate: props.data ? props.data.Rate : "",
  };
  useEffect(() => {
    async function fetchAllData() {
      try {
        const data = await graphqlQuery(allAccountCategories, base_url);
        if (data?.results) {
          setCategoryData(JSON.parse(data?.results));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const CategoryOptions = categoryData
    ? categoryData.map((data) => ({
        parent_key: data.Id,
        value: data.Id,
        label: data.CategoryName,
      }))
    : [];
  const fields = [
    ...accountSubcategoryFields,
    {
      name: "accountCategoryId",
      label: "Account Category ",
      type: "select",
      options: CategoryOptions.map((role) => ({
        value: role.value,
        label: role.label,
      })),
      gridColumn: "span 2",
      required: true,
    },
  ];

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        accountCategoryId,
        accountName,
        accountNumber,
        accountType,
        currency,
        rate,
      } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            accountCategoryId,
            accountName,
            accountNumber,
            accountType,
            currency,
            rate: parseFloat(rate),
          }
        : {
            accountCategoryId,
            accountNumber,
            accountType,
            currency,
            rate: parseFloat(rate),
            accountName,
          };

      const response = await executeAnyMutation(
        props.isEditing
          ? "updateAccountSubCategory"
          : "createAccountSubCategory",
        mutationData,
        "accountsubcategory",
        false,
        base_url
      );

      if (response?.results) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Account sub-category ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={
        props.isEditing
          ? "Edit Account Sub-Category"
          : "Create Account Sub-Category"
      }
      subtitle={
        props.isEditing
          ? "Edit an Existing Account Sub-Category"
          : "Create a New Account Sub-Category"
      }
    />
  );
};

export default AccountSubCategoryForm;
