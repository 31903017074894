import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

// Sample customer data

const CustomerModal = ({ open, onClose,customerData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
    <div>
      <Button variant="contained" color="primary" onClick={open}>
        Show Customers
      </Button>
      
      <Modal open={open} onClose={onClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: colors.primary[400],
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h5" component="h2" gutterBottom>
           <strong>Customer List</strong> 
          </Typography>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{
                backgroundColor:colors.greenAccent[400],
              }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerData.map((customer) => (
                  <TableRow key={customer.Id}>
                    <TableCell>{customer.Id}</TableCell>
                    <TableCell>{customer.Firstname} {""}{customer.OtherNames} </TableCell>
                    <TableCell>{customer.Email}</TableCell>
                    <TableCell>{customer.CustomerMobile}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomerModal;
