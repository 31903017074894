import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import { customerservice, userManagementUrl } from "../../../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../../data/DynamicServiceNew/jsondynamicservice";

const SingularGroupMigrationPage = () => {
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userRoleGroup = parseInt(decodedToken.RoleGroupId);
  const userId = parseInt(decodedToken.Id);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [fieldOfficerSelected, setFieldOfficerSelected] = useState([]);
  const [fieldOfficerData, setFieldOfficerData] = useState("");
  const [customers, setCustomers] = useState("");
  useEffect(() => {
    // Delay for live search to avoid rapid searches
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        const customer = customers.find(
          (cust) =>
            cust.Name.includes(searchTerm) ||
            cust.PhoneNumber.includes(searchTerm)
        );
        setFilteredCustomer(customer || null);

        if (!customer) {
          Swal.fire("Group Not Found", "Group does not exist", "warning");
        }
      } else {
        setFilteredCustomer(null);
      }
    }, 1000); // Adjust delay as necessary (500ms in this example)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchJsonDynamic(
        "allGroups",
        null,
        null,
        customerservice.uri
      );
      const allCustomers = JSON.parse(response.results) || [];
      const fieldOfficerDataResponse = await fetchJsonDynamic(
        "allUsersView",
        null,
        null,
        userManagementUrl.uri
      );
      setCustomers(allCustomers);
      if (fieldOfficerDataResponse?.results) {
        const results = JSON.parse(fieldOfficerDataResponse.results);

        // Filter users with RoleGroupId 5
        const fieldOfficersWithRole5 = results.filter(
          (userRoleGroup) => userRoleGroup.RoleGroupId === 5
        );
        setFieldOfficerData(fieldOfficersWithRole5 || []);
      }
    };
    fetchData();
  }, []);
  const handleCustomerMigrationSubmit = async () => {
    try {
      const mutationData = {
        pgroupId: filteredCustomer.Id,
        pFoId: fieldOfficerSelected,
        pdoneBy: userId,
      };

      const response = await executeAnyMutation(
        "executeSingularGroupFoMigration",
        mutationData,
        "inputModel",
        false,
        customerservice.uri
      );
      if (response.results === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Group Migrated successfully",
        }).then(() => {
          // Clear all fields
          setSearchTerm("");
          setFilteredCustomer(null);
          setFieldOfficerSelected("");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: response.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Book A Loan</Typography>
      <TextField
        label="Search Customer by customer phone number | Name"
        placeholder="Enter search criteria"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Group Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Group Name:</strong> {filteredCustomer.Name}{" "}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {filteredCustomer.EmailAddress}
                  </Typography>
                  <Typography>
                    <strong>Group Code:</strong> {filteredCustomer.Code}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.PhoneNumber}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.BranchId}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.CreatedDate}
                  </Typography>
                  <Typography>
                    <strong>Acoount Balance:</strong>{" "}
                    {filteredCustomer.AccountBalance}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                RO Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Field Officer</InputLabel>
                    <Select
                      value={fieldOfficerSelected}
                      onChange={(e) => setFieldOfficerSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Field Officer
                      </MenuItem>
                      {fieldOfficerData.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.FirstName} {company.LastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleCustomerMigrationSubmit}
          >
            Submit For Customer Migration
          </Button>
        </>
      )}
    </Box>
  );
};

export default SingularGroupMigrationPage;
