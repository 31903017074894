import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e1e2e6",
          200: "#c3c5cd",
          300: "#a4a9b3",
          400: "#868c9a",
          500: "#686f81",
          600: "#535967",
          700: "#3e434d",
          800: "#2a2c34",
          900: "#15161a",
        },
        primary: {
          100: "#d6d8db",
          200: "#142a3a", // main backround
          300: "#848a93",
          400: "#224964", // Dynamic table and sidebar
          500: "#1f76ad",
          600: "#2e3338",
          700: "#22262a",
          800: "#171a1c",
          900: "#0b0d0e",
        },
        greenAccent: {
          100: "#d3f2e3",
          200: "#a7e5c7",
          300: "#7ad9aa",
          400: "#4ecc8e",
          500: "#22bf72",
          600: "#1b995b",
          700: "#147344",
          800: "#0e4c2e",
          900: "#072617",
        },
        redAccent: {
          100: "#f6d4d7",
          200: "#eda9af",
          300: "#e47e86",
          400: "#db535e",
          500: "#d22836",
          600: "#a8202b",
          700: "#7e1820",
          800: "#541016",
          900: "#2a080b",
        },
        blueAccent: {
          100: "#d6d8db",
          200: "#adb1b7",
          300: "#848a93",
          400: "#5b636f",
          500: "#323c4b",
          600: "#28303c",
          700: "#30688e",
          800: "#14181e",
          900: "#0a0c0f",
        },
      }
    : {
        grey: {
          100: "#15161a",
          200: "#2a2c34",
          300: "#3e434d",
          400: "#535967",
          500: "#686f81",
          600: "#868c9a",
          700: "#a4a9b3",
          800: "#c3c5cd",
          900: "#e1e2e6",
        },
        primary: {
          100: "#EEEFEF", // Light mode background
          200: "#ecf0f3", // Platinum for light mode background
          300: "#e0e4e7",
          400: "#d9e2e8",
          500: "#1f76ad",
          600: "#7b8694",
          700: "#7caccb",
          800: "#3e4d62",
          900: "#1F2A40",
        },
        greenAccent: {
          100: "#072617",
          200: "#0e4c2e",
          300: "#147344",
          400: "#1b995b",
          500: "#22bf72",
          600: "#4ecc8e",
          700: "#7ad9aa",
          800: "#a7e5c7",
          900: "#d3f2e3",
        },
        redAccent: {
          100: "#2a080b",
          200: "#541016",
          300: "#7e1820",
          400: "#a8202b",
          500: "#d22836",
          600: "#db535e",
          700: "#e47e86",
          800: "#eda9af",
          900: "#f6d4d7",
        },
        blueAccent: {
          100: "#0a0c0f",
          200: "#14181e",
          300: "#1e242d",
          400: "#28303c",
          500: "#323c4b",
          600: "#5b636f",
          700: "#848a93",
          800: "#adb1b7",
          900: "#d6d8db",
        },
      }),
});
// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: "#1766A2", // Set primary button color for dark mode
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[200], // Light mode applied
            },
          }
        : {
            primary: {
              main: "#1766A2", // Set primary button color for light mode
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[200], // Light mode applied
            },
          }),
    },

    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light"); // Default mode set to light

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};  

export const DarkModeContext = createContext({
  toggleDarkMode: () => {},
});

export const useDarkMode = () => {
  const [mode, setMode] = useState("dark"); // Default mode set to dark

  const darkMode = useMemo(
    () => ({
      toggleDarkMode: () =>
        setMode((prev) => (prev === "dark" ? "light" : "dark")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, darkMode];
};
