import axios from "axios";

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
  };
};
export async function getDocumentAccessPermissions(documentId, baseUrl) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query: `
    {
      documentAccessPermissions(permissionsParams: {documentId:${documentId}})
    }
        `,
    };
    const response = await axios.post(baseUrl, postData, { headers });
    return response.data.data.documentAccessPermissions;
  } catch (error) {
    let error_messages = error.response.data.errors;
    throw error;
  }
}
export async function getDocumentAccessPermissionsById(documentId, baseUrl) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query: `
    {
      userDocumentAccessPermissions(permissionsParams: {documentId:${documentId}})
    }
        `,
    };
    const response = await axios.post(baseUrl, postData, { headers });
    return response.data.data.userDocumentAccessPermissions;
  } catch (error) {
    let error_messages = error.response.data.errors;
    throw error;
  }
}
