import {
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { uploadDocument } from "../../../data/Axios/DynamicService";
import { companyManagementUrl } from "../../../config";
import { useDispatch } from "react-redux";
import { UpdateDataToStore } from "../../../store/Actions/CustomAction";

const LogoUploadForm = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = companyManagementUrl.upload_logo_uri;



  const initialValues = {
    courtId: props.data ? props.data.courtId : "",
  };

  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .required("Please select a file to upload!")
      .test("fileSize", "The file has exceeded the 50mb limit", value => {
        return value && value.size <= 52428800; // 1MB
      })
    // .test("fileType", "Unsupported file format", value => {
    //   return value && ['image/jpeg', 'image/png', 'application/pdf', ].includes(value.type);
    // }),
  });

  const caseIdFromSession = JSON.parse(localStorage.getItem("CaseId"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userIdFromSession = parseInt(decodedToken.Id);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const logoObject = {
        logoUpload: true,
      };

      const response = await uploadDocument(
        props.isEditing ? "remove" : "add",
        values.file,
        logoObject,
        base_url
      );

      if (response == 200) {
        // props.onClose();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Document uploaded successfully.`,
        });
        // const actionKey = "REFRESH_DOCUMENTS_TABLE";
        // dispatch(UpdateDataToStore(actionKey, "success"));
      }

      setSubmitting(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
      // const actionKey = 'REFRESH_DOCUMENTS_TABLE'
      // dispatch(UpdateDataToStore(actionKey, 'error'));
      setSubmitting(false);
    }
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box>
      <Header
        title="Company Logo"
        subtitle={"Select an image to upload"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Button
              variant="contained"
              component="label"
              sx={{
                marginTop: "1rem",
                bgcolor: `${colors.grey[400]}`
              }}
            >
              Select a file to upload
              <input
                id="file"
                name="file"
                type="file"
                hidden
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
            </Button>
            {touched.file && errors.file ? (
              <Typography color="error">{errors.file}</Typography>
            ) : null}
            {values.file && (
              <Typography sx={{ margin: "1rem" }}>{values.file.name}</Typography>
            )}

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box >
  );
};

export default LogoUploadForm;
