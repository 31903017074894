import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import {
  companyManagementUrl,
  customerservice,
  setupManagementUrl,
  userManagementUrl,
} from "../../../config";
import { customerFields } from "../../../data/DynamicForm/FormFields/CustomerFormFields";
import DynamicForm from "../../../data/Axios/DynamicForm";
import { fetchAllData, graphqlQuery } from "../../../data/Axios/DynamicService";
import { getrolesquery } from "../../../data/Axios/queries";

const base_url = customerservice.uri;
const setup_url = setupManagementUrl.uri;
const comp_url = companyManagementUrl.uri;
const user_url = userManagementUrl.uri;

const CustomerForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const [loading, setLoading] = useState(true);
  const [maritalStatusData, setMaritalStatustData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [yearsLivedAtResidenceData, setYearsLivedAtResidenceData] = useState(
    []
  );

  const [educationLevelData, setEducationLevelData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [FOData, setFOData] = useState([]);
  const [branchoptionsInitialvalue, setBranchOptionsInitialValue] = useState(
    []
  );
  const [FOoptionsInitialvalue, setFOOptionsInitialValue] = useState([]);

  const [branchIdData, setBranchIdData] = useState(null);
  const [FOIdData, setFOIdData] = useState(null);
  const [Mappedbranches, setMappedBranches] = useState([]);
  const [formData, setFormData] = useState({
    idFrontPhoto: null,
    idBackPhoto: null,
    selfiePhoto: null,
  });
  const [MappedFOs, setMappedFOs] = useState([]);
  const [roleData, setRoleData] = useState([]);
  // Function to convert file to byte array
  // Improved file to byte array conversion

  useEffect(() => {
    async function fetchAllData() {
      try {
        const facts = await graphqlQuery(getrolesquery, base_url);
        if (facts) {
          setRoleData(facts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchAllData();
  }, []);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const maritalStatusDataResponse = await fetchJsonDynamic(
          "allMaritalStatus",
          null,
          null,
          setup_url
        );
        const yearsLivedAtResidenceResponse = await fetchJsonDynamic(
          "allResidencePeriod",
          null,
          null,
          setup_url
        );
        const educationLevelResponse = await fetchJsonDynamic(
          "allEducationLevel",
          null,
          null,
          setup_url
        );
        const regionResponse = await fetchJsonDynamic(
          "region",
          null,
          null,
          comp_url
        );
        const titleResponse = await fetchJsonDynamic(
          "titles",
          null,
          null,
          setup_url
        );
        const genderResponse = await fetchJsonDynamic(
          "allGender",
          null,
          null,
          setup_url
        );
        if (genderResponse?.results) {
          const result = JSON.parse(genderResponse?.results);
          setGenderData(result || []);
        }
        if (regionResponse?.results) {
          const result = JSON.parse(regionResponse?.results);
          setRegionData(result || []);
        }
        if (titleResponse?.results) {
          const result = JSON.parse(titleResponse?.results);
          setTitleData(result || []);
        }
        if (educationLevelResponse?.results) {
          const result = JSON.parse(educationLevelResponse?.results);
          setEducationLevelData(result || []);
        }
        if (yearsLivedAtResidenceResponse?.results) {
          const result = JSON.parse(yearsLivedAtResidenceResponse?.results);
          setYearsLivedAtResidenceData(result || []);
        }
        if (maritalStatusDataResponse?.results) {
          const results = JSON.parse(maritalStatusDataResponse?.results);
          setMaritalStatustData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const regionOptions = regionData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));
  const titleOptions = titleData.map((status) => ({
    value: status.Id,
    label: status.Title,
  }));
  const genderOptions = genderData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));
  const educationlevelOptions = educationLevelData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));
  const yearsLivedAtResidenceOptions = yearsLivedAtResidenceData.map(
    (relation) => ({
      value: relation.Name,
      label: relation.Name,
    })
  );
  const maritalStatusOptions = maritalStatusData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));

  const handleFieldChange = async (field, value) => {
    if (field.name === "regionId") {
      const branchOptions = await fetchBranches(value);
      setBranchOptionsInitialValue(branchOptions || []); // Ensure an array is set
      setBranchData(branchOptions); // Set the branch data for further rendering in form
      // setBranchIdData(null); // Reset the branchId data if region changes
      return branchOptions;
    }
    if (field.name === "branchId") {
      const FOOptions = await fetchFieldOfficers(value);
      setFOOptionsInitialValue(FOOptions || []);
      setFOData(FOOptions);
      setFOIdData(null);
      return FOOptions;
    }
  };
  const today = new Date();
  today.setFullYear(today.getFullYear() - 18); // 18 years ago
  const maxDateString = today.toISOString().split("T")[0];
  

  const fetchBranches = async (regionId) => {
    try {
      const params = { regionId: parseInt(regionId) };

      const businessDetails = await fetchJsonDynamic(
        "allBranchesByColumnValues",
        "branchModel",
        params,
        companyManagementUrl.uri
      );
      const allBranchDetails = JSON.parse(businessDetails?.results) || [];

      const branchOptions = allBranchDetails.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.Name,
      }));

      setMappedBranches(branchOptions);
      setBranchData(branchOptions);

      return branchOptions; // Return the mapped branch options
    } catch (error) {
      console.error("Error fetching branches:", error);
      return [];
    }
  };
  const fetchFieldOfficers = async (branchId) => {
    try {
      const params = { branchId: parseInt(branchId) };

      const FODetails = await fetchJsonDynamic(
        "allUsersByColumnValues",
        "usermodel",
        params,
        userManagementUrl.uri
      );
      const allFODetails = JSON.parse(FODetails?.results) || [];

      const FOOptions = allFODetails.map((officer) => ({
        parent_key: officer.Id,
        value: officer.Id,
        label: officer.FirstName,
      }));

      setMappedFOs(FOOptions);
      setFOData(FOOptions);

      return FOOptions; // Return the mapped FO options
    } catch (error) {
      console.error("Error fetching Field Officers:", error);
      return [];
    }
  };

  const tokenRoleGroupId = parseInt(decodedToken.RoleGroupId);
  const formFields =
    tokenRoleGroupId !== 5
      ? [
          {
            name: "regionId",
            label: "Region",
            type: "select",
            options: regionOptions,
            gridColumn: "span 2",
            required: true,
            parent: "main",
          },
          {
            name: "branchId",
            label: "Branch",
            type: "select",
            options: Mappedbranches,
            gridColumn: "span 2",
            required: true,
            parent: "dependent",
            dependentOn: "regionId",
          },
          {
            name: "fieldOfficerId",
            label: "Field Officer",
            type: "select",
            options: MappedFOs,
            gridColumn: "span 2",
            required: true,
            parent: "subdependent", // Dependent on branchId
            dependentOn: "branchId",
            subparent: "branchId", // Indicates a subparent
          },
        ]
      : [];

  const fields = [
    {
      name: "titleId",
      label: "Title",
      type: "select",
      options: titleOptions,
      gridColumn: "span 2",
      required: false,
    },
    {
      name: "genderId",
      label: "Gender",
      type: "select",
      options: genderOptions,
      gridColumn: "span 2",
      required: false,
    },
    {
      name: "maritalStatusId",
      label: "Marital Status",
      type: "select",
      options: maritalStatusOptions,
      gridColumn: "span 2",
      required: false,
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      max: maxDateString, // Restrict maximum selectable date to 18 years ago
      gridColumn: "span 2",
      required: true,
      validationMessage: "You must be at least 18 years old.", // Optional for error handling
    },
   
    ...customerFields,
    {
      name: "educationLevelId",
      label: "Education Level",
      type: "select",
      options: educationlevelOptions,
      gridColumn: "span 2",
      required: false,
    },
    {
      name: "yearsLivedAtResidence",
      label: "Period at Residence",
      type: "select",
      options: yearsLivedAtResidenceOptions,
      gridColumn: "span 2",
      required: false,
    },
    ...formFields,
    {
      name: "idFrontPhoto",
      label: "ID Front Photo",
      type: "file",
      accept: "image/*",
      gridColumn: "span 2",
      onChangeHandler: (e) =>
        handleFileChange("idFrontPhoto", e.target.files[0]),
    },
    {
      name: "idBackPhoto",
      label: "ID Back Photo",
      type: "file",
      accept: "image/*",
      gridColumn: "span 2",
      onChangeHandler: (e) =>
        handleFileChange("idBackPhoto", e.target.files[0]),
    },
    {
      
      name: "selfiePhoto",
      label: "Selfie Photo",
      type: "file",
      accept: "image/*",
      gridColumn: "span 2",
      onChangeHandler: (e) =>
        handleFileChange("selfiePhoto", e.target.files[0]),
    },
  ];

  const formatDate = (date) => {
    if (!date) return null; // Handle null or undefined dates
    return date.split("T")[0]; // Extract the date part
  };

  const initialValues = {
    id: props.data ? props.data.Id : null,
    firstName: props.data ? props.data.FirstName : null,
    otherNames: props.data ? props.data.OtherNames : null,
    mobileNumber: props.data ? props.data.MobileNumber : null,
    emailAddress: props.data ? props.data.EmailAddress : null,
    idNoOrPassport: props.data ? props.data.IdNoOrPassport : null,
    dateOfBirth: props.data ? formatDate(props.data.DateOfBirth) : null,
    maritalStatusId: props.data ? props.data.MaritalStatusId : null,
    postalAddress: props.data ? props.data.PostalAddress : null,
    physicalAddress: props.data ? props.data.PhysicalAddress : null,
    yearsLivedAtResidence: props.data ? props.data.YearsLivedAtResidence : null,
    educationLevelId: props.data ? props.data.EducationLevelId : null,
    postalCode: props.data ? props.data.PostalCode : null,
    regionId: props.data ? props.data.RegionId : null,
    branchId: props.data ? props.data.BranchId : null,
    fieldOfficerId: props.data ? props.data.FieldOfficerId : null,
    titleId: props.data ? props.data.TitleId : null,
    genderId: props.data ? props.data.GenderId : null,
  };
  const convertFileToByteArray = async (file) => {
    if (!file) return null;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uint8Array = new Uint8Array(reader.result); // Byte array
        resolve(Array.from(uint8Array)); // Convert Uint8Array to regular array
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (fieldName, file) => {
    if (file) {
      const byteArray = await convertFileToByteArray(file);
      setFormData((prev) => ({
        ...prev,
        [fieldName]: byteArray, // Store as a proper array
      }));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        titleId,
        firstName,
        otherNames,
        mobileNumber,
        emailAddress,
        idNoOrPassport,
        dateOfBirth,
        maritalStatusId,
        postalAddress,
        physicalAddress,
        yearsLivedAtResidence,
        educationLevelId,
        postalCode,
        regionId,
        branchId,
        genderId,
        fieldOfficerId,
        idFrontPhoto, // New fields
        idBackPhoto,
        selfiePhoto,
      } = values;

      const idFrontPhotoBytes = idFrontPhoto
        ? await convertFileToByteArray(idFrontPhoto)
        : null;
      const idBackPhotoBytes = idBackPhoto
        ? await convertFileToByteArray(idBackPhoto)
        : null;
      const selfiePhotoBytes = selfiePhoto
        ? await convertFileToByteArray(selfiePhoto)
        : null;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            firstName,
            otherNames,
            mobileNumber,
            emailAddress,
            idNoOrPassport,
            dateOfBirth,
            titleId,
            maritalStatusId,
            postalAddress,
            yearsLivedAtResidence,
            educationLevelId,
            postalCode,
            physicalAddress,
            regionId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.RegionId),
            branchId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.BranchId),
            genderId,
            fieldOfficerId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.Id),
            idFrontPhoto: idFrontPhotoBytes,
            idBackPhoto: idBackPhotoBytes,
            selfiePhoto: selfiePhotoBytes,
          }
        : {
            firstName,
            otherNames,
            mobileNumber,
            emailAddress,
            idNoOrPassport,
            dateOfBirth,
            titleId,
            maritalStatusId,
            postalAddress,
            yearsLivedAtResidence,
            educationLevelId,
            postalCode,
            physicalAddress,
            regionId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.RegionId),
            branchId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.BranchId),
            genderId,
            fieldOfficerId:
              tokenRoleGroupId !== 5
                ? fieldOfficerId
                : parseInt(decodedToken.Id),
            idFrontPhoto: idFrontPhotoBytes,
            idBackPhoto: idBackPhotoBytes,
            selfiePhoto: selfiePhotoBytes,
          };

      const response = await executeAnyMutation(
        props.isEditing ? "updateCustomers" : "createCustomer",
        mutationData,
        "customer",
        false,
        base_url
      );

      if (response.results !== null) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Customer ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        }).then(() => {
          props.refetchData();
        });
      } else {
        props.onClose();
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: `${JSON.parse(response.error).Error}`,
        });
      }
    } catch (error) {
      props.onClose();
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <div>
      <DynamicForm
        initialValues={initialValues}
        fields={fields}
        onFieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        title={props.isEditing ? "Edit Customer" : "Create Customer"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Customer"
            : "Create a New Customer"
        }
      />
    </div>
  );
};

export default CustomerForm;
