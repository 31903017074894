import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { UploadFileComponent } from "../../../../data/DynamicTable/Modal";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import { CloudUploadIcon } from "lucide-react";

function ReconFileUpload() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpenModal = () => {
    setDialogOpen(true);
  };

  return (
    <Box m="2px">
      <Box
        m="2px"
        sx={{
          justifyContent: "end",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography style={{ color: colors.greenAccent[400], margin: "0 8px" }}>
          Upload Transactions for recon
        </Typography>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{
            backgroundColor: colors.greenAccent[500],
            borderRadius: "4px",
            m: "10px",
          }}
          onClick={handleOpenModal}
        >
          <Typography style={{ color: colors.primary[400], margin: "0 8px" }}>
            Upload
          </Typography>
        </Button>
      </Box>

      <UploadFileComponent
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Box>
  );
}

export default ReconFileUpload;
