import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import DynamicForm from "../../../../../data/Axios/DynamicForm";
import { executeAnyMutation, fetchJsonDynamic } from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice, setupManagementUrl } from "../../../../../config";
import { nextOfKinFields } from "../../../../../data/DynamicForm/FormFields/CustomerFormFields";


const base_url = customerservice.uri;
const setup_url = setupManagementUrl.uri;

const NOKForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const customerId= parseInt(localStorage.getItem("CustomerId"))
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [rightsData, setRightsData] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maritalStatusData, setMaritalStatustData] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const maritalStatusDataResponse = await fetchJsonDynamic(
          "allMaritalStatus",
          null,
          null,
          setup_url
        );
        if (maritalStatusDataResponse?.results) {
          const results = JSON.parse(maritalStatusDataResponse?.results);
          setMaritalStatustData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);
 const maritalStatusOptions = maritalStatusData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));

  const fields = [
    {
      name: "maritalStatus",
      label: "Marital Status",
      type: "select",
      options: maritalStatusOptions,
      gridColumn: "span 2",
      required: false,
    },
    ...nextOfKinFields,
  ];

  const initialValues = {
    id: props.data ? props.data.Id : "",
 
    nextOfKinRelationship: props.data ? props.data.NextOfKinRelationship : "",
    firstName: props.data ? props.data.FirstName : "",
    otherNames: props.data ? props.data.OtherNames : "",
    nextOfKinMobileNumber: props.data ? props.data.NextOfKinMobileNumber : "",
    idNoOrPassport: props.data ? props.data.IdNoOrPassport : "",
    maritalStatus: props.data ? props.data.MaritalStatus : "",
    dob: props.data ? props.data.Dob : "",
    currentResidence: props.data ? props.data.CurrentResidence : "",
    town: props.data ? props.data.Town : "",
    houseName: props.data ? props.data.HouseName : "",
    floorNumber: props.data ? props.data.FloorNumber : "",
    roomNumber: props.data ? props.data.RoomNumber : "",
    occupation: props.data ? props.data.Occupation : "",

  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        nextOfKinRelationship,
        firstName,
        otherNames,
        nextOfKinMobileNumber,
        idNoOrPassport,
        maritalStatus,
        dob,
        currentResidence,
        town,
        houseName,
        floorNumber,
        roomNumber,
        occupation,
    
      } = values;

      const mutationData = {
        //id: props.data.Id,
        nextOfKinRelationship,
        firstName,
        otherNames,
        nextOfKinMobileNumber,
        idNoOrPassport,
        maritalStatus:parseInt(maritalStatus),
        dob,
        currentResidence,
        town,
        houseName,
        floorNumber,
        roomNumber,
        occupation,
        customerId,
       
      };

      if (props.isEditing) {
        mutationData.id = props.data.Id;
      }

      const response = await executeAnyMutation(
        props.isEditing ? "updateNextOfKin" : "createNextOfKin",
        mutationData,
        "nextOfKin",
        false,
        base_url
      );

      if (response.results) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `NOK${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
      else if (response.error)
      {
        throw new Error(response.error)
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  useEffect(() => {
    async function fetchAllData() {
      try {
        const maritalstatus = await fetchJsonDynamic(
          "allMaritalStatus",
          null,
          null,
          setupManagementUrl.uri
        );
      
        
        if (maritalstatus?.results) {
            const results = JSON.parse(maritalstatus?.results);
            setMaritalStatus(results || []);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);

  const forms =[
    ...nextOfKinFields,
  
      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "select", 
        options: maritalStatusOptions,
        gridColumn: "span 2",
        required: false,
      },
    
  ]
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={forms}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit NOK" : "Create NOK"}
      subtitle={
        props.isEditing
          ? "Edit an Existing NOK"
          : "Create a NOK"
      }
    />
  );
};

export default NOKForm;
