import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  Modal,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Block as BlockIcon,
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  fetchExpensesData,
  fetchExpenseById,
  updateExpense,
  addExpense,
  toggleExpenseStatus,
  fetchAccountSubCategory,
} from "../../../data/expensesData";
import Swal from "sweetalert2";

const Expense = () => {
  const theme = useTheme();
  const [expenseData, setExpenseData] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [expenses, setExpenses] = useState([]);
  const [formData, setFormData] = useState({
    expenseName: "",
    amount: "",
    description: "",
    createdBy: "",
    accountSubcategoryId: "",
  });

  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [accountSubcategory, setAccountSubCategory] = useState([]);

  useEffect(() => {
    const getExpensesData = async () => {
      const { expenses, totalExpenses } = await fetchExpensesData();
      setExpenseData(expenses);
      setTotalExpenses(totalExpenses);
      const accountSubcategoryData = await fetchAccountSubCategory();
      setAccountSubCategory(accountSubcategoryData);
    };

    getExpensesData();
  }, []);

  const handleUpdateExpense = async () => {
    try {
      const updateExpenses = await updateExpense(modalData);
      if (updateExpenses) {
        Swal.fire("Success!", "Expense updated successfully", "success");
        setEditModalOpen(false);
        const { expenses } = await fetchExpensesData(); // Re-fetch expenses to get the updated list
        setExpenseData(expenses); // Update state with new expense data
      }
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const expenseData = await fetchExpenseById(id);
      if (expenseData) {
        setModalData(expenseData);
        setEditModalOpen(true);
      } else {
        console.error("Expense data not found for ID:", id);
      }
    } catch (error) {
      console.error("Failed to open edit modal:", error);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setFormData({
      expenseName: "",
      amount: 0,
      description: "",
      createdBy: "",
      accountSubcategoryId: "",
    });
    setEditModalOpen(false);
  };
  const handleActionSelect = async (event, id) => {
    const action = event.target.value;

    try {
      const intId = parseInt(id, 10); // Ensure id is an integer

      // Find the corresponding expense entry to get its current status
      const expenseEntry = expenseData.find((expense) => expense.id === intId); // Assuming expenseData is an array
      if (!expenseEntry) {
        console.error("Expense entry not found");
        return;
      }
      if (action === "edit") {
        // Re-fetch expenses to update the list
        const updatedExpenses = await fetchExpensesData();
        setExpenseData(updatedExpenses.expenses);
      } else {
        // Determine the new status based on the action
        const newStatus = action === "disapprove" ? false : true; // Set new status based on action

        // Call the toggle function with the new status
        const response = await toggleExpenseStatus(intId, newStatus);
        if (response) {
          Swal.fire(
            "Success!",
            `ExpenseEntry ${
              newStatus === true ? "Approved" : "Disapproved"
            } Successfully.`,
            "success"
          );
        }
      }
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.errors[0].message}`, "error");
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData({
      amount: "",
      description: "",
      date: "",
      category: "",
      status: true,
      accountSubcategoryId: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    openModal
      ? setFormData({ ...formData, [name]: value })
      : setModalData({
          ...modalData,
          [name]: value,
        });
  };

  const handleAddExpense = async (e) => {
    e.preventDefault();

    const formDataToSubmit = {
      ...formData,
    };

    try {
      const addedExpense = await addExpense(formDataToSubmit);
      if (addedExpense) {
        // Fetch updated expenses and total
        const { expenses, totalExpenses } = await fetchExpensesData();
        setExpenseData(expenses); // Update the state with the new expenses
        setTotalExpenses(totalExpenses); // Update total expenses
        setOpenModal(false); // Close the modal
        // Reset form data
        setFormData({
          expenseName: "",
          amount: "",
          description: "",
          createdBy: "",
          accountSubcategoryId: "",
        });
      }
    } catch (error) {
      console.error("Error adding expense:", error);
    }
  };

  const uniqueAccountSubcategories = accountSubcategory.filter(
    (value) => value.accountCategoryId === 2
  );
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "expenseName", headerName: "Expense", flex: 1 },
    { field: "accountName", headerName: "Account Type", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "createdDate", headerName: "Date", flex: 1 },
    {
      field: "cancelled",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row: { cancelled } }) => {
        const statusText =
          cancelled === 1 || cancelled === true ? "Approved" : "Disapproved";
        const statusColor =
          cancelled === 1 || cancelled === true ? "green" : "red";
        return (
          <Typography variant="body1" style={{ color: statusColor }}>
            {statusText}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row: { id, cancelled } }) => (
        <Box>
          <Select
            value=""
            onChange={(e) => handleActionSelect(e, id)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Actions
            </MenuItem>
            <MenuItem value="edit" onClick={() => handleOpenEditModal(id)}>
              <EditIcon color={colors.grey[100]} />
              <Typography variant="body1" color={colors.blueAccent[100]}>
                Edit
              </Typography>
            </MenuItem>
            <MenuItem
              value={
                cancelled === 1 || cancelled === true ? "disapprove" : "Approve"
              }
            >
              {cancelled === 1 || cancelled === true ? (
                <>
                  <BlockIcon />
                  <Typography variant="body1">Disapprove</Typography>
                </>
              ) : (
                <>
                  <CheckCircleIcon />
                  <Typography variant="body1">Approve</Typography>
                </>
              )}
            </MenuItem>
          </Select>
        </Box>
      ),
    },
  ];

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box m="20px">
      <IconButton color="primary" onClick={handleBackClick} sx={{ mr: 1 }}>
        <ArrowBackIcon />
      </IconButton>
      <Header title="Expense Management" subtitle="Manage Expenses" />

      <Button
        onClick={handleOpenModal}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
      >
        Add Expense
      </Button>
      <Box
        sx={{
          backgroundColor: "initial",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "20px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#4F93D7",
            fontWeight: "600",
          }}
        >
          Total Expenses: KES {totalExpenses}
        </Typography>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid
          rows={expenseData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>

      {/* Add Expense Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            width: "400px",
            bgcolor:
              theme.palette.mode === "light" ? "#fff" : colors.primary[700],
            p: 4,
            boxShadow: 3,
            overflow: "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New Expense
          </Typography>
          <TextField
            label="Expense Name"
            name="expenseName"
            fullWidth
            margin="normal"
            value={formData.expenseName}
            onChange={handleInputChange}
          />

          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            value={formData.description}
            onChange={handleInputChange}
          />
          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel id="interval-select-label">Account Type</InputLabel>
            <Select
              labelId="interval-select-label"
              name="accountSubCategoryId"
              value={formData.accountSubCategoryId}
              onChange={handleInputChange}
              displayEmpty
              label="Account SubCategory"
            >
              {uniqueAccountSubcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  {subcategory.accountName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Amount"
            name="amount"
            fullWidth
            margin="normal"
            type="number"
            value={formData.amount}
            onChange={handleInputChange}
          />

          <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddExpense}
            >
              Add Expense
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Expense Modal */}
      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            width: "400px",
            bgcolor: colors.primary[200],
            p: 4,
            boxShadow: 3,
            overflow: "auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Expense
          </Typography>
          <TextField
            label="Amount"
            name="amount"
            fullWidth
            margin="normal"
            type="number"
            value={modalData.amount}
            onChange={(e) =>
              setModalData({ ...modalData, amount: e.target.value })
            }
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            value={modalData.description}
            onChange={(e) =>
              setModalData({ ...modalData, description: e.target.value })
            }
          />
          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel id="interval-select-label">Account Type</InputLabel>
            <Select
              labelId="interval-select-label"
              name="accountSubCategoryId"
              value={modalData.accountSubCategoryId}
              onChange={handleInputChange}
              displayEmpty
              label="Account SubCategory"
            >
              {uniqueAccountSubcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  {subcategory.accountName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Expense Name"
            name="expenseName"
            fullWidth
            margin="normal"
            value={modalData.expenseName}
            onChange={(e) =>
              setModalData({ ...modalData, expenseName: e.target.value })
            }
          />
          <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateExpense}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Expense;
