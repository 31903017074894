import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PulseLoader } from "react-spinners"; // Importing the loader
import { allSubRights } from "../../../data/Axios/queries";
import { roleManagemenUrl } from "../../../config";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import SubRightsForm from "./form";
import { subrightColumns } from "../../../data/Columns/Rolemanagement/SubrightColumns";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
const base_url = roleManagemenUrl.uri;

const ignoreFields = ["Icon", "Action", "StatusFlag"];
const SubRightTable = () => {
  const actions = {
    entity: "Rights",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      modelName: "subRightIds",
      permission: "ActivatePermission",
      inputObjectName: "bulkActivateDeactivateSubRights",
    },
    dataObject: {
      ignoreFields,
      objectName: "allSubRights",
      parameters: "",
    },
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Subright Table"
        subtitle="View all Subrights, create edit or view"
        FormComponent={SubRightsForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};
export default SubRightTable;
