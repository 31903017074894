import axios from 'axios';
 const endpoint = 'https://opobe.mcb.co.ke/opogateway/opoemails';
// Utility function to get authorization headers
const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  return {
    'Authorization': formattedToken, 
    'Content-Type': 'application/json',
  };
};
export const fetchEmails = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const query = `
    query {
  emailsByCompany {
    body
    companyName
    companyEmail
    companyId
    contactEmailPassword
    createdBy
    createdDate
    emailClient
    emailClientPortNumber
    emailDisplayName
    encrypted
    failedReason
    fileName
    fileType
    hasFile
    isActive
    id
    recipientEmail
    retries
    sentDateTime
    status
    statusFlag
    subject
    subjectTitle
    updatedBy
    updatedDate
  }
}
  `;

  try {
    const response = await axios.post(endpoint, {
      query
    },
{
    headers:{
      'Authorization': formattedToken, 
      'Content-Type': 'application/json',
    },
});

    const emails = response.data.data.emailsByCompany;
    return emails;
  } catch (error) {
    console.error('Error fetching emails:', error);
    return [];
  }
};

export const createEmailMessage = async (emailInput) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const mutation = `
    mutation CreateEmailMessage($email: EmailMessagesInput!) {
      createEmailMessage(email: $email) {
        id
        recipientEmail
        subject
        subjectTitle
        body
        companyId
        
      }
    }
  `;

  const variables = {
    email: emailInput,
  };

  try {
    const response = await axios.post(endpoint, {
      query: mutation,
      variables,
    }, {
      headers:{
        'Authorization': formattedToken, 
        'Content-Type': 'application/json',
      },
    });

    return response.data.data.createEmailMessage;
  } catch (error) {
    console.error('Error creating email message:', error);
    return null;
  }
};

export const sendBulkEmails = async (emailMessages) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

    const mutation = `
        mutation CreateEmailMessages($emailMessages: EmailMessagesInput!) {
            createEmailMessages(emailMessages: $emailMessages) {
                id
                recipientEmail
                subject
                body
                sentDateTime
                status
                isActive
                createdBy
                createdDate
            }
        }
    `;

    const response = await axios.post(endpoint, {
        query: mutation,
        variables: { emailMessages }, // Correctly named variable
    }, {
      headers:{
        'Authorization': formattedToken, 
        'Content-Type': 'application/json',
      },
    });

    return response.data.data.createEmailMessages; // Return the created email messages data
};

// Function to send a single email
export const sendEmail = async (emailInput) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

    const mutation = `
        mutation CreateEmailMessage($emailMessages: EmailMessagesInput!) {
            createEmailMessage(emailMessages: $emailMessages) { // Use emailMessages instead of email
                id
                recipientEmail
                subject
                body
                sentDateTime
                status
                isActive
                createdBy
                createdDate
            }
        }
    `;

    const variables = {
        emailMessages: { // Correctly named variable
            email: emailInput, // Ensure you are nesting email input correctly
        },
    };

    const response = await axios.post(endpoint, {
        query: mutation,
        variables,
    }, {
      headers:{
        'Authorization': formattedToken, 
        'Content-Type': 'application/json',
      },
    });

    return response.data.data.createEmailMessage; // Return the created email message data
};
