import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";

const BookLoan = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  useEffect(() => {
    // Delay for live search to avoid rapid searches
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        const customer = customers.find(
          (cust) =>
            cust.IdNoOrPassport.includes(searchTerm) ||
            cust.MobileNumber.includes(searchTerm)
        );
        setFilteredCustomer(customer || null);

        if (!customer) {
          Swal.fire("Customer Not Found", "Customer does not exist", "warning");
        }
      } else {
        setFilteredCustomer(null);
      }
    }, 1000); // Adjust delay as necessary (500ms in this example)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchJsonDynamic(
        "allCustomers",
        null,
        null,
        customerservice.uri
      );
      const allCustomers = JSON.parse(response.results) || [];

      const params = {companyId: companyId};
      const productResponse = await fetchJsonDynamic(
        "allProductsByColumnValues",
        "parameters",
        params,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setCustomers(allCustomers);
      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);
  const handleAppraisalSubmit = async () => {
    try {
      const mutationData = {
        p_CustomerId: filteredCustomer.Id,
        p_Principle: parseInt(amount),
        p_ProductId: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "insertLoanApplications",
        mutationData,
        "loanApplication",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults) {
        if (parsedResults === "Loan Booked Successfully") {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: parsedResults,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Book A Loan</Typography>
      <TextField
        label="Search Customer by customer phone number | ID No. | Passport"
        placeholder="Enter search criteria"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Customer Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Customer Name:</strong> {filteredCustomer.FirstName}{" "}
                    {filteredCustomer.OtherNames}{" "}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {filteredCustomer.EmailAddress}
                  </Typography>
                  <Typography>
                    <strong>ID Number:</strong>{" "}
                    {filteredCustomer.IdNoOrPassport}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.MobileNumber}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.BranchId}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.DateCreated}
                  </Typography>
                  <Typography>
                    <strong>Current Loan Limit:</strong>{" "}
                    {filteredCustomer.LoanLimit}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Amount to Disburse"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
          >
            Submit For Appraisal
          </Button>
        </>
      )}
    </Box>
  );
};

export default BookLoan;