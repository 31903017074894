import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../../../data/Axios/DynamicForm";
import { executeAnyMutation, fetchJsonDynamic } from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice, setupManagementUrl } from "../../../../../config";
import { customerBusinessDetailsFields } from "../../../../../data/DynamicForm/FormFields/CustomerFormFields";

const base_url = customerservice.uri;

const CustomerBusinessDetailsForm = (props) => {
  const customerId = parseInt(localStorage.getItem("CustomerId"));

  const [businessTypeData, setBusinessTypeData] = useState([]);

  const [industryTypesData, setIndustryTypesData] = useState([]);

  const initialValues = {
    businessName: props.data ? props.data.BusinessName : "",
    yearOfEstablishment: props.data ? props.data.YearOfEstablishment : "",
    businessLocation: props.data ? props.data.BusinessLocation : "",
    landMark: props.data ? props.data.LandMark : "",
    customerId: customerId, // Assuming the customerId comes from localStorage or props
    averageCashSales: props.data ? props.data.AverageCashSales : "",
    //haveALocalAuthority: props.data ? props.data.HaveALocalAuthority : "",
    businessTypeId: props.data ? props.data.BusinessTypeId : "",
    industryTypeId: props.data ? props.data.IndustryTypeId : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = {
        ...values,
        averageCashSales:parseFloat(values.averageCashSales),
        customerId: parseInt(customerId),
        businessTypeId: parseInt(values.businessTypeId),
        industryTypeId: parseInt(values.industryTypeId),
        //haveALocalAuthority: parseInt(values.haveALocalAuthority),
      };

      if (props.isEditing) {
        mutationData.id = props.data.Id;
      }

      const response = await executeAnyMutation(
        props.isEditing ? "updateCustomerBusinessDetails" : "createCustomerBusinessDetails",
        mutationData,
        "businessDetails",
        false,
        base_url
      );

      if (response.results) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Business Details ${props.isEditing ? "updated" : "created"} successfully.`,
        });
      } else if (response.error) {
        throw new Error(response.error);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  useEffect(() => {
    async function fetchAllData() {
      try {
        const businessTypes = await fetchJsonDynamic(
          "businessTypes",
          null,
          null,
          setupManagementUrl.uri
        );
        const industryTypes = await fetchJsonDynamic(
            "allIndustryTypes",
            null,
            null,
            setupManagementUrl.uri
          );
  

        if (businessTypes?.results) {
          const results = JSON.parse(businessTypes?.results);
          setBusinessTypeData(results || []);
        }
        
        if (industryTypes?.results) {
            const results = JSON.parse(industryTypes?.results);
            setIndustryTypesData(results || []);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);
  const industryTypeOptions = industryTypesData
  ? industryTypesData.map((branch) => ({
      value: branch.Id,
      label: branch.Name,
    }))
  : [];
  const businessTypesOptions = businessTypeData
  ? businessTypeData.map((branch) => ({
      value: branch.Id,
      label: branch.Name,
    }))
  : [];
  const forms =[
    ...customerBusinessDetailsFields,
    {
        name: "businessTypeId",
        label: "Business Type",
        type: "select", 
        options: businessTypesOptions,
        gridColumn: "span 2",
        required: true,
      },
      {
        name: "industryTypeId",
        label: "Industry Type",
        type: "select",
        options: industryTypeOptions,
        gridColumn: "span 2",
        required: true,
      },
  ]

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={forms}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Business Details" : "Create Business Details"}
      subtitle={props.isEditing ? "Edit existing business details" : "Create new business details"}
    />
  );
};

export default CustomerBusinessDetailsForm;
