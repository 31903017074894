import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners"; // Importing the loader
import { jwtDecode } from "jwt-decode";
import { loginCallApi } from "../../data/userData";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import money from "../../Assets/Images/business.jpg";

function saveTokenToStorage(decodedToken) {
  localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Example: Using local storage
}

async function saveDataToLocalStorage(response) {
  const token = response.data.token;
  localStorage.setItem("token", JSON.stringify(token));

  const filePath = response.data.companyLogoPath
    ? `${response.data.companyLogoPath}`
    : null;
  localStorage.setItem("filePath", filePath);

  console.log("file path", localStorage.getItem("filePath"));
  const expirationTime = new Date().getTime() + 5 * 60 * 60 * 1000;
  //const expirationTime = new Date().getTime() + 1 * 60 * 1000;
  localStorage.setItem("tokenExpiration", expirationTime);
  localStorage.setItem("rights", JSON.stringify(response.data.rights));
  const decodedToken = jwtDecode(token);
  saveTokenToStorage(decodedToken);
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mcb.co.ke/">
        MCB Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function LoginSide({ onLogin }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  React.useEffect(() => {
    // Load saved credentials if "Remember Me" was checked
    const savedUsername = sessionStorage.getItem("username");
    const savedPassword = sessionStorage.getItem("password");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false); // Adding a loading state

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the request starts

    try {
      const response = await loginCallApi(username, password);
      if (response === "ERR_NETWORK") {
        Swal.fire({
          icon: "error",
          title: "Error Network!",
          text: "Unable to login, check your internet connection and try again.",
        });
      }
      if (
        response.code === "ERR_BAD_RESPONSE" ||
        response.code === "ERR_BAD_REQUEST"
      ) {
        if (response.response.data) {
          Swal.fire({
            icon: "error",
            title: "Invalid Credentials!",
            text: response.response.data,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Server Error!",
            text: "Server error, contact Admin",
          });
        }
      }


      // if (
      //   response.status === 401 
      // ) {
      //   if (response.response.data) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Server Error!",
      //       text: "Contact Admin!",
      //     });
      //   } else {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Connection Error!",
      //       text: "Check Internet Connection",
      //     });
      //   }
      // }


      if (response.status === 200) {
        if (response.data.changepassword === true) {
          saveDataToLocalStorage(response);
          navigate("/changepassword");
          return;
        } else {
          if (rememberMe) {
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("password", password);
          } else {
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("password");
          }
          saveDataToLocalStorage(response);
          const rights = response.data.rights;
          const cleanedData = JSON.parse(rights.replace(/\\/g, ""));
          localStorage.setItem("rightsforrbac", cleanedData);

          const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
          const roleGroupId = parseInt(decodedToken.RoleGroupId);

          if (roleGroupId === 1) {
            onLogin();
            navigate("/MCB-SWITCH");
          } else {
            onLogin();
            navigate("/super-admin-dashboard");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Set loading to false when the request is finished
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      )}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: "relative",
            backgroundImage: `url(${money})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.1)", // 30% transparent white
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={handleUsernameChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="HashedPassword"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="HashedPassword"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading} // Disable the button while loading
              >
              Ingia
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  {/* <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link> */}
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
