// CourtTypeView.js
import React from "react";
import DynamicCard from "./MainCardComponent";
import NOKTable from "../Customers/customers/ManageCustomer/NOK";
import GuarantorsTable from "../Customers/customers/ManageCustomer/GuarantorDetails";
import BusinessDetailsTable from "../Customers/customers/ManageCustomer/BusinessDetails";
import GroupRolesTable from "../Customers/groups/ManageGroup/GroupRoles";
import GroupMembersTable from "../Customers/groups/ManageGroup/Members";
import InterestPaymentMethodsTable from "../SetupManagement/InterestPaymentMethods";
import MpesaStatemets from "../Customers/customers/ManageCustomer/MpesaStatements";
import Customer360 from "../Customers/customer360";
import CustomerLoanApplication from "../Customers/customers/LoanDAta";


export const NextOfKin = () => {
  return (
    <DynamicCard>
      <NOKTable />
    </DynamicCard>
  );
};
export const Guarantor = () => {
  return (
    <DynamicCard>
      <GuarantorsTable />
    </DynamicCard>
  );
};
export const BusinessDetails = () => {
  return (
    <DynamicCard>
      <BusinessDetailsTable />
    </DynamicCard>
  );
};
export const GroupRoles = () => {
  return (
    <DynamicCard>
      <GroupRolesTable />
    </DynamicCard>
  );
};
export const Members = () => {
  return (
    <DynamicCard>
      <GroupMembersTable />
    </DynamicCard>
  );
};

export const MpesaStatements = () => {
  return (
    <DynamicCard>
      <MpesaStatemets />
    </DynamicCard>
  );
};

export const BioData = () => {
  return (
    <DynamicCard>
      <Customer360 />
    </DynamicCard>
  );
}

export const LoanData = () => {
  return (
    <DynamicCard>
      <CustomerLoanApplication />
    </DynamicCard>
  );
}
