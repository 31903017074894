import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../../../data/Axios/DynamicForm";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice } from "../../../../../config";

const base_url = customerservice.uri;

const GroupMembersForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const groupId= parseInt(localStorage.getItem("GroupId"))
  const userId = parseInt(decodedToken.Id);

  const [customersData, setCustomersData] = useState([]);
  const [groupRolesData, setGroupRolesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const customersResponse = await fetchJsonDynamic("allCustomers", null, null, base_url);
        if (customersResponse?.results) {
          const results = JSON.parse(customersResponse?.results);
          setCustomersData(results || []);
        }
        const params = { groupId: groupId };
        const groupRolesResponse = await fetchJsonDynamic("groupRolesByColumnValues", "groupRole", params, base_url);
        if (groupRolesResponse?.results) {
          const results = JSON.parse(groupRolesResponse?.results);
          setGroupRolesData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const customersOptions = customersData.map((customer) => ({
    value: customer.Id,
    label: `${customer.FirstName} ${customer.OtherNames} - ${customer.MobileNumber}`,
  }));

  const roleOptions = groupRolesData.map((role) => ({
    value: role.Id,
    label: role.RoleName,
  }));

  const fields = [
    {
      name: "customerIds",
      label: "Customers",
      type: "multiselect",
      options: customersOptions,
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "roleId",
      label: "Roles",
      type: "select",
      options: roleOptions,
      required: true,
      gridColumn: "span 2",
    }
  ];

  const initialValues = {
    groupId: "",
    roleId: "",
    customerIds: "",
    createdBy: userId,
    createdDate: new Date().toISOString(),
    updatedBy: userId,
    updatedDate: new Date().toISOString(),
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { roleId, customerIds } = values;
  
      // Construct a single payload
      const mutationData = {
        groupId: parseInt(groupId),
        roleId: parseInt(roleId),
        customerIds // Pass the entire array of customerIds
      };
  
      // Send a single mutation request with the payload
      const response = await executeAnyMutation(
        "createCustomerGroup", // Use the mutation name that supports multiple IDs
        mutationData,
        "inputModel",
        false,
        base_url
      );
  
      // Handle the response as needed
      if (response && response.results) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "CustomerGroup records created successfully.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  
    setSubmitting(false);
  };
  

  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title="Create Customer Group"
      subtitle="Create a new CustomerGroup record"
    />
  );
};

export default GroupMembersForm;
