import React from "react";
import { Box } from "@mui/material";
import { customerservice } from "../../../config";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";


const base_url = customerservice.uri;

const CustomerLoanLimitApprovalTable = () => {
  const isApproved = { isApproved: false };
  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    approveLoanLimit: {
      key: "APPROVELOANLIMIT",
      button_name: "ApproveloanLimit",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "param", // Specify the model name here
      inputObjectName: "updateCustomerLoanLimit",
      flag: 1,
    },
    rejectLoanLimitUpdate: {
      key: "REJECTLOANLIMIT",
      button_name: "RejectloanLimit",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "param", // Specify the model name here
      inputObjectName: "updateCustomerLoanLimit",
      flag: 2,
    },
    dataObject: {
      ignoreFields: ["CreateDate", "UpdateDate", "IsActive", "DateApproved"],
      objectName: "loanLimitApprovalViewByColumnValues",
      parameters: isApproved,
      inputObjectName: "loanLimitApprovalView",
    },
    showStatus: true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Approve Customer Loan Limit"
        subtitle="Approve Loan limit"
        // FormComponent={SuspendedTransactionForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default CustomerLoanLimitApprovalTable;
