import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import DynamicForm from "../../../../../data/Axios/DynamicForm";
import { executeAnyMutation, fetchJsonDynamic } from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice, setupManagementUrl } from "../../../../../config";
import { guarantorFields } from "../../../../../data/DynamicForm/FormFields/CustomerFormFields";

const base_url = customerservice.uri;
const setup_url = setupManagementUrl.uri;


const GuarantorForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const customerId= parseInt(localStorage.getItem("CustomerId"))
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [rightsData, setRightsData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [maritalStatusData, setMaritalStatustData] = useState([]);
  const [customerRelationshipData, setCustomerRelationshipData] = useState([]);


  useEffect(() => {
    async function fetchAllData() {
      try {
        const maritalStatusDataResponse = await fetchJsonDynamic(
          "allMaritalStatus",
          null,
          null,
          setup_url
        );
        const customerRelationshipsResponse = await fetchJsonDynamic(
          "allCustomerRelationships",
          null,
          null,
          setup_url
        );
        if (customerRelationshipsResponse?.results) {
          const result = JSON.parse(customerRelationshipsResponse?.results);
          setCustomerRelationshipData(result || []);
        }
        if (maritalStatusDataResponse?.results) {
          const results = JSON.parse(maritalStatusDataResponse?.results);
          setMaritalStatustData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);
 const maritalStatusOptions = maritalStatusData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));
  const customerRelationshipOptions = customerRelationshipData.map((relation) => ({
    value: relation.Id,
    label: relation.Name,
  }));
  const fields = [
    {
      name: "maritalStatus",
      label: "Marital Status",
      type: "select",
      options: maritalStatusOptions,
      gridColumn: "span 2",
      required: false,
    },
    {
      name: "relationshipToLoanee",
      label: "Relationship",
      type: "select",
      options: customerRelationshipOptions,
      gridColumn: "span 2",
      required: false,
    },
    ...guarantorFields,
  ];


  const [maritalStatus, setMaritalStatus] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const initialValues = {
    id: props.data ? props.data.Id : "",
    relationshipToLoanee: props.data ? props.data.RelationshipToLoanee : "",
    firstName: props.data ? props.data.FirstName : "",
    otherNames: props.data ? props.data.OtherNames : "",
    mobileNumber: props.data ? props.data.MobileNumber : "",
    idNoOrPassport: props.data ? props.data.IdNoOrPassport : "",
    maritalStatus: props.data ? props.data.MaritalStatus : "",
    dob: props.data ? props.data.Dob : "",
    currentResidence: props.data ? props.data.CurrentResidence : "",
    town: props.data ? props.data.Town : "",
    houseName: props.data ? props.data.HouseName : "",
    floorNumber: props.data ? props.data.FloorNumber : "",
    roomNumber: props.data ? props.data.RoomNumber : "",
    occupation: props.data ? props.data.Occupation : "",

  };
  useEffect(() => {
    async function fetchAllData() {
      try {
        const maritalstatus = await fetchJsonDynamic(
          "allMaritalStatus",
          null,
          null,
          setupManagementUrl.uri
        );
      
        
        if (maritalstatus?.results) {
            const results = JSON.parse(maritalstatus?.results);
            setMaritalStatus(results || []);
          }
          const relationship = await fetchJsonDynamic(
            "allCustomerRelationships",
            null,
            null,
            setupManagementUrl.uri
          );
        
          
          if (relationship?.results) {
              const results = JSON.parse(relationship?.results);
              setRelationships(results || []);
            }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);
  
  const relationshipsOptions = relationships
  ? relationships.map((branch) => ({
      value: branch.Id,
      label: branch.Name,
    }))
  : [];
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        relationshipToLoanee,
        firstName,
        otherNames,
        mobileNumber,
        idNoOrPassport,
        maritalStatus,
        dob,
        currentResidence,
        town,
        houseName,
        floorNumber,
        roomNumber,
        occupation,
    
      } = values;

      const mutationData = {
        //id: props.data.Id,
        relationshipToLoanee:parseInt(relationshipToLoanee),
        firstName,
        otherNames,
        mobileNumber,
        idNoOrPassport,
        maritalStatus:parseInt(maritalStatus),
        dob,
        currentResidence,
        town,
        houseName,
        floorNumber,
        roomNumber,
        occupation,
        customerId,
       
      };

      if (props.isEditing) {
        mutationData.id = props.data.Id;
      }

      const response = await executeAnyMutation(
        props.isEditing ? "updateGuarantor" : "createGuarantor",
        mutationData,
        "guarantor",
        false,
        base_url
      );

      if (response.results) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Guarantor${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
      else if (response.error)
      {
        throw new Error(response.error)
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  const forms =[
    ...guarantorFields,
  
      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "select", 
        options: maritalStatusOptions,
        gridColumn: "span 2",
        required: false,
      },
      {
        name: "relationshipToLoanee",
        label: "Relationship",
        type: "select", 
        options: relationshipsOptions,
        gridColumn: "span 2",
        required: false,
      },
  ]
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={forms}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Guarantor" : "Create Guarantor"}
      subtitle={
        props.isEditing
          ? "Edit an Existing Guarantor"
          : "Create a Guarantor"
      }
    />
  );
};

export default GuarantorForm;
