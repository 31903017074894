import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../data/Axios/DynamicForm";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";

import { companyManagementUrl } from "../../../config";
import {
  companyFields,
  regionFields,
} from "../../../data/DynamicTable/CompanyManagementForms";
import {
  allCompanies,
  allSubscriptionPlans,
} from "../../../data/Axios/queries";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = companyManagementUrl.uri;

const RegionForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const companyDataResponse = await fetchJsonDynamic(
          "companies",
          null,
          base_url
        );
        if (companyDataResponse?.results) {
          const results = JSON.parse(companyDataResponse?.results);
          setCompanyData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);
  const companyOptions = companyData.map((company) => ({
    value: company.Id,
    label: company.CompanyName,
  }));
  const fields = [
    ...regionFields,
    // {
    //   name: "companyId",
    //   label: "Company ID",
    //   type: "select",
    //   gridColumn: "span 2",
    //   options: companyOptions,
    //   required: true,
    // },
  ];

  const initialValues = {
    name: props.data ? props.data.Name : "",
    createdDate: props.data ? props.data.CreatedDate : new Date().toISOString(),
    createdBy: props.data ? props.data.CreatedBy : userId,
    updatedBy: userId,
    updatedDate: new Date().toISOString(),
    companyId: props.data ? props.data.CompanyId : companyId,
    company: props.data ? props.data.Company : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { name, companyId } = values;
      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            name,
            companyId,
            updatedBy: userId,
          }
        : {
            name,
            companyId,
            createdBy: userId,
          };
      const response = await executeAnyMutation(
        props.isEditing ? "updateRegion" : "createRegion",
        mutationData,
        "region",
        false,
        base_url
      );
      if (Object.values(response.results)) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Region ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
  };

  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Region" : "Create Region"}
      subtitle={
        props.isEditing ? "Edit an Existing Region" : "Create a New Region"
      }
    />
  );
};

export default RegionForm;
