import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

import DynamicForm from "../../../../data/Axios/DynamicForm";

import { AccountsandFinanceurl } from "../../../../config";
import Swal from "sweetalert2";
import { accountCategoryFields } from "../../../../data/DynamicTable/AccountsDynamicForms";
import { generateAndExecuteMutation } from "../../../../data/Axios/DynamicService";
import { executeAnyMutation } from "../../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = AccountsandFinanceurl.uri;

const AccountCategoryForm = (props) => {
  const initialValues = {
    id: props.data ? props.data.id : "",
    status: props.data ? props.data.Swaltatus : "",
    categoryName: props.data ? props.data.CategoryName : "",
    currency: props.data ? props.data.Currency : "",
    rate: props.data ? props.data.Rate : "",
    parentAccount: props.data ? props.data.ParentAccount : "",
    oldParent: props.data ? props.data.OldParent : "",

  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { categoryName, currency, rate, } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            categoryName,
            currency,
            rate: parseFloat(rate),
          }
        : {
            categoryName,
            currency,
            rate: parseFloat(rate),
          };

      const response = executeAnyMutation(
        props.isEditing ? "updateAccountCategory" : "createAccountCategory",
        mutationData,
        "accountcategory",
        false,
        base_url
      );

      if (response) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Account category ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      } else {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: `${response?.error}`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={accountCategoryFields}
      onSubmit={handleSubmit}
      title={
        props.isEditing ? "Edit Account Category" : "Create Account Category"
      }
      subtitle={
        props.isEditing
          ? "Edit an Existing Account Category"
          : "Create a New Account Category"
      }
    />
  );
};

export default AccountCategoryForm;
