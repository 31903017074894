import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Button() {
  const [isClicked, setIsClicked] = useState(false); // Example toggle state
  const navigate = useNavigate();

  return (
    <div>
      <button
        className={
          isClicked
            ? "bg-yellow-500 w-[100px] rounded-md font-medium my-6 mx-auto py-3"
            : "bg-[#00df9a] w-[100px] rounded-md font-medium my-6 mx-auto py-3"
        }
        onClick={() => {
          setIsClicked(!isClicked); // Toggle state on click
          navigate("/login");
        }}
      >
        Sign In
      </button>
    </div>
  );
}

export default Button;
