import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../data/Axios/DynamicForm";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";

import { companyManagementUrl } from "../../../config";
import {
  branchFields,
  companyFields,
} from "../../../data/DynamicTable/CompanyManagementForms";
import {
  allCompanies,
  allRegions,
  allSubscriptionPlans,
} from "../../../data/Axios/queries";
import { fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = companyManagementUrl.uri;

const BranchForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  const initialValues = {
    name: props.data ? props.data.Name : "",
    contactPerson: props.data ? props.data.ContactPerson : "",
    email: props.data ? props.data.Email : "",
    phoneContact: props.data ? props.data.PhoneContact : "",
    payBill: props.data ? props.data.PayBill : "",
    branchLocation: props.data ? props.data.BranchLocation: "",
    createdDate: props.data ? props.data.CreatedDate : new Date().toISOString(),
    createdBy: props.data ? props.data.CreatedBy : userId,
    updatedBy: userId,
    updatedDate: new Date().toISOString(),
    companyId: props.data ? props.data.CompanyId : companyId,
    company: props.data ? props.data.Company : "",
    regionId: props.data ? props.data.RegionId : "",
    region: props.data ? props.data.Region : "",
  };
  const [companyData, setCompanyData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAllData() {
      try {
        // Fetch companies data
        const companyDataResponse = await fetchJsonDynamic(
          "companies",
          null,
          base_url
        );
        if (companyDataResponse?.results) {
          const results = JSON.parse(companyDataResponse.results);
          setCompanyData(results || []);
        }

        // Fetch regions data
        const regionDataResponse = await fetchJsonDynamic(
          "region",
          null,
          null,
          base_url
        );
        if (regionDataResponse?.results) {
          const results = JSON.parse(regionDataResponse.results);
          setRegionData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  // Mapping data for dropdown options
  const companyOptions = companyData.map((company) => ({
    value: company.Id,
    label: company.Name,
  }));

  const regionOptions = regionData.map((region) => ({
    value: region.Id,
    label: region.Name,
  }));

  const fields = [
    ...branchFields,
    {
      name: "regionId",
      label: "Region",
      type: "select",
      gridColumn: "span 2",
      options: regionOptions,
      required: true,
    },
  ];
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { 
        name, 
        companyId, 
        regionId,
        contactPerson,
        email,
        phoneContact,
        payBill,
        branchLocation,
      } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            name,
            companyId,
            contactPerson,
            email,
            phoneContact,
            branchLocation, 
            payBill,
            regionId,
            updatedBy: userId,
          }
        : {
            name,
            companyId,
            contactPerson,
            email,
            phoneContact,
            branchLocation, 
            payBill,
            regionId,
            createdBy: userId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateBranch" : "createBranch",
        mutationData,
        "branch",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Branch ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Branch" : "Create Branch"}
      subtitle={
        props.isEditing ? "Edit an Existing Branch" : "Create a New Branch"
      }
    />
  );
};

export default BranchForm;
