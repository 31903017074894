import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  ALL_MODULES,
  CREATE_MODULE,
  UPDATE_MODULE,
} from "../../../data/RoleManagementData"; // Import your GraphQL mutations for creating and updating a module
import swal from "sweetalert";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { generateMutation } from "../../../data/Axios/DynamicService";
import {
  graphqlMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import { roleManagemenUrl, setupManagementUrl } from "../../../config";
import { allCompanies } from "../../../data/Axios/queries";
import Swal from "sweetalert2";

const base_url = roleManagemenUrl.uri;
const setup_url = setupManagementUrl.uri;

const ModuleForm = (props) => {
  const {
    loading: modulesLoading,
    error: modulesError,
    data: modulesData,
  } = useQuery(ALL_MODULES);
  const [createModule] = useMutation(CREATE_MODULE, {
    onCompleted: (data) => {
      props.onClose();
      props.onAction();
      swal("Success!", "Module has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create module, try again later", "error");
    },
  });

  const [updateModule] = useMutation(UPDATE_MODULE, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Module has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update module, try again later", "error");
    },
  });

  const initialValues = {
    module: props.data ? props.data.Module : "",
    icon: props.data ? props.data.Icon : "",
    companyId: props.data ? props.data.companyId : "",
  };

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);

  const validationSchema = yup.object().shape({
    module: yup.string().required("Module name is required"),
    icon: yup.string().required("Icon is required"),
  });
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { module, icon, createdBy, IsActive } = values;

      if (props.isEditing) {
        // If editing, update right
        await updateModule({
          variables: {
            id: props.data.Id,
            module,
            icon,
            createdBy: userId,
            IsActive: 1,
            companyId: parseInt(companyId),
          },
        });
        console.log("Module updated successfully!");
      } else {
        await createModule({
          variables: {
            module,
            icon,
            IsActive: 1,
            createdBy: userId,
            companyId: parseInt(companyId),
          },
        });
        console.log("Module created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Module" : "Create Module"}
        subtitle={
          props.isEditing ? "Edit an Existing Module" : "Create a New Module"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Module Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.module}
                name="module"
                error={touched.module && !!errors.module}
                helperText={touched.module && errors.module}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Icon *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.icon}
                name="icon"
                error={touched.icon && !!errors.icon}
                helperText={touched.icon && errors.icon}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ModuleForm;
