import axios from "axios";
import { roleManagemenUrl } from "../../config";

const role_baseUrl = roleManagemenUrl.uri;
const token = localStorage.getItem("token");
const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

const headers = {
  Authorization: formattedToken,
  "Content-Type": "application/json",
};
const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
  };
};

// Usage

export async function graphqlQuery(query, base_url) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query,
    };

    const response = await axios.post(base_url, postData, { headers });

    const dataObject = Object.values(response.data.data)[0];
    return dataObject;
  } catch (error) {
    throw error;
  }
}

export async function fetchAllData(query) {
  try {
    const data = await graphqlQuery(query);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export function generateMutation(
  mutationName,
  inputObjectName,
  inputFields,
  InputObject
) {
  let mutation = `mutation ${mutationName} {`;
  mutation += `${inputObjectName}(`;
  mutation += `${InputObject}: {`;
  const inputEntries = Object.entries(inputFields);
  inputEntries.forEach(([key, value], index) => {
    mutation += `${key}: ${JSON.stringify(value)}`;

    if (index < inputEntries.length - 1) {
      mutation += ", ";
    }
  });
  mutation += `}`;
  mutation += `) {
    id
    ${Object.keys(inputFields).join("\n")} 
  }
}`;
  return mutation;
}

export async function graphqlMutation(mutation, base_url) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query: mutation,
    };
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function graphqlQueryEngine(query, base_url) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query,
    };
    // export function generateBulkMutation(operationName, mutationFields, dataArray) {
    //   let mutation = `mutation {
    //     ${operationName}(
    //       ${mutationFields}: [`;

    //   // Iterate over the data array to construct mutation entries
    //   dataArray.forEach((data, index) => {
    //     mutation += `{
    //       ${Object.entries(data)
    //         .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
    //         .join("\n")}
    //     }`;

    //     // Add comma if not the last entry
    //     if (index < dataArray.length - 1) {
    //       mutation += ",";
    //     }
    //   });

    //   // Close the mutation string
    //   mutation += `]
    //   ) {
    //     ${Object.keys(dataArray[0]).join("\n")}
    //   }
    // }`;

    //   return mutation;
    // }

    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    console.error("Error executing GraphQL query:", error);
    throw error;
  }
}

export function generateQuery(queryName, modelName, inputFields, returnFields) {
  let query = `query { ${queryName}(`;

  if (Object.keys(inputFields).length > 0) {
    if (modelName) {
      query += `${modelName}: {`;
    }
    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      query += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        query += ", ";
      }
    });
    // query += `}`;
  }
  query += `)`;

  // Handle null or empty returnFields
  if (returnFields && returnFields.length > 0) {
    const returnFieldStrings = returnFields.join("\n");
    query += ` { ${returnFieldStrings} }`;
  }
  query += ` }`;

  return query;
}

export function generateQueryBalance(
  queryName,
  modelName,
  inputFields,
  returnFields
) {
  let query = `query { ${queryName}(`;

  if (Object.keys(inputFields).length > 0) {
    if (modelName) {
      query += `${modelName}: {`;
    }
    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      query += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        query += ", ";
      }
    });
    // query += `}`;
  }
  query += `})`;

  // Handle null or empty returnFields
  if (returnFields && returnFields.length > 0) {
    const returnFieldStrings = returnFields.join("\n");
    query += ` { ${returnFieldStrings} }`;
  }
  query += ` }`;

  return query;
}

export async function fetchDataEngine(
  queryName,
  modelName,
  inputFields,
  returnFields,
  baseurl
) {
  try {
    const query = generateQuery(
      queryName,
      modelName,
      inputFields,
      returnFields
    );
    const data = await graphqlQuery(query, baseurl);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function fetchDataEngineBalance(
  queryName,
  modelName,
  inputFields,
  returnFields,
  baseurl
) {
  try {
    const query = generateQueryBalance(
      queryName,
      modelName,
      inputFields,
      returnFields
    );
    const data = await graphqlQuery(query, baseurl);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export function generateMutationBalance(
  mutationName,
  modelName,
  inputFields,
  returnFields
) {
  let mutation = `mutation { ${mutationName}(`;

  if (Object.keys(inputFields).length > 0) {
    if (modelName) {
      mutation += `${modelName}: {`;
    }
    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      mutation += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        mutation += ", ";
      }
    });
  }
  mutation += `})`;

  // Handle null or empty returnFields
  if (returnFields && returnFields.length > 0) {
    const returnFieldStrings = returnFields.join("\n");
    mutation += ` { ${returnFieldStrings} }`;
  }
  mutation += ` }`;

  return mutation;
}

export async function mutateDataEngineBalance(
  mutationName,
  modelName,
  inputFields,
  returnFields,
  baseurl
) {
  try {
    const mutation = generateMutationBalance(
      mutationName,
      modelName,
      inputFields,
      returnFields
    );
    const data = await graphqlQuery(mutation, baseurl);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export function generateCustomQuery(
  queryName,
  subQueryName,
  inputFields,
  returnFields
) {
  let query = `query ${queryName} `;

  query = `{ ${subQueryName}(`;

  if (Object.keys(inputFields).length > 0) {
    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      query += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        query += ", ";
      }
    });
  }
  query += `)`;

  const returnFieldStrings = returnFields.join("\n");
  query += ` { ${returnFieldStrings} } }`;

  return query;
}

export async function fetchDataEngineCustomized(
  queryName,
  subQueryName,
  inputFields,
  returnFields,
  baseurl
) {
  try {
    const query = generateCustomQuery(
      queryName,
      subQueryName,
      inputFields,
      returnFields,
      baseurl
    );
    const data = await graphqlQuery(query, baseurl);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function generateAndExecuteBulkMutation(
  operationName,
  mutationFields,
  dataArray,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    // Generate the mutation string
    let mutation = `mutation {
      ${operationName}(
        ${mutationFields}: [`;

    // Iterate over the data array to construct mutation entries
    dataArray.forEach((data, index) => {
      mutation += `{
        ${Object.entries(data)
          .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
          .join("\n")}
      }`;

      // Add comma if not the last entry
      if (index < dataArray.length - 1) {
        mutation += ",";
      }
    });

    // Close the mutation string
    mutation += `]
      ) {
        ${Object.keys(dataArray[0]).join("\n")}
      }
    }`;

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}
export async function ExecuteBulkMutationForRights(
  operationName,
  mutationFields,
  dataArray,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    // Generate the mutation string
    let mutation = `mutation {
      ${operationName}(
        ${mutationFields}: [`;

    // Iterate over the data array to construct mutation entries
    dataArray.forEach((data, index) => {
      mutation += `{
        ${Object.entries(data)
          .filter(([key]) => key !== "subRights")
          .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
          .join("\n")}`;

      // Add subRights if they exist
      if (data.subRights && data.subRights.length > 0) {
        mutation += `,
        subRights: [
          ${data.subRights
            .map(
              (subRight) => `{
              ${Object.entries(subRight)
                .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
                .join("\n")}
            }`
            )
            .join(",")}
        ]`;
      }

      mutation += `}`;

      // Add comma if not the last entry
      if (index < dataArray.length - 1) {
        mutation += ",";
      }
    });

    // Close the mutation string
    mutation += `]
      ) {`;

    // Add the fields to be returned in the response
    const fieldsToReturn = Object.keys(dataArray[0])
      .filter((key) => key !== "subRights")
      .join("\n");

    mutation += `${fieldsToReturn}`;

    // Check if there are subRights to include in the response
    if (dataArray[0].subRights && dataArray[0].subRights.length > 0) {
      mutation += `
        subRights {
          ${Object.keys(dataArray[0].subRights[0]).join("\n")}
        }`;
    }

    // Close the mutation string
    mutation += `
      }
    }`;

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}
export async function generateAndExecuteMutation(
  inputObjectName,
  inputFields,
  InputObject,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    let mutation = `mutation {`;

    // Conditionally add InputObject if it is provided
    if (InputObject) {
      mutation += `${inputObjectName}(${InputObject}: {`;
    } else {
      mutation += `${inputObjectName}(`;
    }

    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      mutation += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        mutation += ", ";
      }
    });

    // Close the InputObject if it was opened
    if (InputObject) {
      mutation += `}`;
    }

    if (
      inputObjectName.toLowerCase().includes("activate") ||
      inputObjectName.toLowerCase().includes("deactivate")
    ) {
      mutation += `) { results error } }`;
    } else if (inputObjectName === "bulkApproveRejectCustomersForCredit") {
      mutation += `){results error} }`;
    }  else {
      mutation += `) { results error } }`;
    }

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}
export async function generateAndExecuteReconcileSuspendedTxn(
  suspendedC2BFields,
  base_url
) {
  try {
    const headers = getAuthHeaders();

    // Construct the mutation string
    let mutation = `mutation {
      reconcileSuspendedTxn(
        suspendedC2B: {`;

    // Iterate over the suspendedC2BFields to add fields to the mutation
    const fieldEntries = Object.entries(suspendedC2BFields);
    fieldEntries.forEach(([key, value], index) => {
      mutation += `${key}: ${value !== null ? JSON.stringify(value) : null}`;
      if (index < fieldEntries.length - 1) {
        mutation += ", ";
      }
    });

    mutation += `}) {
        id
        billRefNumber
        businessShortCode
        firstName
        lastName
        invoiceNumber
        middleName
        msisdn
        orgAccountBalance
        thirdPartyTransID
        transactionType
        transAmount
        transID
        transTime
        isReconcilled
        companyId
      }
    }`;

    const postData = { query: mutation };

    // Execute the mutation
    const response = await axios.post(base_url, postData, { headers });
    return response.data.data.reconcileSuspendedTxn; // Return the specific response
  } catch (error) {
    throw error;
  }
}

export async function generateAndExecuteMutationException(
  inputObjectName,
  inputFields,
  InputObject,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    let mutation = `mutation {`;

    // Conditionally add InputObject if it is provided
    if (InputObject) {
      mutation += `${inputObjectName}(${InputObject}: {`;
    } else {
      mutation += `${inputObjectName}(`;
    }

    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      mutation += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        mutation += ", ";
      }
    });

    // Close the InputObject if it was opened
    if (InputObject) {
      mutation += `}`;
    }

    if (
      inputObjectName.toLowerCase().includes("activate") ||
      inputObjectName.toLowerCase().includes("deactivate")
    ) {
      mutation += `) }`;
    } else if (inputObjectName === "bulkApproveRejectCustomersForCredit") {
      mutation += `) }`;
    } else if (inputObjectName === "markPurchasePaid") {
      mutation += `)  { orderId }  }`;
    } else if (
      inputObjectName === "createEmailMessage" ||
      inputObjectName === "createMessage"
    ) {
      mutation += `) { messageId} }`;
    } else {
      mutation += `) { id } }`;
    }

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });

    if (response.data.errors) {
      const extensionsMessage =
        response.data.errors[0].extensions &&
        response.data.errors[0].extensions.message
          ? response.data.errors[0].extensions.message
          : "An unexpected error occurred.";

      return {
        status: response.status,
        errors: [{ message: extensionsMessage }], // Use extensions.message if available
      };
    }

    return {
      status: response.status,
      data: response.data.data,
    };
  } catch (error) {
    let errorMessage = "An unexpected error occurred.";

    if (error.response) {
      // If the server responded with an error status
      errorMessage =
        error.response.data.errors && error.response.data.errors[0].message
          ? error.response.data.errors[0].message
          : error.response.statusText;
    } else if (error.request) {
      errorMessage = "No response from server.";
    } else {
      errorMessage = error.message;
    }

    // Return error message and status
    return {
      status: error.response ? error.response.status : 500,
      errors: [{ message: errorMessage }],
    };
  }
}

export async function MutationWithArrayInput(
  inputObjectName,
  inputFields,
  InputObject,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    let mutation = `mutation {`;

    if (InputObject) {
      mutation += `${inputObjectName}(${InputObject}: {`;
    } else {
      mutation += `${inputObjectName}(`;
    }

    const formatField = (key, value) => {
      if (Array.isArray(value)) {
        return `${key}: [${value
          .map(
            (item) =>
              `{ ${Object.entries(item)
                .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
                .join(", ")} }`
          )
          .join(", ")}]`;
      } else if (typeof value === "object" && value !== null) {
        return `${key}: { ${Object.entries(value)
          .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
          .join(", ")} }`;
      } else {
        return `${key}: ${JSON.stringify(value)}`;
      }
    };

    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      mutation += formatField(key, value);
      if (index < inputEntries.length - 1) {
        mutation += ", ";
      }
    });

    if (InputObject) {
      mutation += `}`;
    }

    mutation += `) { errorMessage statusCode} }`;

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocument(action, file, dataObject, base_url) {
  if (!file) throw new Error("No file provided for upload.");

  const formData = new FormData();
  if (dataObject.logoUpload && dataObject.logoUpload === true) {
    formData.append("logo", file);
  } else {
    formData.append("file", file);
    if (dataObject && dataObject.caseId) {
      formData.append("CaseId", dataObject.caseId);
    } else {
      throw new Error("CaseId is missing.");
    }
  }

  try {
    const token = localStorage.getItem("token");
    const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

    const response = await axios.post(base_url, formData, {
      headers: {
        Authorization: formattedToken,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.status;
  } catch (error) {
    console.error("Error uploading the document:", error);
    throw error;
  }
}

export async function GetRoleRightsByRoleId(roleId) {
  try {
    const headers = getAuthHeaders();
    const postData = {
      query: `
    {
      moduleRightsByRoleId(roleId: ${roleId})
    }
        `,
    };
    const response = await axios.post(role_baseUrl, postData, { headers });
    return response.data.data.moduleRightsByRoleId;
  } catch (error) {
    let error_messages = error.response.data.errors;
    throw error;
  }
}

export async function generateAndExecuteBulkMutationForCases(
  operationName,
  mutationFields,
  dataArray,
  base_url,
  caseId
) {
  try {
    const headers = getAuthHeaders();
    // Generate the mutation string
    let mutation = `mutation {
      ${operationName}(
        caseId:${caseId}
        ${mutationFields}: [`;

    // Iterate over the data array to construct mutation entries
    dataArray.forEach((data, index) => {
      mutation += `{
        ${Object.entries(data)
          .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
          .join("\n")}
      }`;

      // Add comma if not the last entry
      if (index < dataArray.length - 1) {
        mutation += ",";
      }
    });

    // Close the mutation string
    mutation += `]
      ) {
        ${Object.keys(dataArray[0]).join("\n")}
      }
    }`;

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export const generateColumnsFromQuery = (query, MoreignoreFields) => {
  if (typeof query !== "string") {
    console.error("Invalid query format. Expected a string but got:", typeof query, query);
    return [];
  }

  const ignoreFields = [
    "createdDate",
    "createdBy",
    "updatedDate",
    "updatedBy",
    "isActive",
    "companyName",
    ...(MoreignoreFields || []),
  ];

  // Updated regex to handle extra whitespace and newlines
  const fieldRegex = /\b(\w+)\b/g;
  const fields = [...query.matchAll(fieldRegex)];

  const columns = fields
    .map((match) => match[1])
    .filter(
      (field) =>
        !ignoreFields.includes(field) &&
        !field.toLowerCase().endsWith("id") &&
        !field.toLowerCase().startsWith("is")
    )
    .map((field) => ({
      field,
      headerName: formatHeaderName(field),
      flex: 1,
    }));

  return columns;
};

// Utility function to format header names
const formatHeaderName = (field) => {
  return field
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

export async function generateAndExecuteMutationOrders(
  inputObjectName,
  inputFields,
  InputObject,
  base_url,
  returnFields
) {
  try {
    const headers = getAuthHeaders();
    let mutation = `mutation { ${inputObjectName}(`;

    // Conditionally add InputObject if provided
    if (InputObject) {
      mutation += `${InputObject}: {`;
    }

    // Iterate through inputFields to build the mutation
    Object.entries(inputFields).forEach(([key, value], index, array) => {
      if (Array.isArray(value)) {
        mutation += `${key}: [`;
        value.forEach((item, itemIndex) => {
          mutation += `{`;
          Object.entries(item).forEach(
            ([itemKey, itemValue], itemEntryIndex, itemEntryArray) => {
              mutation += `${itemKey}: ${JSON.stringify(itemValue)}`;
              if (itemEntryIndex < itemEntryArray.length - 1) {
                mutation += ", ";
              }
            }
          );
          mutation += `}`;
          if (itemIndex < value.length - 1) {
            mutation += ", ";
          }
        });
        mutation += `]`;
      } else {
        mutation += `${key}: ${JSON.stringify(value)}`;
      }

      // Add comma between fields
      if (index < array.length - 1) {
        mutation += ", ";
      }
    });

    // Close InputObject if it was opened
    if (InputObject) {
      mutation += `}`;
    }
    if (returnFields) {
      mutation += `) { id
     ${returnFields}
     } }`;
    } else {
      mutation += `) { id } }`;
    }
    // Assuming the response contains 'id'

    // Prepare the request payload
    const postData = { query: mutation };

    // Send the mutation request using axios
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export function generateQueryorder(queryName, inputFields) {
  let query = `query { ${queryName}`;

  if (inputFields && Object.keys(inputFields).length > 0) {
    query += `(`;
    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      query += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        query += ", ";
      }
    });
    query += `)`;
  }

  query += ` }`; // Properly close the query string

  return query;
}

// Fetch function to handle GraphQL query
export async function fetchDataEngineorder(queryName, inputFields, baseurl) {
  try {
    const query = generateQueryorder(queryName, inputFields);
    const data = await graphqlQuery(query, baseurl);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function generateAndExecuteMutationMarkPaid(
  inputObjectName,
  inputFields,
  InputObject,
  base_url
) {
  try {
    const headers = getAuthHeaders();
    let mutation = `mutation {`;

    // Start mutation with InputObject
    mutation += `${inputObjectName}(${InputObject}: {`;

    const inputEntries = Object.entries(inputFields);
    inputEntries.forEach(([key, value], index) => {
      mutation += `${key}: ${JSON.stringify(value)}`;
      if (index < inputEntries.length - 1) {
        mutation += ", ";
      }
    });

    // Close the mutation body
    mutation += `}) { orderId amountPaid phone createdBy customerBalance } }`;

    const postData = { query: mutation };
    const response = await axios.post(base_url, postData, { headers });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}
