import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { gql, useMutation } from "@apollo/client";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { generateAndExecuteMutation } from "../../../data/Axios/DynamicService";
import { companyManagementUrl } from "../../../config";

// GraphQL Mutation

// Validation schema using Yup
const validationSchema = yup.object().shape({
  initiatorName: yup.string().required("Initiator Name is required"),
  initiatorPassword: yup.string().required("Initiator Password is required"),
  consumerKey: yup.string().required("Consumer Key is required"),
  consumerSecret: yup.string().required("Consumer Secret is required"),
  lipaNaMpesaOnlinePassKey: yup
    .string()
    .required("Lipa Na Mpesa Online Pass Key is required"),
  shortCode: yup.string().required("Short Code is required"),
});

const MpesaCredentialsForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = await generateAndExecuteMutation(
        "insertMpesaCredentials",
        values,
        "credentials",
        companyManagementUrl.uri
      );
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Mpesa Credentials inserted successfully.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while inserting Mpesa credentials.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    initiatorName: "",
    initiatorPassword: "",
    consumerKey: "",
    consumerSecret: "",
    lipaNaMpesaOnlinePassKey: "",
    shortCode: "",
  };

  return (
    <Box m={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="initiatorName"
                  label="Initiator Name"
                  variant="outlined"
                  fullWidth
                  value={values.initiatorName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.initiatorName && Boolean(errors.initiatorName)}
                  helperText={touched.initiatorName && errors.initiatorName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="initiatorPassword"
                  label="Initiator Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  value={values.initiatorPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.initiatorPassword &&
                    Boolean(errors.initiatorPassword)
                  }
                  helperText={
                    touched.initiatorPassword && errors.initiatorPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
           
              <Grid item xs={6}>
                <TextField
                  name="consumerKey"
                  label="Consumer Key"
                  variant="outlined"
                  fullWidth
                  value={values.consumerKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.consumerKey && Boolean(errors.consumerKey)}
                  helperText={touched.consumerKey && errors.consumerKey}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="consumerSecret"
                  label="Consumer Secret"
                  variant="outlined"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  value={values.consumerSecret}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.consumerSecret && Boolean(errors.consumerSecret)
                  }
                  helperText={touched.consumerSecret && errors.consumerSecret}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="lipaNaMpesaOnlinePassKey"
                  label="Lipa Na Mpesa Online Pass Key"
                  variant="outlined"
                  fullWidth
                  value={values.lipaNaMpesaOnlinePassKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.lipaNaMpesaOnlinePassKey &&
                    Boolean(errors.lipaNaMpesaOnlinePassKey)
                  }
                  helperText={
                    touched.lipaNaMpesaOnlinePassKey &&
                    errors.lipaNaMpesaOnlinePassKey
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="shortCode"
                  label="Short Code"
                  variant="outlined"
                  fullWidth
                  value={values.shortCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.shortCode && Boolean(errors.shortCode)}
                  helperText={touched.shortCode && errors.shortCode}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MpesaCredentialsForm;
