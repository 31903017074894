import React from "react";
import { Box } from "@mui/material";
import CustomForm from "./forms";
import { MajorClassificationColumns } from "../../../data/DynamicTable/Collumns";
import { allReportsMajorClassifications } from "../../../data/Axios/queries";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import { reportsManagementUrl, setupManagementUrl } from "../../../config";

const base_url = reportsManagementUrl.uri;

const MajorClassification = () => {
  const actions = {
    entity:  localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "reportsMajorClassifications",
      parameters: "",
    },
    showStatus: true,
  };

  return (
    <Box m="10px">
      <DynamicTableForJson
        title="Reports"
        subtitle="Major Classifications"
        FormComponent={CustomForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default MajorClassification;
