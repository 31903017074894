import React from "react";
import { Box } from "@mui/material";
import BusinessTypesForm from "./forms";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import { setupManagementUrl } from "../../../config";

const base_url = setupManagementUrl.uri;

const BusinessType = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      ignoreFields: ["UpdateDate", "CreateDate"],
      objectName: "businessTypes",
      parameters: "",
    },
    isSubRight: true,
    showStatus:true,
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="BusinessTypes Management"
        subtitle="Manage BusinessTypes"
        FormComponent={BusinessTypesForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default BusinessType;
