import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";
import { UploadStatement } from "../../../../../data/DynamicTable/DynamicFunctions";
import Header from "../../../../../components/Header";
import { tokens } from "../../../../../theme";

const UploadStatementForm = (props) => {
  const initialValues = {};
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customerId = localStorage.getItem("CustomerId");
  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .required("Please select a file to upload!")
      .test("fileSize", "The file has exceeded the 50mb limit", (value) => {
        return value && value.size <= 52428800; // 1MB
      }),
    // .test("fileType", "Unsupported file format", value => {
    //   return value && ['image/jpeg', 'image/png', 'application/pdf', ].includes(value.type);
    // }),
    password: yup.string().required("Please enter statement password"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Ensure `customerId` is added to `values`
      const enhancedValues = { ...values, customerId: customerId };

      const response = await UploadStatement(enhancedValues);

      if (response === 200) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Statement uploaded successfully.`,
        });
      }

      setSubmitting(false);
      resetForm();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.error || "An unexpected error occurred.",
      });

      setSubmitting(false);
    }
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Remove document" : "Upload Statement"}
        subtitle={
          props.isEditing
            ? "Remove the document from the case"
            : "Add a statement to be crunched"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Button
                variant="contained"
                component="label"
                sx={{
                  gridColumn: "span 2",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  bgcolor: `${colors.grey[400]}`,
                }}
              >
                Select a statement to upload
                <input
                  id="file"
                  name="file"
                  type="file"
                  hidden
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                />
              </Button>
              {touched.file && errors.file ? (
                <Typography color="error">{errors.file}</Typography>
              ) : null}
              {values.file && (
                <Typography sx={{ margin: "1rem" }}>
                  {values.file.name}
                </Typography>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Statement Password *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={touched.module && !!errors.module}
                helperText={touched.module && errors.module}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UploadStatementForm;
