import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme, useMediaQuery, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import * as componentsMap from "../../scenes/Component/VericalTabComponents"
const DynamicTabs = ({ rightId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [subRightComponents, setSubRightComponents] = useState([]);
  const rights = JSON.parse(localStorage.getItem("rights"));
  useEffect(() => {
    if (rights) {
      try {
        const cleanedData = rights
          ? JSON.parse(rights.replace(/\\/g, ""))
          : null;

        const importedComponents = cleanedData
          .filter((child) =>
            child.children?.some(
              (subChild) => subChild.id === parseInt(rightId, 10)
            )
          )
          .flatMap(
            (child) =>
              child.children
                .find((subChild) => subChild.id === parseInt(rightId, 10))
                ?.subrights?.map((subRight) => {
                  // Normalize title by removing spaces and converting to lowercase
                  const normalizedTitle = subRight.title
                    .toLowerCase()
                    .replace(/\s/g, "");
                  // Find a component key that exactly matches the normalized title
                  const componentKey = Object.keys(componentsMap).find(
                    (key) => key.toLowerCase() === normalizedTitle
                  );
                  // const previousTitle = localStorage.getItem("EntityForSubright");
                  // if (previousTitle) {
                  //   localStorage.removeItem("EntityForSubright");
                  // }

                  // Set new subRight title in localStorage

                  const component = componentsMap[componentKey];
                  return {
                    label: subRight.title,

                    content: component ? React.createElement(component) : null,
                  };
                }) || []
          );
        setSubRightComponents(importedComponents);
        localStorage.setItem("EntityForSubright", importedComponents);
      } catch (error) {
        console.error("Failed to parse rights JSON:", error);
      }
    }
  }, [rights, rightId, componentsMap]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Get the label of the selected tab
    const selectedLabel = subRightComponents[newValue]?.label;

    // Save the label in localStorage
    if (selectedLabel) {
      localStorage.setItem("SelectedTabLabel", selectedLabel);
    }
  };

  const handleSelectChange = (event) => {
    handleChange(event, event.target.value);
  };

  const getTabStyle = (isSelected) => ({
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
    margin: 0.3,
    color: isSelected
      ? theme.palette.mode === "dark"
        ? colors.greenAccent[400]
        : colors.greenAccent[400]
      : "inherit",
    backgroundColor: isSelected
      ? theme.palette.mode === "light"
        ? colors.greenAccent[400]
        : colors.greenAccent[400]
      : "inherit",
    "&.Mui-selected": {
      color:
        theme.palette.mode === "dark"
          ? colors.primary[400]
          : colors.primary[900],
    },
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {/* {value === index && (
          <Box sx={{ padding: 3, backgroundColor: `${colors.primary[400]}` }}>
            {children}
          </Box>
        )} */}
        {value === index && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        bgcolor: `${colors.primary[400]}`,
        display: "grid",
        gridTemplateColumns:
          isSmallScreen || isMediumScreen ? "1fr" : "auto 1fr",
        height: "max-content",
        m: 2,
        p: 2,
        width: "100%",
      }}
    >
      {isSmallScreen || isMediumScreen ? (
        <Select
          value={value}
          onChange={handleSelectChange}
          fullWidth
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ backgroundColor: `${colors.primary[400]}`, width: "300px" }}
        >
          {subRightComponents.map((tab, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ backgroundColor: `${colors.primary[400]}` }}
            >
              {tab.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          //   aria-label={title || "Tabs"}
          sx={{
            borderRight: isSmallScreen || isMediumScreen ? 0 : 2,
            borderBottom: isSmallScreen || isMediumScreen ? 2 : 0,
            backgroundColor: `${colors.primary[400]}`,
            width: isSmallScreen || isMediumScreen ? "100%" : "auto",
          }}
        >
          {subRightComponents.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              className={
                value === index ? "custom-tab-active custom-tab" : "custom-tab"
              }
              sx={getTabStyle(value === index)}
            />
          ))}
        </Tabs>
      )}
      {subRightComponents.map((tab, index) => (
        <TabPanel key={index} value={value} index={index} className="w-100">
          {tab.content ? tab.content : null}
        </TabPanel>
      ))}
    </Box>
  );
};

DynamicTabs.propTypes = {
  rights: PropTypes.array.isRequired,
  rightId: PropTypes.string.isRequired,
  componentsMap: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DynamicTabs;
