import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { customerservice , setupManagementUrl, companyManagementUrl, userManagementUrl } from "../../../config";
import { groupFields } from "../../../data/DynamicForm/FormFields/GroupFormFields";
import DynamicForm from "../../../data/Axios/DynamicForm";
import { executeAnyMutation, fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";

const base_url = customerservice.uri;
const setup_url = setupManagementUrl.uri;
const comp_url = companyManagementUrl.uri;

const GroupForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [maritalStatusData, setMaritalStatustData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [yearsLivedAtResidenceData, setYearsLivedAtResidenceData] = useState([]);
  const [educationLevelData, setEducationLevelData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [FOData, setFOData] = useState([]);
  const [branchoptionsInitialvalue, setBranchOptionsInitialValue] = useState([]);
  const [FOoptionsInitialvalue, setFOOptionsInitialValue] = useState([]);

  const [branchIdData, setBranchIdData] = useState(null);
  const [FOIdData, setFOIdData] = useState(null);
  const [Mappedbranches, setMappedBranches] = useState([])
  const [MappedFOs, setMappedFOs] = useState([])

  useEffect(() => {
    async function fetchAllData() {
      try {
        const regionResponse = await fetchJsonDynamic(
          "region",
          null,
          null,
          comp_url
        );
        if (regionResponse?.results) {
          const result = JSON.parse(regionResponse?.results);
          setRegionData(result || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);
 
  const regionOptions = regionData.map((status) => ({
    value: status.Id,
    label: status.Name,
  }));
 
  
  const handleFieldChange = async (field, value ) => {    
    if (field.name === "regionId") {
      const branchOptions = await fetchBranches(value);
      setBranchOptionsInitialValue(branchOptions || []); // Ensure an array is set
      setBranchData(branchOptions); // Set the branch data for further rendering in form
      // setBranchIdData(null); // Reset the branchId data if region changes
      return branchOptions;
    }
    if (field.name === "branchId") {
      const FOOptions = await fetchFieldOfficers(value);
      setFOOptionsInitialValue(FOOptions || []); 
      setFOData(FOOptions); 
      setFOIdData(null); 
      return FOOptions;
    }
  };
  
  const fetchBranches = async (regionId) => {
    try {
      const params = { regionId: parseInt(regionId) };
  
      const businessDetails = await fetchJsonDynamic(
        "allBranchesByColumnValues",
        "branchModel",
        params,
        companyManagementUrl.uri,
      );
    
      const allBranchDetails = JSON.parse(businessDetails.results) || [];
  
      const branchOptions = allBranchDetails.map((role) => ({
        parent_key: role.Id,
        value: role.Id,
        label: role.Name,
      }));
  
      setMappedBranches(branchOptions)
      setBranchData(branchOptions)

  
      return branchOptions; // Return the mapped branch options
    } catch (error) {
      console.error("Error fetching branches:", error);
      return [];
    }
  };
  const fetchFieldOfficers = async (branchId) => {
    try {
      const params = { branchId: parseInt(branchId) };
  
      const FODetails = await fetchJsonDynamic(
        "allUsersByColumnValues",
        "usermodel",
        params,
        userManagementUrl.uri,
      );
  
  
      const allFODetails = JSON.parse(FODetails.results) || [];
  
      const FOOptions = allFODetails.map((officer) => ({
        parent_key: officer.Id,
        value: officer.Id,
        label: officer.FirstName,
      }));
  
      setMappedFOs(FOOptions)
      setFOData(FOOptions)

  
      return FOOptions; // Return the mapped FO options
    } catch (error) {
      console.error("Error fetching Field Officers:", error);
      return [];
    }
  };
  
  const fields = [
    ...groupFields, 
    {
      name: "regionId",
      label: "Region",
      type: "select",
      options: regionData
      ? regionData.map((region) => ({
          value: region.Id,
          label: region.Name,
        }))
      : [],
      gridColumn: "span 2",
      required: true,
      parent: "main",
    },
    {
      name: "branchId",
      label: "Branch",
      type: "select",
      options: Mappedbranches || "",
      gridColumn: "span 2",
      required: true,
      parent: "dependent",
      dependentOn: "regionId",
    },
    {
      name: "fieldOfficerId",
      label: "Field Officer",
      type: "select",
      options: MappedFOs || "",
      gridColumn: "span 2",
      required: true,
      parent: "subdependent", // Dependent on branchId
      dependentOn: "branchId",
      subparent: "branchId", // Indicates a subparent

    },
  ];



  const initialValues = {
    id: props.data ? props.data.Id : null,
    name: props.data ? props.data.Name : null,
    phoneNumber: props.data ? props.data.PhoneNumber : null,
    regionId: props.data ? props.data.RegionId : null,
    branchId: props.data ? props.data.BranchId : null,
    fieldOfficerId: props.data ? props.data.FieldOfficerId : null,
  };
  //prop

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { 
        name, 
        phoneNumber,
        regionId,
        branchId,
        fieldOfficerId, 
      } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.Id,
            name,
            phoneNumber,
            regionId,
            branchId,
            fieldOfficerId,
          }
        : {
            name,
            phoneNumber,
            regionId,
            branchId,
            fieldOfficerId,
          };

      const response = await executeAnyMutation(
        props.isEditing ? "updateGroup" : "createGroup", 
        mutationData,
        "group",
        false,
        base_url
      );

      if (response) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Group ${props.isEditing ? "updated" : "created"} successfully.`,
        }).then(() => {
          props.refetchData();
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <div>
      <DynamicForm
        initialValues={initialValues}
        fields={fields}
        onFieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        title={props.isEditing ? "Edit Group" : "Create Group"}
        subtitle={props.isEditing ? "Edit an Existing Group" : "Create a New Group"}
      />
    </div>
  );
};

export default GroupForm;
