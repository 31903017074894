import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Button,
  IconButton,
  Divider,
  List,
  Menu,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemButton,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  AttachMoney as AttachMoneyIcon,
  Assessment as AssessmentIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  People as PeopleIcon,
  MonetizationOn as MonetizationOnIcon,
  AccountBalance as AccountBalanceIcon,
} from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { fetchExpensesData } from "../../../src/data/expensesData";
import { companyManagementUrl, userManagementUrl } from "../../config";
import {
  CustomersCard,
  ExpensesCard,
  RevenueCard,
  RevenueTrend,
  SalesCard,
  SalesTrend,
  SoldProductsChart,
} from "../../data/Axios/queries";
import { graphqlQuery } from "../../data/Axios/DynamicService";
import { tokens } from "../../theme";
import { fetchJsonDynamic } from "../../data/DynamicServiceNew/jsondynamicservice";

const base_url = companyManagementUrl.uri;

const Dashboard = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(theme.palette.mode);
  const font = tokens(theme.typography);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const user = parseInt(decodedToken.RoleGroupId);
  const [branchData, setBranchData] = useState([]);
  const [fieldOfficerfData, setFieldOfficerData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [fieldOfficerId, setFieldOfficerId] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const circleSize = isSmallScreen ? 150 : 200;
  // Fetch data on component mount
  useEffect(() => {
    async function fetchAllData() {
      try {
        // Fetch regions data
        const regionDataResponse = await fetchJsonDynamic(
          "region",
          null,
          null,
          base_url
        );
        if (regionDataResponse?.results) {
          const results = JSON.parse(regionDataResponse.results);
          setRegionData(results || []);
        }

        // Fetch branches data
        const branchDataResponse = await fetchJsonDynamic(
          "branch",
          null,
          null,
          base_url
        );
        if (branchDataResponse?.results) {
          const results = JSON.parse(branchDataResponse.results);
          setBranchData(results || []);
        }

        // Fetch field officers data (users with Role Group 5)
        const fieldOfficerDataResponse = await fetchJsonDynamic(
          "allUsersView",
          null,
          null,
          userManagementUrl.uri
        );
        if (fieldOfficerDataResponse?.results) {
          const results = JSON.parse(fieldOfficerDataResponse.results);

          // Filter users with RoleGroupId 5
          const fieldOfficersWithRole5 = results.filter(
            (user) => user.RoleGroupId === 5
          );
          setFieldOfficerData(fieldOfficersWithRole5 || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const fetchDashboardData = async () => {
    try {
      const dashboardDataResponse = await fetchJsonDynamic(
        "dashboardMetricsById",
        "dashboardMetrics",
        {
          companyid: null,
          regionid: regionId,
          branchid: branchId,
          fieldofficerid: fieldOfficerId,
        },
        base_url
      );
      if (dashboardDataResponse?.results) {
        const results = JSON.parse(dashboardDataResponse.results);
        setDashboardData(results || []);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [regionId, branchId, fieldOfficerId]);

  const paperStyle = {
    p: 1,
    display: "flex",
    alignItems: "center",
    height: 50, // Consistent height
    backgroundColor: `${colors.primary[400]} !important`,
  };

  const selectStyle = {
    height: "100%", // Matches Paper height
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      padding: "8px", // Controls internal Select padding
    },
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: `${colors.primary[500]} !important`,
    color: "white",
    padding: "0 10px",
    borderRadius: "4px",
    height: "100%", // Matches the Paper height
    marginRight: "10px",
  };
  // Mapping data for dropdown options
  const regionOptions = regionData.map((region) => ({
    value: region.Id,
    label: region.Name,
  }));

  const branchOptions = branchData.map((branch) => ({
    value: branch.Id,
    label: branch.Name,
  }));

  const fieldOfficerOptions = fieldOfficerfData.map((officer) => ({
    value: officer.Id,
    label: `${officer.FirstName} ${officer.LastName}`,
  }));

  const handleRegionChange = (event) => {
    const selectedRegionId = event.target.value;
    setRegionId(selectedRegionId);
    setBranchId(null); // Reset branch and field officer on region change
    setFieldOfficerId(null);
  };

  const handleBranchChange = (event) => {
    const selectedBranchId = event.target.value;
    setBranchId(selectedBranchId);
    setFieldOfficerId(null); // Reset field officer on branch change
  };

  const handleFieldOfficerChange = (event) => {
    const selectedFieldOfficerId = event.target.value;
    setFieldOfficerId(selectedFieldOfficerId);
  };
  // Function to determine progress circle color based on percentage
  const getProgressColor = (value) => {
    if (value <= 40) return "green";
    if (value <= 60) return "#d4a106";
    return "red";
  };
  const getPositiveProgressColor = (value) => {
    if (value <= 40) return "red";
    if (value <= 60) return "#d4a106";
    return "green";
  };
  const handleClearAll = () => {
    // Set each filter to its default value
    setRegionId(null); // or a default value like "default_region"
    setBranchId(null); // or a default value
    setFieldOfficerId(null);
    // Trigger data fetching functions after resetting
    fetchDashboardData();
  };
  return (
    <Box
      sx={{
        p: 3,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "16px",
      }}
    >
      <Grid container spacing={3}>
        {/* Quick Stats */}
        <Grid container spacing={4}>
          {/* Region Dropdown - Display for Role Groups 1, 2, and 3 */}
          {(user === 1 || user === 2 || user === 3) &&
            regionData.length > 0 && (
              <Grid item xs={12} md={3}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    height: 50, // Consistent height
                    backgroundColor: `${colors.primary[400]} !important`,
                  }}
                >
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Region
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Region...</InputLabel>
                    <Select
                      label="Select Region..."
                      value={regionId}
                      onChange={handleRegionChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {regionOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}

          {/* Branch Dropdown - Display for Role Groups 1, 2, and 3 */}
          {(user === 1 || user === 2 || user === 3) &&
            branchData.length > 0 && (
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={paperStyle}>
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Branches
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Branch...</InputLabel>
                    <Select
                      label="Select Branch..."
                      value={branchId}
                      onChange={handleBranchChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {branchOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}

          {/* Sales Rep (Field Officer) Dropdown - Display for Role Groups 1, 2, 3, and 4 */}
          {(user === 1 || user === 2 || user === 3 || user === 4) &&
            fieldOfficerfData.length > 0 && (
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    height: 50, // Ensures consistent height
                    backgroundColor: `${colors.primary[400]} !important`,
                  }}
                >
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Sales Reps
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Sales Rep...</InputLabel>
                    <Select
                      label="Select Sales Rep..."
                      value={regionId}
                      onChange={handleFieldOfficerChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {fieldOfficerOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}
          {/* Clear All Button */}
          {user !== 5 && (
            <Grid item xs={12} md={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </Grid>
          )}
        </Grid>
        {/* Portfolio Overview Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 400,
              background: `${colors.primary[400]} !important`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: "0px 10px 10px 0px",
                  flexShrink: 0,
                }}
              >
                <Typography variant="h6">Portfolio</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  ml: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row", // Stack cards on small screens
                gap: isSmallScreen ? 1 : 2,
                mb: 2,
              }}
            >
              {/* Active Loans Card */}
              <Paper
                sx={{
                  p: isSmallScreen ? 1 : 2,
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  background: `${colors.primary[200]} !important`,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: `1px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        mr: 2,
                        p: isSmallScreen ? 0.5 : 1, // Adjust padding for icon button
                        borderRadius: 0,
                      }}
                    >
                      <AccountBalanceIcon />
                    </IconButton>
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>
                      Active Loans
                    </Typography>
                  </Box>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h2"}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    {dashboardData[0]?.Active_Loans || 0}
                  </Typography>
                </Box>
              </Paper>

              {/* Total Customers Card */}
              <Paper
                sx={{
                  p: isSmallScreen ? 1 : 2,
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  background: `${colors.primary[200]} !important`,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: `1px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        mr: 2,
                        p: isSmallScreen ? 0.5 : 1, // Adjust padding for icon button
                        borderRadius: 0,
                      }}
                    >
                      <PeopleIcon />
                    </IconButton>
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>
                      Total Customers
                    </Typography>
                  </Box>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h2"}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    {dashboardData[0]?.Total_Customers || 0}
                  </Typography>
                </Box>
              </Paper>
            </Box>
            {/* OLB(KSH) Card */}
            <Paper
              sx={{
                p: isSmallScreen ? 1 : 2,
                display: "flex",
                alignItems: "center",
                background: `${colors.primary[200]} !important`,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: "100%",
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      mr: 2,
                      p: isSmallScreen ? 0.5 : 1, // Adjust padding for icon button
                      borderRadius: 0,
                    }}
                  >
                    <MonetizationOnIcon />
                  </IconButton>
                  <Typography variant={isSmallScreen ? "h6" : "h5"}>
                    OLB(KSH)
                  </Typography>
                </Box>
                <Typography
                  variant={isSmallScreen ? "h4" : "h2"}
                  sx={{ alignSelf: "flex-end" }}
                >
                  KSH {dashboardData[0]?.Outstanding_Loan_Balance || 0}
                </Typography>
              </Box>
            </Paper>
          </Paper>
        </Grid>
        {/* Products Sold Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 400,
              background: `${colors.primary[400]} !important`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: "0px 10px 10px 0px",
                  flexShrink: 0,
                }}
              >
                <Typography variant="h6">Performance</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  ml: 2,
                }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: 2 }}>
              {/* Left Side - Circular Progress */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  {/* Background Circle for Remaining Path */}
                  <CircularProgress
                    variant="determinate"
                    value={100} // Background circle covers the full circumference
                    size={circleSize} // Increase size
                    thickness={7} // Increase thickness
                    sx={{
                      color: "rgba(255, 255, 255, 0.4)", // Pale white for the background path
                      position: "absolute",
                    }}
                  />
                  {/* Progress Circle */}
                  <CircularProgress
                    variant="determinate"
                    value={
                      parseFloat(
                        dashboardData[0]?.Funded_Percentage?.toFixed(2)
                      ) || 0
                    } // Set your percentage here
                    size={circleSize} // Match size with background circle
                    thickness={7} // Match thickness with background circle
                    sx={{
                      color: getPositiveProgressColor(
                        parseFloat(
                          dashboardData[0]?.Funded_Percentage?.toFixed(2)
                        ) || 0
                      ),
                    }}
                  />
                  {/* Text inside the circle */}
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5" // Smaller font for "Funded" text
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.Funded_Percentage?.toFixed(2)
                          ) || 0
                        ),
                      }}
                    >
                      Funded
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.Funded_Percentage?.toFixed(2)
                          ) || 0
                        ),
                      }}
                    >
                      {parseFloat(
                        dashboardData[0]?.Funded_Percentage?.toFixed(2)
                      ) || 0}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Right Side - Metrics List */}
              <Box sx={{ width: "50%" }}>
                <List sx={{ p: 0 }}>
                  <ListItemButton divider>
                    <ListItemText primary="New Customers" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.New_Customers || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Disbursed Loans" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.Disbursed_Loans || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Disbursed Amount" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.Disbursed_Amount || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="CR MTD" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {parseFloat(
                        dashboardData[0]?.Collection_Rate_Mtd_Percentage?.toFixed(
                          2
                        )
                      ) || 0}
                      %
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Percentage Mapped" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {parseFloat(
                        dashboardData[0]?.Percentage_Mapped?.toFixed(2)
                      ) || 0}
                      %
                    </Typography>
                  </ListItemButton>
                </List>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Collection Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 580,
              background: `${colors.primary[400]} !important`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Title Inside Paper */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: "0px 10px 10px 0px",
                  flexShrink: 0,
                }}
              >
                <Typography variant="h6">Portfolio</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  ml: 2,
                }}
              />
            </Box>

            {/* Content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              {/* Left Side - Circular Progress for Collection Rate */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  {/* Background Circle for Remaining Path */}
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={circleSize}
                    thickness={7}
                    sx={{
                      color: "rgba(255, 255, 255, 0.4)",
                      position: "absolute",
                    }}
                  />
                  {/* Progress Circle */}
                  <CircularProgress
                    variant="determinate"
                    value={
                      parseFloat(
                        dashboardData[0]?.Collection_Rate_Today_Percentage?.toFixed(
                          2
                        )
                      ) || 0
                    }
                    size={circleSize}
                    thickness={7}
                    sx={{
                      color: getPositiveProgressColor(
                        parseFloat(
                          dashboardData[0]?.Collection_Rate_Today_Percentage?.toFixed(
                            2
                          )
                        ) || 0
                      ),
                    }}
                  />
                  {/* Text inside the circle */}
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.Collection_Rate_Today_Percentage?.toFixed(
                              2
                            )
                          ) || 0
                        ),
                        fontSize: isSmallScreen ? "12px" : "16px", // Adjust font size
                      }}
                    >
                      Collection Rate
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.Collection_Rate_Today_Percentage?.toFixed(
                              2
                            )
                          ) || 0
                        ),
                        fontSize: isSmallScreen ? "16px" : "24px", // Adjust font size
                      }}
                    >
                      {parseFloat(
                        dashboardData[0]?.Collection_Rate_Today_Percentage?.toFixed(
                          2
                        )
                      ) || 0}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Right Side - Metrics List */}
              <Box
                sx={{
                  width: isSmallScreen ? "100%" : "50%", // Adjust width for smaller screens
                  display: "flex",
                  flexDirection: "column",
                  gap: isSmallScreen ? 1 : 2, // Adjust gap
                }}
              >
                {/* Metric 1 */}
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 1,
                    p: isSmallScreen ? 0.5 : 1, // Adjust padding
                  }}
                >
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      p: isSmallScreen ? 0.3 : 0.5, // Adjust inner box padding
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>
                      Loans Due Today
                    </Typography>
                  </Box>
                  <Typography
                    variant={isSmallScreen ? "body1" : "h6"}
                    sx={{ mt: 0.5 }}
                  >
                    {dashboardData[0]?.Count_Loans_Due_Today || 0} Loan
                  </Typography>
                  <Typography variant={isSmallScreen ? "h5" : "h4"}>
                    KSH {dashboardData[0]?.Loans_Due_Today || 0}
                  </Typography>
                </Box>

                {/* Metric 2 */}
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 1,
                    p: isSmallScreen ? 0.5 : 1, // Adjust padding
                  }}
                >
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      p: isSmallScreen ? 0.3 : 0.5, // Adjust inner box padding
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>
                      Collected
                    </Typography>
                  </Box>
                  <Typography
                    variant={isSmallScreen ? "body1" : "h6"}
                    sx={{ mt: 0.5 }}
                  >
                    {dashboardData[0]?.Count_Collected_Today || 0} Loans
                  </Typography>
                  <Typography variant={isSmallScreen ? "h5" : "h4"}>
                    KSH {dashboardData[0]?.Collected_Today || 0}
                  </Typography>
                </Box>

                {/* Metric 3 */}
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 1,
                    p: isSmallScreen ? 0.5 : 1, // Adjust padding
                  }}
                >
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      p: isSmallScreen ? 0.3 : 0.5, // Adjust inner box padding
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>
                      Prepaid Loans
                    </Typography>
                  </Box>
                  <Typography
                    variant={isSmallScreen ? "body1" : "h6"}
                    sx={{ mt: 0.5 }}
                  >
                    {dashboardData[0]?.Count_Prepaid_Loans_Today || 0} Loans
                  </Typography>
                  <Typography variant={isSmallScreen ? "h5" : "h4"}>
                    KSH {dashboardData[0]?.Prepaid_Loans_Today || 0}
                  </Typography>
                </Box>
              </Box>
              ;
            </Box>
          </Paper>
        </Grid>
        {/* risk card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              background: `${colors.primary[400]} !important`,
            }}
          >
            {/* Title Inside Paper */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: "0px 10px 10px 0px",
                  flexShrink: 0,
                }}
              >
                <Typography variant="h6">Risk</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  ml: 2,
                }}
              />
            </Box>
            {/* Top Section with Two Risk Cards */}
            <Grid container spacing={2}>
              {/* First Card - Progress Circle */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: `${theme.palette.background.default}`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: 200,
                  }}
                >
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    {/* Background Circle for Remaining Path */}
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      size={isSmallScreen ? 150 : 150}
                      thickness={6}
                      sx={{
                        color: "rgba(255, 255, 255, 0.4)",
                        position: "absolute",
                      }}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={
                        parseFloat(
                          dashboardData[0]?.Portfolio_At_Risk?.toFixed(2)
                        ) || 0
                      } // Replace 75 with actual dynamic value
                      size={isSmallScreen ? 150 : 150}
                      thickness={6}
                      sx={{
                        color: getProgressColor(
                          parseFloat(
                            dashboardData[0]?.Portfolio_At_Risk?.toFixed(2)
                          ) || 0
                        ),
                      }}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: getProgressColor(
                            parseFloat(
                              dashboardData[0]?.Portfolio_At_Risk?.toFixed(2)
                            ) || 0
                          ),
                          fontSize: isSmallScreen ? "10px" : "12px",
                        }}
                      >
                        % Portfolio At Risk
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color: getProgressColor(
                            parseFloat(
                              dashboardData[0]?.Portfolio_At_Risk?.toFixed(2)
                            ) || 0
                          ),
                        }}
                      >
                        {parseFloat(
                          dashboardData[0]?.Portfolio_At_Risk?.toFixed(2)
                        ) || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              {/* Second Card - List of Metrics */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={2}
                  sx={{
                    backgroundColor: `${theme.palette.background.default}`,
                    display: "flex",
                    flexDirection: "column",
                    height: 200,
                  }}
                >
                  <List sx={{ p: 0 }}>
                    {[
                      {
                        label: "Total Arrears",
                        value: dashboardData[0]?.Total_Arrears || 0,
                      },
                      {
                        label: "Arrears Collected Today",
                        value: dashboardData[0]?.Arrears_Collected_Today || 0,
                      },
                      {
                        label: "NPL Amount Collected",
                        value: dashboardData[0]?.Npl_Amount_Collected || 0,
                      },
                      {
                        label: "RollOver Loans",
                        value: dashboardData[0]?.Roll_Over_Loans || 0,
                      },
                    ].map((item, idx) => (
                      <ListItemButton
                        divider
                        key={idx}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          {/* Label on top left */}
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            //sx={{ mb: 0.5 }}
                          >
                            {item.label}
                          </Typography>
                          {/* Value on bottom right */}
                          <Typography
                            variant="body2"
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                              px: 1,
                              borderRadius: "5px",
                              alignSelf: "flex-end",
                            }}
                          >
                            KSH {item.value}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>

            {/* Bottom Section - Non-Performing Loan Breakdown */}
            <Box sx={{ mt: 3 }}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Non-Performing Loans Breakdown
                </Typography>
                <Grid container spacing={2}>
                  {[
                    {
                      title: "Principal Amount in NPL",
                      value: `KSH ${
                        dashboardData[0]?.Npl_Principal_Amount || 0
                      }`,
                    },
                    {
                      title: "NPL Amount",
                      value: `KSH ${dashboardData[0]?.Npl_Loan_Balance || 0}`,
                    },
                    {
                      title: "Number of NPLs",
                      value: dashboardData[0]?.Count_Npl_Loan_Balance || 0,
                    },
                    {
                      title: "Percentage Turnover",
                      value: `${
                        parseFloat(
                          dashboardData[0]?.Turnover_Percentage?.toFixed(2)
                        ) || 0
                      }%`,
                    },
                  ].map((item, idx) => (
                    <Grid item xs={12} sm={6} md={6} key={idx}>
                      <Box
                        sx={{
                          p: 2,
                          border: `1px solid ${theme.palette.primary.main}`,
                          borderRadius: 1,
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" color="textSecondary">
                          {item.title}
                        </Typography>
                        <Typography variant="h4">{item.value}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
