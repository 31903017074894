import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../../data/DynamicTable/dynamicTableForJson";
import { customerservice } from "../../../../../config";
import GuarantorForm from "./form";
import { useNavigate } from "react-router-dom";
import UploadStatementForm from "./form";

const base_url = customerservice.uri;

const MpesaStatemets = () => {
  const customerId = localStorage.getItem("CustomerId");
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const navigate = useNavigate();

  const actions = {
    entity: "Rights",
    isSubright: true,
    add: {
      key: "ADD",
      button_name: "Upload Statement",
      Show_Button: true,
      permission: "CreatePermission",
    },
    dataObject: {
      objectName: "customerStatementsById",
      parameters: { customerId: parseInt(customerId) },
      inputObjectName: "statements",
    },
    ViewStatementReport: {
      button_name: "View Report",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
    },
  };

  return (
    <Box m="20px">
      <Box>
        <Button onClick={() => navigate(-1)} variant="contained">
          Back to Customers
        </Button>
      </Box>
      <></>
      <DynamicTableForJson
        title="Statements"
        subtitle="Manage Statements"
        FormComponent={UploadStatementForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default MpesaStatemets;
