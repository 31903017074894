import React from "react";
import { Box } from "@mui/material";

import { allRoleGroups } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { roleManagemenUrl } from "../../../config";
import RoleGroupForm from "./Forms";
import { RoleGroupColumns } from "../../../data/Columns/Columns";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = roleManagemenUrl.uri;

const RoleGroups = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const actions = {
    entity: "Role Group",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      modelName: "roleGroupIds",
      inputObjectName: "bulkActivateDeactivateRoleGroups",
      inputSubObjectName: null,
      Show_Button: true,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "allRoleGroups",
      parameters: "",
    },
    UserId: userId,
    required: "roleGroupIds",
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Role Group Management"
        subtitle="Manage Role Group "
        //columns={RoleGroupColumns}
        FormComponent={RoleGroupForm}
        //query={allRoleGroups}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default RoleGroups;
