import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { fetchJsonDynamic } from "../../data/DynamicServiceNew/jsondynamicservice";
import { companyManagementUrl } from "../../config";
import { MCBSwitchCallApi } from "../../data/userData";
import { jwtDecode } from "jwt-decode";
import Laptop2 from "../../Assets/Images/laptop2.jpg";

const defaultTheme = createTheme();

function saveTokenToStorage(decodedToken) {
  localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
}

export default function McbSwitch({ onLogin }) {
  const [companyData, setCompanyData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await fetchJsonDynamic(
        "companies",
        null,
        null,
        companyManagementUrl.uri
      );
      setCompanyData(JSON.parse(response.results) || []);
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (!selectedCompany) return;
    const fetchRegions = async () => {
      const companyId = parseInt(selectedCompany);
      const response = await fetchJsonDynamic(
        "region",
        null,
        null,
        companyManagementUrl.uri
      );
      const allRegions = JSON.parse(response.results) || [];
      const filteredRegions = allRegions.filter(
        (region) => region.CompanyId === companyId
      );
      setRegionData(filteredRegions);
      setSelectedRegion("");
      setBranchData([]);
      setRoleData([]);
    };
    fetchRegions();
  }, [selectedCompany]);

  async function saveDataToLocalStorage(response) {
    const token = response.data.token;
    localStorage.setItem("token", JSON.stringify(token));
    const expirationTime = new Date().getTime() + 5 * 60 * 60 * 1000;

    const filePath = response.data.companyLogoPath
      ? `${response.data.companyLogoPath}`
      : null;
    localStorage.setItem("filePath", filePath);

    localStorage.setItem("tokenExpiration", expirationTime);
    localStorage.setItem("rights", JSON.stringify(response.data.rights));
    const decodedToken = jwtDecode(token);
    console.log("Token", decodedToken);
    saveTokenToStorage(decodedToken);
  }

  useEffect(() => {
    if (!selectedRegion) return;
    const fetchBranches = async () => {
      const response = await fetchJsonDynamic(
        "branch",
        null,
        null,
        companyManagementUrl.uri
      );
      const allBranchData = JSON.parse(response.results) || [];
      const filteredBranches = allBranchData.filter(
        (branch) => branch.RegionId === selectedRegion
      );
      setBranchData(filteredBranches);
      setSelectedBranch("");
      setRoleData([]);
    };
    fetchBranches();
  }, [selectedRegion]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await MCBSwitchCallApi(
        selectedCompany,
        selectedRegion,
        selectedBranch
      );
      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.clear();
        handlePostLogin(response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePostLogin = (response) => {
    saveDataToLocalStorage(response);
    const rights = response.data.rights;
    const cleanedData = JSON.parse(rights.replace(/\\/g, ""));
    console.log(cleanedData, "cleanedData");
    localStorage.setItem("rightsforrbac", cleanedData);
    navigate("/super-admin-dashboard");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      )}
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${Laptop2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white
            borderRadius: "16px", // Rounded corners
            padding: 2,
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Company
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              {/* Company Dropdown */}
              <Select
                fullWidth
                variant="outlined"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Company
                </MenuItem>
                {companyData.map((company) => (
                  <MenuItem key={company.Id} value={company.Id}>
                    {company.CompanyName}
                  </MenuItem>
                ))}
              </Select>

              {/* Region Dropdown */}
              {selectedCompany && (
                <Select
                  fullWidth
                  variant="outlined"
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  displayEmpty
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Select Region
                  </MenuItem>
                  {regionData.map((region) => (
                    <MenuItem key={region.Id} value={region.Id}>
                      {region.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}

              {/* Branch Dropdown */}
              {selectedRegion && (
                <Select
                  fullWidth
                  variant="outlined"
                  value={selectedBranch}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  displayEmpty
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    Select Branch
                  </MenuItem>
                  {branchData.map((branch) => (
                    <MenuItem key={branch.Id} value={branch.Id}>
                      {branch.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
