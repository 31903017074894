import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AnchorTemporaryDrawer from "../../components/Drawer";
import {
  fetchDataEngine,
  fetchDataEngineCustomized,
  generateAndExecuteMutation,
  graphqlQuery,
} from "../Axios/DynamicService";
import { useNavigate } from "react-router-dom";
import usePermissions from "../RBAC/PermissionsHook";
import useSubRightPermissionsHook from "../RBAC/SubRightPermissionHook";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
// import { generateColumnsFromQuery } from "../Axios/DynamicService";
// import ButcheryPOS from "../../scenes/POS/butcheryPOS";
// import Payment from "../../scenes/POS/components/payment";
import {
  executeAnyMutation,
  fetchJsonDynamic,
  generateColumnsFromResults,
} from "../DynamicServiceNew/jsondynamicservice";
import { Circles } from "react-loader-spinner";
import { LoanApprovalDialog } from "../../scenes/LoanManagement/LoanApprovalComponent";
import { GroupLoanApprovalDialog } from "../../scenes/GroupLoanManagement/LoanApprovalComponent";
import { customerservice } from "../../config";
import { ViewStatatementDetails } from "./DynamicFunctions";
import { LoanLimitDialog } from "../../scenes/Customers/customers/SetLoanLimitModel";
import { generateColumnsFromQuery } from "../Axios/DynamicService";


const DynamicTableForJson = ({
  title,
  subtitle,

  FormComponent,
  columns,
  query,
  

  base_url,
  actions,
  ignoreFields,
  setSelectedProductId,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState(null);
  const { hasPermission } = usePermissions();
  const { hasSubRightPermission } = useSubRightPermissionsHook();
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tableRowData, setRowData] = useState("");
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const [openLoanModal, setOpenLoanModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [generatedColumn, setGeneratedColumn] = useState([]);
  const [openGroupLoanModal, setOpenGroupLoanModal] = useState(false);
  const [openLoanLimitModel, setOpenLoanLimitModel] = useState(false);
  const handleActivateDeactivate = async (isActive, id) => {
    try {
      const actionType =
        isActive === true || isActive === 1 ? "deactivate" : "activate";

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${actionType} ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionType} it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        let mutationData = null;

        // Prepare the mutation data based on the model name
        if (actions.modelName) {
          mutationData = {
            modelName: actions.activateDeactivate.modelName,
            isActive: !isActive, // Toggle the current state
            moduleIds: [id], // Wrap the ID in an array as expected by the mutation
          };
        } else {
          mutationData = {
            [actions.activateDeactivate.modelName]: [id], // Wrap the ID in an array
            isActive: !isActive, // Toggle the current state
          };
        }

        // Execute the mutation
        const response = await generateAndExecuteMutation(
          actions.activateDeactivate.inputObjectName,
          mutationData,
          actions.activateDeactivate.inputSubObjectName,
          base_url
        );

        // Check the response and show success
        if (response && Object.keys(response)[0]) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${actionType}d successfully.`,
          });

          // Refresh the table after the operation
          setRefreshTable(true);
        } 
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleViewStatement = async (row) => {
    try {
      const statementId = row.StatementId;
      const CustomerId = localStorage.getItem("CustomerId");
      const customerId = parseInt(CustomerId);

      // Open a new tab immediately, which will not be blocked
      const newTab = window.open("", "_blank");

      // Write a simple loading message or animation to the newly opened tab
      newTab.document.write(`
         <html>
           <head>
             <title>Loading...</title>
             <style>
               body {
                 font-family: Arial, sans-serif;
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 height: 100vh;
                 margin: 0;
                 background-color: #f0f0f0;
               }
               .loading {
                 font-size: 24px;
                 color: #333;
               }
             </style>
           </head>
           <body>
             <div class="loading">Loading, please wait...</div>
           </body>
         </html>
       `);
      newTab.document.close(); // Necessary to complete the document creation

      const response = await ViewStatatementDetails(
        { statementId, customerId },
        customerservice.generate_report_uri,
        () => {}
      );

      if (response.ok) {
        const htmlContent = await response.text();
        newTab.document.open();
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        alert("Failed to load the report. Please try again.");
        newTab.close();
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };
  const handleSababisha = async (loanId, entityId, flag) => {
    try {
      const params = { p_entityid: entityId, p_flag: flag, p_loanid: loanId };
      const action =
        flag === 0 ? actions.initiateSababisha : actions.approveSababisha;
      const actionType = action.button_name;

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `${actionType} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionType}!`,
        cancelButtonText: "Cancel",
      });

      if (confirmation.isConfirmed) {
        // Execute the mutation
        const response = await executeAnyMutation(
          action.inputObjectName,
          params,
          action.modelName,
          "false",
          base_url
        );

        // Check the response and show success
        if (response && Object.keys(response)[0]) {
          await Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${flag === 0 ? "initiated" : "approved"}.`,
          });

          // Refresh the table after the operation
          setRefreshTable(true);
        } else {
          throw new Error("Unexpected response structure.");
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  const handleUpdateLoanLimit = async (
    customerId,
    companyId,
    initiatorId,
    newLoanLimit,
    action
  ) => {
    try {
      const params = {
        customerId: customerId,
        companyId: companyId,
        initiatorId: initiatorId,
        newLoanLimit: newLoanLimit,
        action: action,
      };

      const flag =
        action === 1
          ? actions.approveLoanLimit
          : action === 2
          ? actions.rejectLoanLimitUpdate
          : actions.SetLoanLimit;

      const actionType = flag.button_name;

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `${actionType} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionType}!`,
        cancelButtonText: "Cancel",
      });

      if (confirmation.isConfirmed) {
        // Execute the mutation
        const response = await executeAnyMutation(
          flag.inputObjectName,
          params,
          flag.modelName,
          "false",
          base_url
        );

        // Check the response and show success
        if (response && Object.keys(response)[0]) {
          await Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${
              action === 0
                ? "initiated"
                : action === 1
                ? "approved"
                : "rejected"
            }.`,
          });

          // Refresh the table after the operation
          setRefreshTable(true);
        } else {
          throw new Error("Unexpected response structure.");
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  const getData = useCallback(async () => {
    const response = await fetchJsonDynamic(
      actions.dataObject.objectName,
      actions.dataObject.inputObjectName,
      actions.dataObject.parameters,
      base_url
    );
  
    if (response?.error) {
      setError("Server error, try again later")    } else {
      const parsedResults = JSON.parse(response?.results);
      setData(parsedResults);
      console.log("parsedResults", parsedResults);
      fetchColumns(parsedResults); // Pass parsedResults to the function
    }
  }, [actions.dataObject.objectName, base_url]);
  
  function fetchColumns(keys = null) {
    if (columns === null || columns === undefined) {
      // Use query and ignoreFields to generate columns if columns are not provided
      const columnsData = keys
        ? generateColumnsFromResults(keys, actions.dataObject.ignoreFields)
        : generateColumnsFromQuery(query, ignoreFields);
      setGeneratedColumn(columnsData);
    } else {
      // Use predefined columns if available
      setGeneratedColumn(columns);
    }
  }
  
  // Assuming columns is part of the dependency array
  useEffect(() => {
    fetchColumns();
  }, [columns]);
  
  
  // Assuming columns is part of the dependency array
  useEffect(() => {
    fetchColumns();
  }, [columns]);

  useEffect(() => {
    let isMounted = true;

    getData();

    if (refreshTable && isMounted) {
      getData();
      setRefreshTable(false);
    }

    return () => {
      isMounted = false;
    };
  }, [refreshTable]);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500">Error: {error}</p>
        {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={getData}>Retry</button> */}
      </div>
    );
  }

  if (!data) {
    return (
      <div>
        <Circles height="80" width="80" color="#4fa94d" ariaLabel="loading" />
      </div>
    );
  }

  const filterActionsByRole = (action) => {
    if (actions[action].permission === "hasNoPermission") {
      return actions[action].roleGroupId.includes(RoleGroupId);
    } else if (actions.isSubRight) {
      return hasSubRightPermission(actions.entity, actions[action].permission);
    } else {
      return hasPermission(actions.entity, actions[action].permission);
    }
  };

  const columnsWithActions = [
    ...generatedColumn.map((column) => {
      if (column.field === "id") {
        return {
          ...column,
          renderCell: (params) => params.api.getRowIndex(params.row.Id) + 1,
        };
      }
      return column;
    }),
    ...(actions.showStatus !== true
      ? [
          {
            field: "IsActive",
            headerName: "Status",
            flex: 0.5,
            renderCell: ({ row: { IsActive } }) => {
              const statusText =
                IsActive === true || IsActive === 1 ? "Active" : "Inactive";
              const statusColor =
                IsActive === true || IsActive === 1 ? "green" : "red";
              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Select
              value=""
              displayEmpty
              onChange={(e) => handleAction(e.target.value, row.Id, row)}
            >
              <MenuItem value="" disabled>
                Actions
              </MenuItem>

              {Object.keys(actions)
                .filter(
                  (action) =>
                    action !== "add" &&
                    actions[action].Show_Button &&
                    filterActionsByRole(action)
                )
                .map((action) => {
                  if (action === "activateDeactivate") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleActivateDeactivate(row.isActive, row.Id)
                        }
                      >
                        {row.IsActive === true || row.IsActive === 1
                          ? "Deactivate"
                          : "Activate"}
                      </MenuItem>
                    );
                  }
                  if (action === "initiateSababisha") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleSababisha(row.LoanId, row.CompanyId, 0)
                        }
                      >
                        {actions[action].button_name}
                      </MenuItem>
                    );
                  }
                  if (action === "approveSababisha") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleSababisha(row.LoanId, row.CompanyId, 1)
                        }
                      >
                        {actions[action].button_name}
                      </MenuItem>
                    );
                  }
                  // Check for the "Product Charge" action
                  if (action === "ProductChargesTable") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleAction("ProductChargeAccount", row.Id, row)
                        }
                      >
                        {actions[action].button_name}
                      </MenuItem>
                    );
                  }

                  // Check for the "Product Charges Account" action
                  if (action === "ProductChargesAccountsTable") {
                    return (
                      <MenuItem key={action} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    );
                  }
                  if (action === "ViewStatementReport") {
                    return (
                      <MenuItem key={action} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    );
                  }

                  return (
                    actions[action] && (
                      <MenuItem key={actions[action].key} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </Box>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsEditing(false);
    setEditData(null);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleEdit = (Id) => {
    const itemToEdit = data.find((item) => item.Id === Id);
    setEditData(itemToEdit);
    setIsEditing(true);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleOpenModal = (row) => {
    setRowData(row);
    setOpenLoanModal(true);
  };
  const handleOpenGroupModal = (row) => {
    setRowData(row);
    setOpenGroupLoanModal(true);
  };
 const handleOpenSetLoanLimitModal = (row) => {
   setRowData(row);
   setOpenLoanLimitModel(true);
 };

 const handleCloseLoanLimitModal = (reopen = false) => {
   setOpenLoanLimitModel(false);
   if (reopen) {
     setTimeout(() => setOpenLoanLimitModel(true), 300); // Slight delay to avoid animation conflict
   }
 };
  const handleCloseModal = () => setOpenLoanModal(false);

  const handleAction = async (action, Id, row) => {
    switch (action) {
      case "ViewStatementReport":
        handleViewStatement(row);
        break;
      case "edit":
        handleEdit(Id);
        break;
      case "add":
        handleAdd();
        break;
      case "ManageGroup":
        localStorage.setItem("GroupId", Id);
        navigate("/managegroup");
        break;
      case "ViewCustomerDetails":
        localStorage.setItem("CustomerId", Id);
        navigate("/customer-details");
        break;
      case "ViewCustomer360":
        localStorage.setItem("CustomerId", Id);
        navigate("/customer-360");
        break;
      case "ApproveReject":
        handleOpenModal(row);
        break;
      case "ApproveGroupReject":
        handleOpenGroupModal(row);
        break;
      case "SetLoanLimit":
        handleOpenSetLoanLimitModal(row);
        break;
      case "activateDeactivate":
        handleActivateDeactivate(row.IsActive, row.Id);
        break;
      case "initiateSababisha":
        handleSababisha(row.LoanId, row.CompanyId, 0);
        break;
      case "approveSababisha":
        handleSababisha(row.LoanId, row.CompanyId, 1);
        break;
      case "rejectLoanLimitUpdate":
        handleUpdateLoanLimit(
          row.CustomerId,
          row.CompanyId,
          row.InitiatorId,
          row.RequestedLoanLimit,
          2
        );
        break;
      case "approveLoanLimit":
        handleUpdateLoanLimit(
          row.CustomerId,
          row.CompanyId,
          row.InitiatorId,
          row.RequestedLoanLimit,
          1
        );
        break;
      case "ProductChargesTable":
        localStorage.setItem("ProductId", Id);
        navigate("/productcharges");
        break;
      case "ProductChargesAccountsTable":
        localStorage.setItem("ProductId", Id);
        navigate("/productchargesaccount");
        break;

      default:
        break;
    }
  };

  const handleSearch = (input) => {
    setSearchTerm(input); // Update searchTerm state

    // Use the generatedColumns if columns is null or undefined

    // Ensure that generatedColumn is defined and is an array before filtering
    if (generatedColumn && Array.isArray(generatedColumn)) {
      if (input.trim() !== "") {
        const filteredData = data.filter((row) =>
          generatedColumn.some((column) => {
            const cellValue = row[column.field];
            return (
              typeof cellValue === "string" &&
              cellValue.toLowerCase().includes(input.toLowerCase())
            );
          })
        );
        setData(filteredData);
      } else {
        setRefreshTable(true); // Reset the table when the search term is cleared
      }
    } else {
      console.error(
        "Columns or generatedColumns are not available for search."
      );
    }
  };

  return (
    <Box m="21">
      <Box
        height="75vh"
        m="0px 0 0 0"
        // width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header title={title} subtitle={subtitle} />
          <Box display="flex" justifyContent="flex-end" mt={1}>
            {title && (
              <TextField
                variant="outlined"
                size="medium"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on input change
                placeholder={`Search ${title}`} // Use placeholder instead of label
                sx={{
                  mr: 2,
                  width: {
                    xs: "100%", // Full width on extra-small screens
                    sm: "100%", // 75% width on small screens
                    md: "100%", // 60% width on medium screens
                    lg: "400px", // 50% width on large screens
                    xl: "400px", // 40% width on extra-large screens
                  },
                  height: "40px",
                  backgroundColor: colors.blueAccent[900],
                  borderRadius: "25px", // Add border radius
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "40px", // Add padding to prevent overlap with the icon
                    "& fieldset": {
                      border: "none", // Remove the border
                    },
                  },
                  "& .MuiInputAdornment-root": {
                    position: "absolute", // Position the icon absolutely
                    left: "10px", // Position icon within the field
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "left", // Align text to the left
                    padding: "12px 0", // Adjust padding to align the text vertically
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>

          {actions.add &&
            actions.add.Show_Button &&
            (actions.isSubRight
              ? hasSubRightPermission(actions.entity, actions.add.permission)
              : hasPermission(actions.entity, actions.add.permission)) && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="contained"
                  size="sm"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={() => handleAction("add")}
                >
                  <Typography sx={{ color: colors.primary[400], mx: 1 }}>
                    {actions.add.button_name}
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columnsWithActions}
          components={{ Toolbar: GridToolbar }}
          sx={{ minWidth: isMobile ? "auto" : "auto", width: "100%" }}
          getRowId={(row) => row.Id}
        />
        {openLoanModal && (
          <LoanApprovalDialog
            open={openLoanModal}
            onClose={handleCloseModal}
            Row={tableRowData}
            // loanData={loanData}
          />
        )}
        {openGroupLoanModal && (
          <GroupLoanApprovalDialog
            open={openGroupLoanModal}
            onClose={() => {
              setOpenGroupLoanModal(false);
            }}
            Row={tableRowData}
            // loanData={loanData}
          />
        )}
        {openLoanLimitModel && (
          <LoanLimitDialog
            open={openLoanLimitModel}
            onClose={handleCloseLoanLimitModal}
            Row={tableRowData}
            // loanData={loanData}
          />
        )}
      </Box>
      <AnchorTemporaryDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        FormComponent={() => (
          <FormComponent
            isEditing={isEditing}
            data={editData}
            onAction={() => setRefreshTable(true)}
            onClose={() => setDrawerOpen(false)}
            refetchData={() => setRefreshTable(true)}
          />
        )}
      />
      {isMobile && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullScreen={isMobile}
        >
          <DialogTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setDialogOpen(false)}
              aria-label="close"
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FormComponent
              onClick={() => setDialogOpen(false)}
              isEditing={isEditing}
              courtData={editData}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default DynamicTableForJson;
