import React from "react";
import { Box } from "@mui/material";
import { loanManagementUrl } from "../../config";
import DynamicTableForJson from "../../data/DynamicTable/dynamicTableForJson";

//const base_url = loanManagementUrl.uri;
const base_url = loanManagementUrl.uri;

const ROLoanAppraisal = () => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  //spaced specific columns
  const columns = [
    { field: "Id", headerName: "ID", flex: 0.3 },
    { field: "CustomerPhone", headerName: "Customer Phone" },
    { field: "FirstName", headerName: "First Name", flex: 1 },
    { field: "OtherNames", headerName: "Other Names", flex: 1 },
    { field: "AmountToDisburse", headerName: "Amount to Disburse" },
    { field: "InterestAmount", headerName: "Interest Amount" },
    { field: "LoanAmount", headerName: "Loan Amount", flex: 1 },
    { field: "LoanBalance", headerName: "Loan Balance", flex: 1 },
    { field: "InstallmentAmount", headerName: "Installment Amount" },
  ];
  const actions = {
    entity: localStorage.getItem("SelectedRight"), //,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },

    ApproveReject: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Approve/Reject Loan",
      inputObjectName: "bulkActivateDeactivateRights",
      modelName: "rightIds",
      inputSubObjectName: null,
      Show_Button: true,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "allLoanApplications",
      parameters: "",
      ignoreFields: [
        "LoanDisbursed",
        "Installments",
        "InstallmentAmount",
        "ApplicationDate",
        "IsLoanApproved",
        "IsBranchApproved",
        "AverageCashSales",
        "FoFirstName",
        "FoLastName",
      ],
    },
    showStatus: true,
  };
  const allLoanApplications = `
  {
   allLoanApplications {
        results
        error
    }
  }`;

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Appraise Loans"
        subtitle="Loan Appraisal"
        base_url={base_url}
        columns={columns}
        actions={actions}
      />
    </Box>
  );
};

export default ROLoanAppraisal;
