import React, { useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { executeAnyMutation } from "../../../../data/DynamicServiceNew/jsondynamicservice";
import { AccountsandFinanceurl } from "../../../../config";

const base_url = AccountsandFinanceurl.uri;

const ApproveRejectTransactionForm = (props) => {
  const transId = props.data.Id;
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      name: "p_Action",
      label: "Action",
      type: "select",
      options: [
        { value: 1, label: "Approve" },
        { value: 0, label: "Reject" },
      ],
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "p_Comments",
      label: "Comments",
      type: "textarea",
      required: false,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    p_Action: "",
    p_Comments: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);

      const mutationData = {
        p_Action: values.p_Action,
        p_Comments: values.p_Comments || null,
        p_TransactionId: transId,
      };

      const response = await executeAnyMutation(
        "approveRejectTransactionFn",
        mutationData,
        "param",
        false,
        base_url
      );

      if (response && response.results) {
        let alertConfig = {
          icon: "error",
          title: "Error",
          text: response.results || "An unknown error occurred.",
        };

        if (response.results.toLowerCase().includes("success")) {
          alertConfig = {
            icon: "success",
            title: "Success!",
            text: response.results,
          };
        }

        Swal.fire(alertConfig);

        if (response.results.toLowerCase().includes("success")) {
          props.onClose();
          props.onAction();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="justify-content-center">Processing...</div>;
  }

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title="Approve or Reject Transaction "
      subtitle="Provide necessary details below"
    />
  );
};

export default ApproveRejectTransactionForm;
