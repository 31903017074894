import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../data/Axios/DynamicForm";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../../data/DynamicServiceNew/jsondynamicservice";
import { roleManagemenUrl, setupManagementUrl, userManagementUrl } from "../../../config";

const setup_url = setupManagementUrl.uri;
const user_url = userManagementUrl.uri;
const role_url = roleManagemenUrl.uri;

const ProcessAuthorizationForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const groupId = parseInt(localStorage.getItem("GroupId"));
  const userId = parseInt(decodedToken.Id);

  const [usersData, setUsersData] = useState([]);
  const [processesData, setProcessesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const usersResponse = await fetchJsonDynamic("allUsers", null, null, user_url);
        if (usersResponse?.results) {
          const results = JSON.parse(usersResponse?.results);
          setUsersData(results || []);
        }
        const processesResponse = await fetchJsonDynamic("allProcesses", null, null, setup_url);
        if (processesResponse?.results) {
          const results = JSON.parse(processesResponse?.results);
          setProcessesData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const usersOptions = usersData.map((user) => ({
    value: user.Id,
    label: `${user.FirstName} ${user.LastName}`,
  }));

  const processOptions = processesData.map((process) => ({
    value: process.Id,
    label: process.Name,
  }));

  const fields = [
    {
      name: "userId",
      label: "User",
      type: "select",
      options: usersOptions,
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "processId",
      label: "Process",
      type: "select",
      options: processOptions,
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    processId: "",
    userId: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { processId, userId } = values;

      // Construct the payload
      const mutationData = {
        processId: parseInt(processId),
        userId: parseInt(userId),
      };

      // Send the mutation request
      const response = await executeAnyMutation(
        "createProcessAuthorization",
        mutationData,
        "processAuthorization",
        false,
        role_url
      );

      // Handle the response
      if (response && response.results) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Process Authorization created successfully.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title="Create Process Authorization"
      subtitle="Assign a process to a user"
    />
  );
};

export default ProcessAuthorizationForm;
