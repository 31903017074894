import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import {
  customerservice,
  loanManagementUrl,
  setupManagementUrl,
} from "../../config";
import {
  executeAnyMutation,
  fetchJson,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import CustomerModal from "./CustomerListModal";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const BookGroupLoan = () => {
  // const customersData = [
  //   { id: '36991073', name: 'Lilian Kilani', email: 'fulusi@gmail.com', phone: '0798706353', branch: 'Kitengela', loanLimit: 3000, createdAt: 'Nov 29, 2021' },
  //   // Add more customer data as needed
  // ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState(null);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState("");
  const [customers, setCustomers] = useState("");
  const [open, setOpen] = useState(false);
  const [GroupData, setGroupData] = useState([]);

  const [totalLoanLimit, setTotalLoanLimit] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    // Delay for live search to avoid rapid searches
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        const group = GroupData.find((cust) =>
          cust.GroupCode.includes(searchTerm)
        );

        setFilteredCustomer(group || null);

        if (!group) {
          Swal.fire("Customer Not Found", "Customer does not exist", "warning");
        } else {
          const fetchData = async () => {
            const parameters = {
              groupId: group.Id,
            };
            const customerByGroup = await fetchJson({
              objectName: "customerGroupViewByColumnValues",
              inputObjectName: "customerGroupView",
              params: parameters,
              baseUrl: customerservice.uri,
            });
            const allCustomers = JSON.parse(customerByGroup.results) || [];
            setCustomers(allCustomers);
          //   const totalLoanLimit = customers.reduce((sum, customer) => {
          //     return sum + (customer.LoanLimit || 0); // Adds LoanLimit if not null, otherwise adds 0
          // }, 0);
          // setTotalLoanLimit(totalLoanLimit)
          };
          fetchData();
        }
      } else {
        setFilteredCustomer(null);
      }
    }, 1000); // Adjust delay as necessary (500ms in this example)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const groupResponse = await fetchJsonDynamic(
        "allGroupsView",
        null,
        null,
        customerservice.uri
      );
      const allGroups = JSON.parse(groupResponse.results) || [];
      const productResponse = await fetchJsonDynamic(
        "products",
        null,
        null,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];
      // Filter regions based on selected company ID

      setGroupData(allGroups);
      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);
  const handleAppraisalSubmit = async () => {
    try {
      const mutationData = {
        p_GroupId: filteredCustomer.Id,
        p_ProductId: loanProductSelected,
      };

      const response = await executeAnyMutation(
        "insertGroupLoanApplications",
        mutationData,
        "loanApplication",
        false,
        loanManagementUrl.uri
      );
      const parsedResults = JSON.parse(response.results);
      if (parsedResults) {
        if (parsedResults === "Loan Booked Successfully") {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: parsedResults,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: parsedResults,
          });
        }
      } else if (response.errors) {
        throw new Error(
          response.errors[0].Error || "An internal server error occurred."
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5">Book A Group Loan</Typography>
      <TextField
        label="Search Group by Group Code"
        placeholder="Enter Group Code"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {filteredCustomer && (
        <>
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Group Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                 <Grid item xs={12} sm={6}>
                 <Typography>
                    <strong>Group Name:</strong> {filteredCustomer.GroupName}
                  </Typography>
                  <Typography>
                    <strong>Mobile Number:</strong>{" "}
                    {filteredCustomer.PhoneNumber}
                  </Typography>
                  <Typography>
                    <strong>Branch:</strong> {filteredCustomer.BranchId}
                  </Typography>
                </Grid> 
                <Grid item xs={12} sm={6}>
               
                  <Typography>
                    <strong>Field Officer:</strong> {filteredCustomer.FirstName}{" "}
                    {filteredCustomer.LastName}{" "}
                  </Typography>
                  <Typography>
                    <strong>Date Created:</strong>{" "}
                    {filteredCustomer.CreatedDate}
                  </Typography>
                  <Typography>
                    <strong>Group Loan Limit:</strong> 10,000
                  </Typography>
                  <Button 
                    variant="contained"
                    size="sm"
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      borderRadius: "4px",
                    }}
                  onClick={handleOpen}>
                    <Typography>View Customer List</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003049",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: 1,
                }}
              >
                Loan Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Loan Product</InputLabel>
                    <Select
                      value={loanProductSelected}
                      onChange={(e) => setLoanProductSelected(e.target.value)}
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Select Loan Product
                      </MenuItem>
                      {loanProduct.map((company) => (
                        <MenuItem key={company.Id} value={company.Id}>
                          {company.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleAppraisalSubmit}
          >
            Submit For RO Appraisal
          </Button>
        </>
      )}
      {open && (
        <CustomerModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          customerData={customers}
        />
      )}
    </Box>
  );
};

export default BookGroupLoan;
