export const companyFields = [
  {
    name: "companyName",
    label: "Company Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactPerson",
    label: "Contact Person Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactPhoneNumber",
    label: "Contact PhoneNumber*",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "parentOffice",
    label: "Parent Office *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "postalAddress",
    label: "Postal Address *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "senderId",
    label: "Sender Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "apikey",
    label: "ApiKey *",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
  {
    name: "fsApikey",
    label: "FsApiKey *",
    type: "text",
    gridColumn: "span 2",
    required: false,
  },
];
export const departmentFields = [
  {
    name: "departmentName",
    label: "Department Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const branchFields = [
  {
    name: "name",
    label: "Branch Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactPerson",
    label: "Contact Person *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "email",
    label: "Email *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "phoneContact",
    label: "Phone Contact*",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "branchLocation",
    label: "Branch Location *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "payBill",
    label: "PayBill Number *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
export const regionFields = [
  {
    name: "name",
    label: "Region Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const addOnFields = [
  {
    name: "addOnName",
    label: "Add-On Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "price",
    label: "Price *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const companyAddOnFields = [
  {
    name: "addOn",
    label: "Company Add-On *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "purchasedDate",
    label: "Purchased Date *",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },
];
export const subscriptionPlanFields = [
  {
    name: "planName",
    label: "Plan *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "price",
    label: "Price *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "durationMonths",
    label: "Duration (Months) *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
  // {
  //   name: "storageLimit",
  //   label: "Storage Limit *",
  //   type: "number",
  //   gridColumn: "span 2",
  //   required: true,
  // },
];
export const storageUnitFields = [
  {
    name: "unit",
    label: "Unit *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "description",
    label: "Description *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
export const companyEmailSettingsFields = [
  {
    name: "companyEmail",
    label: "Company Email *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactEmail",
    label: "Contact Email *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactEmailPassword",
    label: "Contact Email Password *",
    type: "text", // Use "password" type to hide the password input
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailDisplayName",
    label: "Email Display Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailClient",
    label: "Email Client *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "emailClientPortNumber",
    label: "Email Client Port Number *",
    type: "number",
    gridColumn: "span 2",
    required: true,
  },
];
