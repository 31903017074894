import {
  Box,
  Button,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Header from "../../../components/Header";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import Swal from "sweetalert2";
import { setupManagementUrl } from "../../../config";

const setup_url = setupManagementUrl.uri;

const Form = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));

  const initialValues = {
    planName: props.data ? props.data.PlanName : "",
    description: props.data ? props.data.Description : "",
    price: props.data ? props.data.Price : "",
    durationMonths: props.data ? props.data.DurationMonths : "",
    storageLimit: props.data ? props.data.StorageLimit : "",
    smsLimit: props.data ? props.data.SmsLimit : "",
    isTrial: props.data ? props.data.IsTrial : false,
  };

  const validationSchema = yup.object().shape({
    planName: yup.string().required("Plan Name is required"),
    description: yup.string().required("Description is required"),
    price: yup.number().required("Price is required").positive(),
    durationMonths: yup.number().required("Duration is required").positive(),
    storageLimit: yup.number().required("Storage Limit is required").positive(),
    smsLimit: yup.number().required("SMS Limit is required").positive(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const variables = props.isEditing
        ? { id: props.data.Id, ...values }
        : { ...values };

      const response = await executeAnyMutation(
        props.isEditing ? "updateSubscriptionPlans" : "createSubscriptionPlans",
        variables,
        "functionParams",
        false,
        setup_url
      );

      if (response?.results) {
        props.onClose();
        props.onAction();

        await Swal.fire(
          "Success!",
          `Subscription Plan has been ${
            props.isEditing ? "updated" : "created"
          } successfully`,
          "success"
        );
      } else {
        props.onClose();
        props.onAction();
        Swal.fire(
          "Error!",
          `${response?.error}`,
          "error"
        )
      }
    } catch (error) {
      console.error("Error:", error.message);
      props.onClose();
      props.onAction();

      await Swal.fire("Error!", `${error.message}`, "error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header
        title={
          props.isEditing
            ? "Edit Subscription Plans"
            : "Create Subscription Plans"
        }
        subtitle={
          props.isEditing
            ? "Edit an Existing Subscription Plan"
            : "Create a New Subscription Plan"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                label="Plan Name*"
                name="planName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.planName}
                error={touched.planName && !!errors.planName}
                helperText={touched.planName && errors.planName}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Description*"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Price*"
                name="price"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                error={touched.price && !!errors.price}
                helperText={touched.price && errors.price}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Duration (Months)*"
                name="durationMonths"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.durationMonths}
                error={touched.durationMonths && !!errors.durationMonths}
                helperText={touched.durationMonths && errors.durationMonths}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Storage Limit*"
                name="storageLimit"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.storageLimit}
                error={touched.storageLimit && !!errors.storageLimit}
                helperText={touched.storageLimit && errors.storageLimit}
              />
              <TextField
                fullWidth
                variant="filled"
                label="SMS Limit*"
                name="smsLimit"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smsLimit}
                error={touched.smsLimit && !!errors.smsLimit}
                helperText={touched.smsLimit && errors.smsLimit}
              />
              <FormControlLabel
                control={
                  <Switch
                    name="isTrial"
                    checked={values.isTrial}
                    onChange={(e) => setFieldValue("isTrial", e.target.checked)}
                  />
                }
                label="Is Trial"
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
