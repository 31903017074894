import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Snackbar,
  Alert,
  Radio,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchCustomSMS,
  createMessage,
  fetchCustomers,
  createSMSesBulk,
  fetchCustomersByStatus,
  handleregex,
} from "./components/smsService";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { mpesaPay } from "../../../data/DynamicTable/DynamicFunctions";

const CustomSMSPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pastMessages, setPastMessages] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPastMessageId, setSelectedPastMessageId] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    amount: "",
  });

  const statuses = [
    "isLoanApproved",
    "isLoanCleared",
    "isInArrears",
    "isNonPerforming",
    "isWrittenOff",
    "isLoanDeclined",
    "isFieldOfficerApproved",
    "isBranchApproved",
    "isRegionApproved",
    "isCompanyApproved",
    "isMigrated",
    "isRescheduled",
    "isRebooked",
    "isVerified",
  ];

  // Load past messages and customers when component mounts
  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const [messages, customerList] = await Promise.all([
          fetchCustomSMS(),
          fetchCustomers(),
        ]);

        // Format past messages
        const formattedMessages = messages.map((message) => ({
          id: message.messageId,
          ...message,
        }));

        // Format customer data to ensure companyId and company are included
        const formattedCustomers = customerList.map((customer) => ({
          ...customer,
          companyId: customer.companyId || "defaultCompanyId", // Assign a default if companyId is null
          company: customer.company || "Unknown Company", // Assign a default name if company is null
        }));

        setPastMessages(formattedMessages);
        // setCustomers(formattedCustomers);
      } catch (error) {
        console.error("Error loading messages or customers:", error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  const appyStatusChange = async (status) => {
    setSelectedStatuses(status);
    const fetchedCustomers = await fetchCustomersByStatus(status);
    setCustomers(fetchedCustomers);
    setDropdownOpen(false); // Close the dropdown
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;

    setSelectedStatuses(
      typeof value === "string" ? value.split(",") : value // Ensure it's an array
    );
  };

  const handleApplySelection = async () => {
    setDropdownOpen(false);
    // alert(`Selected statuses: ${selectedStatuses.join(", ")}`);
    const fetchedCustomers = await fetchCustomersByStatus(selectedStatuses);
    setCustomers(fetchedCustomers);
    // You can also handle further actions with the selected statuses here
  };
  console.log("error:", selectedStatuses);

  const handleCheckboxChange = (id) => {
    setSelectedCustomers(
      (prev) =>
        prev.includes(id)
          ? prev.filter((customerId) => customerId !== id) // Deselect
          : [...prev, id] // Select
    );
  };
  const handleSendMessage = async () => {
    if (!message || !selectedCustomers.length) {
      alert("Please fill in all fields");
      return;
    }

    setLoading(true);

    try {
      if (!customers || customers.length === 0) {
        throw new Error("Customers list is not loaded");
      }

      // Create SMS message object
      const smsMessages = selectedCustomers
        .map((FirstName) => {
          const customer = customers.find((c) => c.FirstName === FirstName);
          return customer
            ? {
                MobileNumber: customer.MobileNumber,
                body: handleregex(message, customer),
                createdBy: customer.CreatedBy, // Use the appropriate user ID
                companyId: customer.CompanyId || "defaultCompanyId", // Provide default if needed
              }
            : null;
        })
        .filter(Boolean); // Filter out any null values

      if (smsMessages.length === 0) {
        throw new Error("No valid customers to send messages to");
      }

      // Use the bulk function for both single and multiple messages
      await createSMSesBulk(smsMessages);
      setSuccessMessageText(`Message(s) sent successfully `);

      setShowSuccessMessage(true);
      resetForm();
      const messages = await fetchCustomSMS();
      setPastMessages(messages);
    } catch (error) {
      console.error("Error sending message:", error);
      setShowSuccessMessage(true);
      setSuccessMessageText("Failed to send messages. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      setSelectedCustomers(
        isAllSelected ? [] : customers.map((customer) => customer.FirstName)
      );
    } else {
      setSelectedCustomers(value);
    }
  };

  const handleSingleCustomerChange = (FirstName) => {
    setSelectedCustomers((prevSelected) =>
      prevSelected.includes(FirstName)
        ? prevSelected.filter((name) => name !== FirstName)
        : [...prevSelected, FirstName]
    );
  };

  

  const handleCloseSnackbar = () => {
    setShowSuccessMessage(false);
  };

  const resetForm = () => {
    setMessage("");
    setSelectedCustomers([]);
    setSelectedPastMessageId("");
  };
  // const TopUpButton = () => {
   
    const handleInputChanges = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleTopUp = async (e) => {
      e.preventDefault(); // Prevent form submission default behavior
      const phoneNumber = formData.phoneNumber;
      const amountToPay = parseFloat(formData.amount);
      //const amountToPay = parseFloat(formData.amount);
      console.log ("Form Data:",formData)
      console.log({
        PaymentType: 2,
        AmountToPay: amountToPay,
        phoneNumber,
      });
      if (!phoneNumber || !amountToPay) {
        alert("Phone number and amount are required!");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await mpesaPay(amountToPay, phoneNumber, null);
        if (response.status === 200) {
          alert("Payment initiated successfully!");
          setIsOpen(false); // Close the modal on success
        } else {
          alert("Payment failed! Please try again.");
        }
      } catch (error) {
        alert(`Error: ${error}`);
      } finally {
        setLoading(false);
      }
    };
  

  const handleRowSelection = (params) => {
    const selectedMessage = pastMessages.find(
      (msg) => msg.messageId === params.id
    );
    if (selectedMessage) {
      setSelectedPastMessageId(selectedMessage.messageId);
      setMessage(selectedMessage.body);
    }
  };

  const handleDropDownOpen = () => {
    setDropdownOpen(true);
  }

  const handleDropDownClose = () => {
    setDropdownOpen(false);
  }

  const isAllSelected = selectedCustomers.length === customers.length;

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "body", headerName: "Message", flex: 1 },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) =>
        new Date(params.row.createdDate).toLocaleDateString(),
    },
    {
      field: "isActive",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button onClick={() => handleRowSelection(params)}>Select</Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      {/* Header */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>Create and Send Custom SMS</h2>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
           <button
      onClick={() => setIsOpen(true)}
      style={{
        padding: "10px 20px",
        backgroundColor: "#4CAF50",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      Top Up
    </button>

    {isOpen && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            width: "400px",
            maxWidth: "90%",
            padding: "20px",
            position: "relative",
          }}
        >
          <h3 style={{ marginBottom: "15px", fontSize: "20px" }}>Top Up</h3>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <div>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Phone Number
              <input
                type="text"
                value={formData.phoneNumber}
                onChange={handleInputChanges}
                name="phoneNumber"
                placeholder="Enter phone number"
                style={{
                  display: "block",
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </label>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Amount
              <input
                type="number"
                value={formData.amount}
                onChange={handleInputChanges}
                name="amount"
                placeholder="Enter amount"
                style={{
                  display: "block",
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </label>
          </div>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <button
              onClick={handleTopUp}
              disabled={loading}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              {loading ? "Processing..." : "Pay Now"}
            </button>
            <button
              onClick={() => setIsOpen(false)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            </div>
            </div>
          </div>
     )}
      </div>
      </div>

      {/* Message Input */}
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <TextField
          label="Custom Message"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </FormControl>

      {/* Customer Selection */}
      {/* Dropdown */}
      {/*
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel>Send to Customers</InputLabel>
        <Select
          multiple
          value={selectedCustomers}
          onChange={handleCustomerChange}
          renderValue={(selected) =>
            selected.length ? selected.join(", ") : "Select Customers"
          }
        >
          <MenuItem
            value="all"
            onClick={() =>
              setSelectedCustomers(
                isAllSelected
                  ? []
                  : customers.map((customer) => customer.FirstName)
              )
            }
          >
            <Checkbox checked={isAllSelected} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {customers.map((customer) => (
            <MenuItem key={customer.Id} value={customer.FirstName}>
              <Checkbox
                checked={selectedCustomers.includes(customer.FirstName)}
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the Select from closing
                  handleSingleCustomerChange(customer.FirstName);
                }}
              />
              <ListItemText primary={customer.FirstName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      */}

      {/* 
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel>Send to Customers</InputLabel>
        <Select
          multiple
          value={selectedStatuses} // Ensure this is always an array
          onChange={handleCustomerChange}
          renderValue={
            (selected) =>
              Array.isArray(selected) && selected.length
                ? "All"
                : "Select Customers" // Fallback when selected is not an array
          }
        >
          
          <MenuItem
            value="all"
            onClick={() =>
              setSelectedStatuses(
                isAllSelected ? [] : statuses.map((status) => status)
              )
            }
          >
            <Checkbox checked={isAllSelected} />
            <ListItemText primary="Select All" />
          </MenuItem>

          
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={selectedStatuses.includes(status)} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl >
       */}

      <FormControl fullWidth>
        <InputLabel>Filter by Status</InputLabel>
        <Select
          multiple
          value={selectedStatuses}
          open={dropdownOpen} // Bind to dropdown state
          onOpen={() => setDropdownOpen(true)} // Open dropdown
          onClose={() => setDropdownOpen(false)} // Close dropdown
          onChange={handleStatusChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
            onClose: () => handleApplySelection(selectedStatuses), // Handle close
          }}
          renderValue={(selected) => selected.join(", ")} // Display selected statuses
        >
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={selectedStatuses.includes(status)} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {customers.length > 0 && (
        <Box>
          {/* Select All Checkbox */}
          <Box display="flex" alignItems="center" gap={1}>
            <Checkbox
              checked={selectedCustomers.length === customers.length} // Checked if all are selected
              indeterminate={
                selectedCustomers.length > 0 &&
                selectedCustomers.length < customers.length
              } // Indeterminate if some are selected
              onChange={(e) => handleSingleCustomerChange(e.target.checked)}
            />
            <ListItemText primary="Select All" />
          </Box>

          {/* Individual Customer Checkboxes */}
          {customers.map((customer) => (
            <Box key={customer.Id} display="flex" alignItems="center" gap={1}>
              <Checkbox
                checked={selectedCustomers.includes(customer.Id)} // Use unique `id`
                onChange={() => handleCheckboxChange(customer.Id)} // Pass `id` to handler
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the Select from closing
                  handleSingleCustomerChange(customer.FirstName);
                }}
              />
              <ListItemText
                primary={`${customer.FirstName} ${customer.OtherNames}`}
              />
            </Box>
          ))}
        </Box>
      )}

      {/* Send Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: "20px" }}
        onClick={handleSendMessage}
        disabled={loading || !message || !selectedCustomers.length}
      >
        {loading ? "Sending..." : "Send SMS"}
      </Button>

      {/* DataGrid for Past Messages */}
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid
          rows={pastMessages}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
        />
      </Box>

      {/* Snackbar for success messages */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessageText}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSMSPage;