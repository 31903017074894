import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl } from "../../../config";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CompanyColumns } from "../../../data/DynamicTable/CompanyManagementColumns";
import { allCompanies } from "../../../data/Axios/queries";
import CompanyForm from "./form";
import { generateAndExecuteMutation } from "../../../data/Axios/DynamicService";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = companyManagementUrl.uri;

const ignoreFields = [
  "ContactEmail",
  "CompanyEmail",
  "ContactPhoneNumber",
  "ContactEmailPassword",
  "EmailClient",
  "EmailDisplayName",
  "EmailClientPortNumber",
  "ParentOffice",
  "ContactPerson",
  "PostalAddress",
  "OpeningDate",
  "SMSReminder",
  "PaymentReply",
  "RegistrationFee",
  "GroupRegistrationFee",
  "FSApiKey",
  "SubscriptionId",
  "LogoPath",
];

const actions = {
  entity: "Companies",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
    modelName: "companyIds",
    inputObjectName: "bulkActivateDeactivateCompany",
  },
  dataObject: {
    ignoreFields,
    objectName: "companies",
    parameters: "",
  },
  //required: "CompanyIds",
  showStatus: false,
};

function CompanyTable() {
 

  return (
    <Box m="2px">
      <DynamicTableForJson
        title="Company"
        subtitle="Create, view or edit account Company"
        FormComponent={CompanyForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default CompanyTable;
