import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import InfoIcon from "@mui/icons-material/Info";
import * as yup from "yup";
import Swal from "sweetalert2";
import { executeAnyMutation, fetchJsonDynamic } from "../../../data/DynamicServiceNew/jsondynamicservice";
import Header from "../../../components/Header";
import { setupManagementUrl } from "../../../config";
import { useEffect, useState } from "react";
import { allInterestPaymentMethods } from "../../../data/Axios/queries";

const setup_url = setupManagementUrl.uri;

const Form = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const CompanyId = decodedToken.CompanyId;
  const [interestPaymentMethods, setInterestPaymentMethods] = useState([]);
  const [interestMethods, setInterestMethods] = useState([]);
  const [repaymentFrequencys, setRepaymentFrequencys] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
      try {
        // Fetch interest payment methods
        const interestpaymentResponse = await axios.post(setup_url, allInterestPaymentMethods, {
          headers: {
            Authorization: formattedToken,
            "Content-Type": "application/json",
          },
        });

        const interestPaymentresponse = interestpaymentResponse?.data?.data?.interestPaymentMethods?.results;

        //interest methods
        const interestMethodResponse = await fetchJsonDynamic(
          "interestMethods",
          null,
          null,
          setup_url
        );
        const InterestMethodResponse = interestMethodResponse?.results;

        //repayment frequencys
        const repaymentFrequencyResponse = await fetchJsonDynamic(
          "productRepaymentFrequency",
          null,
          null,
          setup_url
        );
        const repaymentfrequencyResponse = repaymentFrequencyResponse?.results;
        console.log("IPMs:", repaymentfrequencyResponse)

        if (InterestMethodResponse) {
          const interstmethods = JSON.parse(InterestMethodResponse);
          setInterestMethods(interstmethods || []);
          console.log("FilteredIPMs:", interstmethods)

        }
        if (repaymentfrequencyResponse) {
          const frequencys = JSON.parse(repaymentfrequencyResponse);
          setRepaymentFrequencys(frequencys || []);
          console.log("FilteredFrequencys:", frequencys)

        }
        if (interestPaymentresponse) {
        const interestData = JSON.parse(interestPaymentresponse);
        setInterestPaymentMethods(interestData);
        console.log("FiltereIPMs:", interestData)

        } else {
          console.error("Results field is not found or is empty.")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const initialValues = {
    name: props.data ? props.data.Name : "",
    description: props.data ? props.data.Description : "",
    noOfInstallments: props.data ? props.data.NoOfInstallments : "",
    productInterest: props.data ? props.data.ProductInterest : "",
    isDepositRequired: props.data ? !!props.data.IsDepositRequired : false,
    depositRequired: props.data ? props.data.DepositRequired : "",
    isGroup: props.data ? !!props.data.IsGroup : false,
    isDepositPercentage: props.data ? !!props.data.IsDepositPercentage : false,
    minimumAmount: props.data ? props.data.MinimumAmount : 0,
    maximumAmount: props.data ? props.data.MaximumAmount : "",
    interestMethodId: props.data ? props.data.InterestMethodId || "" : "", 
    interestPaymentMethodId: props.data ? props.data.InterestPaymentMethodId || "" : "", 
    repaymentFrequencyId: props.data ? props.data.RepaymentFrequencyId || "" : "",

  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name required"),
    description: yup.string().required("Description required"),
    noOfInstallments: yup.number().required("No Of Installments required"),
    productInterest: yup.number().required("Product Interest required"),
    depositRequired: yup.number().when("isDepositRequired", {
      is: (isDepositRequired) => isDepositRequired, // Only validate if isDepositRequired is true
      then: yup.number()
        .required("Deposit Required")
        .typeError("Deposit must be a valid number"),
      otherwise: yup.number().nullable(true), // Mark as nullable if not required
    }),
    interestMethodId: yup.string().required("Interest Method selection required"),
    interestPaymentMethodId: yup.string().required("Interest Payment Method selection required"),
    repaymentFrequencyId: yup.string().required("Payment Interval selection required"),
    minimumAmount: yup.number().required("Minimum Amount required"),
    maximumAmount: yup
      .number()
      .required("Maximum Amount required")
      .moreThan(yup.ref('minimumAmount'), "Max Amount must be greater than min Amount"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { 
        name, 
        description, 
        isDepositRequired, 
        isGroup, 
        isDepositPercentage, 
        interestMethodId,
        interestPaymentMethodId,
        repaymentFrequencyId, 
      } = values;
      const noOfInstallments = parseInt(values.noOfInstallments, 10);
      const productInterest = parseInt(values.productInterest, 10);
      const companyId = parseInt(CompanyId, 10);
      const depositRequired = isDepositRequired
          ? isDepositPercentage 
            ? parseFloat(values.depositRequired) // Use value as is if percentage
            : parseFloat(values.depositRequired) // Use value as is if not percentage
          : null;
      const minimumAmount = parseInt(values.minimumAmount);
      const maximumAmount = parseInt(values.maximumAmount)
      const variables = props.isEditing
        ? {
            id: props.data.Id,
            name,
            description,
            noOfInstallments,
            productInterest,
            isDepositRequired,
            depositRequired,
            isGroup,
            isDepositPercentage,
            minimumAmount,
            maximumAmount,
            companyId,
            interestMethodId,
            interestPaymentMethodId,
            repaymentFrequencyId, 
          }
        : {
            name,
            description,
            noOfInstallments,
            productInterest,
            isDepositRequired,
            depositRequired,
            isGroup,
            isDepositPercentage,
            minimumAmount,
            maximumAmount,
            companyId,
            interestMethodId,
            interestPaymentMethodId,
            repaymentFrequencyId, 
          };

      const response = await executeAnyMutation(
        props.isEditing ? "updateProduct" : "createProduct",
        variables,
        "result",
        false,
        setup_url
      );

      if (response) {
        props.onClose();
        props.onAction();
        await Swal.fire(
          "Success!",
          `Product has been ${props.isEditing ? "updated" : "created"} successfully`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Product" : "Create Product"}
        subtitle={props.isEditing ? "Edit Product" : "Create Product"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Product Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <Tooltip title="Enter the name of the product">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 2" }}
              />
              <Tooltip title="Provide a description of the product">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="No Of Installments"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.noOfInstallments}
                name="noOfInstallments"
                error={touched.noOfInstallments && !!errors.noOfInstallments}
                helperText={touched.noOfInstallments && errors.noOfInstallments}
                sx={{ gridColumn: "span 2" }}
              />
              <Tooltip title="Provide the number of installments  for the product">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Product Interest"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.productInterest}
                name="productInterest"
                error={touched.productInterest && !!errors.productInterest}
                helperText={touched.productInterest && errors.productInterest}
                sx={{ gridColumn: "span 2" }}
              />
               <Tooltip title="Provide the  Product interest">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Minimum Loan Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minimumAmount}
                  name="minimumAmount"
                  error={touched.minimumAmount && !!errors.minimumAmount}
                  helperText={touched.minimumAmount && errors.minimumAmount}
                />
                <Tooltip title="Enter the minimum amount for the product range">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Maximum Loan Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maximumAmount}
                  name="maximumAmount"
                  error={touched.maximumAmount && !!errors.maximumAmount}
                  helperText={touched.maximumAmount && errors.maximumAmount}
                />
                <Tooltip title="Enter the maximum amount for the product range">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                </Box>
                <Box sx={{ display: "flex", alignItems: "centre", justifyContent:"space-between", gap: 2 }}>
                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel>Interest Payment Method</InputLabel>
                <Select
                  name="interestPaymentMethodId"
                  value={values.interestPaymentMethodId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.interestPaymentMethodId && !!errors.interestPaymentMethodId}
                >
                  {interestPaymentMethods.map((method) => (
                    <MenuItem key={method.Id} value={method.Id}>
                      {method.PaymentMethod}
                    </MenuItem>
                  ))}
                </Select>
                {touched.interestPaymentMethodId && errors.interestPaymentMethodId && (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.interestPaymentMethodId}</div>
                )}
              </FormControl>
              <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel>Interest Method</InputLabel>
                <Select
                  name="interestMethodId"
                  value={values.interestMethodId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.interestMethodId && !!errors.interestMethodId}
                >
                  {interestMethods.map((method) => (
                    <MenuItem key={method.Id} value={method.Id}>
                      {method.Method}
                    </MenuItem>
                  ))}
                </Select>
                {touched.interestMethodId && errors.interestMethodId && (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.interestMethodId}</div>
                )}
              </FormControl>
              <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel>Payment Interval</InputLabel>
                <Select
                  name="repaymentFrequencyId"
                  value={values.repaymentFrequencyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.repaymentFrequencyId && !!errors.repaymentFrequencyId}
                >
                  {repaymentFrequencys.map((method) => (
                    <MenuItem key={method.Id} value={method.Id}>
                      {method.Name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.repaymentFrequencyId && errors.repaymentFrequencyId && (
                  <div style={{ color: "red", fontSize: "0.875rem" }}>{errors.repaymentFrequencyId}</div>
                )}
              </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isDepositRequired}
                    onChange={(e) => setFieldValue("isDepositRequired", e.target.checked)}
                    name="isDepositRequired"
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    Is Deposit Required
                    <Tooltip title="Check if a deposit is required for this product">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                    </Tooltip>
                  </Box>
                }
              />

              {values.isDepositRequired && (
                <>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label={`Deposit Required${values.isDepositPercentage ? " (%)" : ""}`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.depositRequired}
                    name="depositRequired"
                    error={touched.depositRequired && !!errors.depositRequired}
                    helperText={touched.depositRequired && errors.depositRequired}
                    sx={{ gridColumn: "span 2",  marginRight: 2}}
                  />
                  <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isDepositPercentage}
                        onChange={(e) => setFieldValue("isDepositPercentage", e.target.checked)}
                        name="isDepositPercentage"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        Deposit as Percentage
                        <Tooltip title="Check if the deposit amount is a percentage of the total amount">
                        <IconButton size="small">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                        </Tooltip>
                      </Box>
                    }
                  />
                  </Box>
              </>
            )}
            </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isGroup}
                    onChange={(e) => setFieldValue("isGroup", e.target.checked)}
                    name="isGroup"
                  />
                }
                label={
                    <Box display="flex" alignItems="center">
                      Is Group
                      <Tooltip title="Check if this product is for a group">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
