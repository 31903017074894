import { Box, Button, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { setupManagementUrl} from "../../../config";
import { executeAnyMutation } from "../../../data/DynamicServiceNew/jsondynamicservice";
import Swal from "sweetalert2";

const setup_url = setupManagementUrl.uri;

const Form = (props) => {
  
  const initialValues = {
    paymentMethod: props.data ? props.data.PaymentMethod : "",
  };

  const validationSchema = yup.object().shape({
    paymentMethod: yup.string().required("Payment Method required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { paymentMethod } = values;
      const variables = props.isEditing
        ? { id: props.data.Id, paymentMethod}
        : { paymentMethod };

      const response = await executeAnyMutation(
        props.isEditing ? "updateInterestPaymentMethod" : "createInterestPaymentMethod",
        variables,
        "result",
        false,
        setup_url
      );
      if (response) {
        props.onClose();
        props.onAction();
        await Swal.fire(
          "Success!",
          `interestPaymentMethod has been ${props.isEditing ? "updated" : "created"} successfully`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit InterestPaymentMethod" : "Create InterestPaymentMethod"}
        subtitle={props.isEditing ? "Edit an Existing InterestPaymentMethod" : "Create a New InterestPaymentMethod"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Payment Method"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.paymentMethod}
                name="paymentMethod"
                error={touched.paymentMethod && !!errors.paymentMethod}
                helperText={touched.paymentMethod && errors.paymentMethod}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
