import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
import { AccountsandFinanceurl } from "../../../../config";
import { useNavigate } from "react-router-dom";
import AccountTransferForm from "./form";

const base_url = AccountsandFinanceurl.uri;

const CustomerBalanceTable = () => {

  const navigate = useNavigate();

  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Transfer Funds",
      Show_Button: true,
      permission: "EditPermission",
    },
    dataObject: {
      ignoreFields: [],
      objectName: "customerBalanceViewByColumnValues",
      parameters: {},
      inputObjectName: "customerBalanceView",
    },
  };
  
  return (
    <Box m="20px">
      <Box>
      <Button onClick={ () => navigate(-1)} variant="contained">
            Back
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Inter Account Transfers"
        subtitle="Transfer funds"
        FormComponent={AccountTransferForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default CustomerBalanceTable;
