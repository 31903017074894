import React from "react";
import { Box, Button } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
import { useQuery} from "@apollo/client";
import { setupManagementUrl } from "../../../config";
import Form from "./forms";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = setupManagementUrl.uri;

const InterestPaymentMethodsTable = ({}) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);


  const actions = {
    entity:localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
      modelName: "productChargeIds",
      inputObjectName: "bulkActivateDeactivateProductCharges",
    },
    dataObject: {
     objectName: "interestPaymentMethods",
    parameters: "",
    },
  };
  
  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Interest Payment Methods"
        subtitle="Manage Interest Payment Methods"
        //columns={RightsColumns}
        FormComponent={Form}
        //query={allRights}
        base_url={base_url}
        actions={actions}
        />
    </Box>
  );
};

export default InterestPaymentMethodsTable;
