import axios from "axios";
import Swal from "sweetalert2";
import { AccountsandFinanceurl, salesManagement } from "../config";

// Define your GraphQL endpoint
const endpoint = salesManagement.uri;

// GraphQL query to fetch all expenses
const GET_ALL_EXPENSES = `
  query AllExpensesEntriesView {
    allExpensesEntriesView {
      id
      expenseName
      accountSubCategoryId
      accountName
      amount
      description
      createdDate
      createdBy
      updatedDate
      updatedBy
      isActive
      cancelled
    }
  }
`;

// Function to fetch expenses and calculate the total
export const fetchExpensesData = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  try {
    const response = await axios.post(
      endpoint,
      {
        query: GET_ALL_EXPENSES,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    const expenses = response.data.data.allExpensesEntriesView;

    // Calculate the total expenses
    const totalExpenses = expenses.reduce(
      (total, expense) => total + expense.amount,
      0
    );

    return { expenses, totalExpenses };
  } catch (error) {
    console.error("Error fetching expenses:", error);
    return { expenses: [], totalExpenses: 0 };
  }
};
export const fetchExpenseById = async (id) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const GET_EXPENSE_BY_ID = `
    query ExpensesEntriesById {
    expensesEntriesById(expenseEntries: { id: ${id} }) {
        id
        expenseName
        amount
        description
        accountSubCategoryId
        accountName
        createdDate
        companyId
        companyName
        createdBy
        updatedDate
        updatedBy
        isActive
        cancelled
    }
}
  `;
  try {
    const response = await axios.post(
      endpoint,
      {
        query: GET_EXPENSE_BY_ID,
        variables: { expenseEntries: { id } },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("GraphQL Response:", response.data);

    const expense = response.data?.data?.expensesEntriesById;
    // Additional debugging to check the fetched expense
    console.log("Fetched expense:", expense);

    return expense || null;
  } catch (error) {
    console.error("Error fetching expense by ID:", error);
    return null;
  }
};
export const updateExpense = async (expenseData) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const UPDATE_EXPENSE = `
  mutation UpdateExpensesEntries(
    $expensesEntries: ExpensesEntriesInput!
  ) {
    updateExpensesEntries(
      expensesEntries: $expensesEntries
    ) {
      id
      expenseName
      amount
      description
      createdDate
      createdBy
      updatedDate
      updatedBy
      isActive
      accountSubCategoryId
    }
  }
`;
  try {
    const response = await axios.post(
      endpoint,
      {
        query: UPDATE_EXPENSE,
        variables: {
          expensesEntries: {
            id: expenseData.id,
            expenseName: expenseData.expenseName,
            amount: expenseData.amount,
            description: expenseData.description,
            createdDate: expenseData.createdDate || null, // Handle null if needed
            createdBy: expenseData.createdBy || null,
            accountSubCategoryId: expenseData.accountSubCategoryId, // Handle null if needed
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data.updateExpensesEntries; // Match the return field
  } catch (error) {
    console.error("Error updating expense:", error);
    return null;
  }
};

export const toggleExpenseStatus = async (expenseEntryId, isCancelled) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const TOGGLE_EXPENSE_STATUS = `
  mutation CancelApproveExpensesEntries($expenseEntryId: Int!, $isCancelled: Boolean!) {
    cancelApproveExpensesEntries(expenseEntryId: $expenseEntryId, isCancelled: $isCancelled)
  }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: TOGGLE_EXPENSE_STATUS,
        variables: {
          expenseEntryId: expenseEntryId,
          isCancelled: isCancelled,
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.cancelApproveExpensesEntries; // This will return a Boolean value
  } catch (error) {
    console.error("Error toggling expense status:", error);
    return null;
  }
};

export const addExpense = async (expenseData) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const ADD_EXPENSE = `
    mutation CreateExpensesEntries($expensesEntries: ExpensesEntriesInput!) {
      createExpensesEntries(expensesEntries: $expensesEntries) {
        id
        expenseName
        amount
        description
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        accountSubCategoryId
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: ADD_EXPENSE,
        variables: {
          expensesEntries: {
            expenseName: expenseData.expenseName,
            amount: parseFloat(expenseData.amount),
            description: expenseData.description,
            accountSubCategoryId: parseInt(expenseData.accountSubCategoryId),
            isActive: true,
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.createExpensesEntries;
  } catch (error) {
    Swal.fire("Failure!", `${error.response.data.errors[0].message}`, "error");
    console.error("Error adding expense:", error);
  }
};

// GraphQL query to fetch all fixed expenses

const GET_ALL_FIXED_EXPENSES = `
  query AllFixedEntriesView {
    allFixedEntriesView {
      id
      entry
      intervalId
      amount
      accountName
      description
      createdDate
      createdBy
      updatedDate
      updatedBy
      isActive
      companyId
    }
  }
`;

export const fetchFixedExpensesData = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: GET_ALL_FIXED_EXPENSES,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    // Extract the fixed expenses from the response
    const fixedExpenses = response.data.data.allFixedEntriesView; // Adjusted to match your query structure
    console.log("Fetched Fixed Expenses:", fixedExpenses); // For debugging

    // Calculate the total fixed expenses
    const totalFixedExpenses = fixedExpenses.reduce(
      (total, expense) => total + expense.amount,
      0
    );

    return { fixedExpenses, totalFixedExpenses };
  } catch (error) {
    console.error("Error fetching fixed expenses:", error);
    return { fixedExpenses: [], totalFixedExpenses: 0 };
  }
};

// Add a fixed expense
export const addFixedExpense = async (fixedExpenseData) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const ADD_FIXED_EXPENSE = `
    mutation CreateFixedEntries($fixedEntries: FixedEntriesInput!) {
      createFixedEntries(fixedEntries: $fixedEntries) {
        entry
        intervalId
        amount
        description
        accountSubCategoryId
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        companyId
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: ADD_FIXED_EXPENSE,
        variables: {
          fixedEntries: {
            entry: fixedExpenseData.entry,
            intervalId: fixedExpenseData.intervalId,
            amount: parseFloat(fixedExpenseData.amount),
            description: fixedExpenseData.description || "",
            createdDate: null,
            createdBy: fixedExpenseData.createdBy || null,
            updatedDate: null,
            updatedBy: null,
            isActive: true,
            companyId: null,
            accountSubCategoryId: parseInt(
              fixedExpenseData.AccountSubCategoryId
            ),
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.createFixedEntries;
  } catch (error) {
    console.error("Error adding fixed expense:", error);
    return null;
  }
};

// Update a fixed expense
export const updateFixedExpense = async (fixedExpenseData) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const UPDATE_FIXED_EXPENSE = `
    mutation UpdateFixedEntries($fixedEntries: FixedEntriesInput!) {
      updateFixedEntries(fixedEntries: $fixedEntries) {
        id
        entry
        intervalId
        amount
        description
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        companyId
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: UPDATE_FIXED_EXPENSE,
        variables: {
          fixedEntries: {
            id: fixedExpenseData.id,
            entry: fixedExpenseData.entry, // Updated from expenseName to entry
            amount: parseFloat(fixedExpenseData.amount), // Ensure amount is a number
            description: fixedExpenseData.description || "",
            intervalId: fixedExpenseData.intervalId || "",
            isActive: fixedExpenseData.isActive || "",
            updatedDate: new Date().toISOString(), // Update date to current date
            updatedBy: fixedExpenseData.updatedBy || null,
          },
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.updateFixedEntries;
  } catch (error) {
    console.error("Error updating fixed expense:", error);
    return null;
  }
};

// Toggle the status of a fixed expenseimport axios from 'axios';

export const toggleFixedExpenseStatus = async (fixedExpenseId, isActive) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const TOGGLE_FIXED_EXPENSE_STATUS = `
    mutation ActivateDeactivateFixedEntry($fixedEntryId: Int!, $isActive: Boolean!) {
      activateDeactivateFixedEntry(fixedEntryId: $fixedEntryId, isActive: $isActive)
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: TOGGLE_FIXED_EXPENSE_STATUS,
        variables: {
          fixedEntryId: fixedExpenseId,
          isActive: isActive,
        },
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.data.activateDeactivateFixedEntry;
  } catch (error) {
    console.error("Error toggling fixed expense status:", error);
    return null;
  }
};

// Fetch a fixed expense by ID
export const fetchFixedExpenseById = async (id) => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const GET_FIXED_EXPENSE_BY_ID = `
    query {
      fixedEntriesById(fixedentries: { id: ${id} }) {
        id
        entry
        intervalId
        accountSubCategoryId
        amount
        description
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        companyId
        companyName
        accountName
      }
    }
  `;

  try {
    const response = await axios.post(
      endpoint,
      {
        query: GET_FIXED_EXPENSE_BY_ID,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data?.data?.fixedEntriesById || null;
  } catch (error) {
    console.error("Error fetching fixed expense by ID:", error);
    return null;
  }
};

// Fetch intervals
export const fetchIntervals = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  const query = `
    query Intervals {
      intervals {
        id
        interval
        intervalName
        createdDate
        isActive
      }
    }
  `;

  try {
    const response = await axios.post(
      "https://posdxl.mcb.co.ke/posgateway/POSSetupManagement",
      {
        query: query,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    // Check if there is data in the response
    const intervals = response.data.data?.intervals || [];
    return intervals;
  } catch (error) {
    console.error("Error fetching intervals:", error);
    return [];
  }
};
export const fetchAccountSubCategory = async () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  const query = `
  query AccountSubCategory {
    accountSubCategory {
        id
        status
        accountCategoryId
        accountCategory
        accountName
      }
    }
  `;

  try {
    const response = await axios.post(
      AccountsandFinanceurl.uri,
      {
        query: query,
      },
      {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "application/json",
        },
      }
    );

    // Check if there is data in the response
    const accountSubCategory = response.data.data?.accountSubCategory || [];
    return accountSubCategory;
  } catch (error) {
    console.error("Error fetching intervals:", error);
    return [];
  }
};
