import React from "react";
import { Box, Button } from "@mui/material";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
import { AccountsandFinanceurl, customerservice } from "../../../../config";
import { useNavigate } from "react-router-dom";
import AccountTransferForm from "./form";

const base_url = AccountsandFinanceurl.uri;

const TransactionsTable = () => {

  const navigate = useNavigate();

  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Approve/Reject Transaction",
      Show_Button: true,
      permission: "EditPermission",
    },
    dataObject: {
      ignoreFields: [
        "CustomerIdFrom",
        "CustomerIdTo",
        "ApprovedDate",
        "ApprovedBy",
        "RejectedDate"
      ],
      objectName: "accountTransactionsViewByColumnValues",
      parameters: { isApproved: false },
      inputObjectName: "accountTransactionsView",
    },
  };
  
  return (
    <Box m="20px">
      <Box>
      <Button onClick={ () => navigate(-1)} variant="contained">
            Back
          </Button>
      </Box>
      <>
      </>
      <DynamicTableForJson
        title="Manage Account Transfers"
        subtitle="Account Transactions"
        FormComponent={AccountTransferForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default TransactionsTable;
