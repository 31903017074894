export const groupFields = [
  {
    name: "name",
    label: "Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  }
];
export const groupRoleFields = [
  {
    name: "roleName",
    label: "Role Name",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "uniqueRole",
    label: "Unique Role",
    type: "checkbox", // Assuming it's a boolean; checkbox is a good choice for boolean fields
    gridColumn: "span 2",
    required: false,
  },
];

