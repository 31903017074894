import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../Axios/DynamicService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteIcon,
  Save as SaveIcon,
  Close as CancelIcon,
} from "@mui/icons-material";
import usePermissions from "../RBAC/PermissionsHook";
import DynamicEditableActions from "./DynamicEditableActions";

const AddRecordContext = createContext(null);

function EditToolbar({ setRows, setRowModesModel }) {
  const handleClick = useContext(AddRecordContext);

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

const DynamicEditableTable = ({
  title,
  subtitle,
  columns,
  query,
  base_url,
  actions,
  DataFromGetBy,
  loadingState,
}) => {
  const [data, setData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [editedRows, setEditedRows] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { hasPermission } = usePermissions();
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const [rowId, setRowId] = useState(null);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [newRowIds, setNewRowIds] = useState(new Set());
  useEffect(() => {
    fetchAllData();
  }, [query, base_url]);

  const fetchAllData = async () => {
    try {
      let fetchedData = [];
      if (query && base_url) {
        fetchedData = await graphqlQuery(query, base_url);
      } else {
        fetchedData = DataFromGetBy;
      }
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleClick = async () => {
    try {
      const newRecord = { id: "" }; // Temp ID
      setData((oldRows) => [...oldRows, { ...newRecord }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newRecord.id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
      setNewRowIds((prev) => new Set(prev).add(newRecord.id));
      setRowId(newRecord.id);
    } catch (error) {
      console.error("Error creating record:", error);
    }
  };
  const handleAction = async (action, id, row) => {
    switch (action) {
      case "edit":
        //handleEditClick(id)();
        break;
      case "ViewCase":
        localStorage.setItem("CaseId", JSON.stringify(id));
        navigate("/case-details");
        break;
      default:
        break;
    }
  };

  const filterActionsByRole = (action) => {
    if (actions[action].permission === "hasNoPermission") {
      return actions[action].roleGroupId.includes(RoleGroupId);
    }
    return hasPermission(actions.entity, actions[action].permission);
  };
  useEffect(() => {
    updateColumns();
  }, [rowId]);
  const updateColumns = () => {
    const Allcolumns = [
      ...columns,
      {
        field: "save",
        headerName: "Save",
        flex: 1,
        type: "actions",
        renderCell: (params) => (
          <DynamicEditableActions
            {...{
              params,
              rowId,
              setRowId,
              actions,
              base_url,
              isNew: newRowIds.has(params.id),
            }}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: ({ row }) => (
          <Box>
            <Select
              value=""
              displayEmpty
              onChange={(e) => handleAction(e.target.value, row.id, row)}
            >
              <MenuItem value="" disabled>
                Actions
              </MenuItem>
              {Object.keys(actions)
                .filter(
                  (action) =>
                    action !== "add" &&
                    actions[action].Show_Button &&
                    filterActionsByRole(action)
                )
                .map(
                  (action) =>
                    actions[action] && (
                      <MenuItem key={actions[action].key} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    )
                )}
            </Select>
          </Box>
        ),
      },
    ];
    setDynamicColumns(Allcolumns);
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  return (
    <AddRecordContext.Provider value={handleClick}>
      <Box m="20px">
        <Box
          height="75vh"
          m="0px 0 0 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Header title={title} subtitle={subtitle} />
          </Box>
          <DataGrid
            rows={data}
            columns={dynamicColumns}
            //editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
            components={{
              Toolbar: EditToolbar,
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
            // componentsProps={{
            //   toolbar: { setRows: setData, setRowModesModel },
            // }}
            sx={{ minWidth: isMobile ? "auto" : "900px", width: "100%" }}
          />
        </Box>
      </Box>
    </AddRecordContext.Provider>
  );
};

export default DynamicEditableTable;
