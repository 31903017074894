import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { executeAnyMutation, fetchJsonDynamic } from "../../../../data/DynamicServiceNew/jsondynamicservice";
import { AccountsandFinanceurl, customerservice, setupManagementUrl } from "../../../../config";

const base_url = AccountsandFinanceurl.uri;
const customers_url = customerservice.uri;
const setup_url = setupManagementUrl.uri;

const AccountTransferForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const CustomerId = props.data.Id;
  const userId = parseInt(decodedToken.Id);

  const [customersData, setCustomersData] = useState([]);
  const [transactionTypeData, setTransactionTypeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTransactionType, setSelectedTransactionType] = useState("");

  useEffect(() => {
    async function fetchAllData() {
      try {
        const customersResponse = await fetchJsonDynamic("allCustomersExceptId", "customer", { id: CustomerId }, customers_url);
        if (customersResponse?.results) {
          const results = JSON.parse(customersResponse?.results);
          setCustomersData(results || []);
        }
        const transactionTypeResponse = await fetchJsonDynamic("allTransactionTypes", null, null, setup_url);
        if (transactionTypeResponse?.results) {
          const results = JSON.parse(transactionTypeResponse?.results);
          setTransactionTypeData(results || []);
        }
      } catch (error) {
        console.error("Error fetching Customers data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const customersOptions = customersData.map((customer) => ({
    value: customer.Id,
    label: `${customer.FirstName} ${customer.OtherNames} - ${customer.MobileNumber}`,
  }));

  const transactionTypeOptions = transactionTypeData.map((transactionType) => ({
    value: transactionType.Id,
    label: transactionType.Name,
  }));

  const handleTransactionTypeChange = (value) => {
    setSelectedTransactionType(value); // Update state on selection change
  };

  const fields = [
    {
      name: "TransactionTypeId",
      label: "TransactionType",
      type: "select",
      options: transactionTypeOptions,
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "CustomerIdTo",
      label: "Recipient",
      type: "select",
      options: customersOptions,
      required: false,
      gridColumn: "span 2",
      disableCondition: {
        fieldName: "TransactionTypeId",
        value: 2, // Disable when TransactionTypeId is 2
      },
    },
    {
      name: "TransferAmount",
      label: "Amount",
      type: "number",
      gridColumn: "span 2",
      required: false,
    },
  ];

  const initialValues = {
    CustomerIdFrom: CustomerId,
    CustomerIdTo: "",
    TransferAmount: 0.0,
    TransactionTypeId: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      const customerIdTo = values.TransactionTypeId === 2 ? CustomerId : values.CustomerIdTo;
      const mutationData = {
        p_CustomerIdFrom: parseInt(values.CustomerIdFrom),
        p_CustomerIdTo: parseInt(customerIdTo),
        p_TransferAmount: parseFloat(values.TransferAmount),
        p_TransactionTypeId: parseInt(values.TransactionTypeId),
      };

      const response = await executeAnyMutation(
        "initiateTransactionFn",
        mutationData,
        "param",
        false,
        base_url
      );

      if (response && response.results) {
        let alertConfig = {
          icon: "error",
          title: "Transfer Error",
          text: "An unknown error occurred.",
        };

        switch (response.results) {
          case "Transaction initiated successfully.":
            alertConfig = {
              icon: "success",
              title: "Success!",
              text: "Transaction Initiated successfully.",
            };
            break;
          case "Insufficient funds.":
            alertConfig = {
              icon: "warning",
              title: "Transfer Failed",
              text: "Insufficient funds for the transfer.",
            };
            break;
          case "Transfer failed: Customers belong to different companies.":
            alertConfig = {
              icon: "error",
              title: "Transfer Failed",
              text: "Cannot transfer: Customers belong to different companies.",
            };
            break;
          case "An unexpected error occurred during the transaction.":
            alertConfig = {
              icon: "error",
              title: "Transfer Error",
              text: "An unexpected error occurred during the transfer.",
            };
            break;
          default:
            alertConfig = {
              icon: "error",
              title: "Unknown Error",
              text: response.results,
            };
        }

        Swal.fire(alertConfig);

        if (response.results === "Transfer successful.") {
          props.onClose();
          props.onAction();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  if (loading) {
    return <div className="justify-content-center">Loading...</div>;
  }

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title="Initiate Transaction"
      subtitle="Transfer Funds"
    />
  );
};

export default AccountTransferForm;
