import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Dialog,
  TextField,
  Select,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { customerservice, loanManagementUrl, setupManagementUrl } from "../../config";
import {
  executeAnyMutation,
  fetchJsonDynamic,
} from "../../data/DynamicServiceNew/jsondynamicservice";
import DynamicTableForJson from "../../data/DynamicTable/dynamicTableForJson";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const GroupLoanApprovalDialog = ({ open, onClose, Row }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [amount, setAmount] = useState("");
  const [loanProductSelected, setLoanProductSelected] = useState([]);
  const [loanProduct, setLoanProduct] = useState([]);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [gurantorDetails, setGuarantorDetails] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  useEffect(() => {
    const fetchData = async () => {
      // const params = { customerId: parseInt(Row.Id) };
      // const businessDetails = await fetchJsonDynamic(
      //   "customerBusinessDetailsById",
      //   "customerBusinessDetails",
      //   params,
      //   customerservice.uri
      // );
      // const allBusinessDetails = JSON.parse(businessDetails.results) || [];
      // const guarantorDetails = await fetchJsonDynamic(
      //   "customerBusinessDetailsById",
      //   "customerBusinessDetails",
      //   params,
      //   customerservice.uri
      // );
      // const allGuarantorDetails = JSON.parse(guarantorDetails.results) || [];
      const productResponse = await fetchJsonDynamic(
        "products",
        null,
        null,
        setupManagementUrl.uri
      );
      const allProducts = JSON.parse(productResponse.results) || [];

      setLoanProduct(allProducts);
    };
    fetchData();
  }, []);

  const handleApprove = async () => {
    try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: `Do you want to Approve this loan?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, approve it!`,
          cancelButtonText: "No, keep it",
          input: "text",
          inputPlaceholder: "Enter the reason for Approval",
          inputValidator: (value) => {
            if (!value) {
              return "You need to provide a reason for Approval!";
            }
          },
          didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }// Ensure the backdrop is displayed properly
        });
    
        if (confirmation.isConfirmed) {
          const mutationData = {
            loan_Id: Row.Id,
            flag: 1,
            comments: confirmation.value,
          };
    
          const response = await executeAnyMutation(
            "approveRejectGroupLoan",
            mutationData,
            "param",
            false,
            loanManagementUrl.uri
          );
    
          if (response.results) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: `Loan approved successfully.`,
              didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                  swalContainer.style.zIndex = '2000';
                }
              }
            });
            
            onClose();
          }
          else if (response.error)
          {
            throw new Error
          }
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while processing your request. Please try again later.",
          didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }
        });
      }
  };

  const handleReject = async () => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to reject this loan?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, reject it!`,
        cancelButtonText: "No, keep it",
        input: "text",
        inputPlaceholder: "Enter the reason for rejection",
        inputValidator: (value) => {
          if (!value) {
            return "You need to provide a reason for rejection!";
          }
        },
        didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          } // Ensure the backdrop is displayed properly
      });
  
      if (confirmation.isConfirmed) {
        const mutationData = {
          loan_Id: Row.Id,
          flag: 0,
          comments: confirmation.value,
        };
  
        const response = await executeAnyMutation(
          "approveRejectGroupLoan",
          mutationData,
          "param",
          false,
          loanManagementUrl.uri
        );
  
        if (response.results) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Loan rejected successfully.`,
            didOpen: () => {
                const swalContainer = document.querySelector('.swal2-container');
                if (swalContainer) {
                  swalContainer.style.zIndex = '2000';
                }
              }
          });
          onClose();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
        didOpen: () => {
            const swalContainer = document.querySelector('.swal2-container');
            if (swalContainer) {
              swalContainer.style.zIndex = '2000';
            }
          }
      });
    }
  };
  

  const productOptions = loanProduct
    ? loanProduct.map((loan) => ({
        key: loan.Id,
        value: loan.Id,
        label: loan.Name,
      }))
    : [];

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        disableEnforceFocus
        fullWidth={true}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            overflowY: "auto",
            maxHeight: "90vh",
            maxWidth: isSmallScreen ? "100vw" : "80vw",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Finance Manager Decision
            <Divider
              sx={{ borderBottomWidth: 2, border: "1px solid #003049" }}
              orientation="horizontal"
            />
          </Typography>
  
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledCard variant="outlined">
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Group Details</strong>
                </Typography>
                <Typography>Group Name: <strong>{Row.Name}</strong></Typography>
                <Typography>Group Code: <strong>{Row.Code}</strong></Typography>
                <Typography>Branch: <strong>{Row.BranchName}</strong></Typography>
                <Typography>
                  RO: <strong>{Row.FoFirstName} {Row.FoLastName}</strong>
                </Typography>
              </StyledCard>
  
              <StyledCard variant="outlined">
                <Typography
                  variant="h6"
                  sx={{ color: "#003049", fontWeight: "bold" }}
                >
                  Loan Details
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Loan amount: <strong>{Row.LoanAmount}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Installments: <strong>{Row.Installments}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Installment amount:{" "}
                        <strong>{Row.InstallmentAmount}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Interest: <strong>{Row.InterestAmount}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Product: <strong>{Row.ProductName}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Borrow date: <strong>{Row.ApplicationDate}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Loan Product</InputLabel>
                        <Select
                          value={loanProductSelected}
                          onChange={(e) => setLoanProductSelected(e.target.value)}
                          defaultValue=""
                        >
                          <MenuItem value="" disabled>
                            Select Loan Product
                          </MenuItem>
                          {productOptions.map((loan) => (
                            <MenuItem key={loan.value} value={loan.value}>
                              {loan.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </StyledCard>
  
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApprove}
                  fullWidth
                >
                  Approve
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleReject}
                  fullWidth
                >
                  Reject
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  };