import { gql } from "@apollo/client";

export const getrolesquery = `
   {
    allRoles {
      id
      role
      roleDescription
      roleGroupId
      roleGroup
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      
    }
  }
  `;
export const AllModules = `
    {
    allModules {
      id
        module
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        icon
        displayOrder
    }
  }
  `;
export const allCasesDynamic = `{
  allCasesDynamicView {
    assignedTo
    caseNumber
    caseStatus
    categoryName
    clientType
    companyName
    createdBy
    createdDate
    dateClosed
    dateOpened
    description
    id
    isActive
    isInvoiced
    priority
    statusFlag
    subcategoryName
    summary
    title
    updatedBy
    updatedDate
  }
}`;

export const caseCategory = `{
  allCaseCategories {
    id
    categoryName
    description
    companyId
    
  }
}`;
export const caseSubCategory = `{
{
  allCaseSubcategories {
    id
    categoryId
    subcategoryName
    description
    companyId
    
  }
}`;

export const caseEvents = `
{
  allCaseEvents {
    id
    caseId
    eventTypeId
    eventType
    eventDate
    outcome
    hearingDate
    description
    createdBy
    createdDate
    companyId
    
  }
}
`;

export const caseTasks = `{
  allCaseTaskView {
    caseNumber
    companyName
    createdBy
    createdDate
    description
    dueDate
    id
    isActive
    name
    statusFlag
    updatedBy
    updatedDate
  }
}`;
export const allFacts = `{
  allFactView {
    caseNumber
    categoryName
    companyName
    createdBy
    createdDate
    factDate
    factDescription
    id
    updatedBy
    updatedDate
  }
}`;

export const allCasesEvents = `{
  allCaseEventsView {
    caseNumber
    companyName
    createdBy
    createdDate
    description
    eventDate
    eventType
    hearingDate
    id
    outcome
  }
}`;

export const allEventTypes = ` {
  allEventTypes {
      id
      eventType
      createdDate
      createdBy
      updatedBy
      updatedDate
      companyId
      
  }
}
`;

export const allIndividualClients = `
{
  allCustomers {
        id
        customerName
        phoneNumber
        companyName
        companyId
        email
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        creditApproved
  }
}
`;

export const allCorporateClients = `
{
  allCorporateClients {
    id
    contactNumber
    email
    address
    dateAdded
    notes
    companyName
    registrationNumber
    industrySector
    contactPersonName
    contactPersonPosition
    contactPersonEmail
    contactPersonPhone
    companyAddress
    billingAddress
    incorporationDate
    legalStructure
    countryOfIncorporation
    taxIdentificationNumber
    authorizedSignatoryName
    authorizedSignatoryPosition
    authorizedSignatoryEmail
    authorizedSignatoryPhone
    isActive
    statusFlag
    createdBy
    createdDate
    updatedBy
    updatedDate
    companyId
    
  }
}
`;
export const allUsersQuery = `{
    allUsers {
      id
      firstName
      username
      lastName
      idno
      phone
      gender
      email
      isActive
      roleName
    }
  }`;

export const allCourts = ` {
  allCourts {
    id
    court
    courtTypeId
    courtType
    countryId
    country
    countyId
    county
    subCountyId
    subCounty
    latitude
    longitude
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    
  }
  }`;

export const allCompanies = `{
  companies {
    results
    error
  }
}`;
export const Products = `{
  products {
    error
    results
  }
}`
export const ProductChargesAccount = `{
   productChargesAccount {
    error
    results
  }
}`
export const ProductChargesByProductId = gql`
  query ProductChargesByProductId($productId: Int!) {
  productChargesByProductId(productId: $productId) {
    error
    results
  }
}
`;
export const ProductChargesAccountByProductId = gql`
   query ProductChargesByProductId($productId: Int!) {
    productChargesAccountsByProductId(productId: $productId) {
      results
      error
    }
  }
`;
export const ProductRepaymentFrequency = `{
  productRepaymentFrequency {
  error
  results
  }
}`
export const ProductRepaymentType = `{
  productRepaymentType {
    error
    results
  }
}`
export const allCounties = `{
    allCounties {
      id
      county
      countyCode
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }`;

export const allAccountTypes = ` 
  {
  allAccountTypes {
      id
      accountType
      
      companyId
    }
  } `;
export const allAccountCategories = `{
  accountCategory {
      results
      error
      
    }
  }
  `;
export const allAccountSubCategories = {
    query: `
      {
        accountSubCategory {
          results
          error
        }
      }
    `
  };
  export const allProductChargesAccounts = {
    query: `
    {
    productChargesAccount {
        results
        error
    }
  }
    `};
    export const allInterestPaymentMethods = {
      query: `
      {
      interestPaymentMethods {
        error
        results
      }
    }
      `};

export const allAccounts = ` {
  allAccounts {
      id
      status
      accountName
      accountNumber
      accountType
      companyId
      
      currencyId
      currency
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
  `;
export const allSuspendedTransactions = ` {
   suspendedTxnsByCompanyId {
        id
        billRefNumber
        businessShortCode
        firstName
        lastName
        invoiceNumber
        middleName
        msisdn
        orgAccountBalance
        thirdPartyTransID
        transactionType
        transAmount
        transID
        transTime
        isReconcilled
        companyId
    }
    }
    `;
export const allPaymentTypes = `{
  allPaymentTypes {
      id
      paymentType
      
      companyId

    }
  }
  `;
export const allBanks = `{
  allBanks {
      id
      bankName
      bankSwiftCode
      finCode
      mobile
      createdDate
      createdBy
      email
      companyId
      
    }
  }

  `;
export const allBankBranches = `{
  allBankBranches {
      id
      bankId
      branchCode
      branchName
      branchSwiftCode
      createdDate
      createdBy
      companyId
      
    }
  }

  `;
export const allBankBranchView = `{
    allBankBranches {
        id
        bankId
        branchCode
        branchName
        branchSwiftCode
        createdDate
        createdBy
        companyId
        
    }
}`;
export const allBankAccounts = `{
  allBankBranches {
      id
      bankId
      branchCode
      branchName
      branchSwiftCode
      createdDate
      createdBy
      companyId
      
    }
  }
  `;
export const allBanksBankBranchesAccountView = `{
    allBanksBranchAccountView {
      accountDescription
      accountName
      accountNumber
      bankName
      branchName
      id
      status
    }
  }`;
export const allFiscalYears = `{
  allFiscalYears {
      id
      fiscalYearName
      startDate
      endDate
      status
      createdDate
      createdBy
      companyId
      
    }
  }

  `;
export const allTaxAuthorities = `{
  allTaxAuthoritiesview {
    country
    id
    jurisdiction
    taxAuthorityName
  }
  }
  `;
export const allTaxRates = `{
  allTaxRatesView {
    country
    id
    name
    rate
    taxAuthorityName
  }
  }
  `;

export const allJudgesQuery = `
  {
  allJudges {
      
      companyId
      judgeId
      name
    }
  }
  `;

export const allCountries = `{
    allCountries {
      id
      country
      countryInitials
      createdDate
      createdBy
      updatedBy
      updatedDate
    }
  }`;
export const allRegions = `{
  region{
    id
    regionName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    
  }
}
`;
export const allBranches = `
{
  branch {
    id
    branchName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    
    regionId
    region
  }
}
`;

export const allCompanyBranches = `
{
  allBranchesByColumnValues(branchModel: { companyId: 1 }) {
    id
    branchName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    
    regionId
    region}
}
`;

export const allCompanyBranchesView = `{
    allBranchView {
        id
        branchName
        createdDate
        createdBy
        updatedBy
        companyId
        company
        regionId
        region
    }
}
`;
export const allDepartments = `{
  allDepartments {
    id
    departmentName
    createdDate
    createdBy
    updatedBy
    updatedDate
    companyId
    
  }
}`;
export const allAddOns = `
    {
  allAddOns {
    id
    addOnName
    description
    price
    isActive
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}
`;
export const allCompanyAddOns = `
  {
  allCompanyAddOns {
    id
    companyId
    addOnId
    addOn
    purchasedDate
    
    createdDate
    createdBy
    updatedBy
    updatedDate
  }
}

`;
export const allSubscriptionPlans = {
  query: `
      {
        allSubscriptionPlans {
          results
          error
        }
      }
    `

};
export const allStorageUnits = `
{
  allStorageUnits {
    id
    unit
    description
  }
}

`;
export const allSmsSettings = ` {
    smsSettings {
        companyId
        
        active
        promotionalAmount
        transactionalAmount
        smsLimit
    }
  }
`;
export const notificationsAndCount = `{
    notificationsByUser {
        count
        notifications {
            notificationId
            userId
            notificationType
            body
            templateId
            isRead
            createdDate
            companyId
            company
        }
    }
}
`;

export const allCaseTask = `{
    allCaseTasks {
      id
      caseId
      asigneeId
      description
      isActive
      statusFlag
      createdBy
      createdDate
      updatedBy
      updatedDate
      companyId
      
    }
  }`;
export const allCompanyEmailSettings = `{
  companyEmailSettingsById {
    companyId
    companyName
    companyEmail
    contactEmail
    contactEmailPassword
    emailClient
    emailClientPortNumber
    emailDisplayName
  }
}`;
export const GET_COMPANY_EMAIL_SETTINGS = gql`
  query CompanyEmailSettingsById($companyId: Int!) {
    companyEmailSettingsById(companyId: $companyId) {
      results {
        CompanyEmail
        ContactEmailPassword
        EmailClient
        EmailDisplayName
        EmailClientPortNumber
      }
      error
    }
  }
`;

export const allCourtAssignments = `{
    allCourtAssignments {
        id
        caseId
        courtId
        assignedDate
        createdBy
        createdDate
        companyId
        
    }
}`;

export const allCaseEvents = `{
    allCaseEvents {
        id
        caseId
        eventTypeId
        eventType
        eventDate
        outcome
        hearingDate
        description
        createdBy
        createdDate
        companyId
        
    }
}
`;

export const allJudgeAssignments = ` {
    allJudgeAssignments {
        id
        caseId
        judgeId
        assignedDate
        createdBy
        createdDate
        companyId
        
    }
}
`;

export const allTeamAssignment = `{
    allTeamAssignmentView {
      assignedDate
      caseNumber
      companyName
      createdBy
      createdDate
      id
      isActive
      statusFlag
      updatedBy
      updatedDate
      userName
    }
  }`;
export const allCaseStatus = ` {
    allCaseStatus {
        id
        status
    }
}`;
export const mainDashboardQuery = `
  {
    companySummary {
        team
        numberOfCases
        numberOfCourtCases
        numberOfCorporateClients
        numberOfIndividualClients
        numberOfCaseEvents
        numberOfCaseTasks
        numberOfNewClients
        corporateClientPercentage
        individualClientPercentage
        courtCasePercentage
        casesClosed
        matterCompletion
        taskCompletion
        }

}

`;
export const allCasesView = `{
    allCasesView {
        id
        clientType
        caseNumber
        title
        description
        summary
        categoryName
       clientTypeId
        subcategoryName
        caseStatus
        dateOpened
        dateClosed
        priority
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        isInvoiced
        companyId
        
        regionId
        branchId
        individualFirstName
        individualLastName
        corporateCompanyName
        workFlow
        name
        clientId
        caseCategoryId
        caseSubCategoryId
    }
}`;
export const caseTaskForDashboard = `{
    recentCasesDynamic {
        id
        caseId
        asigneeId
        caseNumber
        title
        firstName
        lastName
        description
        caseDescription
        isActive
        done
        statusFlag
        dueDate
        createdDate
        regionId
        branchId
        companyId
        
    }
}`;

export const caseMonthlyCasesStatististics = `
{
  monthlyStatisticsByYear(year: 2024) {
    assignedCases
    assignedCasesColor
    closedCases
    closedCasesColor
    inprogressCases
    inprogressCasesColor
    month
    newCases
    newCasesColor
  }
}
`;
export const getStorageData = `
{
    storageData {
        storage
        value
        label
        color
    }
}`;

export const allAdhocSmS = `
{
    smSesByCompany {
        messageId
        phoneNumber
        body
        sentDateTime
        status
     
     
    }
}
`;
export const allAdhocEmails = `{
    emailsByCompany {
        messageId
        recipientEmail
        subjectTitle
        body
        sentDateTime
        status
     
    }
}
`;

export const allGenders = `
{
    allGenders {
        id
        gender
        createdDate
        createdBy
        updatedBy
        updatedDate
    }
}`;
export const allSubRights = `
 {
    allSubRights {
        id
        title
        icon
        action
        rightId
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
        
    }
}`;
export const allRights = `{
    allRights {
       id
        title
        icon
        action
        moduleId
        module
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        adminOnly
        
    }
}`;
export const allRoleGroups = `{
    allRoleGroups {
        id
        roleGroup
        isActive
        statusFlag
        createdBy
        createdDate
        updatedBy
        updatedDate
        companyId
             
    }
}
`;

export const recentCaseTasks = `
{
    recentCaseTasks(taskModel: { companyId: null }) {
        id
        description
        caseId
        caseTitle
        assigneeFirstName
        assigneeLastName
        isActive
        statusFlag
        dueDate
        createdDate
        asigneeId
        createdBy
        companyId
        done
    }
}`;
export const allProcessFlows = `{
    allRoleGroups {
        id
        roleGroup
     
    }
}
`;
export const allReportTypes = `
{
    reportTypes {
        id
        majorClassificationID
        majorClassification
        minorClassificationID
        minorClassification
        reportName
        createdBy
        createdDate
        updatedBy
        updatedDate
    }
}
`;
export const allReportsMajorClassifications = `
    {
     reportsMajorClassifications {
    error
    results
  }
  }
  `;

export const allReportMinorClassifications = `
{
   reportsMinorClassifications {
    error
    results
  }
}`;
export const IntervalsQuery = `{
    intervals {
        id
        intervalName
        interval
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
    }
}
`;
export const PaymentMethodsQuery = `
{
    paymentMethods {
        id
        methodName
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
    }
}
`;
export const UOMQuery = `
{
    unitOfMeasure {
        id
        name
        unit
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
    }
}`;

export const AllVendors = `
{
    allVendors {
        id
        vendorName
        contactPerson
        phoneNumber
        email
        address
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        companyId
    }
}`;

export const AllSaleOrders = `
{
   allSaleOrdersView {
        id
        saleDate
        customerId
        customerName
        phoneNumber
        paymentMethodId
        paymentMethod
        isPaid
        createdDate
        createdBy
        updatedDate
        updatedBy
        companyId
        companyName
        cancelled
        customerBalance
        outstanding
        totalAmount
        isCredit
        isInvoiced
    }
}`;

/*
once published
add
to allsaleOrdersView
customerBalance
*/

export const AllSaleOrderItemsViews = `
 {
    allSaleOrderItemViews {
        id
        saleOrder
        customerId
        customerName
        productId
        productName
        amount
        uomId
        unitofMeasure
        quantity
        companyId
        companyName
        createdBy
        updatedBy
        createdDate
        updatedDate
    }
}

`;

export const AllPurchaseOrders = `
{
  allPurchaseOrders {
        id
        vendorId
        orderDate
        totalAmount
        paymentStatus
        deliveryStatus
        createdDate
        createdBy
        updatedDate
        updatedBy
        companyId
        isActive
    }
}
`;
export const AllPurchaseOrdersView = `
{
    allPurchaseOrdersView {
        id
        vendorId
        vendorName
        outstandingBalance
        orderDate
        paymentStatus
        deliveryStatus
        totalAmount
        createdDate
        createdBy
        updatedDate
        updatedBy
        companyId
        companyName
        isActive
    }
}

`;
export const AllPurchaseOrderItemsViews = `
{
  allPurchaseOrderItemViews {
      id
      purchaseOrderId
      productId
      productName
      orderDate
      amount
      uomId
      unitOfMeasure
      quantity
      createdDate
      createdBy
      updatedDate
      updatedBy
      isActive
      companyId
      companyName
  }
}
`;
export const inventoryQuery = `
{
    allInventoryView {
        id
        productId
        productName
        unitPrice
        costPrice
        quantity
        reorderPoint
        lastUpdated
        createdDate
        createdBy
        updatedDate
        updatedBy
        isActive
        companyId
        companyName
    }
}
`;
export const AllProducts = `
{
allProducts {
      id
      productName
      uomId
      unitPrice
      costPrice
      isActive
      reorderPoint
      isInventory
      createdDate
      createdBy
      updatedDate
      updatedBy
  }
}`;

export const AlProducts = `{
  allProducts {
      id
      productName

      unitPrice
      costPrice
      isActive
      reorderPoint
      isInventory
      createdDate
      createdBy
      updatedDate
      updatedBy
  }
}
`;

//Dashboard Data
export const SalesCard = `{
    salesCard {
        percentageChange
        currentMonth
        lastMonth
    }
}`;

export const CustomersCard = `{
  customersCard {
        percentageChange
        currentMonth
        lastMonth
    }
}`;

export const ExpensesCard = `{
   expensesCard {
        percentageChange
        currentMonth
        lastMonth
    }
}`;

export const RevenueCard = `{
  revenueCard {
        percentageChange
        currentMonth
        lastMonth
    }
}`;

export const RevenueTrend = `{
 revenueTrend {
        value
        monthName
    }
}`;

export const SalesTrend = `{
   salesTrend {
        value
        monthName
    }
 }`;

export const SoldProductsChart = `{
  soldProductsChart {
        value
        name
    }
}`;
export const AllProductViews = `{
  allProductViews {
      id
      productName
      productCategory
      unitPrice
      costPrice
      isActive
      reorderPoint
      isInventory
      createdDate
      createdBy
      updatedDate
      updatedBy
      isHotel
      companyId
      companyName
      uomId
      unitofMeasure
      inventoryQuantity
  }
}`;
