import React from "react";
import { Box } from "@mui/material";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
import { AccountsandFinanceurl } from "../../../../config";
import SuspendedTransactionForm from "./form";

const base_url = AccountsandFinanceurl.uri;

const SuspendedTransactionsTable = () => {

  const actions = {
    entity: "Suspended Transactions",
    isSubright: true,
    edit: {
      key: "EDIT",
      button_name: "Reconcile Transaction",
      Show_Button: true,
      permission: "EditPermission",
    },
    dataObject: {
      ignoreFields: ["CreateDate", "UpdateDate", "IsActive"],
      objectName: "suspendedTxnsByCompanyId",
      parameters: null,
      inputObjectName: null,
    },
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Suspended Transactions"
        subtitle="Manage Suspended Transactions"
        FormComponent={SuspendedTransactionForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default SuspendedTransactionsTable;
