import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../../../data/Axios/DynamicForm";
import { executeAnyMutation } from "../../../../../data/DynamicServiceNew/jsondynamicservice";
import { customerservice } from "../../../../../config";
import { groupRoleFields } from "../../../../../data/DynamicForm/FormFields/GroupFormFields";

const base_url = customerservice.uri;

const GroupRoleForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const groupId= parseInt(localStorage.getItem("GroupId"))
  const userId = parseInt(decodedToken.Id);
  const [loading, setLoading] = useState(true);

  const initialValues = {
    id: props.data ? props.data.Id : "",
    roleName: props.data ? props.data.RoleName : "",
    uniqueRole: props.data ? props.data.UniqueRole : false,
    isActive: props.data ? props.data.IsActive : true,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        roleName,
        uniqueRole,
        isActive,
      } = values;

      const mutationData = {
        groupId: parseInt(groupId),
        roleName,
        uniqueRole,
        isActive,
        createdBy: userId,
      };

      if (props.isEditing) {
        mutationData.id = props.data.Id;
        mutationData.updatedBy = userId;
      }

      const response = await executeAnyMutation(
        props.isEditing ? "updateGroupRole" : "createGroupRole",
        mutationData,
        "groupRole",
        false,
        base_url
      );

      if (response.results) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Group Role ${props.isEditing ? "updated" : "created"} successfully.`,
        });
      } else if (response.error) {
        throw new Error(response.error);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={groupRoleFields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Group Role" : "Create Group Role"}
      subtitle={props.isEditing ? "Edit an existing Group Role" : "Create a new Group Role"}
    />
  );
};

export default GroupRoleForm;
