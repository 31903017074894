import React, { useEffect, useState, PureComponent } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Select,
  FormControl,
  InputLabel,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Sector,
  Cell,
  AreaChart,
  Area,
  ComposedChart,
} from "recharts";
import { companyManagementUrl, customerservice } from "../../config";
import { fetchJsonDynamic } from "../../data/DynamicServiceNew/jsondynamicservice";
import { tokens } from "../../theme";

const base_url = companyManagementUrl.uri;

// Sample data for the charts
const sampleData = [
  {
    BranchName: "Westlands",
    ClearedPercentage: 50,
    ArrearsPercentage: 30,
    NonPerformingPercentage: 20,
    TotalLoans: 3,
  },
  {
    BranchName: "CBD",
    ClearedPercentage: 40,
    ArrearsPercentage: 35,
    NonPerformingPercentage: 25,
    TotalLoans: 5,
  },
];

const AnalyticalDashboard = () => {
  const theme = useTheme();
  const [productPopularityData, setProductPopularityData] = useState([]);
  const [loanPerformanceData, setLoanPerformanceData] = useState([]);
  const [groupProductPopularityData, setGroupProductPopularityData] = useState(
    []
  );
  const [groupLoanPerformanceData, setGroupLoanPerformanceData] = useState([]);
  const [maritalStatusDistributionData, setMaritalStatusDistributionData] =
    useState([]);
  const [businessTypesBarchartData, setBusinessTypesBarchartData] = useState(
    []
  );
  const [genderDistributionByRranchData, setGenderDistributionByRranchData] =
    useState([]);
  const [ageDistributionData, setAgeDistributionData] = useState([]);
  const [genderDistributionData, setGenderDistributionData] = useState([]);
  const [educationLevelDistributionData, setEducationLevelDistributionData] =
    useState([]);
  const [ageGenderDistributionData, setAgeGenderDistributionData] = useState(
    []
  );
  const [newRepeatCustomersByGenderData, setNewRepeatCustomersByGenderData] =
    useState([]);
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupBranchId, setGroupBranchId] = useState(null);
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    async function fetchAllData() {
      try {
        // Fetch regions data

        // Fetch branches data
        const loanPerformanceDataResponse = await fetchJsonDynamic(
          "loanPerformance",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (loanPerformanceDataResponse?.results) {
          const results = JSON.parse(loanPerformanceDataResponse.results);
          setLoanPerformanceData(results || []);
        }

        const groupLoanPerformanceDataResponse = await fetchJsonDynamic(
          "groupLoanPerformance",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (groupLoanPerformanceDataResponse?.results) {
          const results = JSON.parse(groupLoanPerformanceDataResponse.results);
          setGroupLoanPerformanceData(results || []);
        }
        const maritalStatusDistributionDataResponse = await fetchJsonDynamic(
          "maritalStatusDistribution",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (maritalStatusDistributionDataResponse?.results) {
          const results = JSON.parse(
            maritalStatusDistributionDataResponse.results
          );
          setMaritalStatusDistributionData(results || []);
        }

        const businessTypesBarchartDataResponse = await fetchJsonDynamic(
          "businessTypesBarchart",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (businessTypesBarchartDataResponse?.results) {
          const results = JSON.parse(businessTypesBarchartDataResponse.results);
          setBusinessTypesBarchartData(results || []);
        }

        const genderDistributionByRranchDataResponse = await fetchJsonDynamic(
          "genderDistributionByRranch",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );

        if (genderDistributionByRranchDataResponse?.results) {
          const rawData = JSON.parse(
            genderDistributionByRranchDataResponse.results
          );

          // Transform the data for the stacked bar chart
          const transformedData = rawData.reduce((acc, curr) => {
            const branch = acc.find((b) => b.BranchName === curr.BranchName);
            if (branch) {
              branch[curr.GenderName] = curr.Value;
            } else {
              acc.push({
                BranchName: curr.BranchName,
                [curr.GenderName]: curr.Value,
              });
            }
            return acc;
          }, []);

          setGenderDistributionByRranchData(transformedData || []);
        }

        const ageDistributionDataResponse = await fetchJsonDynamic(
          "ageDistribution",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (ageDistributionDataResponse?.results) {
          const results = JSON.parse(ageDistributionDataResponse.results);
          setAgeDistributionData(results || []);
        }

        const genderDistributionDataResponse = await fetchJsonDynamic(
          "genderDistribution",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (genderDistributionDataResponse?.results) {
          const results = JSON.parse(genderDistributionDataResponse.results);
          setGenderDistributionData(results || []);
        }

        const educationLevelDistributionDataResponse = await fetchJsonDynamic(
          "educationLevelDistribution",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (educationLevelDistributionDataResponse?.results) {
          const results = JSON.parse(
            educationLevelDistributionDataResponse.results
          );
          setEducationLevelDistributionData(results || []);
        }

        const ageGenderDistributionDataResponse = await fetchJsonDynamic(
          "ageGenderDistribution",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (ageGenderDistributionDataResponse?.results) {
          const results = JSON.parse(ageGenderDistributionDataResponse.results);

          const transformedData = results.reduce((acc, curr) => {
            const age = acc.find((b) => b.AgeRange === curr.AgeRange);
            if (age) {
              age[curr.Gender] = curr.Value;
            } else {
              acc.push({
                AgeRange: curr.AgeRange,
                [curr.Gender]: curr.Value,
              });
            }
            return acc;
          }, []);
          setAgeGenderDistributionData(transformedData || []);
        }

        const newRepeatCustomersByGenderDataResponse = await fetchJsonDynamic(
          "newRepeatCustomersByGender",
          "dashboardMetrics",
          {
            companyId: null,
          },
          base_url
        );
        if (newRepeatCustomersByGenderDataResponse?.results) {
          const results = JSON.parse(
            newRepeatCustomersByGenderDataResponse.results
          );
          const transformedData = results.reduce((acc, curr) => {
            const type = acc.find((b) => b.CustomerType === curr.CustomerType);
            if (type) {
              type[curr.Gender] = curr.Value;
            } else {
              acc.push({
                CustomerType: curr.CustomerType,
                [curr.Gender]: curr.Value,
              });
            }
            return acc;
          }, []);
          setNewRepeatCustomersByGenderData(transformedData || []);
        }
        // Fetch branches data
        const branchDataResponse = await fetchJsonDynamic(
          "branch",
          null,
          null,
          base_url
        );
        if (branchDataResponse?.results) {
          const results = JSON.parse(branchDataResponse.results);
          setBranchData(results || []);
        }
        const groupDataResponse = await fetchJsonDynamic(
          "allGroups",
          null,
          null,
          customerservice.uri
        );
        if (groupDataResponse?.results) {
          const results = JSON.parse(groupDataResponse.results);
          setGroupData(results || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);

  const fetchProductPopularity = async () => {
    try {
      const productPopularityDataResponse = await fetchJsonDynamic(
        "productPopularity",
        "dashboardMetrics",
        {
          companyId: null,
          branchId: branchId,
        },
        base_url
      );
      if (productPopularityDataResponse?.results) {
        const results = JSON.parse(productPopularityDataResponse.results);
        setProductPopularityData(results || []);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchProductPopularity();
  }, [branchId]);
  const fetchGroupProductPopularity = async () => {
    try {
      const groupProductPopularityDataResponse = await fetchJsonDynamic(
        "groupProductPopularity",
        "dashboardMetrics",
        {
          companyId: null,
          branchId: groupBranchId,
          groupId: groupId,
        },
        base_url
      );
      if (groupProductPopularityDataResponse?.results) {
        const results = JSON.parse(groupProductPopularityDataResponse.results);
        setGroupProductPopularityData(results || []);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };
  useEffect(() => {
    fetchGroupProductPopularity();
  }, [groupBranchId, groupId]);
  const selectStyle = {
    height: "100%", // Matches Paper height
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      padding: "8px", // Controls internal Select padding
    },
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const branchOptions = branchData.map((branch) => ({
    value: branch.Id,
    label: branch.Name,
  }));
  const groupOptions = groupData.map((group) => ({
    value: group.Id,
    label: group.Name,
  }));
  const handleBranchChange = (event) => {
    const selectedBranchId = event.target.value;
    setBranchId(selectedBranchId);
  };
  const handleBranchGroupChange = (event) => {
    const selectedGroupBranchId = event.target.value;
    setGroupBranchId(selectedGroupBranchId);
    setGroupId(null); // Reset field officer on branch change
  };
  const handleGroupChange = (event) => {
    const selectedGroupId = event.target.value;
    setGroupId(selectedGroupId);
  };
  return (
    <Box sx={{ padding: theme.spacing(3) }}>
      <Typography variant="h4" gutterBottom>
        Analytical Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Product Popularity (Branch Selection)
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <FormControl sx={{ flex: 1, minWidth: 100 }}>
                <InputLabel>Select Branch...</InputLabel>
                <Select
                  label="Select Branch..."
                  value={branchId}
                  onChange={handleBranchChange}
                >
                  {branchOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={productPopularityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="ProductName" />
                <YAxis
                  label={{
                    value: "Count",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar type="monotone" dataKey="UsageCount" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Loan Performance per Branch
            </Typography>
            <ResponsiveContainer width="100%" height={270}>
              <ComposedChart data={loanPerformanceData}>
                {/* Grid */}
                <CartesianGrid strokeDasharray="3 3" />

                {/* X-Axis */}
                <XAxis dataKey="BranchName" />

                {/* Left Y-Axis for Percentages */}
                <YAxis
                  yAxisId="left"
                  label={{
                    value: "Percentage (%)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />

                {/* Right Y-Axis for Total Loans */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Total Loans",
                    angle: -90,
                    position: "insideRight",
                  }}
                />

                {/* Tooltip */}
                <Tooltip
                  formatter={(value, name) =>
                    name === "Total Loans" ? value : `${value}%`
                  }
                />

                {/* Legend */}
                <Legend />

                {/* Stacked Bars for Percentages */}
                <Bar
                  yAxisId="left"
                  dataKey="ClearedPercentage"
                  stackId="a"
                  fill="#82ca9d"
                  name="Cleared (%)"
                />
                <Bar
                  yAxisId="left"
                  dataKey="ArrearsPercentage"
                  stackId="a"
                  fill="#ffc658"
                  name="Arrears (%)"
                />
                <Bar
                  yAxisId="left"
                  dataKey="NonPerformingPercentage"
                  stackId="a"
                  fill="#d84e47"
                  name="Non-Performing (%)"
                />

                {/* Line for Total Loans */}
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="TotalLoans"
                  stroke="#4e73df"
                  name="Total Loans"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Column 3 */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Group Product Popularity (Filtered by Branch and Group)
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <FormControl sx={{ flex: 1, minWidth: 100 }}>
                <InputLabel>Select Branch...</InputLabel>
                <Select
                  label="Select Branch..."
                  value={groupBranchId}
                  onChange={handleBranchGroupChange}
                >
                  {branchOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: 100 }}>
                <InputLabel>Select Group...</InputLabel>
                <Select
                  label="Select Group..."
                  value={groupId}
                  onChange={handleGroupChange}
                >
                  {groupOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={groupProductPopularityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="ProductName"
                  tickMargin={10} // Add margin between the X-Axis and bars
                />
                <YAxis
                  label={{
                    value: "Count",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar type="monotone" dataKey="TotalCount" fill="#00C49F" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Row 2 - Repeat for more charts */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Group Loan Performance per Branch
            </Typography>
            <ResponsiveContainer width="100%" height={270}>
              <ComposedChart data={groupLoanPerformanceData}>
                {/* Grid */}
                <CartesianGrid strokeDasharray="3 3" />

                {/* X-Axis */}
                <XAxis dataKey="BranchName" />

                {/* Left Y-Axis for Percentages */}
                <YAxis
                  yAxisId="left"
                  label={{
                    value: "Percentage (%)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />

                {/* Right Y-Axis for Total Loans */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Total Loans",
                    angle: -90,
                    position: "insideRight",
                  }}
                />

                {/* Tooltip */}
                <Tooltip
                  formatter={(value, name) =>
                    name === "Total Loans" ? value : `${value}%`
                  }
                />

                {/* Legend */}
                <Legend />

                {/* Stacked Bars for Percentages */}
                <Bar
                  yAxisId="left"
                  dataKey="ClearedPercentage"
                  stackId="a"
                  fill="#82ca9d"
                  name="Cleared (%)"
                />
                <Bar
                  yAxisId="left"
                  dataKey="ArrearsPercentage"
                  stackId="a"
                  fill="#ffc658"
                  name="Arrears (%)"
                />
                <Bar
                  yAxisId="left"
                  dataKey="NonPerformingPercentage"
                  stackId="a"
                  fill="#d84e47"
                  name="Non-Performing (%)"
                />

                {/* Line for Total Loans */}
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="TotalLoans"
                  stroke="#4e73df"
                  name="Total Loans"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Maritial Status Districbustion
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={maritalStatusDistributionData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={(entry) => `${entry.Name}: ${entry.Value}`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="Value"
                >
                  {maritalStatusDistributionData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Business Types
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={businessTypesBarchartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Gender Distribution Across Branches
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={genderDistributionByRranchData}>
                <XAxis dataKey="BranchName" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Female" stackId="a" fill="#8884d8" />
                <Bar dataKey="Male" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Gender Distribution
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={genderDistributionData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={(entry) => `${entry.Name}: ${entry.Value}`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="Value"
                >
                  {genderDistributionData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Age Distribution
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={ageDistributionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Value" stroke="#00c49f" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Education Levels
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={educationLevelDistributionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Age Gender Distribution
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={ageGenderDistributionData}>
                <XAxis dataKey="AgeRange" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Female" stackId="a" fill="#8884d8" />
                <Bar dataKey="Male" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              background: `${colors.primary[400]} !important`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              New or Repeat Customers by Gender
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={newRepeatCustomersByGenderData}>
                <XAxis dataKey="CustomerType" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Female" stackId="a" fill="#8884d8" />
                <Bar dataKey="Male" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticalDashboard;
