import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Button,
  IconButton,
  Divider,
  List,
  Menu,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemButton,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Area,
} from "recharts";
import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  AttachMoney as AttachMoneyIcon,
  Assessment as AssessmentIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  People as PeopleIcon,
  MonetizationOn as MonetizationOnIcon,
  AccountBalance as AccountBalanceIcon,
} from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { fetchExpensesData } from "../../../src/data/expensesData";
import { companyManagementUrl, userManagementUrl } from "../../config";
import {
  CustomersCard,
  ExpensesCard,
  RevenueCard,
  RevenueTrend,
  SalesCard,
  SalesTrend,
  SoldProductsChart,
} from "../../data/Axios/queries";
import { graphqlQuery } from "../../data/Axios/DynamicService";
import { tokens } from "../../theme";
import { fetchJsonDynamic } from "../../data/DynamicServiceNew/jsondynamicservice";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const base_url = companyManagementUrl.uri;

const CollectionAnalysis = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(theme.palette.mode);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const user = parseInt(decodedToken.RoleGroupId);
  const [branchData, setBranchData] = useState([]);
  const [fieldOfficerfData, setFieldOfficerData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [fieldOfficerId, setFieldOfficerId] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("last_7_days"); // Default to last 7 days
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [collectionTrend, setCollectionTrend] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const circleSize = isSmallScreen ? 150 : 200;

  // Fetch data on component mount
  useEffect(() => {
    async function fetchAllData() {
      try {
        // Fetch regions data
        const regionDataResponse = await fetchJsonDynamic(
          "region",
          null,
          null,
          base_url
        );
        if (regionDataResponse?.results) {
          const results = JSON.parse(regionDataResponse.results);
          setRegionData(results || []);
        }

        // Fetch branches data
        const branchDataResponse = await fetchJsonDynamic(
          "branch",
          null,
          null,
          base_url
        );
        if (branchDataResponse?.results) {
          const results = JSON.parse(branchDataResponse.results);
          setBranchData(results || []);
        }

        // Fetch field officers data (users with Role Group 5)
        const fieldOfficerDataResponse = await fetchJsonDynamic(
          "allUsersView",
          null,
          null,
          userManagementUrl.uri
        );
        if (fieldOfficerDataResponse?.results) {
          const results = JSON.parse(fieldOfficerDataResponse.results);

          // Filter users with RoleGroupId 5
          const fieldOfficersWithRole5 = results.filter(
            (user) => user.RoleGroupId === 5
          );
          setFieldOfficerData(fieldOfficersWithRole5 || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const fetchDashboardData = async () => {
    try {
      const dashboardDataResponse = await fetchJsonDynamic(
        "dashboardMetricsById",
        "dashboardMetrics",
        {
          companyid: null,
          regionid: regionId,
          branchid: branchId,
          fieldofficerid: fieldOfficerId,
        },
        base_url
      );
      if (dashboardDataResponse?.results) {
        const results = JSON.parse(dashboardDataResponse.results);
        setDashboardData(results || []);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const dashboardDataResponse = await fetchJsonDynamic(
        "collectionTrendById",
        "dashboardMetrics",
        {
          companyId: null,
          regionId: regionId,
          branchId: branchId,
          fieldOfficerId: fieldOfficerId,
          dateRange: timeRange,
          customStartDate: startDate ? startDate.format("YYYY-MM-DD") : null,
          customEndDate: endDate ? endDate.format("YYYY-MM-DD") : null,
        },
        base_url
      );
      if (dashboardDataResponse?.results) {
        const data = JSON.parse(dashboardDataResponse.results);
        setCollectionTrend(data || []);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [regionId, branchId, fieldOfficerId]);

  useEffect(() => {
    fetchData();
  }, [regionId, branchId, fieldOfficerId, timeRange, startDate, endDate]);

  const handleTimeRangeChange = (event) => {
    const selectedRange = event.target.value;
    setTimeRange(selectedRange);

    // Clear custom dates when switching away from custom range
    if (selectedRange !== "custom") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const paperStyle = {
    p: 1,
    display: "flex",
    alignItems: "center",
    height: 50, // Consistent height
    backgroundColor: `${colors.primary[400]} !important`,
  };

  const selectStyle = {
    height: "100%", // Matches Paper height
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      padding: "8px", // Controls internal Select padding
    },
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: `${colors.primary[500]} !important`,
    color: "white",
    padding: "0 10px",
    borderRadius: "4px",
    height: "100%", // Matches the Paper height
    marginRight: "10px",
  };
  // Mapping data for dropdown options
  const regionOptions = regionData.map((region) => ({
    value: region.Id,
    label: region.Name,
  }));

  const branchOptions = branchData.map((branch) => ({
    value: branch.Id,
    label: branch.Name,
  }));

  const fieldOfficerOptions = fieldOfficerfData.map((officer) => ({
    value: officer.Id,
    label: `${officer.FirstName} ${officer.LastName}`,
  }));

  const handleRegionChange = (event) => {
    const selectedRegionId = event.target.value;
    setRegionId(selectedRegionId);
    setBranchId(null); // Reset branch and field officer on region change
    setFieldOfficerId(null);
  };

  const handleBranchChange = (event) => {
    const selectedBranchId = event.target.value;
    setBranchId(selectedBranchId);
    setFieldOfficerId(null); // Reset field officer on branch change
  };

  const handleFieldOfficerChange = (event) => {
    const selectedFieldOfficerId = event.target.value;
    setFieldOfficerId(selectedFieldOfficerId);
  };
  const handleClearAll = () => {
    // Set each filter to its default value
    setRegionId(null); // or a default value like "default_region"
    setBranchId(null); // or a default value
    setFieldOfficerId(null); // or a default value
    setStartDate(null); // or a default date
    setEndDate(null); // or a default date

    // Trigger data fetching functions after resetting
    fetchDashboardData();
    fetchData();
  };

  const getPositiveProgressColor = (value) => {
    if (value <= 40) return "red";
    if (value <= 60) return "#d4a106";
    return "green";
  };

  return (
    <Box
      sx={{
        p: 3,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "16px",
      }}
    >
      <Grid container spacing={4}>
        {/* Quick Stats */}
        <Grid container spacing={4}>
          {/* Region Dropdown - Display for Role Groups 1, 2, and 3 */}
          {(user === 1 || user === 2 || user === 3) &&
            regionData.length > 0 && (
              <Grid item xs={12} md={3}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    height: 50, // Consistent height
                    backgroundColor: `${colors.primary[400]} !important`,
                  }}
                >
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Region
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Region...</InputLabel>
                    <Select
                      label="Select Region..."
                      value={regionId}
                      onChange={handleRegionChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {regionOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}

          {/* Branch Dropdown - Display for Role Groups 1, 2, and 3 */}
          {(user === 1 || user === 2 || user === 3) &&
            branchData.length > 0 && (
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={paperStyle}>
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Branches
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Branch...</InputLabel>
                    <Select
                      label="Select Branch..."
                      value={branchId}
                      onChange={handleBranchChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {branchOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}

          {/* Sales Rep (Field Officer) Dropdown - Display for Role Groups 1, 2, 3, and 4 */}
          {(user === 1 || user === 2 || user === 3 || user === 4) &&
            fieldOfficerfData.length > 0 && (
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    height: 50, // Ensures consistent height
                    backgroundColor: `${colors.primary[400]} !important`,
                  }}
                >
                  <Typography sx={labelStyle}>
                    <FilterAltIcon style={{ marginRight: "5px" }} />
                    Sales Reps
                  </Typography>
                  <FormControl sx={{ flex: 1, height: "100%" }}>
                    <InputLabel>Select Sales Rep...</InputLabel>
                    <Select
                      label="Select Sales Rep..."
                      value={regionId}
                      onChange={handleFieldOfficerChange} // Trigger region change
                      sx={selectStyle}
                    >
                      {fieldOfficerOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            )}
          {/* Clear All Button */}
          {user !== 5 && (
            <Grid item xs={12} md={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </Grid>
          )}
        </Grid>
        {/* Portfolio Overview Card */}
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 580,
              background: `${colors.primary[400]} !important`,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  {timeRange.replace("_", " ").toUpperCase()} Collection Trend
                </Typography>
                <Select
                  value={timeRange}
                  onChange={handleTimeRangeChange}
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="last_7_days">Last 7 Days</MenuItem>
                  <MenuItem value="last_30_days">Last 30 Days</MenuItem>
                  <MenuItem value="this_month">This Month</MenuItem>
                  <MenuItem value="custom">Custom Range</MenuItem>
                </Select>

                {timeRange === "custom" && (
                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) =>
                        setStartDate(newValue ? dayjs(newValue) : null)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) =>
                        setEndDate(newValue ? dayjs(newValue) : null)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                )}
              </Box>
            </LocalizationProvider>

            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={collectionTrend}>
                <defs>
                  <linearGradient
                    id="CollectedToday"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient
                    id="LoansDueToday"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="TotalArrears" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#d84e47" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#d84e47" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient
                    id="ArrearsCollectedToday"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#4e73df" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#4e73df" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient
                    id="CollectionRateTodayPercentage"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>

                <CartesianGrid strokeDasharray="3 3" />

                <XAxis
                  dataKey="PeriodLabel"
                  label={{
                    value: "Time",
                    position: "insideBottom",
                    offset: -5,
                  }}
                />

                <YAxis
                  yAxisId="left"
                  label={{
                    value: "Amount Collected",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />

                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Collection Rate (%)",
                    angle: -90,
                    position: "insideRight",
                  }}
                />

                <Tooltip />
                <Legend />

                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="CollectedToday"
                  stroke="#8884d8"
                  name="Amount Collected"
                  fillOpacity={1}
                  fill="url(#CollectedToday)"
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="LoansDueToday"
                  stroke="#ffc658"
                  name="Loans Due"
                  fillOpacity={1}
                  fill="url(#LoansDueToday)"
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="TotalArrears"
                  stroke="#d84e47"
                  name="Total Arrears"
                  fillOpacity={1}
                  fill="url(#TotalArrears)"
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="ArrearsCollectedToday"
                  stroke="#4e73df"
                  name="Arrears Collected"
                  fillOpacity={1}
                  fill="url(#ArrearsCollectedToday)"
                />
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="CollectionRateTodayPercentage"
                  stroke="#82ca9d"
                  name="Collection Rate (%)"
                  fillOpacity={1}
                  fill="url(#CollectionRateTodayPercentage)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {/* Products Sold Card */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 580,
              background: `${colors.primary[400]} !important`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: "0px 10px 10px 0px",
                  flexShrink: 0,
                }}
              >
                <Typography variant="h6">Today</Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  ml: 2,
                }}
              />
            </Box>

            {/* The box containing the circular progress and metrics */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Circular Progress (Top) */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  mb: isSmallScreen ? 2 : 0, // Margin bottom for spacing on small screens
                }}
              >
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  {/* Background Circle for Remaining Path */}
                  <CircularProgress
                    variant="determinate"
                    value={100} // Background circle covers the full circumference
                    size={circleSize} // Dynamic size based on screen
                    thickness={7} // Increase thickness
                    sx={{
                      color: "rgba(255, 255, 255, 0.4)", // Pale white for the background path
                      position: "absolute",
                    }}
                  />
                  {/* Progress Circle */}
                  <CircularProgress
                    variant="determinate"
                    value={
                      parseFloat(
                        dashboardData[0]?.CollectionRateTodayPercentage?.toFixed(
                          2
                        )
                      ) || 0
                    }
                    size={circleSize} // Match size with background circle
                    thickness={7} // Match thickness with background circle
                    sx={{
                      color: getPositiveProgressColor(
                        parseFloat(
                          dashboardData[0]?.CollectionRateTodayPercentage?.toFixed(
                            2
                          )
                        ) || 0
                      ),
                    }}
                  />
                  {/* Text inside the circle */}
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.CollectionRateTodayPercentage?.toFixed(
                              2
                            )
                          ) || 0
                        ),
                      }}
                    >
                      Collection Rate
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        color: getPositiveProgressColor(
                          parseFloat(
                            dashboardData[0]?.CollectionRateTodayPercentage?.toFixed(
                              2
                            )
                          ) || 0
                        ),
                      }}
                    >
                      {parseFloat(
                        dashboardData[0]?.CollectionRateTodayPercentage?.toFixed(
                          2
                        )
                      ) || 0}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Metrics List */}
              <Box sx={{ width: "100%" }}>
                <List sx={{ p: 0 }}>
                  <ListItemButton divider>
                    <ListItemText primary="Total Pending Due" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.LoansDueToday || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Amount Collected" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.CollectedToday || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Total Arrears" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.TotalArrears || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Arrears Collected" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.ArrearsCollectedToday || 0}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton divider>
                    <ListItemText primary="Prepayments Collected" />
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        px: 1,
                        borderRadius: "5px",
                      }}
                    >
                      {dashboardData[0]?.PrepaidLoansToday || 0}
                    </Typography>
                  </ListItemButton>
                </List>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CollectionAnalysis;
