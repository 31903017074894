// CourtTypeView.js
import React from "react";
import DynamicCard from "./MainCardComponent";
import AccountCategoriesTable from "../AccountsAndFinanceManagement/Tables/AccountCategories";
import AccountSubcategoriesTable from "../AccountsAndFinanceManagement/Tables/AcocuntSubcategories";
import SuspendedTransactionsTable from "../AccountsAndFinanceManagement/Tables/SuspendedTransactions";
import AccountsTable from "../AccountsAndFinanceManagement/Tables/Accounts";
import FiscalYearTable from "../AccountsAndFinanceManagement/Tables/FiscalYears";
import PaymentTypesTable from "../AccountsAndFinanceManagement/Tables/PaymentTypes";
import TaxAuthorityTable from "../AccountsAndFinanceManagement/Tables/TaxAuthorities";
import TaxRateTable from "../AccountsAndFinanceManagement/Tables/TaxRates";
import CompanyTable from "../CompanyManagement/Company";
import RegionTable from "../CompanyManagement/Regions";
import BranchTable from "../CompanyManagement/Branch";
import DepartmetsTable from "../CompanyManagement/Departments";
import AddOnsTable from "../CompanyManagement/Add-Ons";
import StorageUnitsTable from "../CompanyManagement/StoreageUnits";
import SmsSettings from "../CompanyManagement/SmsSettings";
import EmailSettingsForm from "../CompanyManagement/CompanyEmailSettings/EditableForm";
import MpesaCredentialsForm from "../CompanyManagement/MpesaSettings";
import IndustryType from "../SetupManagement/IndustryTypes";
import BusinessType from "../SetupManagement/BusinessTypes";
import ProductRepaymentTypeTable from "../SetupManagement/ProductRepaymentType";
import ProductRepaymentFrequencies from "../SetupManagement/ProductRepaymentFrequency";
import CustomerRelations from "../SetupManagement/CustomerRelationships";
import GenderTable from "../SetupManagement/Gender";
import SubscriptionPlansTable from "../SetupManagement/SubscriptionPlans";
import EducationLevelTable from "../SetupManagement/EducationLevel";
import ResidencePeriodTable from "../SetupManagement/ResidencePeriod";
import Marital from "../SetupManagement/MaritalStatus";
import UploadLogoTable from "../CompanyManagement/UploadLogo";
import CustomSMSPage from "../Adohc/promotions/customSMS";
import EmailsPage from "../Adohc/promotions/emails";
import ProcessTable from "../SetupManagement/Process";
import TransactionTypeTable from "../SetupManagement/TransactionType";
import CustomSmsRegex from "../Adohc/CustomSmsRegex";
import SingularMigrationPage from "../Customers/customers/customerMigartion/SingularMigration";
import BulkMigrationPage from "../Customers/customers/customerMigartion/BulkMigration";
import CustomerBranchMigrationPage from "../Customers/customers/customerMigartion/interBranchMigration";
import SingularGroupMigrationPage from "../Customers/customers/groupcustomerMigration/singularGroupFoMigration";
import GroupBranchMigrationPage from "../Customers/customers/groupcustomerMigration/GroupInterBranchMigration";
import BulkGroupMigrationPage from "../Customers/customers/groupcustomerMigration/BuikGroupMigration";
export const BusinessTypes = () => {
  return (
    <DynamicCard>
      <BusinessType />
    </DynamicCard>
  );
};

export const IndustryTypes = () => {
  return (
    <DynamicCard>
      <IndustryType />
    </DynamicCard>
  );
};

export const ProductRepaymentType = () => {
  return (
    <DynamicCard>
      <ProductRepaymentTypeTable />
    </DynamicCard>
  );
};

export const ProductRepaymentFrequency = () => {
  return (
    <DynamicCard>
      <ProductRepaymentFrequencies />
    </DynamicCard>
  );
};
export const CustomerRelationships = () => {
  return (
    <DynamicCard>
      <CustomerRelations />
    </DynamicCard>
  );
};

export const EducationLevel = () => {
  return (
    <DynamicCard>
      <EducationLevelTable />
    </DynamicCard>
  );
};
export const ResidencePeriod = () => {
  return (
    <DynamicCard>
      <ResidencePeriodTable />
    </DynamicCard>
  );
};
export const MaritalStatus = () => {
  return (
    <DynamicCard>
      <Marital />
    </DynamicCard>
  );
};

export const Gender = () => {
  return (
    <DynamicCard>
      <GenderTable />
    </DynamicCard>
  );
};

export const SubscriptionPlans = () => {
  return (
    <DynamicCard>
      <SubscriptionPlansTable />
    </DynamicCard>
  );
};

export const AccountCategory = () => {
  return (
    <DynamicCard>
      <AccountCategoriesTable />
    </DynamicCard>
  );
};
export const AccountSubcategory = () => {
  return (
    <DynamicCard>
      <AccountSubcategoriesTable />
    </DynamicCard>
  );
};
export const Accounts = () => {
  return (
    <DynamicCard>
      <AccountsTable />
    </DynamicCard>
  );
};
export const SuspendedTransactions = () => {
  return (
    <DynamicCard>
      <SuspendedTransactionsTable />
    </DynamicCard>
  );
};

export const FiscalYearsView = () => {
  return (
    <DynamicCard>
      <FiscalYearTable />
    </DynamicCard>
  );
};
export const PaymentTypesView = () => {
  return (
    <DynamicCard>
      <PaymentTypesTable />
    </DynamicCard>
  );
};
export const TaxAuthoritiesView = () => {
  return (
    <DynamicCard>
      <TaxAuthorityTable />
    </DynamicCard>
  );
};
export const TaxRatesView = () => {
  return (
    <DynamicCard>
      <TaxRateTable />
    </DynamicCard>
  );
};
export const Companies = () => {
  return (
    <DynamicCard>
      <CompanyTable />
    </DynamicCard>
  );
};

export const Regions = () => {
  return (
    <DynamicCard>
      <RegionTable />
    </DynamicCard>
  );
};

export const CompanyBranches = () => {
  return (
    <DynamicCard>
      <BranchTable />
    </DynamicCard>
  );
};

export const DepartmentView = () => {
  return (
    <DynamicCard>
      <DepartmetsTable />
    </DynamicCard>
  );
};

export const AddOnView = () => {
  return (
    <DynamicCard>
      <AddOnsTable />
    </DynamicCard>
  );
};

export const StorageUnitsView = () => {
  return (
    <DynamicCard>
      <StorageUnitsTable />
    </DynamicCard>
  );
};
export const SMSSettings = () => {
  return (
    <DynamicCard>
      <SmsSettings />
    </DynamicCard>
  );
};
export const MpesaCredentialsSettings = () => {
  return (
    <DynamicCard>
      <MpesaCredentialsForm />
    </DynamicCard>
  );
};
export const EmailSettings = () => {
  return (
    <DynamicCard>
      {/* // <CompanyEmailSettingsTable /> */}
      <EmailSettingsForm />
    </DynamicCard>
  );
};
export const Sms = () => {
  return (
    <DynamicCard>
      <CustomSMSPage />
    </DynamicCard>
  );
};
export const SmsPlaceholders = () => {
  <DynamicCard>
    <CustomSmsRegex />
  </DynamicCard>;
};
export const Emails = () => {
  return (
    <DynamicCard>
      <EmailsPage />
    </DynamicCard>
  );
};

export const AdhocSMSBulk = () => {
  return (
    <DynamicCard>
      <CustomSMSPage />
    </DynamicCard>
  );
};

export const UploadLogo = () => {
  return (
    <DynamicCard>
      <UploadLogoTable />
    </DynamicCard>
  );
};

export const Process = () => {
  return (
    <DynamicCard>
      <ProcessTable />
    </DynamicCard>
  );
};

export const TransactionType = () => {
  return (
    <DynamicCard>
      <TransactionTypeTable />
    </DynamicCard>
  );
};

export const SingularMigration = () => {
  return (
    <DynamicCard>
      <SingularMigrationPage />
    </DynamicCard>
  );
};

export const BulkMigration = () => {
  return (
    <DynamicCard>
      <BulkMigrationPage />
    </DynamicCard>
  );
};

export const CustomerBranchMigration = () => {
  return (
    <DynamicCard>
      <CustomerBranchMigrationPage />
    </DynamicCard>
  );
};

export const SingularGroupMigration = () => {
  return (
    <DynamicCard>
      <SingularGroupMigrationPage />
    </DynamicCard>
  );
};

export const BulkGroupMigration = () => {
  return (
    <DynamicCard>
      <BulkGroupMigrationPage />
    </DynamicCard>
  );
};

export const GroupBranchMigration = () => {
  return (
    <DynamicCard>
      <GroupBranchMigrationPage />
    </DynamicCard>
  );
};
