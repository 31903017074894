import React from "react";
import { Box } from "@mui/material";
import { loanManagementUrl } from "../../../config";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";

const base_url = loanManagementUrl.uri;

const PendingDisbursementsTable = () => {

  const isSent = {isSent: false }
  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    isSubright: true,
    initiateSababisha: {
      key: "INITIATESABABISHA",
      button_name: "Initiate Sababisha",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "param", // Specify the model name here
      inputObjectName: "sababishaFunction",
      flag: 0
    },
    approveSababisha: {
      key: "APPROVESABABISHA",
      button_name: "Approve Sababisha",
      Show_Button: true,
      permission: "ActivatePermission",
      modelName: "param", // Specify the model name here
      inputObjectName: "sababishaFunction",
      flag: 1
    },
    dataObject: {
      ignoreFields: ["CreateDate", "UpdateDate", "IsActive"],
      objectName: "disbursementViewByColumnValues",
      parameters: isSent,
      inputObjectName: "disbursementView",
    },
  };

  return (
    <Box m="20px">
      <DynamicTableForJson
        title="Pending Disbursements"
        subtitle="Sababisha"
        // FormComponent={SuspendedTransactionForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default PendingDisbursementsTable;
