import React from "react";
import { Box, Button } from "@mui/material";
import { customerservice, loanManagementUrl } from "../../../../config";
import { useNavigate } from "react-router-dom";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";

const base_url = loanManagementUrl.uri;

const CustomerLoanApplication = () => {
  const customerId = localStorage.getItem("CustomerId");
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const navigate = useNavigate();

  const actions = {
    entity: "Rights",
    isSubright: true,
    dataObject: {
      objectName: "loanApplicationByCustomer",
      parameters: { customerId: parseInt(customerId) },
      inputObjectName: "loans",
      ignoreFields: [
        "CustomerPhone",
        "UpdateDate",
        "CreateDate",
        "LoanApprovalDate",
        "LoanDisburseDate",
        "ExpectedClearDate",
        "LoanClearedDate",
        "RolledOver",
        "ProductId",
        "ArrearsOverDueDays",
        "PrincipleArrears",
        "InterestArrears",
        "IsNonPerforming",
        "RolloverPenalty",
        "IsWrittenOff",
        "LoanDeclineId",
        "IsLoanDeclined",
        "LoanDeclinedReason",
        "WrittenOffDate",
        "RolloverDate",
        "IsFieldOfficerApproved",
        "FieldOfficerComment",
        "IsBranchApproved",
        "BranchComments",
        "IsRegionApproved",
        "RegionComments",
        "IsCompanyApproved",
        "CompanyComments",
        "IsMigrated",
        "RemainingInstallments",
        "MigratedNextInstallmentDate",
        "IsRescheduled",
        "AccountId",
        "ServiceLoans",
        "IsRebooked",
        "ChannelId",
        "BranchId",
        "RegionId",
        "CompanyId",
        "FieldOfficerApprovedBy",
        "FieldOfficerApprovedDate",
        "BranchVerifiedBy",
        "BranchVerifiedDate",
        "CompanyApprovedBy",
        "CompanyApprovedDate",
        "MigratedBy",
        "MigratedDate",
        "DateCreated",
        "CreatedBy",
        "UpdatedDate",
        "UpdatedBy",
        "VerifiedBy",
        "VerifiedDate",
        "IsVerified",
      ],
    },
    showStatus: true,
  };

  return (
    <Box m="20px">
      <Box>
        <Button onClick={() => navigate(-1)} variant="contained">
          Back to Customers
        </Button>
      </Box>
      <></>
      <DynamicTableForJson
        title="Loans"
        subtitle="Manage Loans"
        // FormComponent={UploadStatementForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default CustomerLoanApplication;
