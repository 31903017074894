import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl } from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import {
  allAccountCategories,
  allAccountTypes,
  allAccounts,
  allPaymentTypes,
} from "../../../../data/Axios/queries";
import {
  AccountCategoriesColumns,
  AccountSubCategoriesColumns,
  AccountsColumns,
  PaymentTypesColumns,
} from "../../../../data/DynamicTable/AccountsColumns";
import AccountCategoryForm from "./form";
import AccountsForm from "./form";
import PaymentTypeForm from "./form";
const base_url = AccountsandFinanceurl.uri;
const actions = {
  entity: "Payment Types",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus:true,
};
function PaymentTypesTable() {
  return (
    <Box m="2px">
      <DynamicTable
        title="Payment Types"
        subtitle="Create, view or edit payment types
        "
        columns={PaymentTypesColumns}
        FormComponent={PaymentTypeForm}
        query={allPaymentTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default PaymentTypesTable;
