import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@mui/material";
import DynamicTableForJson from "../../../data/DynamicTable/dynamicTableForJson";
import {
  MinorClassificationColumns,
  RightsColumns,
} from "../../../data/DynamicTable/Collumns";
import {
  allReportMinorClassifications,
  allRights,
} from "../../../data/Axios/queries";
import {
  reportsManagementUrl,
  roleManagemenUrl,
  setupManagementUrl,
} from "../../../config";
import MinorReportForm from "./forms";

const base_url = reportsManagementUrl.uri;

const MinorClassification = () => {
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [details, setDetails] = useState("");
  const [darkMode, setIsDarkMode] = useState("true");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const actions = {
    entity: localStorage.getItem("SelectedRight"),
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    dataObject: {
      objectName: "reportsMinorClassifications",
      parameters: "",
    },
    showStatus: true,
  };

  return (
    <Box m="10px">
      <DynamicTableForJson
        title="Reports"
        subtitle="Minor Classifications"
        //columns={MinorClassificationColumns}
        FormComponent={MinorReportForm}
        query={allReportMinorClassifications}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default MinorClassification;
