import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl } from "../../../../config";
import AccountCategoryForm from "./form";
import DynamicTableForJson from "../../../../data/DynamicTable/dynamicTableForJson";
const base_url = AccountsandFinanceurl.uri;

function AccountSubcategoriesTable() {
  const actions = {
    entity: localStorage.getItem("SelectedTabLabel"),
    isSubRight: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    dataObject: {
      ignoreFields: [
        "AccountCategory",
        "Status",
        "CreatedBy",
        "UpdatedBy",
        "UpdatedDate",
        "CreatedDate",
        "CompanyId",
        "ParentAccount",
        "Company"
      ],
      objectName: "accountSubCategory",
      parameters: "",
    },
    showStatus:true,
  };
  return (
    <Box m="2px">
      <DynamicTableForJson
        title="Account Subcategories"
        subtitle="Create, view or edit account subcategories"
        FormComponent={AccountCategoryForm}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default AccountSubcategoriesTable;
